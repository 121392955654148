import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {NewsService} from '../../services/news.service';
import {take} from 'rxjs/operators';
import {News} from '../../../shared/models/entity/News';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class NewsResolver extends AbstractResolver<News> {

  constructor(spinnerService: SmartSpinnerService, private newsService: NewsService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<News> {
    const newsId = route.paramMap.get('newsId');
    return newsId && newsId !== 'new' ? this.newsService.getById(newsId).pipe(take(1)) : of(new News());
  }
}
