import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {Category} from '../../../../models/entity/classifications/Category';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClassificationService} from '../../../../../app-root/services/classification.service';

@Component({
  selector: 'a-select-category-id',
  templateUrl: './a-select-category-id.component.html',
  styleUrls: ['./a-select-category-id.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCategoryIdComponent),
      multi: true
    }
  ]
})
export class ASelectCategoryIdComponent implements OnInit {

  public categories: Array<Category>;
  public selectedCategoryId: string;
  private onNgChange: (values: string) => void;
  private onNgTouched: () => void;

  private _domainId: string;

  @Input()
  get domainId(): string {
    return this._domainId;
  }

  set domainId(domainId: string) {
    this._domainId = domainId;
    this.categories = [];
    if (domainId) {
      this.classificationService.findDomainId(domainId).subscribe(domain => {
        if (domain.categories) {
          this.categories = domain.categories;
          this.disabled = this.categories.length === 0;
        }
      });
    }

  }

  @Input()
  public name: string = 'category';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean = false;


  constructor(private classificationService: ClassificationService) {
  }

  ngOnInit(): void {
  }


  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(category: string): void {
    this.selectedCategoryId = category;
  }

  public onChange(category: string): void {
    this.selectedCategoryId = category;
    this.onNgChange(category);
  }
}
