import {Injectable} from '@angular/core';
import {CustomSearch} from '../../shared/models/entity/custom-menus/CustomSearch';
import {BehaviorSubject} from 'rxjs';

interface CustomSearchState { customSearch: CustomSearch; opened: boolean; }

@Injectable({
  providedIn: 'root'
})
export class SelectedMenuService {
  private customSearchSubject = new BehaviorSubject({customSearch: null, opened: false});

  constructor() { }

  public getCustomSearchSubject(): BehaviorSubject<CustomSearchState> {
    return this.customSearchSubject;
  }

  public publishCustomSearch(search: CustomSearch, opened = false) {
    this.customSearchSubject.next({customSearch: search, opened });
  }

  public resetSelected() {
    this.customSearchSubject.next({customSearch: null, opened: false});
  }
}
