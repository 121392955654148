import {Component} from '@angular/core';
import {PaginatedCompanies} from '../../../../shared/models/entity/paginated/paginated-entities/PaginatedCompanies';
import {ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: './companies-tab.component.html',
  styleUrls: ['./companies-tab.component.scss']
})
export class CompaniesTabComponent {

  public initialCompanies: PaginatedCompanies;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.initialCompanies = data['initialCompanies'];
    });
  }

}
