import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs';
import {ResponseTemplateService} from '../../services/responseTemplate.service';
import {ResponseTemplate} from '../../../shared/models/entity/ResponseTemplate';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class ResponseTemplateResolver extends AbstractResolver<ResponseTemplate> {

  constructor(spinnerService: SmartSpinnerService, private responseTemplateService: ResponseTemplateService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResponseTemplate> {
    const responseTemplateId = route.paramMap.get('responseTemplateId');
    return !responseTemplateId ?
      of(new ResponseTemplate()) : this.responseTemplateService.getById(responseTemplateId);
  }
}
