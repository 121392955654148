import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from '../../../app-root/services/session.service';
import { APopupComponent } from '../../../shared/lib-components/atoms/a-popup/a-popup.component';
import { SimpleTeam } from '../../../shared/models/entity/teams/simple-team';
import { CompleteCurrentUser } from '../../../shared/models/entity/users/CompleteCurrentUser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-team-duplicate-popup',
  templateUrl: './team-duplicate-popup.component.html',
  styleUrls: ['./team-duplicate-popup.component.scss']
})
export class TeamDuplicatePopupComponent implements OnInit, OnDestroy {

  @Input() public duplicateClicked = new EventEmitter<SimpleTeam>();

  @ViewChild('duplicateTeamPopup')
  duplicatePopup: APopupComponent;

  teamToDuplicate: SimpleTeam;
  currentUser: CompleteCurrentUser;
  private currentUserSub: Subscription;

  constructor(private router: Router,
              private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.duplicateClicked.subscribe((teamToDuplicate: SimpleTeam) => {
      this.teamToDuplicate = teamToDuplicate;
      this.duplicatePopup.open();
    });
    this.currentUserSub = this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  validateDuplicate(
    duplicateAgents: boolean,
    duplicateDomains: boolean,
    duplicateCompanies: boolean,
    duplicateEmails: boolean
  ) {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'teams', 'new'], {
      state: {
        teamIdToDuplicate: this.teamToDuplicate.id,
        duplicateAgents: duplicateAgents,
        duplicateDomains: duplicateDomains,
        duplicateCompanies: duplicateCompanies,
        duplicateEmails: duplicateEmails,
      }
    });
  }

  cancelDuplicate() {
    this.duplicatePopup.close();
  }

  ngOnDestroy(): void {
    this.currentUserSub.unsubscribe();
  }
}
