import {Type} from 'class-transformer';
import {ModificationInfo} from '../common/ModificationInfo';
import {SimpleDomain} from '../classifications/SimpleDomain';
import {SimpleCompany} from '../companies/SimpleCompany';

export class CompleteUserTeam {
  public id: string;
  @Type(() => ModificationInfo)
  public name: string;
  public agents: Array<string>;
  @Type(() => SimpleDomain)
  public domains: Array<SimpleDomain>;
  @Type(() => SimpleCompany)
  public companies: Array<SimpleCompany>;
  public emails;
}
