import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Company} from '../../../../models/entity/companies/Company';
import {Observable} from 'rxjs';
import {CollectionUtils} from '../../../../services/collection-utils';
import {finalize, tap} from 'rxjs/operators';

@Component({
  selector: 'a-select-company-async',
  templateUrl: './a-select-company-async.component.html',
  styleUrls: ['./a-select-company-async.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCompanyAsyncComponent),
      multi: true
    }
  ]
})
export class ASelectCompanyAsyncComponent implements ControlValueAccessor {

  @Input()
  public placeholderKey: string;

  @Input()
  placeholderKeyI18nParams: any;
  @Input()
  public companiesObs: Observable<Company[]>;
  public companiesLoading: boolean = false;
  public companies: Array<Company>;
  public selectedCompany: Company;
  public hideSelected: boolean = false;
  private onNgChange: (values: Company) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'companies';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  constructor() {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(company: Company): void {
    this.selectedCompany = company;
  }

  public onChange(company: Company): void {
    this.selectedCompany = company;
    this.onNgChange(company);
  }

  public compare(company1: Company, company2: Company): boolean {
    return company1 != null && company2 != null && company1.id === company2.id;
  }

  public openFired() {
    if (this.companiesLoading || CollectionUtils.notEmpty(this.companies)) { return; }
    this.fetch();
  }

  public fetch() {
    this.companiesLoading = true;
    if (!this.companiesObs) {
      console.error('[a-select-company-async] no companies observable provided');
      return;
    }
    this.companiesObs.pipe(
      finalize(() => this.companiesLoading = false),
      tap(companies => {
        if (CollectionUtils.notEmpty(companies)) { this.hideSelected = true; }
      })
    ).subscribe(companies => {
      this.companies = companies;
    });
  }

  public selectCompany(companyId: string) {
    this.companiesLoading = true;
    if (!this.companiesObs) {
      console.error('[a-select-company-async] no companies observable provided');
      return;
    }

    this.companiesObs
      .pipe(
        tap(companies => {
          if (CollectionUtils.notEmpty(companies)) { this.hideSelected = true; }
        }),
        finalize(() => this.companiesLoading = false),
      )
      .subscribe(companies => {
        this.companies = companies;
        this.selectedCompany = this.companies.find(el => el.id === companyId);
      })
  }
}
