import {Component, Input} from '@angular/core';
import {CompleteMessage} from '../../../models/entity/requests/CompleteMessage';
import {Role} from '../../../models/entity/enums/Role';
import {CompleteRequest} from '../../../models/entity/requests/CompleteRequest';
import {User} from "../../../models/entity/users/User";

@Component({
  selector: 'm-message',
  templateUrl: './m-message.component.html',
  styleUrls: ['./m-message.component.scss']
})
export class MMessageComponent {

  @Input()
  set message(message: CompleteMessage) {
    this._message = message;
    this.restrictedUsersFormatted = MMessageComponent.formatRestrictedUsers(message.restrictedUsers);
  }

  get message(): CompleteMessage {
    return this._message;
  }

  private _message: CompleteMessage;

  @Input()
  public request: CompleteRequest;

  public restrictedUsersFormatted: string = '';

  public getAuthorRole(): Role {
    return this.request.agents
      && this.request.agents.find(agent => agent.id === this._message.author.id) ?
      Role.AGENT : Role.USER;
  }

  private static formatRestrictedUsers(users: User[]): string {
    const names = users.map(u => u.fullName);
    return names.join(', ');
  }
}
