import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {NewsService} from '../../services/news.service';
import {PaginatedNews} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedNews';
import {NewsSearchCriteria} from '../../../shared/models/criterias/NewsSearchCriteria';
import {SessionService} from '../../services/session.service';
import {switchMap, take} from 'rxjs/operators';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

@Injectable()
export class WorkspaceNewsResolver extends AbstractResolver<PaginatedNews> {

  private initialPage: number = 0;
  private step: number = 10;
  private searchCriteria: NewsSearchCriteria = new NewsSearchCriteria();
  private currentUser: CompleteCurrentUser;

  constructor(spinnerService: SmartSpinnerService,
              private newsService: NewsService,
              private sessionService: SessionService,
              private searchCriteriaService: SearchCriteriaService
  ) {
    super(spinnerService);
    this.sessionService.getCurrentUserOnce().subscribe(user => this.currentUser = user);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedNews> {
    return this.searchCriteriaService.buildSearchCriteriaByQueriesFromRouteSnapshot(
      route,
      this.searchCriteria, {
        startingPage: this.initialPage,
        step: this.step,
      }).pipe(
      switchMap(searchCriterias => {
        if (this.currentUser.isAgentAdmin()) {
          return this.newsService.find(searchCriterias);
        } else {
          return of(PaginatedNews.emptyResult());
        }
      }),
      take(1),
    );
  }
}
