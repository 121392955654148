import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CompanyService} from '../../services/company.service';
import {AbstractResolver} from '../abstract-resolver';
import {SimpleCompanyWithAgents} from '../../../shared/models/entity/companies/SimpleCompanyWithAgents';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class SimpleCompanyWithAgentsResolver extends AbstractResolver<SimpleCompanyWithAgents> {

  constructor(spinnerService: SmartSpinnerService, private companyService: CompanyService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SimpleCompanyWithAgents> {
    const companyId = route.paramMap.get('companyId');
    return companyId ?
      this.companyService.getById(companyId) :
      of(SimpleCompanyWithAgents.newCompany());
  }
}
