import {Routes} from '@angular/router';
import {DashboardPageComponent} from './pages/dashboard-page.component';
import {LoggedInGuard} from '../app-root/guards/logged-in.guard';
import {CurrentDashboardCustomizationResolver} from '../app-root/resolvers/workspace/current-dashboard-customization.resolver';
import {resolve} from '@angular/compiler-cli/src/ngtsc/file_system';

export const dashboardRoutes: Routes = [
  {
    path: '', component: DashboardPageComponent, resolve: {
      dashboardHeader: CurrentDashboardCustomizationResolver
    }
  }
];
