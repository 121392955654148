import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Route, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {getDefaultedBy} from '../functions/get-defaulted';
import {PaginatedCriteria} from '../models/entity/paginated/PaginatedCriteria';
import {CompleteCurrentUser} from '../models/entity/users/CompleteCurrentUser';
import {SessionService} from '../../app-root/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class SearchCriteriaService {

  DEFAULT_STEP = 10;
  DEFAULT_STARTING_PAGE = 0;
  private currentUser: CompleteCurrentUser;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public sessionService: SessionService
  ) {
    sessionService.getCurrentUserOnce().subscribe(user => {
      this.currentUser = user;
    });
  }

  public buildSearchCriteriaByQueries<T extends PaginatedCriteria>(
    baseInstance: T,
    options?: BuildByQueriesOptions,
  ): Observable<T> {
    return this
      .activatedRoute
      .queryParams
      .pipe(
        switchMap((params: Params) => {
          return of(baseInstance.fromParams(
            params,
            getDefaultedBy(options?.step, this.DEFAULT_STEP),
            getDefaultedBy(options?.startingPage, this.DEFAULT_STARTING_PAGE)
          ));
        })
      );
  }

  public buildSearchCriteriaByQueriesFromRouteSnapshot<T extends PaginatedCriteria>(
    fromRouteSnapshot: ActivatedRouteSnapshot,
    baseInstance: T,
    options?: BuildByQueriesOptions,
  ): Observable<T> {
    const queryParams = fromRouteSnapshot.queryParams;

    return of(baseInstance.fromParams(
      queryParams,
      getDefaultedBy(options?.step, this.DEFAULT_STEP),
      getDefaultedBy(options?.startingPage, this.DEFAULT_STARTING_PAGE)
    ));
  }

  public navigateWithQueries<T extends PaginatedCriteria>(criteria: T, listUrl: string[], options?: SearchByQueryOptions) {
    if (options?.resetPage) {
      criteria.page = this.DEFAULT_STARTING_PAGE;
      criteria.sort = null;
      criteria.sortOrder = null;
    }

    this.router.navigate(listUrl, {queryParams: criteria.toCleanQueryParams()});
  }
}

interface SearchByQueryOptions {
  resetPage?: boolean;
}

interface BuildByQueriesOptions {
  step?: number;
  startingPage?: number;
}
