import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {ClassificationService} from '../../services/classification.service';
import {Domain} from '../../../shared/models/entity/classifications/Domain';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class DomainResolver extends AbstractResolver<Domain> {

  constructor(spinnerService: SmartSpinnerService, private classificationService: ClassificationService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Domain> {
    const domainId = route.paramMap.get('domainId');
    return domainId ?
      this.classificationService.findDomainId(domainId).pipe(take(1)) : of(new Domain()).pipe(take(1));
  }
}
