import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {environment} from './environments/environment';
import {AppRootModule} from './app/modules/app-root/app-root.module';

// @ts-ignore
import {Dropdown, Foundation} from 'foundation-sites';
// @ts-ignore
import $ from 'jquery';

if (environment.production) {
  enableProdMode();
}

$(document).ready( _ => {
  Foundation.addToJquery($);
  Foundation.plugin(Dropdown, 'Dropdown');
});

platformBrowserDynamic().bootstrapModule(AppRootModule)
  .catch(err => console.error(err));
