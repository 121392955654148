import {Routes} from '@angular/router';
import {RequestByCodeResolver} from '../app-root/resolvers/requests/request-by-code.resolver';
import {RequestsListPageComponent} from './pages/requests-list/requests-list-page.component';
import {RequestDetailsPageComponent} from './pages/request-details/request-details-page.component';
import {RequestRedirectComponent} from './pages/request-redirect/request-redirect.component';
import {TransportUserNewNoteGuard} from '../app-root/guards/transport-user-new-note.guard';
import {IsTransportUserGuard} from '../app-root/guards/is-transport-user.guard';

export const requestRoutes: Routes = [
  {
    path: '', component: RequestsListPageComponent
  },
  {
    path: 'redirect',
    canActivate: [
      IsTransportUserGuard,
    ],
    component: RequestRedirectComponent,
  },
  {
    path: 'new',
    component: RequestDetailsPageComponent,
    canActivate: [
      TransportUserNewNoteGuard,
    ],
    resolve: {
      request: RequestByCodeResolver
    },
  },
  {
    path: ':requestCode', component: RequestDetailsPageComponent,
    resolve: {
      request: RequestByCodeResolver
    },
    runGuardsAndResolvers: 'always'
  }
];
