import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  PaginatedAdministrationUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {AdministrationUserSearchCriteria} from '../../../shared/models/criterias/AdministrationUserSearchCriteria';
import {Router} from '@angular/router';
import {AdministrationUserService} from '../../../app-root/services/administration-user.service';
import {Sort} from '@angular/material/sort';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {WorkspaceSimple} from '../../../shared/models/entity/workspaces/Workspace';
import {SessionService} from '../../../app-root/services/session.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {noop, Subscription} from 'rxjs';
import {AlertService} from '../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../app-root/services/notification.service';
import {PasswordService} from '../../../app-root/services/password.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {UserAdministrationSearchResult} from '../../../shared/models/entity/users/AdministrationUser';
import {UserWithEmail} from '../../../shared/models/entity/users/User';
import {finalize, skip, tap} from 'rxjs/operators';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {NavigationService} from '../../../shared/services/navigation-service';

@Component({
  selector: 'administration-users-list',
  templateUrl: './administration-users-list.component.html',
  styleUrls: ['./administration-users-list.component.scss']
})
export class AdministrationUsersListComponent implements OnInit, OnDestroy {

  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;

  public refreshCurrentUserSub: Subscription;

  @Input()
  public workspaces: Array<WorkspaceSimple>;

  public currentUser: CompleteCurrentUser;

  @Input()
  private initialUsers: PaginatedAdministrationUsers;
  public users: Array<UserAdministrationSearchResult> = [];
  public usersCount: number;

  public administrationUserSearchCriteria: AdministrationUserSearchCriteria = new AdministrationUserSearchCriteria();
  public currentPage: number = this.DEFAULT_STARTING_PAGE;
  private reloadOnNavigationSub: Subscription;

  constructor(private router: Router,
              private administrationUserService: AdministrationUserService,
              private sessionService: SessionService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private passwordService: PasswordService,
              private searchCriteriaService: SearchCriteriaService,
              private navigationService: NavigationService,
  ) { }

  public ngOnInit(): void {
    this.administrationUserSearchCriteria.includeDisabled = true;
    this.refreshCurrentUserSub = this.sessionService.getCurrentUser().subscribe(currentUser => this.currentUser = currentUser);

    this.reloadOnNavigationSub = this.searchCriteriaService.buildSearchCriteriaByQueries(
      this.administrationUserSearchCriteria,
      {
        startingPage: this.DEFAULT_STARTING_PAGE,
        step: this.DEFAULT_STEP
      }
    ).pipe(
      skip(1),
      tap(searchCriterias => {
        this.loadUsers(searchCriterias);
      })
    ).subscribe();


    if (this.initialUsers) {
      this.users = this.initialUsers.result;
      this.usersCount = this.initialUsers.metadata.count;
    } else {
      this.searchCriteriaService.navigateWithQueries(
        this.administrationUserSearchCriteria,
        this.navigationService.getUsersAdministrationListUrl(),
      );
    }
  }

  public sortUsers(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.administrationUserSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.administrationUserSearchCriteria.sort = sort.active;

    this.searchCriteriaService.navigateWithQueries(
      this.administrationUserSearchCriteria,
      this.navigationService.getUsersAdministrationListUrl(),
    );
  }

  public goToCreateUserPage(): void {
    this.router.navigate(['/administration/users/new']);
  }

  public goToUserEditionPage(userId: string): void {
    this.router.navigate(['/administration/users/' + userId]);
  }

  public filter(): void {
    this.searchCriteriaService.navigateWithQueries(
      this.administrationUserSearchCriteria,
      this.navigationService.getUsersAdministrationListUrl(),
      {
        resetPage: true
      }
    );
  }

  private loadUsers(administrationUserSearchCriteria: AdministrationUserSearchCriteria): void {
    this.spinner.show();
    this.administrationUserService.search(administrationUserSearchCriteria).subscribe(
      paginatedUsers => {
        this.users = paginatedUsers.result;
        this.usersCount = paginatedUsers.metadata.count;
        this.spinner.hide();
      }, _ => this.spinner.hide());
  }

  public onPageChanged(administrationUserSearchCriteria: AdministrationUserSearchCriteria): void {
    this.currentPage = administrationUserSearchCriteria.page;
    this.searchCriteriaService.navigateWithQueries(
      this.administrationUserSearchCriteria,
      this.navigationService.getUsersAdministrationListUrl(),
    );
  }

  public onDisableUser(user: UserAdministrationSearchResult): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('administration.disabled.alert.title.user'),
      this.translateService.instant('administration.disabled.alert.message.user', {user: user.fullName}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')).subscribe(
      () => this.disable(user),
      () => noop()
    );
  }

  private disable(user: UserAdministrationSearchResult): void {
    this.spinner.show();
    this.administrationUserService.disable(user.id).subscribe(
      () => {
        this.notificationService.success('administration.disabled.user.success');
        this.spinner.hide();
        this.searchCriteriaService.navigateWithQueries(
          this.administrationUserSearchCriteria,
          this.navigationService.getUsersAdministrationListUrl()
        );
      },
      () => {
        this.notificationService.error('administration.disabled.user.error');
        this.spinner.hide();
      }
    );
  }

  public onResetPassword(user: UserWithEmail): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('forgotten.password.title'),
      this.translateService.instant('forgotten.password.message.user', {user: user.fullName}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')
    ).subscribe(
      () => this.resetPassword(user),
      () => noop()
    );
  }

  private resetPassword(user: UserWithEmail): void {
    this.spinner.show();
    this.passwordService.renewUserPassword(user.email)
      .pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe(
        () => {
          this.notificationService.success('forgotten.password.success');
        },
        () => {
          this.notificationService.error('forgotten.password.error');
        }
      );
  }

  onReactivateUser(user: UserAdministrationSearchResult) {
    this.spinner.show();
    this.administrationUserService.enable(user)
      .pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe(newUser => {
        this.notificationService.success('user.reactive.success');
        this.refreshList(newUser);
      }, err => this.notificationService.error('user.reactivate.error'));
  }

  refreshList(user: UserAdministrationSearchResult): void {
    this.users[this.users.findIndex(el => el.id === user.id)] = user;
    this.users = [... this.users];
  }

  ngOnDestroy(): void {
    this.refreshCurrentUserSub.unsubscribe();
    this.reloadOnNavigationSub.unsubscribe();
  }
}
