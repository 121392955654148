import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SimpleCompany} from '../../../../models/entity/companies/SimpleCompany';
import {Observable, of} from 'rxjs';
import {CollectionUtils} from '../../../../services/collection-utils';
import {finalize, tap} from 'rxjs/operators';

@Component({
  selector: 'a-select-simple-companies',
  templateUrl: './a-select-simple-companies.component.html',
  styleUrls: ['./a-select-simple-companies.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectSimpleCompaniesComponent),
      multi: true
    }
  ]
})
export class ASelectSimpleCompaniesComponent implements ControlValueAccessor {

  @Input()
  public companiesObs: Observable<SimpleCompany[]> = of([]);

  @Input()
  public name: string = 'companies';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;
  public companiesLoading: boolean;
  public items: Array<SimpleCompany>;
  public selectedCompanies: Array<SimpleCompany>;
  public hideSelected: boolean;
  private onNgChange: (values: Array<SimpleCompany>) => void;
  private onNgTouched: () => void;

  constructor() {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(companies: Array<SimpleCompany>): void {
    this.selectedCompanies = companies;
  }

  public onChange(companies: Array<SimpleCompany>): void {
    this.selectedCompanies = companies;
    this.onNgChange(companies);
  }

  public compare(company1: SimpleCompany, company2: SimpleCompany): boolean {
    return company1 != null && company2 != null && company1.id === company2.id;
  }

  public openFired() {
    if (this.companiesLoading || CollectionUtils.notEmpty(this.items)) { return; }
    this.fetch();
  }

  public fetch() {
    this.companiesLoading = true;
    this.companiesObs.pipe(
      finalize(() => this.companiesLoading = false),
      tap(companies => {
        if (CollectionUtils.notEmpty(companies)) { this.hideSelected = true; }
      })
    ).subscribe(companies => {
      this.items = companies;
    });
  }
}
