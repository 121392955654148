import {RequestPriority} from '../entity/enums/RequestPriority';
import {RequestStatus} from '../entity/enums/RequestStatus';
import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {Params} from '@angular/router';
import {CustomQueryEncoder} from '../CustomQueryEncoder';
import {arraysEqual} from '../../functions/array-utils';
import {isArray} from 'rxjs/internal-compatibility';
import {Expose, Transform} from 'class-transformer';

export interface RequestSearchCriteriaMetadata {
  productType?: string[];
  deliveryNumber?: string[];
  originCountryCode?: string[];
  destStateCode?: string[];
  destCountryCode?: string[];
  originStateCode?: string[];
  destinationGlid?: string[];
  originGlid?: string[];
  loadGroup?: string[];
  deliveryType?: DeliveryTypeEnum;
}

export enum InternationalFilterEnum {
  INTERNATIONAL = 'INTERNATIONAL',
  NATIONAL = 'NATIONAL',
  BOTH = 'BOTH'
}

export enum DeliveryTypeEnum {
  RETURN = 'RETURN',
  ISSUE = 'ISSUE',
  STO = 'STO',
  MANAGED_RECOVERY = 'MANAGED_RECOVERY',
}

export enum RequestSearchCriteriaSort {
  BY_CODE = 'BY_CODE',
  BY_CREATION_DATE = 'BY_CREATION_DATE',
  BY_LAST_MESSAGE_DATE = 'BY_LAST_MESSAGE_DATE',
  BY_PRIORITY = 'BY_PRIORITY',
  BY_STATUS = 'BY_STATUS'
}

export class RequestSearchCriteria extends PaginatedCriteria {
  public tags?: Array<string>;
  public q?: string;
  @Expose({ toClassOnly: true})
  @Transform(({obj}) => obj.userPriority ? obj.userPriority : obj.priority, {toClassOnly: true})
  public priority?: RequestPriority;
  public statuses?: Array<RequestStatus> = [];
  public domainIds?: Array<string>;
  public categoryIds?: Array<string>;
  public companyId?: string; // Note : We have a list on server side because we enforce visible companies if no company set
  public code?: string;
  public workspaceCode?: string = null;
  public includeOnlyAssignedRequest?: Boolean;
  public fromDateCreation?: Date;
  public toDateCreation?: Date;
  public internationalFilter: InternationalFilterEnum;
  public metadata?: RequestSearchCriteriaMetadata = {};
  public deliveryNumber: string[];
  public authorName?: string;
  public lastAgentToAnswer?: string;


  static isEmpty(searchCriteria: RequestSearchCriteria, excludeQ: boolean): boolean {
    if (!searchCriteria) {
      return true;
    }

    return (!searchCriteria.tags || searchCriteria.tags.length === 0) && !searchCriteria.priority &&
      (!searchCriteria.statuses || searchCriteria.statuses.length === 0) && !searchCriteria.companyId &&
      (!searchCriteria.domainIds || searchCriteria.domainIds.length === 0) &&
      (!searchCriteria.categoryIds || searchCriteria.categoryIds.length === 0) &&
      (!searchCriteria.code || searchCriteria.code === '') && !searchCriteria.includeOnlyAssignedRequest &&
      (excludeQ || (!searchCriteria.q || searchCriteria.q === ''));
  }

  public equals(other: RequestSearchCriteria) {
    return (
      arraysEqual(this.tags, other.tags) &&
      this.q === other.q &&
      this.priority === other.priority &&
      arraysEqual(this.statuses, other.statuses) &&
      arraysEqual(this.domainIds, other.domainIds) &&
      arraysEqual(this.categoryIds, other.categoryIds) &&
      this.companyId === other.companyId &&
      this.code === other.code &&
      this.workspaceCode === other.workspaceCode &&
      this.includeOnlyAssignedRequest === other.includeOnlyAssignedRequest &&
      this.fromDateCreation === other.fromDateCreation &&
      this.toDateCreation === other.toDateCreation &&
      this.internationalFilter === other.internationalFilter &&
      this.metadata === other.metadata &&
      this.deliveryNumber === other.deliveryNumber &&
      this.authorName === other.authorName &&
      this.lastAgentToAnswer === other.lastAgentToAnswer &&
      this.metadataEquals(other)
    );
  }

  public metadataEquals(other: RequestSearchCriteria) {
    return (
      this.metadataArraysEqual(this.metadata.productType, other.metadata.productType) &&
      this.metadata.deliveryType === other.metadata.deliveryType &&
      this.metadataArraysEqual(this.metadata.deliveryNumber, other.metadata.deliveryNumber) &&
      this.metadataArraysEqual(this.metadata.destCountryCode, other.metadata.destCountryCode) &&
      this.metadataArraysEqual(this.metadata.originCountryCode, other.metadata.originCountryCode) &&
      this.metadataArraysEqual(this.metadata.destStateCode, other.metadata.destStateCode) &&
      this.metadataArraysEqual(this.metadata.originStateCode, other.metadata.originStateCode) &&
      this.metadataArraysEqual(this.metadata.destinationGlid, other.metadata.destinationGlid) &&
      this.metadataArraysEqual(this.metadata.originGlid, other.metadata.originGlid) &&
      this.metadataArraysEqual(this.metadata.loadGroup, other.metadata.loadGroup)
    );
  }

  // for some reasons, sometimes metadata fields are not array.
  // As their type indict, they should be, I guess ? Heh !
  // This function ensures to take into account this ... specific case
  private metadataArraysEqual(a: Array<any>, b: Array<any>) {
    if (!isArray(a) && !isArray(b)) { return a === b; }
    if (!isArray(a) || !isArray(b)) { return !(!isArray(a) && isArray(b)) || (isArray(a) && !isArray(b)); }
    return arraysEqual(a, b);
  }

  public fromSelf(base: RequestSearchCriteria): RequestSearchCriteria {
    this.reset();

    this.statuses = base.statuses;
    this.priority = base.priority;
    this.q = base.q;
    this.code = base.code;
    this.includeOnlyAssignedRequest = base.includeOnlyAssignedRequest;
    this.categoryIds = base.categoryIds;
    this.companyId = base.companyId;
    this.domainIds = base.domainIds;
    this.tags = base.tags;
    this.step = base.step;
    this.page = base.page;
    this.sort = base.sort;
    this.sortOrder = base.sortOrder;
    this.fromDateCreation = base.fromDateCreation;
    this.toDateCreation = base.toDateCreation;
    this.internationalFilter = base.internationalFilter;
    this.deliveryNumber = base.deliveryNumber;
    this.authorName = base.authorName;
    this.lastAgentToAnswer = base.lastAgentToAnswer;
    this.metadata = { ... base.metadata };

    return this;
  }

  /** inherited - to be query compatible **/

  protected reset() {
    this.statuses = undefined;
    this.priority = undefined;
    this.q = undefined;
    this.code = undefined;
    this.includeOnlyAssignedRequest = undefined;
    this.categoryIds = undefined;
    this.companyId = undefined;
    this.domainIds = undefined;
    this.tags = undefined;
    this.fromDateCreation = undefined;
    this.toDateCreation = undefined;
    this.metadata = undefined;
    this.internationalFilter = undefined;
    this.deliveryNumber = undefined;
    this.authorName = undefined;
    this.lastAgentToAnswer = undefined;
  }

  public transformIntoHttpParams(base: HttpParams): HttpParams {
    /** delivery number is kind of special.
     it's normally a metadata field only.
     since we search it in the subject too, we've decided to
     send it to the backside to search in both fields **/
    if (this.deliveryNumber) {
      this.deliveryNumber.forEach(val => {
        base = base.append('deliveryNumber', val.trim());
      });
    }

    if (this.q) {
      base = base.set('q', this.q.trim());
    }
    if (this.priority) {
      base = base.set('priority', this.priority);
    }
    if (this.statuses) {
      this.statuses.forEach(status => base = base.append('statuses', status));
    }
    if (this.domainIds) {
      this.domainIds.forEach(domainId => base = base.append('domainIds', domainId));
    }
    if (this.categoryIds) {
      this.categoryIds.forEach(categoryId => base = base.append('categoryIds', categoryId));
    }
    if (this.companyId) {
      base = base.set('companyIds', this.companyId);
    }
    if (this.tags) {
      this.tags.forEach(tag => base = base.append('tags', tag));
    }
    if (this.code) {
      base = base.set('code', this.code);
    }
    if (this.includeOnlyAssignedRequest) {
      base = base.set('includeOnlyAssignedRequest', this.includeOnlyAssignedRequest.toString());
    }

    if (this.fromDateCreation && this.fromDateCreation.toJSON() != null) {
      base = base.set('fromDateCreation', this.fromDateCreation.toJSON());
    }

    if (this.toDateCreation && this.toDateCreation.toJSON() != null) {
      base = base.set('toDateCreation', this.toDateCreation.toJSON());
    }

    if (this.internationalFilter) {
      base = base.set('internationalFilter', this.internationalFilter.toString());
    }

    if (this.metadata) {
      Object.keys(this.metadata).map(key => {
        if (this.metadata[key] != null) {
          if (isArray(this.metadata[key])) {
            this.metadata[key].forEach(val => {
              base = base.append(`meta_${key}`, val.trim());
            });
          } else {
            base = base.set(`meta_${key}`, this.metadata[key].trim());
          }
        }
      });
    }

    if (this.lastAgentToAnswer) {
      base = base.set('lastAgentToAnswer', this.lastAgentToAnswer);
    }

    if (this.authorName) {
      base = base.set('authorName', this.authorName);
    }

    if (this.workspaceCode) {
      base = base.set('workspaceCode', this.workspaceCode);
    }

    return base;
  }

  /** we override it because workspaceCode is used withing a paginated search, but not in a full **/
  public toHttpParamsWithoutPagination(): HttpParams {
    let base = new HttpParams({encoder: new CustomQueryEncoder()});

    /** delivery number is kind of special.
     it's normally a metadata field only.
     since we search it in the subject too, we've decided to
     send it to the backside to search in both fields **/
    if (this.deliveryNumber) {
      this.deliveryNumber.forEach(val => {
        base = base.append('deliveryNumber', val.trim());
      });
    }

    if (this.q) {
      base = base.set('q', this.q.trim());
    }
    if (this.priority) {
      base = base.set('priority', this.priority);
    }
    if (this.statuses) {
      this.statuses.forEach(status => base = base.append('statuses', status));
    }
    if (this.domainIds) {
      this.domainIds.forEach(domainId => base = base.append('domainIds', domainId));
    }
    if (this.categoryIds) {
      this.categoryIds.forEach(categoryId => base = base.append('categoryIds', categoryId));
    }
    if (this.companyId) {
      base = base.set('companyIds', this.companyId);
    }
    if (this.tags) {
      this.tags.forEach(tag => base = base.append('tags', tag));
    }
    if (this.code) {
      base = base.set('code', this.code);
    }
    if (this.includeOnlyAssignedRequest) {
      base = base.set('includeOnlyAssignedRequest', this.includeOnlyAssignedRequest.toString());
    }

    if (this.fromDateCreation && this.fromDateCreation.toJSON() != null) {
      base = base.set('fromDateCreation', this.fromDateCreation.toJSON());
    }

    if (this.toDateCreation && this.toDateCreation.toJSON() != null) {
      base = base.set('toDateCreation', this.toDateCreation.toJSON());
    }

    if (this.internationalFilter) {
      base = base.set('internationalFilter', this.internationalFilter.toString());
    }

    if (this.authorName) {
      base = base.set('authorName', this.authorName);
    }

    if (this.lastAgentToAnswer) {
      base = base.set('lastAgentToAnswer', this.lastAgentToAnswer);
    }

    if (this.metadata) {
      Object.keys(this.metadata).map(key => {
        if (this.metadata[key] != null) {
          if (isArray(this.metadata[key])) {
            this.metadata[key].forEach(val => {
              base = base.append(`meta_${key}`, val.trim());
            });
          } else {
            base = base.set(`meta_${key}`, this.metadata[key].trim());
          }
        }
      });
    }

    return base;
  }

  public fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, RequestSearchCriteriaSort.BY_CREATION_DATE);

    this.statuses = !params.statuses ? [] : Array.isArray(params.statuses) ?
      params.statuses.map(statusStr => RequestStatus[statusStr]) : [(RequestStatus)[params.statuses]];
    this.priority = params.priority;
    this.q = params.q;
    this.code = params.code;
    this.includeOnlyAssignedRequest =
      params.includeOnlyAssignedRequest ? JSON.parse(params.includeOnlyAssignedRequest) : null;
    this.categoryIds = params.categoryIds && !isArray(params.categoryIds) ? [params.categoryIds] : params.categoryIds;
    this.companyId = params.companyId;
    this.domainIds = params.domainIds && !isArray(params.domainIds) ? [params.domainIds] : params.domainIds;
    this.tags = params.tags && !isArray(params.tags) ? [params.tags] : params.tags;
    this.fromDateCreation = new Date(params.fromDateCreation);
    this.toDateCreation = new Date(params.toDateCreation);
    this.metadata = this.retrieveMetadata(params);
    this.internationalFilter = this.paramsOrDefault(params, 'internationalFilter', InternationalFilterEnum.BOTH);
    this.deliveryNumber = params.deliveryNumber && !isArray(params.deliveryNumber) ? [params.deliveryNumber] : params.deliveryNumber;
    this.lastAgentToAnswer = params.lastAgentToAnswer;
    this.authorName = params.authorName;

    return this;
  }

  public toCleanQueryParams(): Params {
    const queryParams = super.toCleanQueryParams();

    this.formatDateCorrectly('fromDateCreation', queryParams);
    this.formatDateCorrectly('toDateCreation', queryParams);

    this.formatMetadataCorrectly(queryParams);

    return queryParams;
  }

  /** handouts for working with request metadata **/

  private retrieveMetadata(source: Params): RequestSearchCriteriaMetadata {
    const res: RequestSearchCriteriaMetadata = {};

    res.loadGroup = source['meta_loadGroup'] ? this.retrieveArray(source['meta_loadGroup']) : null;

    res.originGlid = source['meta_originGlid'] ? this.retrieveArray(source['meta_originGlid']) : null;
    res.destinationGlid = source['meta_destinationGlid'] ? this.retrieveArray(source['meta_destinationGlid']) : null;

    res.originCountryCode = source['meta_originCountryCode'] ? this.retrieveArray(source['meta_originCountryCode']) : null;
    res.destCountryCode = source['meta_destCountryCode'] ? this.retrieveArray(source['meta_destCountryCode']) : null;

    res.originStateCode = source['meta_originStateCode'] ? this.retrieveArray(source['meta_originStateCode']) : null;
    res.destStateCode = source['meta_destStateCode'] ? this.retrieveArray(source['meta_destStateCode']) : null;

    res.productType = source['meta_productType'] ? this.retrieveArray(source['meta_productType']) : null;

    res.deliveryNumber = source['meta_deliveryNumber'] ? this.retrieveArray(source['meta_deliveryNumber']) : null;

    res.deliveryType = source['meta_deliveryType'];

    return res;
  }

  public removeMetadataNullsAndEmptyStrings() {
    if (this.metadata) {
      Object.keys(this.metadata).map(key => {
        if (this.metadata[key] == null || this.metadata[key] === '') {
          delete this.metadata[key];
        }
      });
    }
  }

  private formatMetadataCorrectly(queryParams: Params) {
    delete queryParams.metadata;
    if (this.metadata) {
      Object.keys(this.metadata).map(key => {
        if (this.metadata[key] && this.metadata[key] != null) {
          if (isArray(this.metadata[key])) {
            queryParams[`meta_${key}`] = this.metadata[key].map(el => el.trim());
          } else {
            queryParams[`meta_${key}`] = this.metadata[key].trim();
          }
        }
      });
    }
  }
}
