import {Type} from 'class-transformer';
import {Domain} from '../classifications/Domain';
import {SimpleCompany} from './SimpleCompany';
import {WorkspaceSimple} from '../workspaces/Workspace';

export class Company extends SimpleCompany {

  allDomainsByDefault: boolean;
  @Type(() => Domain)
  subscribedDomains?: Array<Domain>;
  @Type(() => Domain)
  teamDomains?: Array<Domain>;
  @Type(() => WorkspaceSimple)
  workspace: WorkspaceSimple;

  static from(companySimple: SimpleCompany, workspaceSimple: WorkspaceSimple): Company {
    const company = new Company();
    company.id = companySimple.id;
    company.code = companySimple.code;
    company.name = companySimple.name;
    company.workspace = workspaceSimple;
    company.subscribedDomains = [];
    company.teamDomains = [];
    company.allDomainsByDefault  = undefined;
    return company;
  }

  public visibleDomains(): Array<Domain> {
    return this.subscribedDomains
      .concat(this.teamDomains)
      .filter((el, i, arr) => arr.findIndex(it => it.id === el.id) === i);
  }

  public toSimple(): SimpleCompany {
    const simple = new SimpleCompany();
    simple.id = this.id;
    simple.code = this.code;
    simple.name = this.name;
    return simple;
  }
}

