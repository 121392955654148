import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CompanyService} from '../../../../app-root/services/company.service';
import {ResponseTemplateService} from '../../../../app-root/services/responseTemplate.service';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgForm} from '@angular/forms';
import {forkJoin, Observable, of} from 'rxjs';
import {SharedCollection} from '../../../models/entity/enums/SharedCollection';
import {AttachmentService} from '../../../../app-root/services/attachment.service';
import {SessionService} from '../../../../app-root/services/session.service';
import {switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ResponseTemplate} from '../../../models/entity/ResponseTemplate';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {Attachment} from '../../../models/entity/Attachment';
import {Domain} from '../../../models/entity/classifications/Domain';
import {Company} from '../../../models/entity/companies/Company';

@Component({
  selector: 'm-template-form',
  templateUrl: './m-template-form.component.html',
  styleUrls: ['./m-template-form.component.scss']
})
export class MTemplateFormComponent implements OnInit {

  @Input()
  public titleSectionKey: string;

  @Input()
  public titleSectionKeyI18nParams: any;

  @Input()
  public responseTemplate: ResponseTemplate;

  public allCompanies: Company[];

  @Output()
  public responseTemplateSaved: EventEmitter<void>;

  @ViewChild('form')
  public form: NgForm;

  public filesToUpload: Array<File> = [];
  public maxFileSize: number = 30000000;

  private currentUser: CompleteCurrentUser;

  constructor(private companyService: CompanyService,
              private router: Router,
              private notificationService: NotificationService,
              private sessionService: SessionService,
              private attachmentService: AttachmentService,
              private responseTemplateService: ResponseTemplateService
  ) {
    this.responseTemplateSaved = new EventEmitter();
  }

  public ngOnInit() {
    this.companyService.findAllCurrent().subscribe(companies => this.allCompanies = companies);
    if (!this.responseTemplate) {
      this.responseTemplate = new ResponseTemplate();
    }
    this.sessionService.getCurrentUserOnce().subscribe(user => this.currentUser = user);
  }

  public send(): void {
    this.responseTemplate.id
      ? this.responseTemplateService.update(this.responseTemplate).subscribe(
        _ => this.onResponseTemplateSaved('templates.form.update.success'),
        _ => this.notificationError()
      )
      :
      this.responseTemplateService.create(this.responseTemplate).pipe(
        tap(responseTemplateSaved => this.responseTemplate = responseTemplateSaved),
        switchMap(savedResponseTemplate => this.uploadFiles(savedResponseTemplate.id)),
        tap(attachments => this.responseTemplate.attachments = attachments),
        switchMap(_ => this.responseTemplateService.update(this.responseTemplate))
      ).subscribe(
        _ => this.onResponseTemplateSaved('templates.form.create.success'),
        _ => this.notificationError()
      );
  }

  private notificationError(): void {
    this.notificationService.error('templates.form.error');
  }

  private onResponseTemplateSaved(translationKey: string): void {
    this.notificationService.success(translationKey);
    this.responseTemplateSaved.emit();
    this.form.form.markAsPristine();
    this.goToSettingsPage();
  }

  private uploadFiles(responseTemplateId: string): Observable<Attachment[]> {
    let uploadObservables: Array<Observable<Attachment>>;
    if (this.filesToUpload.length > 0) {
      uploadObservables = this.filesToUpload.map(file =>
        this.attachmentService.uploadAttachment({
          collection: SharedCollection.RESPONSETEMPLATE,
          id: responseTemplateId
        }, file, this.currentUser));
      return forkJoin(uploadObservables);
    } else {
      return of([]);
    }
  }

  public addFileToUpload(object: Object): void {
    this.filesToUpload.push(object as File);
  }

  public onAttachmentRemoved(removedAttachment: File): void {
    this.filesToUpload = this.filesToUpload.filter(file => file !== removedAttachment);
  }

  public goToSettingsPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'templates']);
  }

  public onDomainChange(selectedDomain: Domain): void {
    this.responseTemplate.domain = selectedDomain;
    this.responseTemplate.category = null;
  }
}
