import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable, of, timer} from 'rxjs';
import {Injectable} from '@angular/core';
import {TeamService} from '../../app-root/services/team.service';
import {map, switchMap} from 'rxjs/operators';
import {SimpleTeam} from '../models/entity/teams/simple-team';

@Injectable()
export class TeamValidators {

  constructor(
    private teamService: TeamService
  ) {
  }

  uniqueName(currentTeam: SimpleTeam): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const currentTeamName = currentTeam?.name;
      if (!currentTeamName || currentTeamName !== control.value) { return this.asyncCheck(control); } else { return of(null); }
    };
  }

  private asyncCheck(control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(500).pipe(
      switchMap(() => this.teamService.nameExists(control.value?.trim())),
      map(exists => exists ? {duplicate: true} : null)
    );
  }
}


