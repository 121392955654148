import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatButtonToggleChange} from '@angular/material/button-toggle/button-toggle';

@Component({
  selector: 'm-paginator',
  templateUrl: './m-paginator.component.html',
  styleUrls: ['./m-paginator.component.scss']
})
export class MPaginatorComponent implements OnInit, OnChanges {

  private static MAX_DISPLAYABLE_PAGE: number = 5;

  @Input()
  public stepsOptions: Array<number> = [10];

  @Input()
  public itemsTotalCount: number = 0;

  @Input()
  public step: number = 0;

  @Input()
  public page: number = 0;

  @Output()
  public pageChange: EventEmitter<number> = new EventEmitter();

  @Output()
  public stepChange: EventEmitter<number> = new EventEmitter();

  public pageCount: number;

  public pages: Array<DisplayablePage> = [];

  public btnHandler(event: MatButtonToggleChange): void {
    this.pages = this.computePages();
    this.pageChange.emit(event.value);
  }

  public ngOnInit() {
    const invalidStepsOption = this.stepsOptions.length === 0 || !this.stepsOptions.every(el => el > 0);
    const invalidItemsCount = this.itemsTotalCount === null || isNaN(this.itemsTotalCount);

    if (invalidItemsCount) {
      throw new DOMException(`app-paginator: wrong number of total items (${this.itemsTotalCount})`);
    }
    if (invalidStepsOption) {
      throw new DOMException(`app-paginator: wrong steps options (value: ${this.stepsOptions})`);
    }

    this.pages = this.computePages();
  }

  public ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange.itemsTotalCount || simpleChange.step || simpleChange.page) {
      this.pages = this.computePages();
    }
  }

  public changeStep(newStep: number) {
    this.stepChange.emit(newStep);
  }

  private computeNumberOfPage() {
    return Math.ceil(this.itemsTotalCount / this.step);
  }

  private computePages(): Array<DisplayablePage> {
    this.pageCount = this.computeNumberOfPage();

    if (!this.pageCount) { return []; }

    let overlapsBeforeExist = false;
    let overlapsAfterExist = false;
    return Array.from(Array(this.pageCount).keys())
      .map(valIdx => new DisplayablePage(valIdx, (valIdx + 1).toString(), this.isPageDisplayable(valIdx)))
      .map(page => {
        if (!page.isDisplay) {
          if (!overlapsAfterExist && page.index > this.page) {
            overlapsAfterExist = true;
            page.toDisplayableOverlaps();
          } else if (!overlapsBeforeExist && page.index < this.page) {
            overlapsBeforeExist = true;
            page.toDisplayableOverlaps();
          }
        }
        return page;
      })
      .filter(page => page.isDisplay);
  }

  private isPageDisplayable(pageIndex: number): boolean {
    return this.pageCount <= MPaginatorComponent.MAX_DISPLAYABLE_PAGE ||
      pageIndex === 0 || pageIndex === this.pageCount - 1 || (pageIndex >= this.page - 1 && pageIndex <= this.page + 1);
  }
}

export class DisplayablePage {
  index: number;
  text: string;
  isDisplay: boolean;
  clickable: boolean;

  constructor(index: number, text: string, isDisplay: boolean) {
    this.index = index;
    this.text = text;
    this.isDisplay = isDisplay;
    this.clickable = isDisplay;
  }

  public toDisplayableOverlaps(): void {
    this.text = '...';
    this.isDisplay = true;
    this.clickable = false;
  }
}
