import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {Params} from '@angular/router';

export class AdministrationUserSearchCriteria extends PaginatedCriteria {

  public q?: string;
  public workspaceId?: string;
  public includeDisabled?: boolean;

  public transformIntoHttpParams(base: HttpParams): HttpParams {
    if (this.q) {
      base = base.set('q', this.q.trim());
    }

    if (this.workspaceId) {
      base = base.set('workspaceId', this.workspaceId);
    }

    if (this.includeDisabled) {
      base = base.set('includeDisabled', String(this.includeDisabled));
    }

    return base;
  }

  fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, null);

    this.q = params.q;
    this.workspaceId = params.workspaceId;
    this.includeDisabled = params.includeDisabled;

    return this;
  }

  protected reset() {
    super.defaultReset();

    this.q = undefined;
    this.workspaceId = undefined;
    this.includeDisabled = undefined;
  }
}
