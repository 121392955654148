import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {Company} from '../../../models/entity/companies/Company';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {CollectionUtils} from '../../../services/collection-utils';
import {finalize, tap} from 'rxjs/operators';
import {C} from '@angular/cdk/keycodes';

@Component({
  selector: 'a-select-companies-async',
  templateUrl: './a-select-companies-async.component.html',
  styleUrls: ['./a-select-companies-async.component.scss'],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCompaniesAsyncComponent),
      multi: true
    }
  ]
})
export class ASelectCompaniesAsyncComponent implements ControlValueAccessor, OnInit {
  @Input()
  public companiesObs: Observable<Company[]> = of([]);
  public selectedCompanies: Company[];
  public items: Company[] = [];
  private onNgChange: (values: Array<Company>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'companies-async';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;
  public companiesLoading: boolean = false;
  hideSelected: boolean = true;
  constructor() {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(companies: Array<Company>): void {
    this.selectedCompanies = companies;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    if (CollectionUtils.empty(this.selectedCompanies)) {
      this.hideSelected = false;
    }
  }

  public onChange(companies: Array<Company>): void {
    this.selectedCompanies = companies;
    this.onNgChange(companies);
  }

  public compare(company1: Company, company2: Company): boolean {
    return company1 != null && company2 != null && company1.id === company2.id;
  }

  public openFired() {
    if (this.companiesLoading || CollectionUtils.notEmpty(this.items)) { return; }
    this.fetch();
  }

  public fetch() {
    this.companiesLoading = true;
    if (!this.companiesObs) {
      console.error('[a-select-companies-async] no companies observable provided');
      return;
    }

    this.companiesObs.pipe(
      finalize(() => this.companiesLoading = false),
      tap(companies => {
        if (CollectionUtils.notEmpty(companies)) { this.hideSelected = true; }
      })
    ).subscribe(companies => {
      this.items = companies;
    });
  }
}
