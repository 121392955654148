import {Component, Input} from '@angular/core';
import {SizeAvatar} from '../../atoms/a-avatar/a-avatar.component';
import {PositionDropdown} from '../m-badge-dropdown/m-badge-dropdown.component';
import {SimpleCompany} from '../../../models/entity/companies/SimpleCompany';

@Component({
    selector: 'm-company-icon-list',
    templateUrl: './m-company-icon-list.component.html',
    styleUrls: ['./m-company-icon-list.component.scss']
})
export class MCompanyIconListComponent {

  @Input() public sizeAvatar: SizeAvatar = 'DEFAULT';
  @Input() public white: boolean;
  @Input() public set companies(companies: Array<SimpleCompany>) {
    this._displayedCompanies = companies.slice(0, this.nbCompanyDisplayed);
    this._remainingCompanies = companies.slice(this.nbCompanyDisplayed);
  }

  public nbCompanyDisplayed = 3;
  public _displayedCompanies: Array<SimpleCompany> = [];
  public _remainingCompanies: Array<SimpleCompany> = [];
  public POSITION_DROPDOWN = PositionDropdown;
}
