import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {RequestService} from '../../services/request.service';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {WorkspaceSimple} from '../../../shared/models/entity/workspaces/Workspace';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class WorkspaceByCodeResolver extends AbstractResolver<WorkspaceSimple> {

  constructor(spinnerService: SmartSpinnerService,
              private requestService: RequestService,
              private sessionService: SessionService
  ) {
    super(spinnerService, 'Loading workspace...');
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkspaceSimple> {
    const workspaceCode = route.paramMap.get('workspaceCode');
    if (workspaceCode === undefined || workspaceCode == null) { return of(null); }
    return this.sessionService.getCurrentUserOnce()
      .pipe(
        switchMap((currentUser) => {
          if (currentUser.activeWorkspace.code !== workspaceCode) {
            return this.sessionService.activeWorkspace(workspaceCode)
              .pipe(map(user => user.activeWorkspace));
          } else {
            return of(currentUser.activeWorkspace);
          }
        })
      );
  }
}
