import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {SessionService} from '../../services/session.service';
import {Subscription} from 'rxjs';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.scss']
})
export class AppPageComponent implements OnInit, OnDestroy {

  public title = 'Service desk';
  public menuOpened = false;
  public onLogin: boolean;
  public onNoWorkspace: boolean;
  public onWorkspacesChoice: boolean;
  public onAdministrationPage: boolean;

  /** subscriptions **/
  private changeLangueOnUserChange: Subscription;
  private changeSmartSpinnerI18nLabel: Subscription;

  /** spinners **/
  public smartSpinnerI18nKey: string = '';

  constructor(private translateService: TranslateService,
              private router: Router,
              private sessionService: SessionService,
              private smartSpinnerService: SmartSpinnerService) {

    translateService.setDefaultLang(environment.defaultLocale);
    translateService.use(environment.locale);

    this.onLogin = this.router.url.indexOf('/login') > -1;
    this.onNoWorkspace = this.router.url.indexOf('/noWorkspace') > -1;
    this.onWorkspacesChoice = this.router.url.indexOf('/workspacesChoice') > -1;
    this.onAdministrationPage = this.router.url.startsWith('/administration');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe( _ => {
      this.onLogin = this.router.url.indexOf('/login') > -1;
      this.onNoWorkspace = this.router.url.indexOf('/noWorkspace') > -1;
      this.onWorkspacesChoice = this.router.url.indexOf('/workspacesChoice') > -1;
      this.onAdministrationPage = this.router.url.startsWith('/administration');
    });

    this.changeSmartSpinnerI18nLabel = this.smartSpinnerService.getI18nLabelBus().subscribe(newLabel => {
      this.smartSpinnerI18nKey = newLabel;
    });
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang(environment.defaultLocale);
    this.changeLangueOnUserChange = this.sessionService.getCurrentUser().subscribe( user => {
      if (user && user.locale) {
        this.translateService.use(user.locale);
      }
    });
  }

  ngOnDestroy(): void {
    this.changeLangueOnUserChange.unsubscribe();
    this.changeSmartSpinnerI18nLabel.unsubscribe();
  }

  public onSideMenuOpenedChange(menuOpened: boolean): void {
    this.menuOpened = menuOpened;
  }

  public onActivate(component: any): void {
    if (component.reloadMenuCounter) {
      component.reloadMenuCounter.subscribe( _ => {});
    }
  }
}
