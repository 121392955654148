import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {fromPositionIcon, fromSize, fromVisibility, PositionIcon, Size, Visibility} from '../links-utils';

@Component({
    selector: 'a-link-icon',
    templateUrl: './a-link-icon.component.html',
    styleUrls: [
        '../links.scss',
        './a-link-icon.component.scss'
    ]
})
export class ALinkIconComponent implements OnChanges, OnInit {

  @Input()
  public disabled: boolean = false;
  public fromSize = fromSize;

  @Input()
  public visibility: Visibility = 'MEDIUM';

  @Input()
  public positionIcon: PositionIcon = 'LEFT';

  @Input()
  public size: Size = 'DEFAULT';
  public cssClass: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size || changes.visibility || changes.positionIcon) {
      this.cssClass = 'linkIcon ' + ' ' + fromSize(this.size) + ' ' + fromVisibility(this.visibility) + ' ' + fromPositionIcon(this.positionIcon);
    }
  }

  ngOnInit() {
    this.cssClass = 'linkIcon ' + ' ' + fromSize(this.size) + ' ' + fromVisibility(this.visibility) + ' ' + fromPositionIcon(this.positionIcon);
  }
}
