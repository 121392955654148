import {Component, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../app-root/services/session.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {TeamService} from '../../../app-root/services/team.service';
import {TeamCreation} from '../../../shared/models/entity/teams/team-creation';
import {TeamUpdate} from '../../../shared/models/entity/teams/team-update';
import {NotificationService} from '../../../app-root/services/notification.service';
import {SimpleTeam} from '../../../shared/models/entity/teams/simple-team';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnInit {
  mode: 'CREATE'|'UPDATE'|'DUPLICATE' = 'CREATE';

  team: SimpleTeam;

  duplicateClicked = new EventEmitter<SimpleTeam>();

  private currentUser: CompleteCurrentUser;

  constructor(private router: Router,
              private sessionService: SessionService,
              private teamService: TeamService,
              private notificationService: NotificationService,
              private spinnerService: SmartSpinnerService,
              private route: ActivatedRoute) {

    const state = this.router.getCurrentNavigation().extras.state;
    this.route.data.subscribe(data => {
      this.team = data.team;

      if (!this.team && state && state.teamIdToDuplicate) {
        this.mode = 'DUPLICATE';
        this.spinnerService.show();
        this.teamService.getById(state.teamIdToDuplicate).subscribe(team => {
          this.spinnerService.hide();
          this.team = team.duplicate(state.duplicateAgents, state.duplicateDomains, state.duplicateCompanies, state.duplicateEmails);
        });
      } else if (this.team) {
        this.mode = 'UPDATE';
      } else {
        this.mode = 'CREATE';
      }
    });
  }

  ngOnInit(): void {
    this.sessionService.getCurrentUserOnce().subscribe(user => this.currentUser = user);
  }

  goToSettingsPage() {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'teams']);
  }

  update(teamUpdateRequest: TeamUpdate) {
    this.teamService.update(teamUpdateRequest)
      .pipe(
        switchMap(_ => {
          return this.refetchCurrentUserIfUserAffectedByTeam(teamUpdateRequest);
        })
      )
      .subscribe(_ => {
      this.notificationService.success('common.success');
      this.goToSettingsPage();
      }, error => {
          this.notificationService.error('common.error');
          console.error(error);
      } );
  }

  private refetchCurrentUserIfUserAffectedByTeam(newTeam: TeamUpdate | TeamCreation) {
    if (this.currentUserAffectedByTeam(newTeam)) {
      return this.sessionService.fetchCurrentUser();
    } else {
      return of(null);
    }
  }

 // if user was present on the team, or is present on the new team
  currentUserAffectedByTeam(newTeam: TeamUpdate | TeamCreation): boolean {
    return this.team?.agents.map(el => el.userId).indexOf(this.currentUser.id) !== -1
      || newTeam.agents.indexOf(this.currentUser.id) !== -1;
  }

  refresh(): void {
    this.router.navigate([], {relativeTo: this.route});
  }

  create(teamCreationRequest: TeamCreation) {
    this.teamService.create(teamCreationRequest)
      .pipe(
        switchMap(_ => {
          if (this.currentUserAffectedByTeam(teamCreationRequest)) {
            return this.sessionService.fetchCurrentUser();
          } else {
            return of();
          }
        })
      )
      .subscribe(_ => {
      this.notificationService.success('common.success');
      this.goToSettingsPage();
    }, error => {
        this.notificationService.error('common.error');
        console.error(error);
    });
  }

  openDuplicatePopup() {
    this.duplicateClicked.emit(this.team);
  }
}
