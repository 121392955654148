import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Domain} from '../../../models/entity/classifications/Domain';
import {SimpleCompanyWithDomains} from '../../../models/entity/companies/SimpleCompanyWithDomains';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {Subject, Subscription} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';

@Component({
  selector: 'tr[m-simple-company-with-domains]',
  templateUrl: './m-simple-company-with-domains.component.html',
  styleUrls: ['./m-simple-company-with-domains.component.scss']
})
export class MSimpleCompanyWithDomainsComponent implements OnInit, OnDestroy {

  @Input()
  public simpleCompanyWithDomains: SimpleCompanyWithDomains;
  @Input()
  public isAgentAdmin: boolean = false;
  @Input()
  public companyCheckboxValueBus: Subject<boolean>;
  @Output()
  public checked = new EventEmitter<string>();
  @Output()
  public unchecked = new EventEmitter<string>();
  @Output()
  public companyChecked = new EventEmitter<string>();
  @Output()
  public companyUnchecked = new EventEmitter<string>();
  public checkboxValue: boolean = false;
  public companyCheckboxValueBusSub: Subscription;
  public companyDomains: Array<Domain>;
  private workspaceCode: string;

  constructor(private route: ActivatedRoute,
              public workspaceUserService: WorkspaceUserService,
              private router: Router) {
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  ngOnInit() {
    this.companyCheckboxValueBusSub = this.companyCheckboxValueBus.subscribe(value => this.checkboxValue = value);
    this.companyDomains = this.simpleCompanyWithDomains.subscribedDomains;
  }

  ngOnDestroy() {
    this.companyCheckboxValueBusSub.unsubscribe();
  }

  public goToCompanyEditionPage(companyId: string): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'companies', companyId]);
  }

  checkboxChange(checkbox: boolean) {
    if (checkbox) {
      this.companyChecked.emit(this.simpleCompanyWithDomains.id);
    } else {
      this.companyUnchecked.emit(this.simpleCompanyWithDomains.id);
    }
  }
}
