import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {AdministrationUserService} from '../../services/administration-user.service';
import {User} from '../../../shared/models/entity/users/User';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class UsersResolver extends AbstractResolver<Array<User>> {

  constructor(spinnerService: SmartSpinnerService, private administrationUserService: AdministrationUserService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<User>> {
    return this.administrationUserService.findAll().pipe(take(1));
  }
}
