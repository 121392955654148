import {Entity} from '../common/Entity';
import {Type} from 'class-transformer';
import {LoginCustomization} from './LoginCustomization';
import {DashboardCustomization} from './DashboardCustomization';
import {SimpleCompany} from '../companies/SimpleCompany';
import {User} from '../users/User';

export class WorkspaceModification extends Entity {

  public code?: string;
  public name?: string;
  public description?: string;
  @Type(() => LoginCustomization)
  public loginCustomization?: LoginCustomization  = new LoginCustomization();
  @Type(() => DashboardCustomization)
  public dashboardCustomization?: DashboardCustomization = new DashboardCustomization();
  @Type(() => SimpleCompany)
  public initialCompany: SimpleCompany;
  @Type(() => User)
  public initialAgentAdmin: User;

  public static new(): WorkspaceModification {
    const workspaceModification = new WorkspaceModification();
    workspaceModification.loginCustomization = new LoginCustomization();
    workspaceModification.dashboardCustomization = new DashboardCustomization();
    workspaceModification.initialCompany = new SimpleCompany();
    workspaceModification.initialAgentAdmin = null;
    return workspaceModification;
  }
}
