import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {
  PaginatedSimpleWorkspaceUserListElements
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceUserListElements';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

@Injectable()
export class SimpleWorkspaceUserListElementsResolver extends AbstractResolver<PaginatedSimpleWorkspaceUserListElements> {

  private initialPage: number = 0;
  private step: number = 10;
  private currentUser: CompleteCurrentUser;
  private workspaceUserSearchCriteria = new WorkspaceUserSearchCriteria();

  constructor(spinnerService: SmartSpinnerService,
              private workspaceUserService: WorkspaceUserService,
              private sessionService: SessionService,
              private searchCriteriaService: SearchCriteriaService,
  ) {
    super(spinnerService);
    this.sessionService.getCurrentUserOnce().subscribe(currentUser => this.currentUser = currentUser);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedSimpleWorkspaceUserListElements> {
    return this.searchCriteriaService.buildSearchCriteriaByQueriesFromRouteSnapshot(
      route,
      this.workspaceUserSearchCriteria,
      {
        startingPage: this.initialPage,
        step: this.step
      }
    ).pipe(
      switchMap(workspaceUserSearchCriteria => {
        if (this.currentUser.isAgentAdmin()) {
          return this.workspaceUserService.findSimpleUsersListElements(workspaceUserSearchCriteria);
        } else {
          return of(PaginatedSimpleWorkspaceUserListElements.emptyResult());
        }
      }),
      take(1)
    );
  }

}
