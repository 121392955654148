import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {Router} from '@angular/router';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {Domain} from '../../../shared/models/entity/classifications/Domain';

@Component({
  selector: 'workspace-customization',
  templateUrl: './workspace-customization.component.html',
  styleUrls: ['./workspace-customization.component.scss']
})
export class WorkspaceCustomizationComponent implements OnInit {

  private currentUser: CompleteCurrentUser;

  @Input()
  public domains: Array<Domain>;

  constructor(private sessionService: SessionService,
              private router: Router) {}

  ngOnInit(): void {
    this.sessionService.getCurrentUserOnce().subscribe(user => this.currentUser = user);
  }

  public goToAddDomainPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'domains', 'new']);
  }

  public goToDomainEditionPage(domainId: string): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'domains', domainId]);
  }
}
