import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {OAuth2CodeFlowConfiguration} from '../models/entity/OAuth2CodeFlowConfiguration';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class OAuth2CodeFlowService {

  private baseUrl: string = `${environment.apiUrl}/api/public/oauth2`

  constructor(private httpCLient: HttpClient) {
  }

  loadConfiguration(): Observable<OAuth2CodeFlowConfiguration> {
    return this.httpCLient.get<OAuth2CodeFlowConfiguration>(`${this.baseUrl}/configuration`)
      .pipe(map((principal: any) =>  plainToClass(OAuth2CodeFlowConfiguration, principal as Object)));
  }
}
