import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'getAvatar'
})
export class GetAvatarPipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(url: string, ...args: unknown[]): Observable<SafeUrl> {
    if (!url) { return of(null); }

    return this.http
      .get(url, {responseType: 'blob'})
      .pipe(
        map(blob => URL.createObjectURL(blob)),
        map(objectUrl => this.sanitizer.bypassSecurityTrustUrl(objectUrl))
      );
  }

}
