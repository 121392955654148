import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {
  DeliveryTypeEnum,
  InternationalFilterEnum,
  RequestSearchCriteria,
  RequestSearchCriteriaSort
} from '../../../shared/models/criterias/RequestSearchCriteria';
import {Observable, of, Subscription} from 'rxjs';
import {catchError, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {RequestService} from '../../../app-root/services/request.service';
import {NavigationEnd, Params, Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../app-root/services/notification.service';
import {TagService} from '../../../app-root/services/tag.service';
import {BlobService} from '../../../app-root/services/blop.service';
import {PaginatedRequests} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedRequests';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {buildRequestExportFormat, ExportFormat} from '../../../shared/models/entity/enums/ExportFormat';
import {SearchResultRequest} from '../../../shared/models/entity/requests/RequestSearchResult';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {SimpleCompany} from '../../../shared/models/entity/companies/SimpleCompany';
import {APopupComponent} from '../../../shared/lib-components/atoms/a-popup/a-popup.component';
import {Role} from '../../../shared/models/entity/enums/Role';
import {ActualizeMetricsBusService} from '../../../shared/services/actualize-metrics-bus.service';
import {RequestStatus} from '../../../shared/models/entity/enums/RequestStatus';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {SimpleDomain} from '../../../shared/models/entity/classifications/SimpleDomain';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {CustomSearch} from '../../../shared/models/entity/custom-menus/CustomSearch';
import {CustomSearchService} from '../../../app-root/services/custom-search.service';
import {ToastrService} from 'ngx-toastr';
import {SelectedMenuService} from '../../../app-root/services/selected-menu.service';

@Component({
  templateUrl: './requests-list-page.component.html',
  styleUrls: ['./requests-list-page.component.scss']
})
export class RequestsListPageComponent implements OnInit, OnDestroy {

  public EXCEL_EXPORT_FORMAT = ExportFormat.EXCEL;
  public DEFAULT_STEP: number = 50;
  public DEFAULT_STARTING_PAGE: number = 0;
  // search criterias made by user on current screen
  public searchCriteria: RequestSearchCriteria = new RequestSearchCriteria();
  // search criterias when arrived on screen
  public effectiveSearchCriteria: RequestSearchCriteria = new RequestSearchCriteria();
  public currentPage: number = this.DEFAULT_STARTING_PAGE;
  public requests: Array<SearchResultRequest>;
  public checkableRequest: Array<SearchResultRequest>;
  public resultsCount: number = 0;
  public showFilters: boolean = false;
  public existingTags: String[] = [];
  public EXTRACT_LIMIT: number = 8000;
  public currentUser: CompleteCurrentUser;
  public currentCompany: SimpleCompany;
  public currentUserCompanies: Array<SimpleCompany>;
  public isUserRole: boolean = true;
  public selectedRequests: Set<string> = new Set<string>();
  public Role = Role;
  public RequestStatus = RequestStatus;
  public effectiveQueryParams: Params;
  public areAllRequestChecked: boolean;
  public userDomains: SimpleDomain[];
  public InternationalFilterEnum = InternationalFilterEnum;
  public DeliveryTypeEnum = DeliveryTypeEnum;
  public SortEnum = RequestSearchCriteriaSort;
  public showFiltersSub: Subscription;
  public formInvalid: boolean;

  @ViewChild('closeRequestsModal')
  private closeRequestsModal: APopupComponent;
  @ViewChild('saveSearchModal')
  private saveSearchModal: APopupComponent;
  @ViewChild('updateSearchModal')
  private updateSearchModal: APopupComponent;

  /** subscription **/
  private reloadOnNavigationChangeSub: Subscription;
  private reloadCustomMenus: Subscription;
  private saveSearchName: string;
  private userCustomSearches: CustomSearch[];
  private customSearchToUpdateId: string;
  private customSearchCallout: boolean;
  private invalidDomain: boolean;
  private invalidCompany: boolean;
  customSearchCalloutText: string;

  constructor(private sessionService: SessionService,
              private requestService: RequestService,
              private router: Router,
              private spinner: SmartSpinnerService,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private tagService: TagService,
              private actualizeMetricsBus: ActualizeMetricsBusService,
              private querySearchService: SearchCriteriaService,
              private customSearchService: CustomSearchService,
              private toastrService: ToastrService,
              private selectedMenu: SelectedMenuService,
  ) {

  }

  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload';

    this.reloadCustomMenus = this.sessionService
      .getCurrentUser().pipe(filter(user => user != null))
      .subscribe(currentUser => this.userCustomSearches = currentUser.getActiveContextualWorkspace().customSearches);

    this.showFiltersSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
          this.showFilters = !!this.router.getCurrentNavigation()?.extras.state?.filtersOpened;
        }
      );

    this.tagService.getExistingTagsForRequest().subscribe(tags => this.existingTags = tags);
    this.reloadOnNavigationChangeSub = this
      .querySearchService
      .buildSearchCriteriaByQueries<RequestSearchCriteria>(this.searchCriteria)
      .pipe(
        switchMap(searchCriteria => {
          this.searchCriteria = searchCriteria;
          return this.loadRequests(searchCriteria);
        })
      )
      .subscribe(_ => {
        // we do it after loading request to prevent clipping list modification to appear (bad for user ux)
        this.currentPage = this.searchCriteria.page;
        this.effectiveSearchCriteria = new RequestSearchCriteria().fromSelf(this.searchCriteria);
        this.effectiveQueryParams = this.searchCriteria.toCleanQueryParams();
        this.areAllRequestChecked = false;
      });

    this.sessionService.getCurrentUserOnce().subscribe(user => {
      this.currentUser = user;
      this.isUserRole = this.currentUser.isUser();
      this.currentUserCompanies = this.currentUser.getCompaniesInActiveWorkspace();
      this.currentCompany = this.currentUser.activeCompany;
      this.userDomains = user.getDomainsInActiveWorkspace();
      this.refreshFormValidity(this.searchCriteria);
    });
  }

  ngOnDestroy(): void {
    this.reloadOnNavigationChangeSub.unsubscribe();
    this.reloadCustomMenus.unsubscribe();
    this.showFiltersSub.unsubscribe();
  }

  public toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  private loadRequests(searchCriteria: RequestSearchCriteria, showSpinner: boolean = true): Observable<PaginatedRequests> {
    this.customSearchCallout = false;
    this.refreshFormValidity(searchCriteria);
    if (showSpinner) { this.spinner.show(); }
    return this.requestService.search(searchCriteria)
      .pipe(
        tap(paginatedRequests => {
          this.requests = paginatedRequests.result;
          this.resetCheckedRequests();
          this.checkableRequest = this.requests.filter(el => el.assignedToCurrent);
          this.resultsCount = paginatedRequests.metadata.count;
        }),
        catchError((err) => {
          this.requests = null;
          this.resultsCount = 0;
          if (this.selectedMenu.getCustomSearchSubject().getValue().customSearch?.initial === false && err.status === 450 ) {
            this.customSearchCalloutText = err.error;
            this.customSearchCallout = true;
          } else {
            this.notificationService.error('request.list.search.error');
          }

          return of(null);
        }),
        finalize(() => {
          if (showSpinner) {
            this.spinner.hide();
          }
        })
      );
  }

  public refreshFormValidity(searchCriteria: RequestSearchCriteria) {
    this.invalidDomain = false;
    this.invalidCompany = false;
    if (this.userDomains != null && searchCriteria.domainIds != null) {
      const domainIds = this.userDomains.map(el => el.id);
      this.invalidDomain = !searchCriteria.domainIds.every(el => domainIds.indexOf(el) !== -1);
    }
    if (this.currentUserCompanies != null && searchCriteria.companyId != null) {
      this.invalidCompany = ! (this.currentUserCompanies.map(el => el.id).indexOf(searchCriteria.companyId) !== -1);
    }
    this.formInvalid = this.invalidCompany || this.invalidDomain;
  }

  public sortRequests(sort: Sort): void {
    if (!sort.active || sort.direction === '' && sort.active === 'code') {
      return;
    }

    this.effectiveSearchCriteria.sort = sort.active;
    this.effectiveSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.searchNavigate(this.effectiveSearchCriteria, false);
  }

  public cleanFilters(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'requests']);
  }

  public extractRequestList(exportFormat: ExportFormat): void {
    this.spinner.show();
    this.requestService.extractRequestList(this.searchCriteria, exportFormat).subscribe(
      blob => {
        this.spinner.hide();
        BlobService.downloadBlob(blob, buildRequestExportFormat(exportFormat).requestFileName);
      },
      () => {
        this.notificationService.error('request.list.extract.error');
        this.spinner.hide();
      }
    );
  }

  public searchNavigate(criteria: RequestSearchCriteria, resetPage: boolean): void {
    if (!criteria.equals(this.effectiveSearchCriteria)) { this.selectedMenu.resetSelected(); }
    const listUrl = ['workspaces', this.currentUser.activeWorkspace.code, 'requests'];
    this.querySearchService.navigateWithQueries<RequestSearchCriteria>(criteria, listUrl, {resetPage: resetPage});
  }

  /** date filters **/
  setFromDate(string: string) {
    this.searchCriteria.fromDateCreation = new Date(string);
  }

  setToDate(string: string) {
    this.searchCriteria.toDateCreation = new Date(string);
  }

  getFormattedFromDate(): string {
    return this.searchCriteria.fromDateCreation.toJSON()
      ? this.searchCriteria.fromDateCreation.toJSON().split('T')[0]
      : null;
  }

  getFormattedToDate(): string {
    return this.searchCriteria.toDateCreation.toJSON()
      ? this.searchCriteria.toDateCreation.toJSON().split('T')[0]
      : null;
  }

  /** checkboxes **/

  private resetCheckedRequests() {
    this.areAllRequestChecked = false;
    this.selectedRequests.clear();
  }

  public switchCheckValueAll() {
    if (!this.allRequestsChecked()) {
      this.checkableRequest.map(el => {
        el.checkedInList = true;
        this.selectedRequests.add(el.id);
      });
      this.areAllRequestChecked = true;
    } else {
      this.checkableRequest.map(el => {
        el.checkedInList = false;
        this.selectedRequests.delete(el.id);
      });
      this.areAllRequestChecked = false;
    }
  }

  public allRequestsChecked(): boolean {
    return this.checkableRequest.length !== 0 && this.checkableRequest.map(el => this.selectedRequests.has(el.id)).every(el => el);
  }

  /** close modal **/
  public openCloseRequestsModal() {
    this.closeRequestsModal.open();
  }

  public cancelCloseRequests() {
    this.closeRequestsModal.close();
  }

  public validateCloseRequests() {
    this.spinner.show();
    this.requestService.bulkClose(Array.from(this.selectedRequests.values())).subscribe(_ => {
      this.closeRequestsModal.close();
      this.resetCheckedRequests();
      this.loadRequests(this.effectiveSearchCriteria, false).subscribe();
      this.actualizeMetricsBus.emit();
      this.notificationService.success('common.success');
      this.spinner.hide();
    }, err => {
      this.notificationService.error(err);
      this.spinner.hide();
    });
  }

  public childrenRequestChecked(requestCheckedId: string) {
    this.selectedRequests.add(requestCheckedId);
    this.areAllRequestChecked = this.allRequestsChecked();
  }

  public childrenRequestUnchecked(requestCheckedId: string) {
    this.selectedRequests.delete(requestCheckedId);
    this.areAllRequestChecked = this.allRequestsChecked();
  }

  public addDeliveryNumberToFilter(deliveryNumber: string) {
    this.searchCriteria.deliveryNumber = [deliveryNumber];
    this.searchNavigate(this.searchCriteria, true);
  }

  public validateSaveSearch() {
    const customSearch = new CustomSearch(this.searchCriteria, this.saveSearchName);

    this.customSearchService
      .saveCustomSearch(customSearch)
      .subscribe(cust => {
        console.log(`Custom Search ${cust.name} with id ${cust.id} saved`);
        this.toastrService.success(this.translateService.instant('custom-request-search.save.success', {customSearchName: cust.name}));
        this.selectedMenu.publishCustomSearch(cust);
        this.sessionService.fetchCurrentUser().subscribe();
      }, err => {
        console.error(`Custom Search ${customSearch.name} could not be saved : ${err}`);
        if (err.error === 'duplicate') {
          this.toastrService.error(this.translateService.instant('custom-request-search.error.duplicate'));
        } else {
          this.toastrService.error(this.translateService.instant('common.error'));
        }
      }, () => {
        this.saveSearchModal.close();
        this.saveSearchName = null;
      });
  }

  public openSaveSearchModal() {
    this.saveSearchModal.open();
    this.saveSearchName = null;
  }

  whichCustomSearchIsSelected(): CustomSearch {
    for (const customSearch of this.userCustomSearches) {
      if (this.selectedMenu.getCustomSearchSubject().getValue().customSearch?.id === customSearch.id) {
        return customSearch;
      }
    }
    return null;
 }

  openUpdateSearchModal() {
    this.customSearchToUpdateId = this.whichCustomSearchIsSelected()?.id;
    this.updateSearchModal.open();
  }

  cancelUpdateSearch() {
    this.updateSearchModal.close();
  }

  validateUpdateSearch(customSearchToUpdateId: string) {
    const customSearchToUpdate = this.userCustomSearches.find(el => el.id === customSearchToUpdateId);
    this.customSearchService
      .updateCustomSearch(customSearchToUpdateId, CustomSearch.fromCustomSearchAndSearchCriterias(customSearchToUpdate, this.searchCriteria))
      .subscribe(cust => {
        console.log(`Custom Search ${cust.name} with id ${cust.id} updated`);
        this.toastrService.success(this.translateService.instant('custom-request-search.update.success', {customSearchName: cust.name}));
        this.selectedMenu.publishCustomSearch(cust);
        this.sessionService.fetchCurrentUser().subscribe();
        // 224aac7e-8f66-11ee-b9d1-0242ac120002 : If you ask, the request refresh is triggered in the app-menu component, by the
        // reselectMenu() function
      }, err => {
        console.error(`Custom Search ${customSearchToUpdate.name} could not be updated : ${err.message}`);
        this.toastrService.error(this.translateService.instant('common.error'));
      }, () => {
        this.updateSearchModal.close();
      });
  }

  cancelSaveSearch() {
    this.saveSearchModal.close();
  }

  refreshFormValidityWithNewDomainsIds($event: string[]) {
    this.searchCriteria.domainIds = $event;
    this.refreshFormValidity(this.searchCriteria);
  }

  refreshValidityWithNewCompanyId($event: string) {
    this.searchCriteria.companyId = $event;
    this.refreshFormValidity(this.searchCriteria);
  }
}
