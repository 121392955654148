import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isArray} from 'rxjs/internal-compatibility';

@Component({
  selector: 'a-input',
  templateUrl: './a-input.component.html',
  styleUrls: ['./a-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AInputComponent),
      multi: true
    }
  ]
})
export class AInputComponent implements ControlValueAccessor, AfterViewInit {

  @Input()
  public inputType: string = 'text';

  @Input()
  public multipleSeparator: string = ',';

  @Input()
  public multiple: boolean = false;

  @Input()
  public name: string;

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public displayLink: boolean = false;

  @Input()
  public placeholderKey: string = '';

  @Input()
  public placeholderKeyI18nParams: any;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  public hasIcon: boolean;

  @Input()
  public delete: boolean;

  @Input()
  public faIcon: string;

  @Input()
  public max: number;

  @Input()
  public min: number;
  @Input()
  public loading: boolean;

  public data: string;

  private onNgChange: (value: string | string[]) => void;
  private onNgTouched: () => void;

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('input') inputField: ElementRef;
  @Input() public focus: boolean = false;

  ngAfterViewInit() {
    if (this.focus) {
      this.inputField.nativeElement.focus();
    }
  }

  constructor() {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(obj: string | string[]): void {
    if (this.multiple) {
      this.data = obj ? (obj as string[]).join(this.multipleSeparator) : null;
    } else {
      this.data = obj as string;
    }
  }

  public onChange(data: string): void {
    this.data = data;
    if (this.multiple) {
      if (!data) {
        this.onNgChange(data);
      } else {
        const res = data.split(this.multipleSeparator).map(it => it.trim());
        this.onNgChange(isArray(res) ? res : [res]);
      }
    } else {
      this.onNgChange(data);
    }
  }

  public onDelete(): void {
    this.deleted.emit();
  }
}
