import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {
  ServiceDeskAuthToken,
  ServiceDeskAuthTokenWithLogin,
  ServiceDeskSession,
  TokenContext
} from '../../shared/models/ServiceDeskSession';
import {plainToClass} from 'class-transformer';
import {BaererSessionService} from './baerer-session.service';
import {UtilsService} from './utils.service';
import {WorkspaceSimple} from '../../shared/models/entity/workspaces/Workspace';
import {CompleteCurrentUser} from '../../shared/models/entity/users/CompleteCurrentUser';
import {SimpleCompany} from '../../shared/models/entity/companies/SimpleCompany';
import {Company} from '../../shared/models/entity/companies/Company';
import { environment } from '../../../../environments/environment';
import {CurrentUserStorageService} from './current-user-storage.service';

@Injectable()
export class SessionService {

  // undefined is no value, null is no user connected
  private connectedUser: BehaviorSubject<CompleteCurrentUser> = new BehaviorSubject<CompleteCurrentUser>(undefined);
  private activatedWorkspace: BehaviorSubject<WorkspaceSimple> = new BehaviorSubject<WorkspaceSimple>(undefined);
  private activatedCompany: BehaviorSubject<SimpleCompany> = new BehaviorSubject<SimpleCompany>(undefined);

  constructor(private router: Router,
              private http: HttpClient,
              private bearerSessionService: BaererSessionService,
              private currentUserStorageService: CurrentUserStorageService,
              private utilsService: UtilsService) {
  }

  public fetchCurrentUser(): Observable<CompleteCurrentUser> {
    return this.http.get(`${environment.apiUrl}/api/users/me`, {withCredentials: true})
      .pipe(map((principal: any) => plainToClass(CompleteCurrentUser, principal as Object)))
      .pipe(tap((user: CompleteCurrentUser) => {
        this.currentUserStorageService.store(user);
        this.connectedUser.next(user);
        this.activatedCompany.next(user.activeCompany || undefined);
        this.activatedWorkspace.next(user.activeWorkspace || undefined);
      }))
      .pipe(catchError((err: any, caught: Observable<CompleteCurrentUser>) => {
        this.connectedUser.next(null);
        this.activatedCompany.next(null);
        this.activatedWorkspace.next(null);
        return this.connectedUser.asObservable();
      }));
  }

  public authenticate(login: string, password: string): Observable<CompleteCurrentUser> {
    return this.http.get<ServiceDeskAuthToken>(`${environment.apiUrl}/api/users/me/token`,
      {
        withCredentials: true,
        headers: new HttpHeaders()
          .append('Authorization', `Basic ${btoa(login + ':' + password)}`)
      })
      .pipe(map((token: any) => plainToClass(ServiceDeskAuthToken, token as Object)))
      .pipe(map((token: ServiceDeskAuthToken) => new ServiceDeskSession(login, token)))
      .pipe(tap((session: ServiceDeskSession) => this.bearerSessionService.store(session)))
      .pipe(switchMap(() => this.fetchCurrentUser()));
  }

  public authenticateFromAuthorizationCode(authorizationCode: string): Observable<CompleteCurrentUser> {
    return this.http.get<ServiceDeskAuthToken>(`${environment.apiUrl}/api/users/me/token?code=${authorizationCode}`,
      {
        withCredentials: true,
      })
      .pipe(
        map((token: any) => plainToClass(ServiceDeskAuthTokenWithLogin, token as Object)),
        map((token: ServiceDeskAuthTokenWithLogin) => new ServiceDeskSession(token.login, token)),
        tap((session: ServiceDeskSession) => this.bearerSessionService.store(session)),
        switchMap(() => this.fetchCurrentUser())
      );
  }

  public authenticateFromToken(externalToken: string): Observable<CompleteCurrentUser> {
    let context: TokenContext;
    return this.http.post<ServiceDeskAuthTokenWithLogin>(`${environment.apiUrl}/api/tokenized/users/me/token?token=${externalToken}`, {})
      .pipe(
        tap((token: any) => context = token.context),
        map((token: any) => plainToClass(ServiceDeskAuthTokenWithLogin, token as Object)),
        map((token: ServiceDeskAuthTokenWithLogin) =>
          new ServiceDeskSession(token.login, new ServiceDeskAuthToken(token.token, token.timestamp, token.validUntil), context)),
        tap((session: ServiceDeskSession) => this.bearerSessionService.store(session)),
        switchMap(() => this.fetchCurrentUser())
      );
  }

  public logoutFrom(workspaceCode: string = null): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/api/users/me/token`)
      .pipe(
        tap(_ => {
          const backUrl = this.bearerSessionService.current()?.context?.backUrl;
          this.clearSession();

          if (!backUrl || !this.utilsService.isUrl(backUrl)) {
            const queryParams = workspaceCode ? {workspaceCode: workspaceCode} : {};
            this.router.navigate(['/login'], {state: { logout: true }, queryParams});
          } else {
            window.location.href = backUrl;
          }
        })
      );
  }

  public clearSession(): void {
    this.bearerSessionService.clear();
    this.connectedUser.next(null);
    this.activatedCompany.next(null);
    this.activatedWorkspace.next(null);
    this.currentUserStorageService.clear();
  }

  public activeContext(workspace: WorkspaceSimple, company: SimpleCompany): Observable<CompleteCurrentUser> {
    return this.http.post<Company>(`${environment.apiUrl}/api/users/me/context`, {workspace: workspace, company: company})
      .pipe(
        switchMap(() => this.fetchCurrentUser()),
        tap(() =>
          this.router.navigate(['/workspaces', workspace.code, 'dashboard'])
        ),
      );
  }

  public activeWorkspace(workspaceCode: string): Observable<CompleteCurrentUser> {
    return this.http.post<WorkspaceSimple>(`${environment.apiUrl}/api/users/me/context/workspace`, {code: workspaceCode})
      .pipe(
        switchMap(() => this.fetchCurrentUser())
      );
  }

  public getCurrentUser(): Observable<CompleteCurrentUser> {
    return this.connectedUser;
  }

  public getCurrentUserValue(): CompleteCurrentUser {
    return this.connectedUser.getValue();
  }

  // auto-completes. Either this, or think to unsubscribe to getCurrentUser !!
  public getCurrentUserOnce(): Observable<CompleteCurrentUser> {
    return this.connectedUser.pipe(
      take(1)
    );
  }

  public getActivatedCompany(): Observable<SimpleCompany> {
    return this.activatedCompany.pipe(filter(v => v !== undefined));
  }

  public getActivatedWorkspace(): Observable<WorkspaceSimple> {
    return this.activatedWorkspace.pipe(filter(v => v !== undefined));
  }

  public maybeFetchCurrentUserFromStorage(): CompleteCurrentUser {
    if (!this.bearerSessionService.current() || !this.tokenIsStillValid(this.bearerSessionService.current().token)) { return null; }
    const currentUserFromCache = this.currentUserStorageService.current();
    if (currentUserFromCache == null) { return null; } // should not happen but we never know
    this.connectedUser.next(currentUserFromCache);
    this.activatedCompany.next(currentUserFromCache.activeCompany || undefined);
    this.activatedWorkspace.next(currentUserFromCache.activeWorkspace || undefined);
    return currentUserFromCache;
  }

  private tokenIsStillValid(token: ServiceDeskAuthToken) {
    // required for ancient users
    if (typeof token.validUntil === 'string') { return false; }
    return token.validUntil.getTime() > new Date().getTime();
  }
}
