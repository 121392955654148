import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../app-root/services/session.service';
import {PaginatedCriteria, SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {catchError, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {WorkspaceUserService} from '../../../app-root/services/workspace-user.service';
import {Company} from '../../../shared/models/entity/companies/Company';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {NgxSpinnerService} from 'ngx-spinner';
import {
  PaginatedSimpleWorkspaceUserListElements
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceUserListElements';
import {
  SimpleWorkspaceUserListElement
} from '../../../shared/models/entity/users/simple/SimpleWorkspaceUserListElement';
import {CompanyService} from '../../../app-root/services/company.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {NavigationService} from '../../../shared/services/navigation-service';

@Component({
  selector: 'users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy {

  public currentUser: CompleteCurrentUser;
  public DEFAULT_STARTING_PAGE: number = 0;
  public DEFAULT_STEP: number = 10;
  public userSearchCriteria: WorkspaceUserSearchCriteria;
  public paginatedUsers: Array<SimpleWorkspaceUserListElement>;
  private _initialUsersSet: PaginatedSimpleWorkspaceUserListElements;
  public usersCount: number;
  public allCompaniesObs: Observable<Company[]>;
  private reloadOnNavigationSub: Subscription;
  canLoadUsers: boolean;

  @Input()
  public set initialUsersSet(paginatedUsers: PaginatedSimpleWorkspaceUserListElements) {
    this._initialUsersSet = paginatedUsers;
    this.reset(paginatedUsers);
  }

  @Output()
  public reloadUsers: EventEmitter<PaginatedCriteria> = new EventEmitter<PaginatedCriteria>();

  constructor(private router: Router,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private sessionService: SessionService,
              private spinnerService: NgxSpinnerService,
              private companyService: CompanyService,
              private workspaceUserService: WorkspaceUserService,
              private searchCriteriaService: SearchCriteriaService,
              private navigationService: NavigationService
  ) {
    this.sessionService.getCurrentUserOnce().subscribe(user => {
      this.currentUser = user;
    });
  }

  public ngOnDestroy() {
    this.reloadOnNavigationSub.unsubscribe();
  }

  public ngOnInit(): void {
    this.userSearchCriteria = WorkspaceUserSearchCriteria.newEmptyWithPagination(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    this.allCompaniesObs = this.companyService.findAllCurrent();

    this.reloadOnNavigationSub = this
      .searchCriteriaService
      .buildSearchCriteriaByQueries(
        this.userSearchCriteria,
        {step: this.DEFAULT_STEP, startingPage: this.DEFAULT_STARTING_PAGE}
      )
      .pipe(
        filter(_ => this.canLoadUsers === true),
        switchMap(searchCriteria => {
          this.userSearchCriteria = searchCriteria;
          return this.loadUsers(this.userSearchCriteria);
        })
      ).subscribe();

    this.canLoadUsers = true;
    if (this._initialUsersSet) {
      this.usersCount = this._initialUsersSet.metadata.count;
    } else {
      this.searchCriteriaService.navigateWithQueries(
        this.userSearchCriteria,
        this.navigationService.getUsersSettingsListUrl()
      );
    }
  }

  public sortUsers(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.userSearchCriteria.sort = sort.active;
    this.userSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];

    this.searchCriteriaService.navigateWithQueries(
      this.userSearchCriteria,
      this.navigationService.getUsersSettingsListUrl()
    );
  }

  public filter(): void {
    this.searchCriteriaService.navigateWithQueries(
      this.userSearchCriteria,
      this.navigationService.getUsersSettingsListUrl(),
      {resetPage: true}
    );
  }

  public goToAddUserPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'users', 'new']);
  }

  public onUserDisabled(): void {
    this.reloadUsers.emit(this.userSearchCriteria);
  }

  public reset(newSet: PaginatedSimpleWorkspaceUserListElements): void {
    this.userSearchCriteria = new WorkspaceUserSearchCriteria(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    this.paginatedUsers = newSet.result;
  }

  // equivalent to reset(emptySet)
  public resetToEmpty(): void {
    this.userSearchCriteria = new WorkspaceUserSearchCriteria(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    this.paginatedUsers = [];
  }

  public loadUsers(userSearchCriteria: WorkspaceUserSearchCriteria): Observable<PaginatedSimpleWorkspaceUserListElements> {
    this.spinnerService.show();
    return this.workspaceUserService.findSimpleUsersListElements(userSearchCriteria).pipe(
        finalize(() => this.spinnerService.hide()),
        tap(paginatedResult => {
          this.paginatedUsers = paginatedResult.result;
          this.usersCount = paginatedResult.metadata.count;
        }),
        catchError(_ => {
          this.resetToEmpty();
          return of(null);
        })
      );
  }

  public onPageChanged(userSearchCriteria: WorkspaceUserSearchCriteria): void {
    this.searchCriteriaService.navigateWithQueries(
      userSearchCriteria,
      this.navigationService.getUsersSettingsListUrl()
    );
  }

  onStepChanged(userSearchCriteria: WorkspaceUserSearchCriteria) {
    this.searchCriteriaService.navigateWithQueries(
      userSearchCriteria,
      this.navigationService.getUsersSettingsListUrl(),
      {resetPage: true}
    );
  }
}
