import {Component} from '@angular/core';
import {PaginatedCriteria} from '../../../../shared/models/entity/paginated/PaginatedCriteria';
import {WorkspaceAgentSearchCriteria} from '../../../../shared/models/criterias/WorkspaceAgentSearchCriteria';
import {SessionService} from '../../../../app-root/services/session.service';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../../../../shared/models/entity/companies/Company';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {
  PaginatedSimpleWorkspaceAgentListElements
} from '../../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceAgentListElements';
import {getDefaultedBy} from '../../../../shared/functions/get-defaulted';

@Component({
  templateUrl: './agents-tab.component.html',
  styleUrls: ['./agents-tab.component.scss']
})
export class AgentsTabComponent {

  public defaultStartingPage: number = 0;
  public defaultStep: number = 10;

  public allCompanies: Array<Company>;

  public initialAgents: PaginatedSimpleWorkspaceAgentListElements;

  constructor(
    private sessionService: SessionService,
    private workspaceUserService: WorkspaceUserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.initialAgents = data['initialAgents'];
    });
  }

  public reloadAgents(paginatedCriteria?: WorkspaceAgentSearchCriteria): void {
    this.workspaceUserService.findSimpleAgentsListElements(
      WorkspaceAgentSearchCriteria.newEmptyWithPagination(
        getDefaultedBy(paginatedCriteria.page, this.defaultStartingPage),
        getDefaultedBy(paginatedCriteria.step, this.defaultStep)
      )
    ).subscribe(agents => this.initialAgents = agents);
  }

}
