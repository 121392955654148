import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-button-back',
    templateUrl: './a-button-back.component.html',
    styleUrls: ['./a-button-back.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class AButtonBackComponent {

  @Input()
  public loading: false;

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public small: boolean;

  @Input()
  public white: boolean;

  @Input()
  public showBackIcon: boolean = true;

  @Input()
  public expanded: boolean = true;

  back() {
    history.go(-1);
  }
}
