import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {SmartSpinnerService} from '../../shared/services/smart-spinner.service';

export abstract class AbstractResolver<T> implements Resolve<T> {

  protected constructor(private spinnerService: SmartSpinnerService, private label?: string) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> {
    if (this.label) { this.spinnerService.show(this.label); }
    else { this.spinnerService.show(); }
    return this.doResolve(route, state)
      .pipe(finalize(() => this.spinnerService.hide()));
  }

  protected abstract doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T>;
}
