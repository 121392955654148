import {Type} from 'class-transformer';
import {ModificationInfo} from '../common/ModificationInfo';
import {SimpleCompany} from '../companies/SimpleCompany';
import {SimpleDomain} from '../classifications/SimpleDomain';

/** used for lists **/

export class SimpleTeamWithAgentsCount {
  id: string;
  @Type(() => ModificationInfo)
  modificationInfo: ModificationInfo;
  workspaceRef: string;
  name: string;
  agentsCount: number;
  @Type(() => SimpleDomain)
  domains: Array<SimpleDomain>;
  @Type(() => SimpleCompany)
  companies: Array<SimpleCompany>;
}
