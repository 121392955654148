import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../app-root/services/session.service';
import {
  PaginatedWorkspaceUser
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUser';
import {SimpleCompanyWithAgents} from '../../../shared/models/entity/companies/SimpleCompanyWithAgents';

@Component({
  templateUrl: './company-details-page.component.html',
  styleUrls: ['./company-details-page.component.scss']
})
export class CompanyDetailsPageComponent implements OnInit {

  public companyWithAgents: SimpleCompanyWithAgents;

  private workspaceCode: string;

  constructor(private route: ActivatedRoute, private router: Router, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: { companyWithAgents: SimpleCompanyWithAgents, allAgents: PaginatedWorkspaceUser }) => {
      if (data.companyWithAgents) { this.companyWithAgents = data.companyWithAgents; }
    });
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  public onCompanyWithAgentsSaved(): void {
      this.sessionService.fetchCurrentUser().subscribe(() => this.goToSettingsPage());
  }

  public goToSettingsPage(): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'companies']);
  }
}
