import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {switchMap} from 'rxjs/operators';
import {SessionService} from '../services/session.service';
import {of} from 'rxjs';

@Injectable()
export class TransportUserNewNoteGuard implements CanActivate {

  constructor(
    private sessionService: SessionService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.sessionService.getCurrentUserOnce()
      .pipe(
        switchMap((currentUser) => {
          const canAcccess = currentUser.isAgent();
          const workspace = currentUser.activeWorkspace.code;
          if (canAcccess) {
            return of(true);
          } else {
            return of(this.router.createUrlTree(['workspaces', workspace, 'requests', 'redirect']));
          }
        })
      );
  }
}
