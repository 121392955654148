import {Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../app-root/services/session.service';
import {filter, finalize, switchMap, tap} from 'rxjs/operators';
import {CompanyService} from '../../../app-root/services/company.service';
import {CompanySearchCriteria} from '../../../shared/models/criterias/CompanySearchCriteria';
import {Observable} from 'rxjs/internal/Observable';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {
  buildCompanyDomainsExportFormat,
  buildCompanyTeamsExportFormat
} from '../../../shared/models/entity/enums/ExportFormat';
import {BlobService} from '../../../app-root/services/blop.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../../app-root/services/notification.service';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {TeamService} from '../../../app-root/services/team.service';
import {APopupComponent} from '../../../shared/lib-components/atoms/a-popup/a-popup.component';
import {
  MSimpleCompanyWithDomainsComponent
} from '../../../shared/lib-components/molecules/m-company-with-agents/m-simple-company-with-domains.component';
import {SimpleCompanyWithDomains} from '../../../shared/models/entity/companies/SimpleCompanyWithDomains';
import {
  PaginatedSimpleCompaniesWithDomainsCount
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleCompaniesWithDomainsCount';
import {SimpleTeam} from '../../../shared/models/entity/teams/simple-team';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {NavigationService} from '../../../shared/services/navigation-service';

@Component({
  selector: 'companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit, OnDestroy {

  public currentUser: CompleteCurrentUser;
  public companySearchCriteria: CompanySearchCriteria = new CompanySearchCriteria();
  public currentSort: Sort = {active: 'BY_NAME', direction: 'asc'};
  public checkedCompany: Set<string> = new Set();
  public totalNumberOfCompanies: number = 0;
  // pagination
  public DEFAULT_PAGINATOR_STEP: number = 50;
  public numberOfCompanyPages: number = 0;
  public DEFAULT_STARTING_PAGE: number = 0;

  @ViewChild('teamAssignationModal')
  public teamAssignationModal: APopupComponent;

  @Input()
  public companies: Array<SimpleCompanyWithDomains> = [];

  @Input()
  public initialCompanies: PaginatedSimpleCompaniesWithDomainsCount;

  @ViewChildren(MSimpleCompanyWithDomainsComponent)
  public companyWithDomainsComponent: MSimpleCompanyWithDomainsComponent[];

  /** subscription **/
  private currentUserSub: Subscription;
  public allTeams: Array<SimpleTeam>;
  public allTeamsLoading: boolean;
  public areAllCompaniesChecked: boolean;
  public companyCheckboxValueBus: Subject<boolean>;

  private canLoadCompanies = false;
  private reloadOnNavigationSub: Subscription;

  constructor(private router: Router,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private sessionService: SessionService,
              private spinner: NgxSpinnerService,
              private notificationService: NotificationService,
              private companyService: CompanyService,
              private teamService: TeamService,
              private searchCriteriaService: SearchCriteriaService,
              private navigationService: NavigationService,
  ) {
    this.companyCheckboxValueBus = new Subject<boolean>();
  }

  public searchNavigate(resetPage: boolean = false) {
    this.searchCriteriaService.navigateWithQueries(
      this.companySearchCriteria,
      this.navigationService.getCompaniesSettingsListUrl(),
      {resetPage}
    );
  }

  public goToAddCompaniesPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'companies', 'new']);
  }

  public pageChanged(companySearchCriteria: CompanySearchCriteria): void {
    this.addSortToSearchCriteria(this.currentSort);
    this.resetCheckedCompanies();
    this.spinner.show();

    this.retrieveCompanies(companySearchCriteria)
      .pipe(
        tap(paginatedCompanies => this.companies = paginatedCompanies.result),
        tap(paginatedCompanies => this.totalNumberOfCompanies = paginatedCompanies.metadata.count),
      ).subscribe(_ => {
        this.spinner.hide();
        this.checkedCompany.clear();
      });
  }

  public filter(qSearchCriteria: string): void {
    this.companySearchCriteria.q = qSearchCriteria;
    this.searchNavigate(true);
  }

  public ngOnInit(): void {
    this.currentUserSub = this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);

    this.reloadOnNavigationSub = this
      .searchCriteriaService
      .buildSearchCriteriaByQueries(
        this.companySearchCriteria,
        {step: this.DEFAULT_PAGINATOR_STEP, startingPage: this.DEFAULT_STARTING_PAGE}
      )
      .pipe(
        filter(_ => this.canLoadCompanies === true),
        switchMap(searchCriteria => {
          this.companySearchCriteria = searchCriteria;
          return this.loadCompanies(this.companySearchCriteria);
        })
      ).subscribe();

    this.canLoadCompanies = true;

    if (this.initialCompanies) {
      this.companies = this.initialCompanies.result;
      this.totalNumberOfCompanies = this.initialCompanies.metadata.count;
    } else {
      this.searchCriteriaService.navigateWithQueries(
        this.companySearchCriteria,
        this.navigationService.getCompaniesSettingsListUrl()
      );
    }
  }

  ngOnDestroy(): void {
    this.currentUserSub.unsubscribe();
    this.reloadOnNavigationSub.unsubscribe();
  }

  public retrieveCompanies(companySearchCriteria: CompanySearchCriteria): Observable<PaginatedSimpleCompaniesWithDomainsCount> {
    return this.companyService.find(companySearchCriteria);
  }

  public loadCompanies(companySearchCriteria: CompanySearchCriteria): Observable<PaginatedSimpleCompaniesWithDomainsCount> {
    this.spinner.show();
    return this.retrieveCompanies(companySearchCriteria)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
        tap(paginatedResult => {
          this.areAllCompaniesChecked = false;
          this.companies = paginatedResult.result;
          this.totalNumberOfCompanies = paginatedResult.metadata.count;
        })
      );
  }

  public addSortToSearchCriteria(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.companySearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.companySearchCriteria.sort = sort.active;
  }

  public onMatSortChange(sort: Sort): void {
    this.addSortToSearchCriteria(sort);
    this.searchCriteriaService.navigateWithQueries(
      this.companySearchCriteria,
      this.navigationService.getCompaniesSettingsListUrl()
    );
  }

  public extractCompanyWithTeamsAndDomainsColumns(): void {
    this.spinner.show();
    forkJoin(
      [
        this.companyService.extractCompanyWithDomainsColumns(),
        this.companyService.extractCompanyWithTeamsColumns()
      ]
    ).pipe(
        finalize(() => this.spinner.hide())
    ).subscribe(([ companyDomainBlob, companyTeamBlob]) => {
      this.areAllCompaniesChecked = false;
      BlobService.downloadBlob(companyDomainBlob, buildCompanyDomainsExportFormat().requestFileName);
      BlobService.downloadBlob(companyTeamBlob, buildCompanyTeamsExportFormat().requestFileName);
    },
    () => this.notificationService.error('request.list.extract.error')
    );
  }

  /** modal **/

  openTeamAssignationModal() {
    this.teamAssignationModal.open();
    this.allTeamsLoading = true;
    this.teamService.findAllSimple().subscribe(teams => {
      this.allTeams = teams;
      this.allTeamsLoading = false;
    });
  }

  cancel() {
    this.teamAssignationModal.close();
  }

  validate(teamId: string) {
    this.spinner.show();
    const teamToUpdate = this.allTeams.find(el => el.id === teamId);
    this.teamService.addCompaniesToTeam(teamToUpdate, Array.from(this.checkedCompany)).subscribe(_ => {
      this.notificationService.success('settings.companies.team-modal.validate.success');
      this.spinner.hide();
      this.teamAssignationModal.close();
      this.router
        .navigate(['/workspaces', this.currentUser.activeWorkspace.code, 'settings', 'teams', teamId])
        .catch(err => new Error(err));
    }, err => this.notificationService.error('basic.error', {err}));
  }

  /** checkboxes **/

  public companyChecked(companyCheckedId: string) {
    this.checkedCompany.add(companyCheckedId);
    this.areAllCompaniesChecked = this.allCompaniesChecked();
  }

  public companyUnchecked(requestCheckedId: string) {
    this.checkedCompany.delete(requestCheckedId);
    this.areAllCompaniesChecked = this.allCompaniesChecked();
  }

  switchCheckValueAll(value) {
    if (value) {
      this.checkedCompany = new Set<string>(this.companies.map(company => company.id));
      this.areAllCompaniesChecked = true;
      this.companyCheckboxValueBus.next(true);
    } else {
      this.checkedCompany = new Set<string>();
      this.areAllCompaniesChecked = false;
      this.companyCheckboxValueBus.next(false);
    }
  }

  allCompaniesChecked(): boolean {
    return this.companies.map(el => this.checkedCompany.has(el.id)).every(el => el);
  }

  private resetCheckedCompanies() {
    this.areAllCompaniesChecked = false;
    this.checkedCompany.clear();
  }
}
