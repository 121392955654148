import {Entity} from '../models/entity/common/Entity';

export function distinctEntities<T extends Entity>(array): Array<T> {
  return array.filter((el, i, arr) => arr.findIndex(it => it.id === el.id) === i);
}

export function distinct<T>(array): Array<T> {
  return distinctBy(array, item => item);
}

export function distinctBy<T>(array, identity): Array<T> {
  const alreadySeenElement = [];
  return array.reduce((acc, current) => {
    if (!alreadySeenElement.includes(identity(current))) {
      alreadySeenElement.push(identity(current));
      return [...acc, current];
    } else {
      return acc;
    }
  }, []);
}

export function flatten<T>(array): Array<T> {
  return [].concat(... array);
}

export function arraysEqual(a: Array<any>, b: Array<any>): boolean {
  if (!a && b || !b && a) { return false; }
  if (!a && !b) { return true; }

  return a.filter(aEl => b.indexOf(aEl) !== -1).length === a.length;
}
