import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {Domain} from '../entity/classifications/Domain';
import {HttpParams} from '@angular/common/http';
import {Category} from '../entity/classifications/Category';
import {CompleteRequest} from '../entity/requests/CompleteRequest';
import {Params} from '@angular/router';

export class ResponseTemplateSearchCriteria extends PaginatedCriteria {

  constructor(public page: number = PaginatedCriteria.DEFAULT_PAGE,
              public step: number = PaginatedCriteria.DEFAULT_STEP,
              public q: string,
              public categoryId: string,
              public domainId: string,
              public name: string) {
    super(page, step);
  }

  static empty(): ResponseTemplateSearchCriteria {
    return new ResponseTemplateSearchCriteria(null, null, null, null, null, null);
  }

  static fromRequest(request: CompleteRequest) {
    return new ResponseTemplateSearchCriteria(null, null, null, request.category?.id, request.domain?.id, null);
  }

  static from(domain?: Domain, category?: Category) {
      return new ResponseTemplateSearchCriteria(null, null, null, category?.id, domain?.id, null);
    }

  protected transformIntoHttpParams(base: HttpParams): HttpParams {
    if (this.q) {
      base = base.set('q', this.q.trim());
    }
    if (this.domainId) {
      base = base.set('domainId', this.domainId);
    }
    if (this.name) {
      base = base.set('name', this.name);
    }
    if (this.categoryId) {
      base = base.set('categoryId', this.categoryId);
    }

    return base;
  }

  fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, null);

    this.q = params.q;
    this.domainId = params.domainId;
    this.name = params.name;
    this.categoryId = params.categoryId;

    return this;
  }

  protected reset() {
    super.defaultReset();

    this.q = undefined;
    this.domainId = undefined;
    this.name = undefined;
    this.categoryId = undefined;
  }
}
