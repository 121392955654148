import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {TeamSearchCriteria} from '../../../shared/models/entity/teams/team-search-criteria';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamService} from '../../../app-root/services/team.service';
import {SimpleTeamWithAgentsCount} from '../../../shared/models/entity/teams/simple-team-with-agents-count';
import {NgxSpinnerService} from 'ngx-spinner';
import {
  PaginatedSimpleTeamsWithAgentsCount
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleTeamsWithAgentsCount';
import {SimpleTeam} from '../../../shared/models/entity/teams/simple-team';
import {plainToClass} from 'class-transformer';
import {noop, Subscription} from 'rxjs';
import {AlertService} from '../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../app-root/services/notification.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {NavigationService} from '../../../shared/services/navigation-service';
import {skip, tap} from 'rxjs/operators';

@Component({
  selector: 'teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss']
})
export class TeamsListComponent implements OnInit, OnDestroy {

  /* pagination */
  DEFAULT_STARTING_PAGE: number = 0;
  DEFAULT_STEP: number = 10;

  /* results */
  teamsSearchCriteria: TeamSearchCriteria = new TeamSearchCriteria();
  displayedTeams: SimpleTeamWithAgentsCount[] = [];
  teamsCount: number;

  duplicateClicked = new EventEmitter<SimpleTeam>();

  @Input()
  initialTeams: PaginatedSimpleTeamsWithAgentsCount;
  private reloadOnNavigationSub: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private teamService: TeamService,
              private notificationService: NotificationService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService,
              private translateService: TranslateService,
              private searchCriteriaService: SearchCriteriaService,
              private navigationService: NavigationService,
  ) {
  }

  public ngOnInit(): void {
    this.reloadOnNavigationSub = this.searchCriteriaService.buildSearchCriteriaByQueries(
      this.teamsSearchCriteria,
      {
        startingPage: this.DEFAULT_STARTING_PAGE,
        step: this.DEFAULT_STEP
      }
    ).pipe(
      skip(1),
      tap(_ => this.loadTeams()),
    ).subscribe();

    this.initializeWithInitialTeams(this.initialTeams);
  }

  ngOnDestroy(): void {
    this.reloadOnNavigationSub.unsubscribe();
  }

  public filter(qSearchCriteria: string): void {
    this.teamsSearchCriteria.q = qSearchCriteria;
    this.searchCriteriaService.navigateWithQueries(this.teamsSearchCriteria, this.navigationService.getTeamsSettingsListUrl(), {resetPage: true});
  }

  goToTeam(teamId: string): void {
    this.router.navigate([teamId], { relativeTo: this.route});
  }

  deleteTeam(teamId: string) {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('settings.teams.delete.popup.title'),
      this.translateService.instant('settings.teams.delete.popup.message')
    ).subscribe( _ => {
      this.teamService.delete(teamId).subscribe(__ => {
        this.notificationService.success('settings.teams.delete.popup.success');
        this.searchCriteriaService.navigateWithQueries(this.teamsSearchCriteria, this.navigationService.getTeamsSettingsListUrl(), {resetPage: true});
      }, __ => {
        this.notificationService.error('responseTemplate.delete.error');
      });
    }, _ => noop());
  }

  private loadTeams() {
    this.spinner.show();

    this.teamService.find(this.teamsSearchCriteria).subscribe(teamsPaginated => {
      this.displayedTeams = teamsPaginated.result;
      this.teamsCount = teamsPaginated.metadata.count;
    }, (error) => {
      this.notificationService.error('Something bad happened...');
      console.error(error);
    }, () => {
      this.spinner.hide();
    });
  }

  openDuplicatePopup(team: SimpleTeamWithAgentsCount) {
    this.duplicateClicked.emit(plainToClass(SimpleTeam, team));
  }

  private initializeWithInitialTeams(initialTeams: PaginatedSimpleTeamsWithAgentsCount) {
    this.displayedTeams = initialTeams.result;
    this.teamsCount = initialTeams.metadata.count;
  }

  onStepChanged() {
    this.searchCriteriaService.navigateWithQueries(this.teamsSearchCriteria, this.navigationService.getTeamsSettingsListUrl(), {resetPage: true});
  }

  onPageChanged() {
    this.searchCriteriaService.navigateWithQueries(this.teamsSearchCriteria, this.navigationService.getTeamsSettingsListUrl());
  }
}
