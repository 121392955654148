import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, RequiredValidator, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {agentRoles, Role} from '../../../../models/entity/enums/Role';

@Component({
  selector: 'a-select-agent-role-form',
  templateUrl: './a-select-agent-role-form.component.html',
  styleUrls: ['./a-select-agent-role-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectAgentRoleFormComponent),
      multi: true
    }
  ]
})
export class ASelectAgentRoleFormComponent implements ControlValueAccessor, OnInit {

  public rolesWithLabel: { label: string; role: Role }[];
  public roleControl = new FormControl();

  private onNgChange: (value: Role[]) => void;
  private onNgTouched: () => void;

  @Input()
  public required: boolean;

  @Input()
  public name: string = 'role';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean;

  @Input()
  public multiple = false;

  constructor(private translateService: TranslateService) {
    this.rolesWithLabel = agentRoles().map(role =>  {
      return {label: translateService.instant('user.role.' + role), role: role};
    });
  }

  ngOnInit(): void {
    this.roleControl.valueChanges.subscribe(newValue => {
      this.onChange(newValue);
    });
    if (this.required) {
      this.roleControl.setValidators(Validators.required);
    }
    if (this.disabled) {
      this.roleControl.disable();
    }
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(role: Role[]): void {
    this.roleControl.setValue(
      {label: this.translateService.instant('user.role.' + role), role: role}
    );
  }

  public onChange(roleWithLabel: any): void {
    if (!this.onNgChange) { return; }

    if (roleWithLabel instanceof Array) {
      roleWithLabel !== undefined && roleWithLabel !== null
        ? this.onNgChange(roleWithLabel.map(el => el.role))
        : this.onNgChange(undefined);
    } else {
      roleWithLabel !== undefined && roleWithLabel !== null
        ? this.onNgChange(roleWithLabel.role)
        : this.onNgChange(undefined);
    }
  }

  compare(role1: { label: string; role: Role },
          role2: { label: string; role: Role }): boolean {
    return role1 != null && role2 != null && role1.role === role2.role;
  }
}
