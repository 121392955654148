import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {Params} from '@angular/router';

export class WorkspaceUserSearchCriteria extends PaginatedCriteria {

  constructor(public page: number = PaginatedCriteria.DEFAULT_PAGE,
              public step: number = PaginatedCriteria.DEFAULT_STEP,
              public companyId?: string,
              public q?: string) {
    super(page, step);
  }

  static newEmptyWithPagination(page?: number, step?: number) {
    return new WorkspaceUserSearchCriteria(page, step);
  }

  protected transformIntoHttpParams(params: HttpParams): HttpParams {
    if (this.q) {
      params = params.set('q', this.q.trim());
    }

    if (this.companyId) {
      params = params.set('companyId', this.companyId);
    }

    return params;
  }

  public fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, null);

    this.q = params.q;
    this.companyId = params.companyId;

    return this;
  }

  protected reset() {
    super.defaultReset();

    this.q = undefined;
    this.companyId = undefined;
  }
}
