import {Component, Input} from '@angular/core';
import {SizeAvatar} from '../../atoms/a-avatar/a-avatar.component';
import {User} from '../../../models/entity/users/User';
import {Role} from '../../../models/entity/enums/Role';
import { PositionDropdown } from '../m-badge-dropdown/m-badge-dropdown.component';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';
import {WorkspaceUserWithAvatar} from '../../../models/entity/users/interfaces/WorkspaceUserWithAvatar';

@Component({
    selector: 'm-avatar-list',
    templateUrl: './m-avatar-list.component.html',
    styleUrls: ['./m-avatar-list.component.scss']
})
export class MAvatarListComponent {

  @Input() public sizeAvatar: SizeAvatar = 'DEFAULT';
  @Input() public white: boolean;
  @Input() public defaultRole: Role;
  @Input() public dropdownTitleKey;
  @Input() public set users(users: Array<SimpleWorkspaceUser|User>) {
    this._displayedUsers = users.slice(0, this.nbUserDisplayed);
    this._remainingUsers = users.slice(this.nbUserDisplayed);
  }

  public nbUserDisplayed = 3;
  public _displayedUsers: Array<WorkspaceUserWithAvatar|User> = [];
  public _remainingUsers: Array<WorkspaceUserWithAvatar|User> = [];
  public POSITION_DROPDOWN = PositionDropdown;
}
