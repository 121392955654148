import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {PaginatedWorkspaces} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {WorkspaceService} from '../../services/workspace.service';
import {WorkspacesSearchCriteria} from '../../../shared/models/criterias/WorkspacesSearchCriteria';
import {AbstractResolver} from '../abstract-resolver';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';

@Injectable()
export class WorkspacesPaginateResolver extends AbstractResolver<PaginatedWorkspaces> {

  private initialPage: number = 0;
  private step: number = 10;

  private searchCriteria = new WorkspacesSearchCriteria();

  constructor(
    spinnerService: SmartSpinnerService,
    private workspaceService: WorkspaceService,
    private searchCriteriaService: SearchCriteriaService,
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedWorkspaces> {
    return this.searchCriteriaService.buildSearchCriteriaByQueriesFromRouteSnapshot(
      route,
      this.searchCriteria,
      {
        startingPage: this.initialPage,
        step: this.step
      }
    ).pipe(
      take(1),
      switchMap(searchCriteria => {
        return this.workspaceService.search(searchCriteria);
      })
    );
  }
}
