import { Component, OnInit } from '@angular/core';
import {PaginatedWorkspaces} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {
  PaginatedAdministrationUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-administration-workspaces',
  templateUrl: './administration-workspaces.component.html',
  styleUrls: ['./administration-workspaces.component.scss']
})
export class AdministrationWorkspacesComponent implements OnInit {
  initialWorkspaces: PaginatedWorkspaces;

  constructor(
    route: ActivatedRoute
  ) {
    route.data.subscribe((data: { initialWorkspaces: PaginatedWorkspaces, initialUsers: PaginatedAdministrationUsers }) => {
      this.initialWorkspaces = data.initialWorkspaces;
    });
  }

  ngOnInit(): void {
  }

}
