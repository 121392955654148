import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgForm} from '@angular/forms';
import {AInputMailValidatorComponent} from '../../atoms/forms/a-input-mail-validator/a-input-mail-validator.component';
import {UtilsService} from '../../../../app-root/services/utils.service';
import {AttachmentService} from '../../../../app-root/services/attachment.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {finalize, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {SharedCollection} from '../../../models/entity/enums/SharedCollection';
import {SessionService} from '../../../../app-root/services/session.service';
import {PaginatedCriteria} from '../../../models/entity/paginated/PaginatedCriteria';
import {RequestService} from '../../../../app-root/services/request.service';
import {AlertService} from '../../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../../models/entity/enums/Role';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {Company} from '../../../models/entity/companies/Company';
import {WorkspaceUserWithCompanies} from '../../../models/entity/users/WorkspaceUserWithCompanies';
import {WorkspaceUserWithCompaniesModification} from '../../../models/entity/users/WorkspaceUserModification';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {WorkspaceUser} from '../../../models/entity/users/WorkspaceUser';
import {SearchResultRequest} from '../../../models/entity/requests/RequestSearchResult';
import {AdministrationUserService} from '../../../../app-root/services/administration-user.service';
import {TeamService} from '../../../../app-root/services/team.service';
import {getNotPresentFrom} from '../../../functions/get-not-present-from';
import {equalsArray} from '../../../functions/equals-array';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';
import {CompanyService} from '../../../../app-root/services/company.service';
import {LightTeam} from '../../../models/entity/teams/light-team';

// /!\ this screen is only seen by an agent or agent-admin current user
// either to modify their personal infos for an agent, or modify other infos for an agent admin
// for the user counterpart, please see current-user-profile-page.component

@Component({
  selector: 'm-user-form',
  templateUrl: './m-user-form.component.html',
  styleUrls: ['./m-user-form.component.scss']
})
export class MUserFormComponent implements OnInit {
  constructor(private notificationService: NotificationService,
              private spinner: NgxSpinnerService,
              private attachmentService: AttachmentService,
              private workspaceUserService: WorkspaceUserService,
              private utilsService: UtilsService,
              private sessionService: SessionService,
              private requestService: RequestService,
              private alertService: AlertService,
              private translateService: TranslateService,
              private userService: AdministrationUserService,
              private companyService: CompanyService,
              private teamService: TeamService) {
  }

  @ViewChild('emailInputComponent')
  private emailInputComponent: AInputMailValidatorComponent;

  @Input()
  public disabled: boolean = false;

  @Input()
  public title: string = '';

  @Input()
  public companies: Array<Company>;

  @Input()
  get user(): WorkspaceUserWithCompanies {
    return this._user;
  }

  set user(user: WorkspaceUserWithCompanies) {
    this._user = user;
    if (user) {
      this.isUser = this.user.role === Role.USER;
      this.userModification = WorkspaceUserWithCompaniesModification.from(user);
      this.initialCompanies = [ ...this.userModification.companies];
      this.initialAutoAffectedCompanies = [ ...this.userModification.companiesAutoAffected];
      this.companiesWithAutoAffectationSorted = this.userModification.companies
        .sort((a, b) => this.utilsService.compareStrings(a.name, b.name, true));
      this.initialEmail = user.email;
    }
  }

  @Input()
  public userTeams: LightTeam[] = [];

  @Output()
  public userSaved = new EventEmitter<WorkspaceUserWithCompanies>();

  @ViewChild('form')
  public form: NgForm;

  public formSpinnerName: string = 'mUserFormSpinner';
  public initialEmail: string;
  public avatarChanged: boolean = false;
  public avatarToUploadPreview: string;
  public avatarToUpload: File;
  public companiesWithAutoAffectationSorted: Array<Company>;
  private currentUser: CompleteCurrentUser;
  public userModification: WorkspaceUserWithCompaniesModification;
  public initialCompanies: Array<Company>;
  public isUser: boolean = true;
  public selectedTeams: LightTeam[];
  public Role = Role;
  public companiesObs: Observable<Company[]>;
  public teamsObs: Observable<LightTeam[]>;
  private originalAgentTeamIds: string[];
  private _user: WorkspaceUserWithCompanies;
  private initialAutoAffectedCompanies: Array<Company>;

  public ngOnInit() {
    this.sessionService.getCurrentUserOnce().subscribe(user => {
      this.currentUser = user;
    });

    if (!this.user.isUser()) {
      this.teamsObs = this.teamService.findAllLight();
      this.selectedTeams = this.userTeams;
      this.originalAgentTeamIds = this.userTeams?.map(it => it.id);
    } else {
      this.teamsObs = of([]);
    }

    // companies obs
    if (this.currentUser.activeRole === Role.AGENT_ADMIN) {
      this.companiesObs = this.companyService.findAllCurrent().pipe(
        shareReplay(1) // important so that the other select doesn't reload everything
      );
    } else {
      this.companiesObs = of([]);
    }
  }

  // Needed because the component (select-companies) keeps track of its own dataset,
  // causing incoherences between ours and theirs.
  // Reallocating the variable launches the ngModelChange event, which in turns changes the component dataSet
  // according to ours.
  public onUpdate(): void {
    if (this.user.role === Role.USER) {
      this.updateUser();
    } else if (this.user.role === Role.AGENT_ADMIN || this.user.role === Role.AGENT) {
      this.updateAgent();
    }
  }

  private updateUser() {
    this.save(this.userModification.toWorkspaceUserWithCompanies())
      .pipe(
        switchMap((newUser) => this.uploadAvatar(newUser)),
        switchMap(() => this.maybeUpdateUserInfo()),
        finalize(() => this.spinner.hide(this.formSpinnerName))
      ).subscribe(
      () => {
        this.notificationService.success(this.userModification.workspaceUser.userId ? 'notification.user.updated' : 'notification.user.saved');
        this.userSaved.emit();
      },
      error => this.showGenericError(error)
    );
  }

  public updateAgent(): void {
    let userTmp: WorkspaceUserWithCompanies;

    this.spinner.show(this.formSpinnerName);

    if (this.userModification.workspaceUser.role === Role.AGENT) {
      this.userModification.allCompanies = false;
      this.userModification.allCompaniesAutoAffected = false;
    }

    this.save(this.userModification.toWorkspaceUserWithCompanies()).pipe(
      tap(savedUser => userTmp = savedUser),
      switchMap( _ => {
        if (this.teamsHaveBeenModified()) { return this.updateTeamModificationsForUser(userTmp); } else { return of(null); }
      } ),
      switchMap(() => this.uploadAvatar(userTmp)),
      switchMap(() => this.maybeUpdateUserInfo()),
      switchMap(() => this.workspaceUserService.getByUserIdInCurrentWorkspace(userTmp.userId)),
      finalize(() => this.spinner.hide(this.formSpinnerName))
    ).subscribe(
      finalUser => {
        this.notificationService.success(this.userModification.workspaceUser.userId ? 'notification.user.updated' : 'notification.user.saved');
        this.user = finalUser;
        this.form.form.markAsPristine();
        this.userSaved.emit(finalUser);
      },
      error => {
        this.showGenericError(error);
      }
    );
  }

  private maybeUpdateUserInfo() {
    if (this.userModification.workspaceUser.id) {
      return this.userService.update(this.userModification.toUserWithEmail());
    } else {
      return of(null);
    }
  }

  public save(user: WorkspaceUserWithCompanies): Observable<WorkspaceUserWithCompanies> {
    return this.user.id ? this.workspaceUserService.update(user) : this.workspaceUserService.create(user);
  }

  private uploadAvatar(user: WorkspaceUserWithCompanies): Observable<any> {
    if (this.avatarChanged && this.avatarToUpload) {
      return this.attachmentService.uploadAttachment({
        collection: SharedCollection.USERS,
        id: user.userId
      }, this.avatarToUpload, this.currentUser);
    } else if (this.avatarChanged && !this.avatarToUpload) {
      return this.attachmentService.removeAvatar(user.userId);
    } else {
      return of(null);
    }
  }

  public onAutoAffectedCompaniesChange(companies: Array<Company>): void {
    if (this.companyHasBeenRemoved(this.userModification.companiesAutoAffected, companies)) {
      const removedCompany = this.retrieveDeletedCompany(this.userModification.companiesAutoAffected, companies);

      if (!this.initialAutoAffectedCompanies.map(company => company.id).includes(removedCompany.id)) {
        this.userModification.companiesAutoAffected = companies;
        return;
      }

      if (this.user.isAgent()) {
        this.workspaceUserService.findAutoAffectedAgentsForCompany(removedCompany.id)
          .subscribe(res => {
            if (this.agentIsLastAutoAffected(res, this.user)) {
              this.notificationService.error('user.form.company.deleteError');
              this.revertModificationCompaniesChange();
            } else {
              this.userModification.companiesAutoAffected = companies;
            }
          });
      } else { // is an user
        this.userModification.companiesAutoAffected = companies;
      }
    } else {
      this.userModification.companiesAutoAffected = companies;
    }
  }

  public revertModificationCompaniesChange(): void {
    this.userModification.companies = [...this.userModification.companies];
    this.userModification.companiesAutoAffected = [...this.userModification.companiesAutoAffected];
  }

  public companyHasBeenRemoved(initial: Array<Company>, newSet: Array<Company>): boolean {
    const initialId = initial.map(company => company.id);
    return newSet.filter(company => initialId.includes(company.id)).length < initial.length;
  }

  public retrieveDeletedCompany(initial: Array<Company>, newSet: Array<Company>): Company {
    const deletedId = initial.map(company => company.id).find(companyId => !newSet.map(company => company.id).includes(companyId));
    return initial.find(company => company.id === deletedId);
  }

  public agentIsLastAutoAffected(agents: Array<SimpleWorkspaceUser>, agent: SimpleWorkspaceUser): boolean {
    return agents.length === 1 && agents[0].userId === agent.userId;
  }

  public showGenericError(error: any): void {
    this.notificationService.error(this.userModification.workspaceUser.userId ?
      'notification.user.updated.error' : 'notification.user.saved.error', {error: error.message || error.statusText});
    console.error(error.stack);
  }

  public onCompaniesChange(companies: Array<Company>): void {
    if (this.companyHasBeenRemoved(this.userModification.companies, companies)) {
      const removedCompany = this.retrieveDeletedCompany(this.userModification.companies, companies);

      if (!this.initialCompanies.map(company => company.id).includes(removedCompany.id)) {
        this.updateUserModificationCompanies(companies);
        return;
      }

      forkJoin([
        this.requestService.findRelatedRequestsForCompanyAgent(removedCompany.id, this.user.userId),
        this.workspaceUserService.findAutoAffectedAgentsForCompany(removedCompany.id),
        this.workspaceUserService.findAgentsForCompany(removedCompany.id)
          .pipe(
            map(agents => agents.filter(agent => agent.userId !== this.user.userId))
          )
      ]).subscribe(([relatedRequests, relatedAutoAffectedAgents, agentsOnDeletedCompany]
                      : [Array<SearchResultRequest>, Array<WorkspaceUser>, Array<WorkspaceUser>]) => {
        if (this.agentIsLastAutoAffected(relatedAutoAffectedAgents, this.user)) {
          this.notificationService.error('user.form.company.deleteError');
          this.revertModificationCompaniesChange();
        } else if (relatedRequests.length > 0) {
          this.alertService.confirmWithSelectInput(
            this.translateService.instant('agent.reassignation.title'),
            this.translateService.instant('agent.reassignation.message'),
            this.translateService.instant('agent.reassignation.validate'),
            this.translateService.instant('agent.reassignation.cancel'),
            new Map(agentsOnDeletedCompany.map(agent => [agent.userId, agent.fullName]))
          ).subscribe(
            userId => {
              this.workspaceUserService.getByUserIdInCurrentWorkspace(userId).pipe(
                switchMap(user => this.requestService.substituteAgentOnCompany(removedCompany.id, this.user.userId, user.userId))
              ).subscribe(
                _ => {
                  this.notificationService.success('agent.reassignation.success');
                  this.updateUserModificationCompanies(companies);
                },
                err => this.showGenericError(err)
              );
            },
            () => this.revertModificationCompaniesChange()
          );
        } else {
          this.updateUserModificationCompanies(companies);
        }
      });
    } else {
      this.updateUserModificationCompanies(companies);
    }
  }

  public updateUserModificationCompanies(companies: Array<Company>): void {
    this.userModification.companies = companies;
    this.userModification.companiesAutoAffected =
      this.userModification.companiesAutoAffected.filter(company => companies.map(it => it.id).includes(company.id));
    this.companiesWithAutoAffectationSorted = companies
      .sort((a, b) => this.utilsService.compareStrings(a.name, b.name, true));
  }

  public emailIsValid(): boolean {
    return this.emailInputComponent && this.emailInputComponent.inputNgModel.errors == null;
  }

  public onAvatarAdded(value: any): void {
    this.avatarChanged = true;
    this.avatarToUpload = value as File;
    this.form.form.markAsDirty();
    const reader = new FileReader();
    reader.readAsDataURL(value as Blob);
    reader.onload = (event) => this.avatarToUploadPreview = event.target.result.toString();
  }

  public onAvatarRemoved(): void {
    this.avatarChanged = true;
    this.avatarToUpload = null;
    this.avatarToUploadPreview = null;
    this.user.avatar = null;
    this.form.form.markAsDirty();
  }

  private teamsHaveBeenModified() {
    return !equalsArray(this.selectedTeams?.map(el => el.id), this.originalAgentTeamIds);
  }

  private getRemovedTeams(): Array<string> {
    return getNotPresentFrom(this.originalAgentTeamIds, this.selectedTeams?.map(el => el.id));
  }

  private getAddedTeams(): Array<string> {
    return getNotPresentFrom(this.selectedTeams?.map(el => el.id), this.originalAgentTeamIds);
  }

  private updateTeamModificationsForUser(user): Observable<void[]> {
    const addedTeams = this.getAddedTeams();
    const removedTeams = this.getRemovedTeams();

    return forkJoin([
      this.teamService.addAgentToTeams(addedTeams, user.userId),
      this.teamService.removeAgentFromTeams(removedTeams, user.userId)
    ]);
  }
}
