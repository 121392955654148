import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap} from 'rxjs/operators';
import {SessionService} from './services/session.service';
import {LoginPageComponent} from '../login/pages/login/login-page.component';
import {PasswordService} from './services/password.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from './services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {WorkspaceService} from './services/workspace.service';
import {OAuth2CodeFlowService} from '../shared/services/o-auth2-code-flow.service';
import {SmartSpinnerService} from '../shared/services/smart-spinner.service';

@Component({template: "<div></div>"})
export class OAuth2CodeFlowHandlerComponent extends LoginPageComponent implements OnInit {

  constructor(sessionService: SessionService,
              passwordService: PasswordService,
              spinner: NgxSpinnerService,
              notificationService: NotificationService,
              translateService: TranslateService,
              workspaceService: WorkspaceService,
              oAuth2CodeFlowService: OAuth2CodeFlowService,
              router: Router,
              route: ActivatedRoute,
              private spinnerService: SmartSpinnerService) {
    super(sessionService, passwordService, spinner, notificationService, translateService, workspaceService, oAuth2CodeFlowService, router, route);
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      map(params => {
        if (params && params['code']) {
          this.spinnerService.show();
          return params['code'];
        } else {
          return null;
        }
      }),
      filter(code => code != null),
      switchMap(code => this.sessionService.authenticateFromAuthorizationCode(code))
    ).subscribe(completeUser => {
        this.spinnerService.hide();
        this.navigateToDashboardOrRedirect(completeUser.getActiveContextualWorkspace().code, completeUser);
      },
      () => {
        this.router.navigate(["login"], { queryParams: { authenticationError: 'true' } })
        this.spinnerService.hide();
      }
    );
  }
}
