import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LightTeam} from '../../../models/entity/teams/light-team';
import {Observable} from 'rxjs';
import {CollectionUtils} from '../../../services/collection-utils';
import {finalize, tap} from 'rxjs/operators';

@Component({
  selector: 'a-select-teams-lazyloading',
  templateUrl: './a-select-teams-lazyloading.component.html',
  styleUrls: ['./a-select-teams-lazyloading.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectTeamsLazyloadingComponent),
      multi: true
    }
  ]
})
export class ASelectTeamsLazyloadingComponent implements OnInit, ControlValueAccessor {

  @Input()
  name: string;
  @Input()
  teamObs: Observable<LightTeam[]>;
  teams: LightTeam[];
  selectedTeams: LightTeam[];
  teamsLoading: boolean;
  disabled: boolean;
  hideSelected: boolean = true;

  onChange = (teams: LightTeam[]) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: LightTeam[]): void {
    this.selectedTeams = obj;
  }

  constructor() { }

  ngOnInit(): void {
    if (CollectionUtils.empty(this.selectedTeams)) {
      this.hideSelected = false;
    }
  }

  private fetchAccessibleTeams() {
    this.teamsLoading = true;
    this.teamObs
      .pipe(
        finalize(() => this.teamsLoading = false),
        tap(teams => {
          if (CollectionUtils.notEmpty(teams)) {
            this.hideSelected = true;
          }
        })
      )
      .subscribe(teams => {
        this.teams = teams;
      });
  }

  private openFired() {
    if (this.teamsLoading || CollectionUtils.notEmpty(this.teams)) { return; }
    this.fetchAccessibleTeams();
  }
}
