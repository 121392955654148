import {
  PaginatedResponseTemplates
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedResponseTemplates';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {ResponseTemplateSearchCriteria} from '../../../shared/models/criterias/ResponseTemplateSearchCriteria';
import {ResponseTemplateService} from '../../services/responseTemplate.service';
import {Injectable} from '@angular/core';
import {switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

@Injectable()
export class WorkspaceResponseTemplatesPaginateResolver extends AbstractResolver<PaginatedResponseTemplates> {

  private page: number = 0;
  private step: number = 10;
  private searchCriteria = ResponseTemplateSearchCriteria.empty();
  private currentUser: CompleteCurrentUser;

  constructor(spinnerService: SmartSpinnerService,
              private responseTemplateService: ResponseTemplateService,
              private sessionService: SessionService,
              private searchCriteriaService: SearchCriteriaService,
  ) {
    super(spinnerService);
    this.searchCriteria.page = this.page;
    this.searchCriteria.step = this.step;
    this.sessionService.getCurrentUserOnce().subscribe(user => this.currentUser = user);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedResponseTemplates> {
    return this.searchCriteriaService.buildSearchCriteriaByQueriesFromRouteSnapshot(
      route,
      this.searchCriteria, {
      startingPage: this.page,
      step: this.step,
    }).pipe(
      switchMap(searchCriteria => {
        if (this.currentUser.isAgentAdmin()) {
          return this.responseTemplateService.find(searchCriteria);
        } else {
          return of(PaginatedResponseTemplates.emptyResult());
        }
      }),
      take(1),
    );
  }
}
