import {Type} from 'class-transformer';
import {ModificationInfo} from '../common/ModificationInfo';
import {SimpleCompany} from '../companies/SimpleCompany';
import {SimpleDomain} from '../classifications/SimpleDomain';
import {SimpleWorkspaceUser} from '../users/simple/SimpleWorkspaceUser';

/** used for detailed (edition, creation) screens **/

export class LightTeam {
  id: string;
  @Type(() => ModificationInfo)
  modificationInfo: ModificationInfo;
  workspaceRef: string;
  name: string;
  emails: Array<string>;
}
