import { Component, OnInit } from '@angular/core';
import {PaginatedWorkspaces} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {
  PaginatedAdministrationUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {ActivatedRoute} from '@angular/router';
import {WorkspaceSimple} from '../../../shared/models/entity/workspaces/Workspace';

@Component({
  selector: 'app-administration-users',
  templateUrl: './administration-users.component.html',
  styleUrls: ['./administration-users.component.scss']
})
export class AdministrationUsersComponent implements OnInit {
  initialUsers: PaginatedAdministrationUsers;
  allWorkspaces: WorkspaceSimple[];

  constructor(route: ActivatedRoute) {
    route.data.subscribe((data: { initialWorkspaces: PaginatedWorkspaces, initialUsers: PaginatedAdministrationUsers }) => {
      this.initialUsers = data.initialUsers;
      this.allWorkspaces = data.initialWorkspaces.result.map( workspace => workspace.toWorkspaceSimple());
    });
  }

  ngOnInit(): void {

  }

}
