import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PaginatedWorkspaces} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {WorkspacesSearchCriteria} from '../../../shared/models/criterias/WorkspacesSearchCriteria';
import {Sort} from '@angular/material/sort';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {Router} from '@angular/router';
import {WorkspaceService} from '../../../app-root/services/workspace.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {WorkspaceSearchResult} from '../../../shared/models/entity/workspaces/WorkspaceSearchResult';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {skip, switchMap, tap} from 'rxjs/operators';
import {NavigationService} from '../../../shared/services/navigation-service';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'administration-workspaces-list',
  templateUrl: './administration-workspaces-list.component.html',
  styleUrls: ['./administration-workspaces-list.component.scss']
})
export class AdministrationWorkspacesListComponent implements OnInit, OnDestroy {

  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;

  @Input()
  private initialWorkspaces: PaginatedWorkspaces;
  public workspaces: Array<WorkspaceSearchResult> = [];
  public workspacesCount: number;

  public workspacesSearchCriteria: WorkspacesSearchCriteria = new WorkspacesSearchCriteria();
  private reloadOnNavSub: Subscription;

  constructor(
    private router: Router,
    private workspaceService: WorkspaceService,
    private spinner: NgxSpinnerService,
    private searchCriteriaService: SearchCriteriaService,
    private navigationService: NavigationService
  ) {}

  public ngOnInit(): void {
    this.reloadOnNavSub = this.searchCriteriaService.buildSearchCriteriaByQueries(
      this.workspacesSearchCriteria,
      {
        startingPage: this.DEFAULT_STARTING_PAGE,
        step: this.DEFAULT_STEP
      }
    ).pipe(
      skip(1),
      switchMap(searchCriteria => {
        return this.loadWorkspaces(searchCriteria);
      }),
    ).subscribe();

    if (this.initialWorkspaces) {
      this.workspaces = this.initialWorkspaces.result;
      this.workspacesCount = this.initialWorkspaces.metadata.count;
    } else {
      this.searchCriteriaService.navigateWithQueries(
        this.workspacesSearchCriteria,
        this.navigationService.getWorkspacesAdministrationListUrl()
      );
    }
  }

  ngOnDestroy(): void {
    this.reloadOnNavSub.unsubscribe();
  }

  public sortWorkspaces(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.workspacesSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.workspacesSearchCriteria.sort = sort.active;
    this.searchCriteriaService.navigateWithQueries(
      this.workspacesSearchCriteria,
      this.navigationService.getWorkspacesAdministrationListUrl(),
    );
  }

  public goToAddWorkspacePage(): void {
    this.router.navigate(['/administration/workspaces/new']);
  }

  public goToWorkspaceEditionPage(workspaceId: string): void {
    this.router.navigate(['/administration/workspaces/' + workspaceId]);
  }

  public filter(qSearchCriteria: string): void {
    this.workspacesSearchCriteria.q = qSearchCriteria;
    this.searchCriteriaService.navigateWithQueries(
      this.workspacesSearchCriteria,
      this.navigationService.getWorkspacesAdministrationListUrl(),
      {resetPage: true}
    );
  }

  private loadWorkspaces(workspacesSearchCriteria: WorkspacesSearchCriteria): Observable<PaginatedWorkspaces> {
    this.spinner.show();
    return this.workspaceService.search(workspacesSearchCriteria).pipe(
      tap(
        paginatedWorkspaces => {
          this.workspaces = paginatedWorkspaces.result;
          this.workspacesCount = paginatedWorkspaces.metadata.count;
          this.spinner.hide();
        },
        _ => this.spinner.hide()
      ),
    );
  }

  public pageChanged(workspacesSearchCriteria: WorkspacesSearchCriteria): void {
    this.searchCriteriaService.navigateWithQueries(
      workspacesSearchCriteria,
      this.navigationService.getWorkspacesAdministrationListUrl(),
    );
  }

  public stepChanged(workspacesSearchCriteria: WorkspacesSearchCriteria) {
    this.searchCriteriaService.navigateWithQueries(
      workspacesSearchCriteria,
      this.navigationService.getWorkspacesAdministrationListUrl(),
      {resetPage: true}
    );
  }
}
