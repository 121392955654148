import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {NavigationEnd, Router} from '@angular/router';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {StatsService} from '../../../app-root/services/stats.service';
import {filter, take, tap} from 'rxjs/operators';
import {Stats} from '../../../shared/models/Stats';
import {Subscription} from 'rxjs';

class Tab {
  public label: string;
  public badge?: number;
  public link: string;
}

@Component({
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit, OnDestroy {

  public currentUser: CompleteCurrentUser;

  public route: string;
  public tabIndex: number = 0;

  public tabs: Tab[] = [];

  /** subscriptions **/
  private refreshSettingsStatSub: Subscription;
  private changeTabSubscription: Subscription;

  constructor(private sessionService: SessionService,
              private statsService: StatsService,
              private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1)
    ).subscribe((event: NavigationEnd) => {
      this.route = event.url.split('#')[0].split('/').pop();
    });
  }

  public ngOnInit(): void {
    this.sessionService.getCurrentUserOnce().pipe(
      tap(user => this.currentUser = user),
      tap(_ => this.tabs = this.getTabs(this.currentUser.isAgentAdmin(), Stats.emptyStats())),
      tap(_ => this.selectCurrentTab())
    ).subscribe();

    this.refreshSettingsStatSub = this.statsService.getSettings()
      .subscribe(stats => {
        this.tabs = this.getTabs(this.currentUser.isAgentAdmin(), stats);
      });

    this.changeTabSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url.split('#')[0].split('/').pop();
        this.selectCurrentTab();
      }
    });
  }

  private getTabs(isAgentAdmin: boolean, stats: Stats) {
    const tabs = [
      {label: 'settings.tabs.information', link: 'information'},
      {label: 'settings.tabs.agents', badge: stats.agentsCount, link: 'agents'}
    ];
    if (isAgentAdmin) {
      tabs.push(
        {label: 'settings.tabs.teams', badge: stats.teamsCount, link: 'teams'},
        {label: 'settings.tabs.users', badge: stats.usersCount, link: 'users'},
        {label: 'settings.tabs.companies', badge: stats.companiesCount, link: 'companies'},
        {label: 'settings.tabs.news', badge: stats.newsCount, link: 'news'},
        {label: 'settings.tabs.templates', badge: stats.responseTemplatesCount, link: 'templates'},
        {label: 'settings.tabs.customization', link: 'customization'}
      );
    }
    return tabs;
  }

  public selectCurrentTab() {
    this.tabIndex = this.tabs.findIndex(tab => this.route.startsWith(tab.link));
  }

  ngOnDestroy(): void {
    this.refreshSettingsStatSub.unsubscribe();
    this.changeTabSubscription.unsubscribe();
  }
}
