import {SimpleCompany} from './SimpleCompany';
import {Type} from 'class-transformer';
import {SimpleWorkspaceUser} from '../users/simple/SimpleWorkspaceUser';
import {SimpleDomain} from '../classifications/SimpleDomain';
import {Domain} from '../classifications/Domain';
import {ModificationInfo} from '../common/ModificationInfo';

export class SimpleCompanyWithAgents extends SimpleCompany {
  modificationInfo: ModificationInfo;
  allDomainsByDefault: boolean;
  @Type(() => SimpleDomain)
  subscribedDomains?: Array<SimpleDomain>;
  @Type(() => SimpleDomain)
  teamDomains?: Array<SimpleDomain>;
  @Type(() => SimpleWorkspaceUser)
  public autoAffectedAgents: Array<SimpleWorkspaceUser>;
  @Type(() => SimpleWorkspaceUser)
  public agents: Array<SimpleWorkspaceUser>;
  @Type(() => SimpleWorkspaceUser)
  public users: Array<SimpleWorkspaceUser>;

  static newCompany(): SimpleCompanyWithAgents {
    const companyWithAgents = new SimpleCompanyWithAgents();
    companyWithAgents.autoAffectedAgents = [];
    companyWithAgents.agents = [];
    companyWithAgents.users = [];
    companyWithAgents.subscribedDomains = [];
    companyWithAgents.teamDomains = [];
    return companyWithAgents;
  }

  public visibleDomains(): Array<Domain> {
    return this.subscribedDomains
      .concat(this.teamDomains)
      .filter((el, i, arr) => arr.findIndex(it => it.id === el.id) === i);
  }
}
