import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable, of} from 'rxjs';
import {TeamSearchCriteria} from '../../shared/models/entity/teams/team-search-criteria';
import {SessionService} from './session.service';
import {TeamUpdate} from '../../shared/models/entity/teams/team-update';
import {TeamCreation} from '../../shared/models/entity/teams/team-creation';
import {plainToClass, plainToInstance} from 'class-transformer';
import {SimpleTeam} from '../../shared/models/entity/teams/simple-team';
import {
  PaginatedSimpleTeamsWithAgentsCount
} from '../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleTeamsWithAgentsCount';
import {map, switchMap, take} from 'rxjs/operators';
import {LightTeam} from '../../shared/models/entity/teams/light-team';

@Injectable()
export class TeamService {

  private baseUrl = `${environment.apiUrl}/api/teams`;

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  public find(searchCriteria: TeamSearchCriteria): Observable<PaginatedSimpleTeamsWithAgentsCount> {
    return this.sessionService.getCurrentUser()
      .pipe(
        take(1),
        map(user => {
            searchCriteria.workspaceId = user.activeWorkspace.id;
            return searchCriteria.toHttpParams();
        }),
        switchMap(params => this.http.get<PaginatedSimpleTeamsWithAgentsCount>(`${this.baseUrl}`, { params })),
        map(paginatedTeams => plainToInstance(PaginatedSimpleTeamsWithAgentsCount, paginatedTeams))
      );
  }

  public findByAgent(agentId: string): Observable<LightTeam[]> {
    return this.http.get<Array<LightTeam>>(`${this.baseUrl}/agent/${agentId}`).pipe(
      map(teams => plainToInstance(LightTeam, teams))
    );
  }

  public findByCompany(companyId: string): Observable<LightTeam[]> {
    return this.http.get<Array<LightTeam>>(`${this.baseUrl}/company/${companyId}/simple`).pipe(
      map(teams => plainToInstance(LightTeam, teams as [LightTeam]))
    );
  }

  public nameExists(teamName: string): Observable<boolean> {
    const params = {
      teamName
    };
    return this.http.get<boolean>(`${this.baseUrl}/exists/name`, {params});
  }

  public getById(teamId: string): Observable<SimpleTeam> {
    return this.http.get<SimpleTeam>(`${this.baseUrl}/${teamId}`).pipe(map(team => plainToClass(SimpleTeam, team)));
  }

  public delete(teamId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${teamId}`);
  }

  public update(teamUpdateRequest: TeamUpdate): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}`, teamUpdateRequest);
  }

  public create(teamCreationRequest: TeamCreation): Observable<SimpleTeam> {
    return this.http.post<void>(`${this.baseUrl}`, teamCreationRequest)
      .pipe(map(team => plainToClass(SimpleTeam, team)));
  }

  public findAllSimple(): Observable<SimpleTeam[]> {
    let workspaceId;
    this.sessionService.getCurrentUser().subscribe(user => workspaceId = user.activeWorkspace.id);
    const params = {
      workspaceId
    };

    return this.http.get<Array<SimpleTeam>>(`${this.baseUrl}/withoutPagination`, {
      params
    }).pipe(
      map(team => plainToInstance(SimpleTeam, team))
    );
  }

  public findAllLight(): Observable<LightTeam[]> {
    let workspaceId;
    this.sessionService.getCurrentUser().subscribe(user => workspaceId = user.activeWorkspace.id);
    const params = {
      workspaceId
    };

    return this.http.get<Array<SimpleTeam>>(`${this.baseUrl}/withoutPagination/light`, {
      params
    }).pipe(
      map(team => plainToInstance(LightTeam, team))
    );
  }

  public addCompaniesToTeam(teamToUpdate: SimpleTeam, companyIds: Array<string>): Observable<void> {
    const update = TeamUpdate.fromSimple(teamToUpdate);
    update.addCompaniesId(companyIds);
    return this.update(update);
  }

  public addAgentsToTeam(teamToUpdate: SimpleTeam, agentsIds: string[]) {
    const update = TeamUpdate.fromSimple(teamToUpdate);
    update.addAgentsIds(agentsIds);
    return this.update(update);
  }

  public addTeamsToCompany(companyId: string, teamIds: Array<string>): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${companyId}`, teamIds);
  }

  public addAgentToTeams(teamIds: string[], agentId: string): Observable<void> {
    if (teamIds == null || teamIds.length === 0) { return of(null); }
    return this.http.put<void>(`${this.baseUrl}/agentAdd/${agentId}`, { teamIds });
  }

  public removeAgentFromTeams(teamIds: string[], agentId: string): Observable<void> {
    if (teamIds == null || teamIds.length === 0) { return of(null); }
    return this.http.put<void>(`${this.baseUrl}/agentRemove/${agentId}`, {teamIds});
  }

  public duplicate(teamId: string, duplicateAgents: boolean, duplicateDomains: boolean, duplicateCompanies: boolean, duplicateEmails: boolean): Observable<SimpleTeam> {
    const params = new HttpParams({fromObject: {
        'duplicateAgents': duplicateAgents.toString(),
        'duplicateDomains': duplicateDomains.toString(),
        'duplicateCompanies': duplicateCompanies.toString(),
        'duplicateEmails': duplicateEmails.toString()
      }});

    return this.http.post<SimpleTeam>(`${this.baseUrl}/duplicate/${teamId}`, {}, { params }).pipe(
      map(team => plainToClass(SimpleTeam, team))
    );
  }
}
