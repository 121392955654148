import { Injectable } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartSpinnerService {

  private spinnerName = 'smart-spinner';
  private concurrentUses: number = 0;
  private changei18nLabelBus: BehaviorSubject<string>;

  constructor(private spinnerService: NgxSpinnerService) {
    this.changei18nLabelBus = new BehaviorSubject<string>(undefined);
  }

  getI18nLabelBus(): Observable<string> {
    return this.changei18nLabelBus;
  }

  show(label?: string) {
    if (label) { this.changei18nLabelBus.next(label); }
    this.concurrentUses = this.concurrentUses + 1;
    if (this.concurrentUses > 1) {
      return;
    }
    this.spinnerService.show(this.spinnerName);
  }

  hide() {
    this.concurrentUses = this.concurrentUses - 1;
    if (this.concurrentUses === 0) {
      this.spinnerService.hide(this.spinnerName);
      this.changei18nLabelBus.next(null);
    }
  }
}
