import {RequestSearchCriteria} from '../../criterias/RequestSearchCriteria';
import {Type} from 'class-transformer';
import {Entity} from '../common/Entity';

export class CustomSearch extends Entity {
  id?: string;
  name?: string;
  @Type(() => RequestSearchCriteria)
  requestSearchCriteria: RequestSearchCriteria;
  selected?: boolean;
  initial: boolean = false;

  constructor(searchCriteria: RequestSearchCriteria, name: string) {
    super();
    this.name = name;
    this.requestSearchCriteria = new RequestSearchCriteria();
    if (searchCriteria) {
      this.requestSearchCriteria.fromSelf(searchCriteria);
      this.requestSearchCriteria.removeMetadataNullsAndEmptyStrings();
    }
  }

  static fromCustomSearchAndSearchCriterias(customMenu: CustomSearch, searchCriteria: RequestSearchCriteria): CustomSearch {
    const res = new CustomSearch(searchCriteria, customMenu.name);
    res.id = customMenu.id;
    res.modificationInfo = customMenu.modificationInfo;
    return res;
  }
}
