import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SimpleRequest} from '../../../../models/entity/requests/SimpleRequest';
import {Subject} from 'rxjs';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {RequestService} from '../../../../../app-root/services/request.service';
import {RequestSearchCriteria} from '../../../../models/criterias/RequestSearchCriteria';

@Component({
  selector: 'a-select-request',
  templateUrl: './a-select-request.component.html',
  styleUrls: ['./a-select-request.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectRequestComponent),
      multi: true
    }
  ]
})
export class ASelectRequestComponent implements ControlValueAccessor {

  public displayedRequests: { request: SimpleRequest; codeWithTopic: string }[];
  public selectedRequest: { request: SimpleRequest; codeWithTopic: string };

  private onNgChange: (values: SimpleRequest) => void;
  private onNgTouched: () => void;

  @Input()
  private currentRequestId: string;

  @Input()
  public name: string = 'request';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  private criteria: RequestSearchCriteria = new RequestSearchCriteria();
  public requestLoading: boolean = false;
  public searchOutput: Subject<string> = new Subject<string>();
  public isOpened: boolean;
  public placeholderi18n: string = 'common.search';

  constructor(requestService: RequestService) {
    this.searchOutput
      .pipe(
        tap(() => this.isOpened = false),
        filter(q => q !== undefined && q.trim() !== ''),
        tap(() => this.requestLoading = true),
        debounceTime(300),
        switchMap(q => {
          this.criteria.q = q;
          return requestService.searchSimpleWithoutPagination(this.criteria);
        }))
      .subscribe((requests: Array<SimpleRequest>) => {
        this.requestLoading = false;
        this.displayedRequests = requests.filter(request => request.id !== this.currentRequestId).map(request => {
          return {request: request, codeWithTopic: request.getCodeWithTopic()};
        });
        this.isOpened = true;
      }, err => {
        console.error(`[a-select-request] something bad happened\nError:\n${err}`);
      });
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(request: SimpleRequest): void {
    this.selectedRequest = request ? {request: request, codeWithTopic: request.getCodeWithTopic()} : undefined;
  }

  public onChange(requestWithCodeWithTopic: { request: SimpleRequest; codeWithTopic: string }): void {
    this.isOpened = false;
    this.selectedRequest = requestWithCodeWithTopic;
    requestWithCodeWithTopic ? this.onNgChange(requestWithCodeWithTopic.request) : this.onNgChange(undefined);
  }

  public compare(requestWithCodeWithTopic1: { request: SimpleRequest; codeWithTopic: string },
                 requestWithCodeWithTopic2: { request: SimpleRequest; codeWithTopic: string }): boolean {
    return requestWithCodeWithTopic1 != null && requestWithCodeWithTopic2 != null &&
      requestWithCodeWithTopic1.request.id === requestWithCodeWithTopic2.request.id;
  }

  public onSearch($event: { term: string; items: any[] }): void {
    this.searchOutput.next($event.term);
  }
}
