import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppPageComponent} from './pages/app/app-page.component';
import {CommonModule} from '@angular/common';
import {RequestModule} from '../request/request.module';
import {SharedModule} from '../shared/shared.module';
import {LoginModule} from '../login/login.module';
import {DashboardModule} from '../dashboard/dashboard.module';
import {SettingsModule} from '../settings/settings.module';
import {FeedbackModule} from '../feedback/feedback.module';
import {AdministrationModule} from '../administration/administration.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {DirectivesModule} from '../shared/directives/directives.module';

@NgModule({
  declarations: [
    AppPageComponent
  ],
    imports: [
        CommonModule,
        AppRoutingModule,
        RequestModule,
        FeedbackModule,
        DashboardModule,
        SharedModule,
        LoginModule,
        SettingsModule,
        AdministrationModule,
        NgxSpinnerModule,
        DirectivesModule
    ]
})
export class AppModule {
}
