import {Injectable} from '@angular/core';
import {CompleteCurrentUser} from '../../shared/models/entity/users/CompleteCurrentUser';
import {classToPlain, plainToClass} from 'class-transformer';

const CURRENT_USER_STORAGE_KEY = '__SERVICE_DESK_CURRENT_USER__';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserStorageService {
  private localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
  }

  store(currentUser: CompleteCurrentUser): void {
    this.localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(classToPlain(currentUser)));
  }

  current(): CompleteCurrentUser {
    const currentUser = JSON.parse(this.localStorage.getItem(CURRENT_USER_STORAGE_KEY));
    if (currentUser) {
      return plainToClass(CompleteCurrentUser, currentUser as Object);
    }
    return null;
  }

  clear(): void {
    this.localStorage.removeItem(CURRENT_USER_STORAGE_KEY);
  }
}
