import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {BaseComponentsModule} from './lib-components/base.components.module';
import {AppHeaderComponent} from './components/app-header/app-header.component';
import {AppMenuComponent} from './components/app-menu/app-menu.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {DelayPipe} from '../app-root/services/pipes/delay.pipe';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {AppActiveContextFormComponent} from './components/app-menu/app-active-context-form/app-active-context-form.component';
import {DirectivesModule} from './directives/directives.module';
import {TeamValidators} from './validators/team.validators';
import {NgxSpinnerModule} from 'ngx-spinner';
import {DragDropModule} from '@angular/cdk/drag-drop';

const components: any[] = [
  AppHeaderComponent,
  AppMenuComponent,
  AppActiveContextFormComponent
];

@NgModule({
  declarations: [components, DelayPipe],
  providers: [
    TeamValidators
  ],
  exports: [components],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        BaseComponentsModule,
        MatButtonToggleModule,
        DirectivesModule,
        NgxSpinnerModule,
        DragDropModule
    ]
})
export class SharedModule {
}
