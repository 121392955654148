import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';
import {finalize, take} from 'rxjs/operators';

@Component({
  selector: 'm-user-dropdown-lazyloading',
  templateUrl: './m-user-dropdown-lazyloading.component.html',
  styleUrls: ['./m-user-dropdown-lazyloading.component.scss']
})
export class MUserDropdownLazyloadingComponent {

  @Input() public totalUsers: number;
  @Input() public titleAllUsersKey: string;
  @Input() public usersObservable: Observable<Array<SimpleWorkspaceUser>>;
  @Input() public dropdownId: string;

  public users: Array<SimpleWorkspaceUser> = [];
  public loading: boolean;

  retrieveUsers() {
    this.loading = true;
    this
      .usersObservable
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(users => this.users = users);
  }
}
