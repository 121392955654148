import {Domain} from '../classifications/Domain';
import {Category} from '../classifications/Category';
import {Type} from 'class-transformer';
import {Role} from '../enums/Role';
import {SimpleCompany} from '../companies/SimpleCompany';
import {CompleteFeedback} from './CompleteFeedback';
import {CompleteUser} from '../users/CompleteUser';

export class UserQualification {
  public firstName: string;
  public lastName: string;
  public email: string;
}

export class QualificationDataModification {

  @Type(() => UserQualification)
  public unknownUsers: Array<UserQualification> = [];
  @Type(() => UserQualification)
  public knownUsers: Array<UserQualification> = [];
  @Type(() => SimpleCompany)
  public commonCompany?: SimpleCompany;
  @Type(() => Domain)
  public domain?: Domain;
  @Type(() => Category)
  public category?: Category;

  constructor(feedback: CompleteFeedback) {
    const existingRecipientUsers: Array<string> = feedback.allUsers.map(recipient => recipient.email);
    this.unknownUsers = feedback.allEmails
      .filter(recipientEmail => !existingRecipientUsers.includes(recipientEmail))
      .map(unknownRecipientEmail => CompleteUser.newUserWithEmail(Role.USER, unknownRecipientEmail));
    this.knownUsers = feedback.allUsers;
    this.domain = null;
    this.category = null;
    this.commonCompany = null;
  }
}
