import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Metrics} from '../../shared/models/Metrics';
import { environment } from '../../../../environments/environment';

@Injectable()
export class MetricsService {

  private baseUrl = `${environment.apiUrl}/api/metrics`;

  constructor(private http: HttpClient) {}

  public getCurrent(): Observable<Metrics> {
    return this.http.get<Metrics>(this.baseUrl + '/current')
      .pipe(map((principal: any) => plainToClass(Metrics, principal as Object)));
  }
}
