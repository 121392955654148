import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'request-redirect',
  templateUrl: './request-redirect.component.html',
  styleUrls: ['./request-redirect.component.scss']
})
export class RequestRedirectComponent implements OnInit {
  public cmaWebUrl = environment.cmaWebUrl + '/bluechat';

  constructor() { }

  ngOnInit(): void {
  }

}
