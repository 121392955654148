import {Component} from '@angular/core';
import {WorkspaceUserSearchCriteria} from '../../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {Company} from '../../../../shared/models/entity/companies/Company';
import {ActivatedRoute} from '@angular/router';
import {
  PaginatedSimpleWorkspaceUserListElements
} from '../../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceUserListElements';

@Component({
  templateUrl: './users-tab.component.html',
  styleUrls: ['./users-tab.component.scss']
})
export class UsersTabComponent {

  public allCompanies: Array<Company>;

  public initialUsers: PaginatedSimpleWorkspaceUserListElements;

  constructor(
    private workspaceUserService: WorkspaceUserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.initialUsers = data['initialUsers'];
    });
  }

  public reloadUsers(paginatedCriteria?: WorkspaceUserSearchCriteria): void {
    this.workspaceUserService.findSimpleUsersListElements(
      WorkspaceUserSearchCriteria.newEmptyWithPagination(
        paginatedCriteria?.page,
        paginatedCriteria?.step
      )
    ).subscribe(users => this.initialUsers = users);
  }
}
