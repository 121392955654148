import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {Domain} from '../../../../models/entity/classifications/Domain';
import {ClassificationService} from '../../../../../app-root/services/classification.service';
import {CompanyService} from '../../../../../app-root/services/company.service';
import * as domain from 'domain';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-select-domain-id',
  templateUrl: './a-select-domain-id.component.html',
  styleUrls: ['./a-select-domain-id.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectDomainIdComponent),
      multi: true
    }
  ]
})
export class ASelectDomainIdComponent implements OnInit {
  public domains: Array<Domain>;
  public selectedDomainId: string;
  private onNgChange: (values: string) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'domain';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean = false;

  private injectDomains(): void {
      this.classificationService.findAllDomainsByCurrentWorkspace().subscribe(domains => this.domains = domains);
  }

  constructor(public classificationService: ClassificationService, public companyService: CompanyService) {}

  ngOnInit(): void {
    this.injectDomains();
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(domainId: string): void {
    this.selectedDomainId = domainId;
  }

  public onChange(domainId: string): void {
    this.selectedDomainId = domainId;
    this.onNgChange(domainId);
  }
}
