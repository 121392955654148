import {Type} from 'class-transformer';
import {ModificationInfo} from '../common/ModificationInfo';
import {SimpleCompany} from '../companies/SimpleCompany';
import {SimpleDomain} from '../classifications/SimpleDomain';
import {SimpleWorkspaceUser} from '../users/simple/SimpleWorkspaceUser';

/** used for detailed (edition, creation) screens **/

export class SimpleTeam {
  id: string;
  @Type(() => ModificationInfo)
  modificationInfo: ModificationInfo;
  workspaceRef: string;
  name: string;
  @Type(() => SimpleWorkspaceUser)
  agents: Array<SimpleWorkspaceUser>;
  @Type(() => SimpleDomain)
  domains: Array<SimpleDomain>;
  @Type(() => SimpleCompany)
  companies: Array<SimpleCompany>;
  emails: Array<string>;

  public duplicate(duplicateAgents: boolean, duplicateDomains: boolean, duplicateCompanies: boolean, duplicateEmails: boolean): SimpleTeam {
    const result = new SimpleTeam();
    result.id = null;
    result.modificationInfo = null;
    result.workspaceRef = null;
    result.name = null;
    result.agents = duplicateAgents && this.agents ? this.agents.map(el => el) : [];
    result.domains = duplicateDomains && this.domains ? this.domains.map(el => el) : [];
    result.companies = duplicateCompanies && this.companies ? this.companies.map(el => el) : [];
    result.emails = duplicateEmails ? this.emails: [];
    return result;
  }
}
