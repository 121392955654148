import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {CollectionUtils} from '../../../services/collection-utils';
import {finalize, tap} from 'rxjs/operators';

@Component({
  selector: 'a-select-async',
  templateUrl: './a-select-async.component.html',
  styleUrls: ['./a-select-async.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectAsyncComponent),
      multi: true
    }
  ]
})
export class ASelectAsyncComponent<T> implements ControlValueAccessor, OnInit, OnChanges {
  @Input() public itemsObs: Observable<T[]> = of([]);
  @Input() public name;
  @Input() public clearable: boolean = true;
  @Input() public disabled: boolean = false;
  @Input() public required: boolean;
  @Input() public multiple: boolean = false;
  @Input() public bindLabel: string = 'name';
  @Input() public dropdownTemplate: TemplateRef<any>;

  public itemsLoading: boolean = false;
  public hideSelected: boolean = true;
  public selectedItems: T[] | T;
  public items: T[] = [];
  private onNgChange: (values: Array<T>) => void;
  private onNgTouched: () => void;

  constructor() {}

  reset() {
    this.items = [];
    this.selectedItems = undefined;
    this.itemsLoading = false;

    this.setHideSelected();
  }

  setHideSelected() {
    this.hideSelected = !(
      (this.multiple && CollectionUtils.empty(this.selectedItems as T[])) || (!this.multiple && this.selectedItems == null)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemsObs) {
      this.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(items: Array<T> | T): void {
    this.selectedItems = items;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.setHideSelected();
  }

  public onChange(items: Array<T>): void {
    this.selectedItems = items;
    this.onNgChange(items);
  }

  public openFired() {
    if (this.itemsLoading || (CollectionUtils.notEmpty(this.items) && this.multiple)) { return; }
    this.fetch();
  }

  public fetch() {
    this.itemsLoading = true;
    this.itemsObs.pipe(
      finalize(() => this.itemsLoading = false),
      tap(items => {
        if (CollectionUtils.notEmpty(items) && this.multiple) { this.hideSelected = true; }
      })
    ).subscribe(items => {
      this.items = items;
    });
  }
}
