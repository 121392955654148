import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {fromSize, Size} from '../buttons-utils';

@Component({
  selector: 'a-button-round',
  templateUrl: './a-button-round.component.html',
  styleUrls: ['./a-button-round.component.scss']
})
export class AButtonRoundComponent implements OnChanges, OnInit {

  @Output()
  public btnClick = new EventEmitter<UIEvent>();

  @Input()
  public disabled = false;

  @Input()
  public validate: boolean;

  @Input()
  public pulse: boolean;

  @Input()
  public collapse: boolean;

  @Input()
  public hollow = false;

  @Input()
  public white: boolean;

  @Input()
  public faIcon: string;

  @Input()
  public type: 'button' | 'submit' = 'button';

  @Input()
  public size: Size = 'DEFAULT';

  @Input()
  public loading: boolean;

  public fromSize = fromSize;
  public cssClass: string;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.size) {
      this.cssClass = fromSize(this.size);
    }
  }

  public ngOnInit() {
    this.cssClass = fromSize(this.size);
  }

  public click(e): void {
    this.btnClick.emit(e);
  }
}
