import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {CompanyService} from '../../../app-root/services/company.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {MetricsService} from '../../../app-root/services/metrics.service';
import {Metrics} from '../../models/Metrics';
import {filter, switchMap, tap} from 'rxjs/operators';
import {requestOpenStatuses, RequestStatus} from '../../models/entity/enums/RequestStatus';
import {RequestPriority} from '../../models/entity/enums/RequestPriority';
import {AppService} from '../../../app-root/services/app.service';
import {App} from '../../models/App';
import {Location} from '@angular/common';
import {CompleteCurrentUser} from '../../models/entity/users/CompleteCurrentUser';
import {environment} from '../../../../../environments/environment';
import {Subscription} from 'rxjs';
import {ActualizeMetricsBusService} from '../../services/actualize-metrics-bus.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CustomSearch} from '../../models/entity/custom-menus/CustomSearch';
import {CustomSearchService} from '../../../app-root/services/custom-search.service';
import {ToastrService} from 'ngx-toastr';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SelectedMenuService} from '../../../app-root/services/selected-menu.service';
import {RequestSearchCriteria} from '../../models/criterias/RequestSearchCriteria';
import {APopupComponent} from '../../lib-components/atoms/a-popup/a-popup.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  host: {
    '[class._retracted]': 'menuOpened',
  },
})
export class AppMenuComponent implements OnInit , OnDestroy {
  public requestOpenStatuses: Array<RequestStatus> = requestOpenStatuses();
  public IN_PROGRESS: RequestStatus = RequestStatus.IN_PROGRESS;
  public WAITING_FOR_CUSTOMER: RequestStatus = RequestStatus.WAITING_FOR_CUSTOMER;
  public CREATED: RequestStatus = RequestStatus.CREATED;
  public CLOSED: RequestStatus = RequestStatus.CLOSED;
  public EXPEDITE: RequestPriority = RequestPriority.EXPEDITE;
  public currentUser: CompleteCurrentUser;
  public currentLocationPath: string;
  public metrics: Metrics;
  public appData: App;
  public workspaceCode: string;
  public cmawebUrl = environment.cmaWebUrl;
  public isTransportUser = true;
  public isCmaConnectionActivated = false;
  public openRequestSearchCriteria: RequestSearchCriteria;
  @Output()
  public sideMenuOpenedEvent = new EventEmitter<boolean>();
  @Input()
  public menuOpened = false;
  public menusSearchCriteria: Object;
  public menus: Array<CustomSearch | InitialMenu> = [];
  public waitingForCustomerSearchCriterias: RequestSearchCriteria;
  public createdSearchCriterias: RequestSearchCriteria;
  public inProgressSearchCriterias: RequestSearchCriteria;
  public expediteSearchCriterias: RequestSearchCriteria;
  public closedSearchCriterias: RequestSearchCriteria;
  public newNameForCustomSearch: string;
  public customSearchToDelete: CustomSearch;

  private actualizeMetricsAtUserChangeSub: Subscription;
  private actualizeCurrentLocationAtNavigationEndSub: Subscription;
  private actualizeMetricsAtNavigationStartSub: Subscription;
  private actualizeAppDataOnUserChangeSub: Subscription;
  private actualizeCurrentUser: Subscription;
  private localStorage: Storage;
  private customSearches: CustomSearch[];
  private initialMenus: Array<InitialMenu> = [];
  private menuSortStorageKey: string;
  @ViewChild('deleteCustomSearchModal')
  private deleteCustomSearchModal: APopupComponent;
  @ViewChild('renameCustomSearchModal')
  private renameCustomSearchModal: APopupComponent;
  private customSearchToRename: CustomSearch;
  private metricsBusSub: Subscription;

  constructor(private sessionService: SessionService,
              private companyService: CompanyService,
              private router: Router,
              private metricsService: MetricsService,
              private activatedRoute: ActivatedRoute,
              private appService: AppService,
              public location: Location,
              private actualizeMetricsBus: ActualizeMetricsBusService,
              private spinnerService: NgxSpinnerService,
              private customSearchService: CustomSearchService,
              private toastr: ToastrService,
              private selectedMenuService: SelectedMenuService,
              private translateService: TranslateService,
  ) {
    this.router.onSameUrlNavigation = 'reload';
    this.actualizeCurrentLocationAtNavigationEndSub = router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.currentLocationPath = location.path();
    });
    this.actualizeMetricsAtNavigationStartSub = router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      this.refreshMetrics();
    });
    this.localStorage = window.localStorage;
    this.openRequestSearchCriteria = new RequestSearchCriteria();
    this.openRequestSearchCriteria.statuses = this.requestOpenStatuses;
    this.waitingForCustomerSearchCriterias = new RequestSearchCriteria();
    this.waitingForCustomerSearchCriterias.statuses = [RequestStatus.WAITING_FOR_CUSTOMER];
    this.createdSearchCriterias = new RequestSearchCriteria();
    this.createdSearchCriterias.statuses = [RequestStatus.CREATED];
    this.inProgressSearchCriterias = new RequestSearchCriteria();
    this.inProgressSearchCriterias.statuses = [RequestStatus.IN_PROGRESS];
    this.expediteSearchCriterias = new RequestSearchCriteria();
    this.expediteSearchCriterias.statuses = this.requestOpenStatuses;
    this.expediteSearchCriterias.priority = RequestPriority.EXPEDITE;
    this.closedSearchCriterias = new RequestSearchCriteria();
    this.closedSearchCriterias.statuses = [RequestStatus.CLOSED];
  }

  getInitialMenus(): InitialMenu[] {
    if (this.currentUser.isAgent()) {
      return [
        new InitialMenu('_all', this.openRequestSearchCriteria, 'metrics.open.request', 'metrics.requests', 'allRequest'),
        new InitialMenu('_waiting', this.waitingForCustomerSearchCriterias, 'metrics.requests.waiting.for.customer', 'metrics.requests', 'waitingForCustomerRequest'),
        new InitialMenu('_new', this.createdSearchCriterias, 'metrics.requests.created', 'metrics.requests', 'createdRequest'),
        new InitialMenu('_progress', this.inProgressSearchCriterias, 'metrics.requests.in.progress', 'metrics.requests', 'inProgressRequest'),
        new InitialMenu('_warning', this.expediteSearchCriterias, 'metrics.requests.expedite', 'metrics.requests', 'expediteRequest', this.shouldExpeditePulse()),
        new InitialMenu('_closed', this.closedSearchCriterias, 'metrics.closed.request', 'metrics.requests', 'closed'),
      ];
    } else {
      return [
        new InitialMenu('_all', this.openRequestSearchCriteria, 'metrics.open.request', 'metrics.requests', 'allRequest'),
        new InitialMenu('_waiting', this.waitingForCustomerSearchCriterias, 'metrics.requests.waiting.for.customer', 'metrics.requests', 'waitingForCustomerRequest'),
        new InitialMenu('_new', this.createdSearchCriterias, 'metrics.requests.created', 'metrics.requests', 'createdRequest'),
        new InitialMenu('_progress', this.inProgressSearchCriterias, 'metrics.requests.in.progress', 'metrics.requests', 'inProgressRequest'),
        new InitialMenu('_closed', this.closedSearchCriterias, 'metrics.closed.request', 'metrics.requests', 'closed'),
      ];
    }
  }

  ngOnInit(): void {
    this.subscribeToActualizeCurrentUser();
    this.subscribeToActualizeMetricsAtUserChange();
    this.subscribeToActualizeAppDataOnUserChange();

    this.metricsBusSub = this.actualizeMetricsBus.get().subscribe(_ => {
      this.refreshMetrics();
    });

    this.subscribeToActualizeCustomMenuChange();
  }

  private subscribeToActualizeCustomMenuChange() {
    this.selectedMenuService.getCustomSearchSubject().subscribe(({customSearch, opened}) => {
      this.menus.forEach(el => el.selected = false);

      if (customSearch == null) {
        return;
      }
      customSearch.selected = true;

      // to force refresh the menu
      // onSameUrlNav doesn't work here
      // my guess is that because we have the same queries, onSameUrlNav = reload doesnt apply
      this.router.navigate(['workspaces', this.workspaceCode, 'requests']).then(el =>
        this.router.navigate(
          ['workspaces', this.workspaceCode, 'requests'],
          {
            queryParams: this.menusSearchCriteria[customSearch.initial ? customSearch.name : customSearch.id],
            state: {filtersOpened: opened},
          }
        )
      );
    });
  }

  subscribeToActualizeAppDataOnUserChange() {
    this.actualizeAppDataOnUserChangeSub = this.sessionService.getCurrentUser().pipe(
      filter(user => !!user),
      tap(user => this.isTransportUser = user.isUser()),
      switchMap(() => this.appService.getAppData())
    ).subscribe(appData => this.appData = appData);
  }

  ngOnDestroy(): void {
    this.actualizeMetricsAtUserChangeSub.unsubscribe();
    this.actualizeCurrentLocationAtNavigationEndSub.unsubscribe();
    this.actualizeMetricsAtNavigationStartSub.unsubscribe();
    this.actualizeAppDataOnUserChangeSub.unsubscribe();
    this.actualizeCurrentUser.unsubscribe();
    this.metricsBusSub.unsubscribe();
  }

  public toggleRetractMenu(): void {
    this.sideMenuOpenedEvent.next(!this.menuOpened);
  }

  public logout(): void {
    this.spinnerService.show('logout');
    this.sessionService.logoutFrom(this.currentUser.activeWorkspace?.code).subscribe(_ => this.spinnerService.hide('logout'));
  }

  public refreshMetrics(): void {
    if (this.currentUser && this.currentUser.activeWorkspace) {
      this.metricsService.getCurrent().subscribe(metrics => {
        this.metrics = metrics;
        this.refreshMetricsInsideMenus();
        this.refreshShouldExpeditePulse();
      });
    }
  }

  refreshShouldExpeditePulse() {
    const res = this.menus.find(el => el.name === 'expediteRequest') as InitialMenu;
    if (!res) { return; }
    res.shouldBadgePulse = this.shouldExpeditePulse();
  }

  private refreshMetricsInsideMenus() {
    for (const menu of this.menus) {
      const initial = menu as InitialMenu;
      if (initial.initial) {
        initial.metrics = this.metrics[initial.name + 'Count'];
      }
    }
  }

  public subscribeToActualizeMetricsAtUserChange(): void {
    this.actualizeMetricsAtUserChangeSub = this.sessionService
      .getCurrentUser()
      .pipe(
        filter(user => user && !!user.activeWorkspace),
        switchMap(() => this.metricsService.getCurrent())
      ).subscribe(metrics => {
        this.metrics = metrics;
        this.refreshMetricsInsideMenus();
        this.refreshShouldExpeditePulse();
      });
  }

  public resetSelectedMenu() {
    this.menus.forEach(menu => {
      menu.selected = false;
    });
    this.selectedMenuService.resetSelected();
  }

  public goToNewRequestPage(): void {
    this.resetSelectedMenu();
    this.router.navigate(['workspaces', this.workspaceCode, 'requests', 'new']);
  }

  public isOnNewRequestPage(): boolean {
    return this.router.url === '/workspaces/' + this.workspaceCode + '/requests/new';
  }

  public goToRequestListPage(): void {
    this.resetSelectedMenu();
    this.router.navigate(['workspaces', this.workspaceCode, 'requests']);
  }

  public isOnDashboardPage(): boolean {
    return this.router.url === '/workspaces/' + this.workspaceCode + '/dashboard';
  }

  public isOnRequestListPage(): boolean {
    return this.router.url === '/workspaces/' + this.workspaceCode + '/requests';
  }

  public isOnSettingsPage(): boolean {
    return this.router.url.startsWith('/workspaces/' + this.workspaceCode + '/settings');
  }

  public goToSettingsPage(): void {
    this.resetSelectedMenu();
    if (this.currentUser.isUser()) {
      this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'me']);
    } else if (this.isOnDashboardPage() && this.currentUser.isAgentAdmin()) {
      this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'news']);
    } else {
      this.router.navigate(['workspaces', this.workspaceCode, 'settings']);
    }
  }

  public goToAdministration(): void {
    this.router.navigate(['administration', 'users']);
  }

  public shouldExpeditePulse(): boolean {
    return this.metrics && this.metrics.expediteRequestCount > 0;
  }

  private subscribeToActualizeCurrentUser() {
    this.actualizeCurrentUser = this.sessionService.getCurrentUser().pipe(filter(el => el !== null)).subscribe(currentUser => {
      this.currentUser = currentUser;
      this.workspaceCode = currentUser?.activeWorkspace.code;
      this.customSearches = (currentUser?.getActiveContextualWorkspace().customSearches);
      this.menuSortStorageKey = `${this.currentUser?.getActiveContextualWorkspace().workspaceUserId}-menuOrder`;

      if (this.currentUser) {
        this.initialMenus = this.getInitialMenus();
      }

      const selectedMenu = this.selectedMenuService.getCustomSearchSubject().getValue().customSearch;

      this.menus = [].concat(this.initialMenus);

      if (this.customSearches) {
        this.menus = [].concat(this.initialMenus).concat(this.customSearches);
      }

      this.sortMenusByStorageOrder();

      this.menusSearchCriteria = {};
      this.menus
        ?.forEach(menu => {
          if ((menu as InitialMenu).initial) {
            this.menusSearchCriteria[menu.name] = menu.requestSearchCriteria.toCleanQueryParams();
          } else {
            this.menusSearchCriteria[menu.id] = menu.requestSearchCriteria.toCleanQueryParams();
          }
        });

      // 224aac7e-8f66-11ee-b9d1-0242ac120002 : This function triggers the refresh of the request-list screen
      if (selectedMenu) {
        this.menus.some(menu => {
          const menuSelectedIsInitial = menu.initial && selectedMenu.name === menu.name;
          const menuSelectedIsNotInitial = !menu.initial && selectedMenu.id === menu.id;
            if (menuSelectedIsInitial || menuSelectedIsNotInitial) {
            this.selectMenu(menu);
          }
        });
      }
    });
  }

  public openDeleteCustomSearchModal(customSearch: CustomSearch) {
    this.customSearchToDelete = customSearch;
    this.deleteCustomSearchModal.open();
  }

  public renameCustomSearch() {
    this.customSearchService
      .rename(this.customSearchToRename.id, this.newNameForCustomSearch)
      .subscribe(_ => {
        this.toastr.success(this.translateService.instant('custom-request-search.renamed.success'));
        this.sessionService.fetchCurrentUser().subscribe();
      }, err => {
        if (err.error === 'duplicate') {
          this.toastr.error(this.translateService.instant('custom-request-search.error.duplicate'));
        } else {
          this.toastr.error('common.error');
        }
      }, () => {
        this.renameCustomSearchModal.close();
        this.customSearchToRename = null;
        this.newNameForCustomSearch = null;
      });
  }

  public deleteCustomSearch(id: string) {
    this.customSearchService
      .delete(id, this.currentUser.getActiveContextualWorkspace().workspaceUserId)
      .subscribe( _ => {
        this.toastr.success(this.translateService.instant('custom-request-search.delete.success'));
        this.sessionService.fetchCurrentUser().subscribe();
      }, err => {
        this.toastr.error('common.error');
      }, () => {
        this.deleteCustomSearchModal.close();
      });
  }

  public drag(dropEvent: CdkDragDrop<ElementRef<HTMLElement>, any>) {
    this.modifyMenuPlace(
      dropEvent.previousIndex,
      dropEvent.currentIndex
    );
    this.storeNewOrder();
  }

  private modifyMenuPlace(previousIndex: number, currentIndex: number) {
    moveItemInArray(this.menus, previousIndex, currentIndex);
  }

  private storeNewOrder() {
    const obj = {};
    this.menus.map((it, index) => (it as InitialMenu).initial ? obj[it.name] = index : obj[it.id] = index);
    this.localStorage.setItem(this.menuSortStorageKey, JSON.stringify(obj));
  }

  private sortMenusByStorageOrder() {
    if (this.localStorage.getItem(this.menuSortStorageKey)) {
      const sortOrder = JSON.parse(this.localStorage.getItem(this.menuSortStorageKey));
      const sortedMenu: Array<InitialMenu | CustomSearch> = [];
      const leftovers = [];
      this.menus?.forEach(menu => {
          const sortedIndex = (menu as InitialMenu).initial ? sortOrder[menu.name] : sortOrder[menu.id];
          if (sortedIndex != null) {
            sortedMenu[sortedIndex] = menu;
          } else {
            leftovers.push(menu);
          }
      });
      this.menus = sortedMenu.filter(el => el != null).concat(leftovers);
    }
  }

  selectMenu(menu: InitialMenu | CustomSearch, openMenu = false) {
    this.selectedMenuService.publishCustomSearch(menu, openMenu);
  }

  cancelDeleteCustomSearch() {
    this.deleteCustomSearchModal.close();
  }

  openRenameCustomSearchModal(menu: CustomSearch) {
    this.customSearchToRename = menu;
    this.newNameForCustomSearch = this.customSearchToRename.name;
    this.renameCustomSearchModal.open();
  }

  cancelRenameCustomSearch() {
    this.renameCustomSearchModal.close();
    this.customSearchToRename = null;
    this.newNameForCustomSearch = null;
  }
}

export class InitialMenu extends CustomSearch {
  icon: string;
  initial: boolean = true;
  label: string;
  title: string;
  metrics: number;
  shouldBadgePulse: boolean;
  shouldBadgeBeError: boolean;

  constructor(icon, searchCriterias, i18nTitle, i18nLabel, name, shouldBadgePulse?, shouldBadgeBeError?) {
    super(searchCriterias, name);
    this.icon = icon;
    this.shouldBadgeBeError = shouldBadgeBeError;
    this.shouldBadgePulse = shouldBadgePulse;
    this.label = i18nLabel;
    this.title = i18nTitle;
  }

}
