import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  PaginatedResponseTemplates
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedResponseTemplates';
import {ResponseTemplateService} from '../../../app-root/services/responseTemplate.service';
import {ResponseTemplateSearchCriteria} from '../../../shared/models/criterias/ResponseTemplateSearchCriteria';
import {ResponseTemplate} from '../../../shared/models/entity/ResponseTemplate';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {NotificationService} from '../../../app-root/services/notification.service';
import {catchError, skip, switchMap, tap} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {NavigationService} from '../../../shared/services/navigation-service';

@Component({
  selector: 'response-templates-list',
  templateUrl: './response-templates-list.component.html',
  styleUrls: ['./response-templates-list.component.scss']
})
export class ResponseTemplatesListComponent implements OnInit, OnDestroy {

  @Input()
  public paginatedResponseTemplates: PaginatedResponseTemplates;
  public responseTemplates: Array<ResponseTemplate>;
  public DEFAULT_STEP = 10;
  private DEFAULT_STARTING_PAGE = 0;
  public searchCriterias: ResponseTemplateSearchCriteria;
  public responseTemplatesCount: number = 0;
  private reloadOnNavigationSub: Subscription;

  constructor(
    private responseTemplateService: ResponseTemplateService,
    private spinner: SmartSpinnerService,
    private notificationService: NotificationService,
    private searchCriteriaService: SearchCriteriaService,
    private navigationService: NavigationService,
  ) {
    this.searchCriterias = ResponseTemplateSearchCriteria.empty();
    this.searchCriterias.step = this.DEFAULT_STEP;
    this.searchCriterias.page = this.DEFAULT_STARTING_PAGE;
  }

  public ngOnInit(): void {
    this.reloadOnNavigationSub = this.searchCriteriaService.buildSearchCriteriaByQueries(
      this.searchCriterias,
      {
        startingPage: this.DEFAULT_STARTING_PAGE,
        step: this.DEFAULT_STEP
      }
    ).pipe(
      skip(1),
      switchMap(_ => this.loadTemplatesResponses())
    ).subscribe();

    this.responseTemplates = this.paginatedResponseTemplates.result;
    this.responseTemplatesCount = this.paginatedResponseTemplates.metadata.count;
  }

  ngOnDestroy(): void {
    this.reloadOnNavigationSub.unsubscribe();
  }

  private loadTemplatesResponses(): Observable<PaginatedResponseTemplates> {
    this.spinner.show();
    return this.responseTemplateService
      .find(this.searchCriterias).pipe(
        tap(res => {
          this.paginatedResponseTemplates = res;
          this.responseTemplates = res.result;
          this.responseTemplatesCount = res.metadata.count;
          this.spinner.hide();
        }),
        catchError(_ => {
          this.spinner.hide();
          return of(null);
        })
      );
  }

  public onPageChanged(): void {
    this.searchCriteriaService.navigateWithQueries(
      this.searchCriterias,
      this.navigationService.getResponseTemplateSettingsListUrl()
    );
  }

  public filter(): void {
    this.searchCriteriaService.navigateWithQueries(
      this.searchCriterias,
      this.navigationService.getResponseTemplateSettingsListUrl(),
      {resetPage: true}
    );
  }

  deleteTemplate(templateId: string) {
    this.spinner.show();
    this.responseTemplateService.delete(templateId)
      .pipe(
        tap(_ => {
          this.searchCriteriaService.navigateWithQueries(
            this.searchCriterias,
            this.navigationService.getResponseTemplateSettingsListUrl(),
            {resetPage: true}
          );
        }),
      ).subscribe(__ => {
      this.notificationService.success('responseTemplate.delete.success');
    }, __ => {
      this.notificationService.success('responseTemplate.delete.error');
    }, () => {
      this.spinner.hide();
    });
  }

  onStepChanged() {
    this.searchCriteriaService.navigateWithQueries(
      this.searchCriterias,
      this.navigationService.getResponseTemplateSettingsListUrl(),
      {resetPage: true});
  }
}
