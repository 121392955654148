import {Company} from './companies/Company';
import {Domain} from './classifications/Domain';
import {Category} from './classifications/Category';
import {Attachment} from './Attachment';
import {Type} from 'class-transformer';
import {WorkspaceSimple} from './workspaces/Workspace';

export class ResponseTemplate {

  public id: string;
  public name: string;
  @Type(() => Attachment)
  public attachments: Array<Attachment> = [];
  public message: string;
  @Type(() => Domain)
  public domain: Domain;
  @Type(() => Category)
  public category: Category;
  @Type(() => WorkspaceSimple)
  public workspace: WorkspaceSimple;
}
