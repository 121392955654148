import {Type} from 'class-transformer';
import {WorkspaceUser} from './WorkspaceUser';
import {WorkspaceUserWithCompanies} from './WorkspaceUserWithCompanies';
import {Company} from '../companies/Company';
import {CompanyWithAutoAffectation} from '../companies/CompanyAutoAffectation';
import {UserWithEmail} from './User';

export class WorkspaceUserWithCompaniesModification {
  @Type(() => Company)
  public companies: Array<Company>;
  public allCompanies: boolean;
  @Type(() => Company)
  public companiesAutoAffected: Array<Company>;
  public allCompaniesAutoAffected: boolean;
  public workspaceUser: WorkspaceUser;

  static from(workspaceUser: WorkspaceUserWithCompanies): WorkspaceUserWithCompaniesModification {
    const mod: WorkspaceUserWithCompaniesModification = new WorkspaceUserWithCompaniesModification();

    mod.workspaceUser = workspaceUser;
    mod.companies = workspaceUser.companies.map(it => it.company);
    mod.companiesAutoAffected = workspaceUser.companies?.filter(it => it.autoAffected).map(it => it.company);
    mod.allCompanies = workspaceUser.allCompanies;
    mod.allCompaniesAutoAffected = workspaceUser.allCompaniesAutoAffected;

    return mod;
  }

  public toWorkspaceUserWithCompanies(): WorkspaceUserWithCompanies {
    const workspaceUser = new WorkspaceUserWithCompanies();

    workspaceUser.id = this.workspaceUser.id;
    workspaceUser.userId = this.workspaceUser.userId;
    workspaceUser.role = this.workspaceUser.role;
    workspaceUser.activeWorkspace = this.workspaceUser.activeWorkspace;
    workspaceUser.activeCompany = this.workspaceUser.activeCompany;
    workspaceUser.firstName = this.workspaceUser.firstName;
    workspaceUser.lastName = this.workspaceUser.lastName;
    workspaceUser.superAdmin = this.workspaceUser.superAdmin;
    workspaceUser.email = this.workspaceUser.email;
    workspaceUser.disabled = this.workspaceUser.disabled;
    workspaceUser.avatar = this.workspaceUser.avatar;
    workspaceUser.subscribedDomains = this.workspaceUser.subscribedDomains;
    workspaceUser.locale = this.workspaceUser.locale;
    workspaceUser.companies = this.companies?.map( it => {
      const company = new CompanyWithAutoAffectation();
      company.company = it;
      company.autoAffected = this.companiesAutoAffected.map(comp => comp.id).includes(it.id);
      return company;
    });
    workspaceUser.allCompanies = this.allCompanies;
    workspaceUser.allCompaniesAutoAffected = this.allCompaniesAutoAffected;
    workspaceUser.locale = this.workspaceUser.locale;

    return workspaceUser;
  }

  toUserWithEmail() {
    const result = new UserWithEmail();

    result.lastName = this.workspaceUser.lastName;
    result.firstName = this.workspaceUser.firstName;
    result.email = this.workspaceUser.email;
    result.id = this.workspaceUser.userId;
    result.superAdmin = this.workspaceUser.superAdmin;
    result.disabled = this.workspaceUser.disabled;
    result.avatar = this.workspaceUser.avatar;

    return result;
  }
}
