import {HttpParams} from '@angular/common/http';
import {Params} from '@angular/router';
import {isArray} from 'rxjs/internal-compatibility';
import {RequestSearchCriteria} from '../../criterias/RequestSearchCriteria';
import {CustomQueryEncoder} from '../../CustomQueryEncoder';

export abstract class PaginatedCriteria {
  static DEFAULT_PAGE = 0;
  static DEFAULT_STEP = 10;

  constructor(
    public page: number = PaginatedCriteria.DEFAULT_PAGE,
    public step: number = PaginatedCriteria.DEFAULT_STEP,
    public sort?: string,
    public sortOrder?: SortOrder
  ) {}

  /** used to initialize search criteria from the url on a list **/
  public abstract fromParams(params: Params, defaultStep: number, defaultPage: number): this;

  /** used to transform search criteria into http queries to communicate with the back-end **/
  protected abstract transformIntoHttpParams(base: HttpParams): HttpParams;

  /** used internally to reset the search criteria to a blank state **/
  protected abstract reset();

  /** used to transform search criteria into queries to change frontend's list url **/
  public toCleanQueryParams(): Params {
    // make a string object from request
    const queryParams = Object.assign({}, this);
    // remove null / falsey values
    this.removeIncorrectParams(queryParams);

    return queryParams;
  }

  public toHttpParamsWithoutPagination(): HttpParams {
    let params = new HttpParams({encoder: new CustomQueryEncoder()});
    params = this.transformIntoHttpParams(params);
    return params;
  }

  public toHttpParams(): HttpParams {
    let params = new HttpParams({encoder: new CustomQueryEncoder()});
    params = this.transformIntoHttpParams(params);

    if (this.page !== null && this.page !== undefined
      && this.step !== null && this.step !== undefined) {
      params = params.set('page', this.page.toString()).set('step', this.step.toString());
    }

    if (this.sort != null) {
      params = params.set('sort', this.sort);
    }

    if (this.sortOrder != null) {
      params = params.set('sortOrder', this.sortOrder.toString());
    }

    return params;
  }

  protected initializeFromParams(params: Params, defaultStep: number, defaultPage: number, defaultSort: string) {
    this.reset();
    this.paginationFromParams(params, defaultStep, defaultPage, defaultSort);
  }

  protected defaultReset() {
    this.step = undefined;
    this.page = undefined;
    this.sort = undefined;
    this.sortOrder = undefined;
  }

  /** handouts **/

  // remove null / falsey values
  protected removeIncorrectParams(params: Params | HttpParams) {
    Object.keys(params).forEach(k => {
      if (params[k] === null || params[k] === undefined
        || params[k] === '' || params[k].length === 0) {
        delete params[k];
      }});
  }

  protected retrieveArray(data: string): string[] {
    return isArray(data) ? data : [data];
  }

  protected formatDateCorrectly(dateFieldName: string, source: Params) {
    if (source[dateFieldName]) {
      source[dateFieldName] = new Date(source[dateFieldName]).toJSON();
    }
  }

  protected paramsOrDefault<T>(params: Params, paramName: keyof RequestSearchCriteria, defaulted: T): T {
    return params[paramName] != null ? params[paramName] : defaulted;
  }

  private paginationFromParams(params: Params, defaultStep: number, defaultPage: number, defaultSort: string) {
    this.step = params.step ? +params.step : defaultStep;
    this.page = params.page ? +params.page : defaultPage;
    this.sort = params.sort ? params.sort : defaultSort;
    this.sortOrder = params.sortOrder ? SortOrder[params.sortOrder] : SortOrder.DESC;
  }
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}
