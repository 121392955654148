import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CompanyService} from '../../services/company.service';
import {SessionService} from '../../services/session.service';
import {switchMap, take} from 'rxjs/operators';
import {Company} from '../../../shared/models/entity/companies/Company';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class WorkspaceCompaniesResolver extends AbstractResolver<Array<Company>> {

  constructor(spinnerService: SmartSpinnerService,
              private companyService: CompanyService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Company>> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap(currentUser => {
        if (currentUser.isAgentAdmin()) {
          return this.companyService.findAllCurrent();
        } else {
          return of([]);
        }
      }),
      take(1)
    );
  }
}
