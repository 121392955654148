import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestStatus} from '../../../models/entity/enums/RequestStatus';
import {UtilsService} from '../../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../app-root/services/request.service';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertService} from '../../../../app-root/services/alert.service';
import {noop, Observable} from 'rxjs';
import {DatePipe} from '@angular/common';
import {SearchResultRequest} from '../../../models/entity/requests/RequestSearchResult';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {Params, Router} from '@angular/router';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {Role} from '../../../models/entity/enums/Role';
import {RequestSearchCriteria} from '../../../models/criterias/RequestSearchCriteria';
import {RequestPriority} from '../../../models/entity/enums/RequestPriority';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';
import {RequestMetadataKeys} from '../../../models/entity/requests/RequestMetadataKeys';

@Component({
  selector: 'tr[m-request]',
  templateUrl: './m-request.component.html',
  styleUrls: ['./m-request.component.scss']
})
export class MRequestComponent implements OnInit {
  public CREATED_REQUEST = RequestStatus.CREATED;
  public requestReminderInfo: RequestReminderInfos;
  private _request: SearchResultRequest;

  @Input()
  public requestCode: string;

  @Input()
  public requestQueryParams: Params;

  @Input()
  public listSearchCriterias: RequestSearchCriteria;

  @Input()
  public currentUser: CompleteCurrentUser;

  @Output()
  private requestChecked = new EventEmitter<string>();

  @Output()
  private requestUnchecked = new EventEmitter<string>();

  @Output()
  public deliveryNumberClicked = new EventEmitter<string>();

  public Role = Role;
  public deliveryNumber: string;
  public RequestStatus = RequestStatus;
  public relatedRequestInfo: string;
  public findUsersForRequestObservable: Observable<SimpleWorkspaceUser[]>;
  public findAgentsForRequestObservable: Observable<SimpleWorkspaceUser[]>;
  public requestPriority: RequestPriority;
  public readabilityCssClass: string;
  public requestLastMessageDate: string;
  public requestLastStatusChangedDate: string;
  public requestCreatedDate: string;
  public lastAgentToRespond: string;
  public userIsAgent: boolean;
  @Input()
  get request(): SearchResultRequest {
    return this._request;
  }

  set request(request: SearchResultRequest) {
    this._request = request;
    if (request) {
      this.deliveryNumber = this._request.getMetadata(RequestMetadataKeys.DELIVERY_NUMBER);
      this.relatedRequestInfo = this.getRelatedRequestsInfo();
      this.readabilityCssClass = this.request.assignedToCurrent ? 'fas fa-pen-nib' : 'icon-read';
      this.requestCreatedDate = this.datePipe.transform(this.request.createdDate, 'dd/MM/yyyy HH:mm');
      this.requestLastMessageDate = this.datePipe.transform(this.request.lastMessageDate, 'dd/MM/yyyy HH:mm');
      this.requestLastStatusChangedDate = this.datePipe.transform(this.request.lastStatusChangeDate, 'dd/MM/yyyy HH:mm');
      this.lastAgentToRespond = this.request.lastAgentToRespond;
      this.requestReminderInfo = {
        isEnabled: (request.status === RequestStatus.WAITING_FOR_CUSTOMER && request.usersCount > 0)
          || (request.usersCount === 0 && request.agentsCount > 1),
        hoverMsg: request.status !== RequestStatus.WAITING_FOR_CUSTOMER ?
          this.translateService.instant('request.reminder.infos.not.waitingForCustomer') :
            request.usersCount === 0 && request.agentsCount <= 1 ?
            this.translateService.instant('request.reminder.infos.not.users') : null
      };
    }
  }

  @Input()
  public requestsListCount: number;

  constructor(private utilsService: UtilsService,
              private translateService: TranslateService,
              private requestService: RequestService,
              private notificationService: NotificationService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService,
              private router: Router,
              private datePipe: DatePipe,
              public workspaceUserService: WorkspaceUserService
  ) {
  }

  ngOnInit(): void {
    this.findUsersForRequestObservable = this.workspaceUserService.findUsersForRequest(this.request.id);
    this.findAgentsForRequestObservable = this.workspaceUserService.findAgentsForRequest(this.request.id);
    this.requestPriority = this.request.getVisiblePriority(this.currentUser);
    this.userIsAgent = this.currentUser.isAgent();
  }

  public onSendReminder(): void {
    if (this.requestReminderInfo.isEnabled) { // Manage here because of strange behavior with event.stopPropagation()
      this.beforeSendingReminder().subscribe(
        () => this.sendReminder(),
        () => noop());

    }
  }

  public getRelatedRequestsInfo(): string {
    return this.request.relatedRequests.map((req) => `${req.code}: ${req.topic}`).join('\n');
  }

  private beforeSendingReminder(): Observable<void> {
    if (!this.request.lastReminderDate) {
      return this.alertService.confirmWithCustomBtn(
        this.translateService.instant('request.reminder.alert.title'),
        this.translateService.instant('request.reminder.alert.message.first'),
        this.translateService.instant('action.send'),
        this.translateService.instant('action.cancel'));
    } else {
      return this.alertService.confirmWithCustomBtn(
        this.translateService.instant('request.reminder.alert.title'),
        this.translateService.instant('request.reminder.alert.message',
          {reminderDate: this.datePipe.transform(this.request.lastReminderDate, 'dd/MM/yyyy HH:mm')}),
        this.translateService.instant('action.send'),
        this.translateService.instant('action.cancel'));
    }
  }

  private sendReminder(): void {
    this.spinner.show(this.request.id);
    this.requestService.sendReminder(this.request.id).subscribe(
      () => {
        this.request.lastReminderDate = new Date();
        this.notificationService.success('request.send.reminder.success');
        this.spinner.hide(this.request.id);
      },
      () => {
        this.notificationService.error('request.send.reminder.error');
        this.spinner.hide(this.request.id);
      });
  }

  // ** checkboxes ** //

  public checkboxChange(currentCheckboxValue: boolean) {
    const newValue = !currentCheckboxValue;
    this.request.checkedInList = newValue;

    if (newValue) {
      this.requestChecked.emit(this.request.id);
    } else {
      this.requestUnchecked.emit(this.request.id);
    }
  }
}

export interface RequestReminderInfos {
  isEnabled: boolean;
  hoverMsg: string;
}
