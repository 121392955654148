import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {plainToClass, plainToInstance} from 'class-transformer';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {Role} from '../../shared/models/entity/enums/Role';
import {WorkspaceUserSearchCriteria} from '../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {WorkspaceAgentSearchCriteria} from '../../shared/models/criterias/WorkspaceAgentSearchCriteria';
import {WorkspaceUserWithCompanies} from '../../shared/models/entity/users/WorkspaceUserWithCompanies';
import {WorkspaceUser} from '../../shared/models/entity/users/WorkspaceUser';
import {environment} from '../../../../environments/environment';
import {PaginatedWorkspaceUser} from '../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUser';
import {
  PaginatedSimpleWorkspaceUser
} from '../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceUser';
import {ServiceDeskLocale} from '../../shared/models/entity/enums/ServiceDeskLocale';
import {
  PaginatedSimpleWorkspaceUserListElements
} from '../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceUserListElements';
import {SimpleWorkspaceUser} from '../../shared/models/entity/users/simple/SimpleWorkspaceUser';
import {
  PaginatedSimpleWorkspaceAgentListElements
} from '../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceAgentListElements';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceUserService {

  constructor(private http: HttpClient) { }

  private baseUrl = `${environment.apiUrl}/api/workspaceUsers`;

  public getByUserIdInCurrentWorkspace(userId: string): Observable<WorkspaceUserWithCompanies> {
    return this.http.get<WorkspaceUserWithCompanies>(`${this.baseUrl}/users/${userId}/workspaces/current`).pipe(
      map(workspaceUser => plainToClass(WorkspaceUserWithCompanies, workspaceUser))
    );
  }

  public isExistingEmailInWorkspace(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/email/${email}/exists`);
  }

  public getUserByUserIdInCurrentWorkspace(userId: string): Observable<WorkspaceUserWithCompanies> {
    return this.getByUserIdInCurrentWorkspace(userId)
      .pipe(filter((user: WorkspaceUserWithCompanies) => user.role === Role.USER ));
  }

  public getAgentByUserIdInCurrentWorkspace(userId: string): Observable<WorkspaceUserWithCompanies> {
    return this.getByUserIdInCurrentWorkspace(userId)
      .pipe(filter((user: WorkspaceUserWithCompanies) => user.role !== Role.USER ));
  }

  public update(user: WorkspaceUserWithCompanies): Observable<WorkspaceUserWithCompanies> {
    return this.http.put<WorkspaceUserWithCompanies>(`${this.baseUrl}`, user).pipe(
      map(workspaceUser => plainToClass(WorkspaceUserWithCompanies, workspaceUser))
    );
  }

  public create(user: WorkspaceUserWithCompanies): Observable<WorkspaceUserWithCompanies> {
    return this.http.post<WorkspaceUserWithCompanies>(`${this.baseUrl}`, user).pipe(
      map(workspaceUser => plainToClass(WorkspaceUserWithCompanies, workspaceUser))
    );
  }

  public findAutoAffectedAgentsForCompany(companyId: string): Observable<Array<SimpleWorkspaceUser>> {
    return this.http.get<Array<SimpleWorkspaceUser>>(`${this.baseUrl}/company/${companyId}/agents/autoAffected`)
      .pipe(map((workspaceUsers: any) => plainToInstance(SimpleWorkspaceUser, workspaceUsers as [Object])));
  }

  public findAgentsForCompany(companyId: string): Observable<Array<SimpleWorkspaceUser>> {
    return this.http.get<Array<SimpleWorkspaceUser>>(`${this.baseUrl}/company/${companyId}/agents`)
      .pipe(map((workspaceUsers: any) => plainToInstance(SimpleWorkspaceUser, workspaceUsers as [Object])));
  }

  public findAgentsForCompanyAndDomain(companyId: string, domainId: string): Observable<Array<SimpleWorkspaceUser>> {
    const params = domainId ? { domainId: domainId } : {};
    return this.http.get<Array<SimpleWorkspaceUser>>(`${this.baseUrl}/company/${companyId}/agents`, { params })
      .pipe(map((workspaceUsers: any) => plainToInstance(SimpleWorkspaceUser, workspaceUsers as [Object])));
  }

  public findAgentsForTeam(teamId: string): Observable<Array<SimpleWorkspaceUser>> {
    return this.http.get<Array<SimpleWorkspaceUser>>(`${this.baseUrl}/teams/${teamId}/agents`)
      .pipe(map((workspaceUsers: any) => plainToInstance(SimpleWorkspaceUser, workspaceUsers as [Object])));
  }

  public findUsersForCompany(companyId: string): Observable<Array<SimpleWorkspaceUser>> {
    return this.http.get<Array<SimpleWorkspaceUser>>(`${this.baseUrl}/company/${companyId}/users`)
      .pipe(map((workspaceUsers: any) => plainToInstance(SimpleWorkspaceUser, workspaceUsers as [Object])));
  }

  public findUsersForRequest(requestId: string): Observable<Array<SimpleWorkspaceUser>> {
    return this
      .http
      .get(`${this.baseUrl}/request/${requestId}/users`)
      .pipe(map((workspaceUsers: any) => plainToInstance(SimpleWorkspaceUser, workspaceUsers as [Object])));
  }

  public findAgentsForRequest(requestId: string): Observable<Array<SimpleWorkspaceUser>> {
    return this
      .http
      .get(`${this.baseUrl}/request/${requestId}/agents`)
      .pipe(map((workspaceUsers: any) => plainToInstance(SimpleWorkspaceUser, workspaceUsers as [Object])));
  }

  public findSimpleUsersListElements(workspaceUserSearchCriteria: WorkspaceUserSearchCriteria): Observable<PaginatedSimpleWorkspaceUserListElements> {
    return this
      .http
      .get<PaginatedSimpleWorkspaceUserListElements>(`${this.baseUrl}/users/listElements`, {params: workspaceUserSearchCriteria.toHttpParams()})
      .pipe(map(paginatedWorkspaceUsers => plainToClass(PaginatedSimpleWorkspaceUserListElements, paginatedWorkspaceUsers)));
  }

  public findSimpleAgentsListElements(agentSearchCriteria: WorkspaceUserSearchCriteria): Observable<PaginatedSimpleWorkspaceAgentListElements> {
    return this
      .http
      .get<PaginatedSimpleWorkspaceAgentListElements>(
        `${this.baseUrl}/agents/listElements`,
        {params: agentSearchCriteria.toHttpParams()}
      ).pipe(map(paginatedWorkspaceUsers => plainToClass(PaginatedSimpleWorkspaceAgentListElements, paginatedWorkspaceUsers)));
  }

  public findSimpleAgents(workspaceAgentSearchCriteria: WorkspaceAgentSearchCriteria): Observable<PaginatedSimpleWorkspaceUser> {
    return this
      .http
      .get<PaginatedSimpleWorkspaceUser>(
        `${this.baseUrl}/agents/simple`,
        {params: workspaceAgentSearchCriteria.toHttpParams()}
      ).pipe(map(paginatedWorkspaceUsers => plainToClass(PaginatedSimpleWorkspaceUser, paginatedWorkspaceUsers)));
  }

  public findSimpleAgentsByTeams(workspaceAgentSearchCriteria: WorkspaceAgentSearchCriteria): Observable<Array<SimpleWorkspaceUser>> {
    return this
      .http
      .get<SimpleWorkspaceUser[]>(
        `${this.baseUrl}/agents/simpleByTeams`,
        {params: workspaceAgentSearchCriteria.toHttpParams()}
      ).pipe(map(paginatedWorkspaceUsers => plainToClass(SimpleWorkspaceUser, paginatedWorkspaceUsers)));
  }

  public disable(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  public findAutoAffectedAgentsForCurrentCompany(): Observable<Array<WorkspaceUser>> {
    return this
      .http
      .get<Array<WorkspaceUser>>(`${this.baseUrl}/company/current/agents/autoAffected`)
      .pipe(map((workspaceUsers: any) => plainToClass(WorkspaceUser, workspaceUsers as [Object])));
  }

  public findAllSimpleAgentsForCurrentWorkspace(): Observable<Array<SimpleWorkspaceUser>> {
    return this.findSimpleAgents(new WorkspaceAgentSearchCriteria())
      .pipe(
        map(result => result.result)
      );
  }

  public findAgents(workspaceAgentSearchCriteria: WorkspaceAgentSearchCriteria): Observable<PaginatedWorkspaceUser> {
    return this
      .http
      .get<PaginatedWorkspaceUser>(`${this.baseUrl}/agents`, {params: workspaceAgentSearchCriteria.toHttpParams()})
      .pipe(map(paginatedWorkspaceUsers => plainToClass(PaginatedWorkspaceUser, paginatedWorkspaceUsers)));
  }

  public updateLocale(workspaceUserId: string, newLocale: ServiceDeskLocale): Observable<void> {
    return this
      .http
      .put<void>(`${this.baseUrl}/locale/${workspaceUserId}/${newLocale}`, {});
  }
}
