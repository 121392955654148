import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {RequestService} from '../../services/request.service';
import {SimpleRequest} from '../../../shared/models/entity/requests/SimpleRequest';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {RequestSearchCriteria} from '../../../shared/models/criterias/RequestSearchCriteria';

@Injectable()
export class RequestNavigationsResolver extends AbstractResolver<Array<SimpleRequest>> {

  constructor(spinnerService: SmartSpinnerService,
              private requestService: RequestService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<SimpleRequest>> {
    return this.requestService.searchSimple(new RequestSearchCriteria().fromParams(route.queryParams, null, null));
  }
}
