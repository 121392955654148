import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';

@Component({
  selector: 'a-status-priority',
  templateUrl: './a-status-priority.component.html',
  styleUrls: ['./a-status-priority.component.scss']
})
export class AStatusPriorityComponent implements OnChanges, OnInit {

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public color: StateColor = 'NOT_DEFINE';

  @Input()
  public size: Size = 'DEFAULT';

  public fromStateColor = fromStateColor;
  public fromSize = fromSize;
  public cssClass: string;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.color) {
      this.cssClass = 'statusPriority' + ' ' + fromStateColor(this.color);
    }
  }

  public ngOnInit() {
    this.cssClass = 'statusPriority' + ' ' + fromStateColor(this.color);
  }
}

export type Size = 'LARGE' | 'DEFAULT' | 'SMALL' | 'TINY';

export function fromSize(size: Size): string {
  switch (size) {
    case 'LARGE':
      return '_large';
    case 'DEFAULT':
      return '_default';
    case 'SMALL':
      return '_small';
    case 'TINY':
      return '_tiny';
  }
}

export type StateColor = 'NOT_DEFINE' | 'LOW' | 'NORMAL' | 'HIGH' | 'EXPEDITE';

export function fromStateColor(color: StateColor): string {
  switch (color) {
    case 'NOT_DEFINE':
      return '_NOT_DEFINE';
    case 'LOW':
      return '_LOW';
    case 'NORMAL':
      return '_NORMAL';
    case 'HIGH':
      return '_HIGH';
    case 'EXPEDITE':
      return '_EXPEDITE';
  }
}
