import {Injectable} from '@angular/core';
import {AbstractResolver} from '../abstract-resolver';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {TeamService} from '../../services/team.service';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {LightTeam} from '../../../shared/models/entity/teams/light-team';
import {SessionService} from '../../services/session.service';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class CurrentUserTeamsResolver extends AbstractResolver<LightTeam[]> {
  DEFAULT_STARTING_PAGE: number = 0;
  DEFAULT_STEP: number = 10;

  constructor(private teamService: TeamService, private sessionService: SessionService, spinnerService: SmartSpinnerService) {
    super(spinnerService);
  }
  public doResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<LightTeam[]> {
    return this.sessionService.getCurrentUserOnce().pipe(
      switchMap(currentUser => this.teamService.findByAgent(currentUser.id))
    );
  }

}
