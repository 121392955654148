import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanyService} from '../../../../app-root/services/company.service';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {TeamCreation} from '../../../models/entity/teams/team-creation';
import {TeamUpdate} from '../../../models/entity/teams/team-update';
import {TeamValidators} from '../../../validators/team.validators';
import {SimpleTeam} from '../../../models/entity/teams/simple-team';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';
import {SimpleCompany} from '../../../models/entity/companies/SimpleCompany';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {ClassificationService} from '../../../../app-root/services/classification.service';
import {Domain} from '../../../models/entity/classifications/Domain';

@Component({
  selector: 'm-team-form',
  templateUrl: './m-team-form.component.html',
  styleUrls: ['./m-team-form.component.scss']
})
export class MTeamFormComponent implements OnInit, OnChanges {

  @Input()
  team: SimpleTeam;

  @Input()
  title: string;

  @Output()
  validated = new EventEmitter<TeamCreation | TeamUpdate>();

  teamForm = this.fb.group({
    agents: [[]],
    name: ['', [Validators.required]],
    domains: [[]],
    companies: [[]],
    emails: [[]]
  });

  // form data
  selectedCompanyIds: string[];

  // data
  readonly emailSeparatorKeyCodes: number[] = [ENTER, COMMA];
  public allSimpleCompaniesObs: Observable<SimpleCompany[]>;
  public allWorkspaceAgentsObs: Observable<SimpleWorkspaceUser[]>;
  public allDomainsObs: Observable<Domain[]>;
  private emailsDuplicate: boolean;
  constructor(private fb: FormBuilder,
              private companyService: CompanyService,
              private teamValidators: TeamValidators,
              private classificationService: ClassificationService,
              private workspaceUserService: WorkspaceUserService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.team) {
      this.teamForm.patchValue(this.team);
    }
    this.addUniqueNameValidators();
  }

  ngOnInit(): void {
    this.allSimpleCompaniesObs = this.companyService.findAllSimpleCurrent();
    this.allWorkspaceAgentsObs = this.workspaceUserService.findAllSimpleAgentsForCurrentWorkspace();
    this.allDomainsObs = this.classificationService.findAllDomainsByCurrentWorkspace();

    this.teamForm.controls.companies.valueChanges.subscribe(companies => this.selectedCompanyIds = companies.map(el => el.id));
  }

  private toTeamCreation(teamForm: FormGroup): TeamCreation {
    const teamCreation = new TeamCreation();
    teamCreation.companies = teamForm.controls.companies.value.map(el => el.id);
    teamCreation.domains = teamForm.controls.domains.value.map(el => el.id);
    teamCreation.name = teamForm.controls.name.value.trim();
    teamCreation.agents = teamForm.controls.agents.value.map(el => el.userId);
    teamCreation.emails = teamForm.controls.emails.value;

    return teamCreation;
  }

  private toTeamUpdate(teamForm: FormGroup, team: SimpleTeam): TeamUpdate {
    const teamUpdate = new TeamUpdate();
    teamUpdate.domains = teamForm.controls.domains.value.map(el => el.id);
    teamUpdate.agents = teamForm.controls.agents.value.map(el => el.userId);
    teamUpdate.companies = teamForm.controls.companies.value.map(el => el.id);
    teamUpdate.name = teamForm.controls.name.value.trim();
    teamUpdate.ref = team.id;
    teamUpdate.emails = teamForm.controls.emails.value;

    return teamUpdate;
  }

  onValidateClick(): void {
    this.validated.emit(this.team ? this.toTeamUpdate(this.teamForm, this.team) : this.toTeamCreation(this.teamForm));
  }

  private addUniqueNameValidators() {
    this.teamForm.get('name').clearAsyncValidators();
    this.teamForm.get('name').setAsyncValidators(this.teamValidators.uniqueName(this.team));
  }


  /** email component **/
  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    this.teamForm.get('emails').markAsDirty();

    if ((this.teamForm.get('emails').value as string[]).indexOf(value) !== -1) {
      this.emailsDuplicate = true;
      return;
    }

    this.emailsDuplicate = false;
    // Add our email
    if ((value || '').trim()) {
      this.teamForm.get('emails').setValue([... this.teamForm.get('emails').value, value.trim()]);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeEmail(email: string): void {
    const index = this.teamForm.get('emails').value.indexOf(email);

    this.teamForm.get('emails').markAsDirty();

    if (index >= 0) {
      this.teamForm.get('emails').value.splice(index, 1);
    }
  }
}
