import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  PaginatedSimpleTeamsWithAgentsCount
} from '../../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleTeamsWithAgentsCount';

@Component({
  selector: 'app-teams-tab',
  templateUrl: './teams-tab.component.html',
  styleUrls: ['./teams-tab.component.scss']
})
export class TeamsTabComponent implements OnInit {

  public initialTeams: PaginatedSimpleTeamsWithAgentsCount;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.initialTeams = data['initialTeams'];
    });
  }

}
