import {Injectable } from '@angular/core';
import {SessionService} from '../../app-root/services/session.service';
import {Router, UrlTree} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router,
              private sessionService: SessionService) {
  }

  public goToSettingsPage() {
    this.router.navigate(['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'settings', 'teams']);
  }

  public getNewsSettingsListUrl(): string[] {
    return ['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'settings', 'news'];
  }

  public getCompaniesSettingsListUrl(): string[] {
    return ['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'settings', 'companies'];
  }

  public getAgentsSettingsListUrl(): string[] {
    return ['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'settings', 'agents'];
  }

  public getTeamsSettingsListUrl() {
    return ['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'settings', 'teams'];
  }

  public getResponseTemplateSettingsListUrl() {
    return ['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'settings', 'templates'];
  }

  public getUsersAdministrationListUrl() {
    return ['administration', 'users'];
  }

  public getWorkspacesAdministrationListUrl() {
    return ['administration', 'workspaces'];
  }

  public getUsersSettingsListUrl() {
    return ['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'settings', 'users'];
  }

  public getDashboardUrlTree(): UrlTree {
    return this.router.createUrlTree(['workspaces', this.sessionService.getCurrentUserValue().activeWorkspace.code, 'dashboard']);
  }
}
