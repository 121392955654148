import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {agentRoles, Role} from '../../../../models/entity/enums/Role';
import {isArray} from 'rxjs/internal-compatibility';

@Component({
  selector: 'a-select-agent-role-filter',
  templateUrl: './a-select-agent-role-filter.component.html',
  styleUrls: ['./a-select-agent-role-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectAgentRoleFilterComponent),
      multi: true
    }
  ]
})
export class ASelectAgentRoleFilterComponent implements ControlValueAccessor, OnInit {
  public rolesWithLabel: { label: string; role: Role | 'ALL' }[];
  public selectedRole: Role;
  private onNgChange: (value: Role|'ALL') => void;
  private onNgTouched: () => void;

  @Input()
  public required: boolean;

  @Input()
  public name: string = 'role';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean;

  constructor(private translateService: TranslateService) {
    this.rolesWithLabel = agentRoles().map(role =>  {
      return {label: translateService.instant('user.role.' + role), role: role} ;
    });
    this.rolesWithLabel.push({label: translateService.instant('common.all'), role: 'ALL'});
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(role: Role): void {
      this.selectedRole = role;
  }

  public onChange(role: Role): void {
    this.selectedRole = role;

    if (!this.onNgChange) { return; }
    this.onNgChange(role);
  }
}
