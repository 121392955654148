import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {Subscription} from 'rxjs';
import {AdministrationStats} from '../../../shared/models/AdministrationStats';
import {StatsService} from '../../../app-root/services/stats.service';

export enum AdministrationTabFragment {
  users,
  workspaces,
}

@Component({
  templateUrl: './administration-page.component.html',
  styleUrls: ['./administration-page.component.scss']
})
export class AdministrationPageComponent implements OnInit, OnDestroy {

  public currentUser: CompleteCurrentUser;
  public tabIndex: number = 0;
  public stats: AdministrationStats;

  /** subscriptions **/
  private currentUserSub: Subscription;

  constructor(private sessionService: SessionService,
              private activatedRoute: ActivatedRoute,
              route: ActivatedRoute,
              private router: Router,
              private statService: StatsService,
  ) {

    this.statService.getAdministrationStats().subscribe((data) => {
      this.stats = data;
    });

  }

  public ngOnInit(): void {
    this.currentUserSub = this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
    this.router.url.split('/').pop() === 'workspaces' ? this.tabIndex = 1 : this.tabIndex = 0;
  }

  ngOnDestroy(): void {
    this.currentUserSub.unsubscribe();
  }

  public goToDashboardPage(): void {
    this.router.navigate(['/dashboard']);
  }
}
