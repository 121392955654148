import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {take} from 'rxjs/operators';
import {AbstractResolver} from '../abstract-resolver';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {
  PaginatedWorkspaceUser
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUser';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {WorkspaceAgentSearchCriteria} from '../../../shared/models/criterias/WorkspaceAgentSearchCriteria';

@Injectable()
export class WorkspaceAgentsResolver extends AbstractResolver<PaginatedWorkspaceUser> {

  constructor(spinnerService: SmartSpinnerService, private workspaceUserService: WorkspaceUserService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedWorkspaceUser> {
    const criteria = new WorkspaceAgentSearchCriteria(null, null);
    criteria.sort = 'BY_LASTNAME';
    criteria.sortOrder = SortOrder.ASC;
    return this.workspaceUserService
      .findAgents(criteria)
      .pipe(
        take(1)
      );
  }
}
