import {Type} from 'class-transformer';
import {SimpleCompany} from './SimpleCompany';
import {Company} from './Company';
import {UserWithCompaniesModification} from '../users/UserModification';

export class CompanyWithAutoAffectation {

  @Type(() => Company)
  company: Company;
  autoAffected: Boolean;

  static from(company: Company, user: UserWithCompaniesModification): CompanyWithAutoAffectation {
    const companyWithAutoAffectation = new CompanyWithAutoAffectation();
    companyWithAutoAffectation.company = company;
    companyWithAutoAffectation.autoAffected = user.companiesWithAutoAffectation ?
      user.companiesWithAutoAffectation.map(comp => comp.id).includes(company.id) : false;
    return companyWithAutoAffectation;
  }
}

export class CompanyWithAutoAffectationSimple {

  @Type(() => SimpleCompany)
  public company: SimpleCompany;
  public autoAffected: Boolean;
}
