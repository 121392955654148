import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Category} from '../../../../models/entity/classifications/Category';
import {Domain} from '../../../../models/entity/classifications/Domain';

@Component({
  selector: 'a-select-category',
  templateUrl: './a-select-category.component.html',
  styleUrls: ['./a-select-category.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCategoryComponent),
      multi: true
    }
  ]
})
export class ASelectCategoryComponent implements ControlValueAccessor {

  public categories: Array<Category>;
  public selectedCategory: Category;
  private onNgChange: (values: Category) => void;
  private onNgTouched: () => void;

  private _domain: Domain;

  @Input()
  get domain(): Domain {
    return this._domain;
  }

  set domain(domain: Domain) {
    this._domain = domain;
    this.categories = [];
    if (domain && domain.categories) {
      this.categories.push(...domain.categories);
    }
    this.disabled = this.categories.length === 0;
  }

  @Input()
  public name: string = 'category';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean = false;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(category: Category): void {
    this.selectedCategory = category;
  }

  public onChange(category: Category): void {
    this.selectedCategory = category;
    this.onNgChange(category);
  }

  compare(category1: Category, category2: Category) {
    return category1 != null && category2 != null && category1.id === category2.id;
  }
}
