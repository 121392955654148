import {Routes} from '@angular/router';
import {LoggedInGuard} from '../app-root/guards/logged-in.guard';
import {FeedbacksListPageComponent} from './pages/feedbacks-list/feedbacks-list-page.component';
import {AgentAdminGuard} from '../app-root/guards/agent-admin.guard';
import {WorkspaceFeedbacksResolver} from '../app-root/resolvers/feedback/workspace-feedbacks.resolver';
import {FeedbackDetailsPageComponent} from './pages/feedback-details/feedback-details-page.component';
import {FeedbackResolver} from '../app-root/resolvers/feedback/feedback.resolver';
import {WorkspaceCompaniesResolver} from '../app-root/resolvers/companies/workspace-companies.resolver';

export const feedbackRoutes: Routes = [
  {
    path: '', component: FeedbacksListPageComponent, canActivate: [AgentAdminGuard],
    resolve: { feedbackList: WorkspaceFeedbacksResolver }
  },
  {
    path: ':feedbackId', component: FeedbackDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: { feedback: FeedbackResolver, allCompanies: WorkspaceCompaniesResolver }
  }
];
