import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordService} from '../../../../app-root/services/password.service';
import {AlertService} from '../../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {noop, Subject, Subscription} from 'rxjs';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {FormControl} from '@angular/forms';
import {ServiceDeskLocale} from '../../../models/entity/enums/ServiceDeskLocale';
import {SimpleWorkspaceAgentListElement} from '../../../models/entity/users/simple/SimpleWorkspaceAgentListElement';

@Component({
  selector: 'tr[m-agent]',
  templateUrl: './m-agent.component.html',
  styleUrls: ['./m-agent.component.scss']
})
export class MAgentComponent implements OnInit, OnDestroy {

  @Input()
  public agent: SimpleWorkspaceAgentListElement;

  @Input()
  public isAgentAdmin: boolean = false;

  @Input()
  public editable: boolean = false;

  @Input()
  public agentCheckboxValueBus: Subject<boolean>;

  @Output()
  public agentChecked = new EventEmitter<string>();

  @Output()
  public agentUnchecked = new EventEmitter<string>();

  @Output()
  public onAgentDisabled: EventEmitter<void> = new EventEmitter<void>();
  public selectLocaleControl = new FormControl();
  public checkboxValue: boolean;
  private workspaceCode: string;
  private agentCheckboxValueBusSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private alertService: AlertService,
    private workspaceUserService: WorkspaceUserService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  ngOnInit(): void {
    this.selectLocaleControl.setValue(this.agent.locale ? this.agent.locale : this.translateService.defaultLang.toUpperCase());
    this.selectLocaleControl.valueChanges.subscribe(newLocale => {
      this.updateAgentLocale(newLocale);
    });
    this.agentCheckboxValueBusSub = this.agentCheckboxValueBus.subscribe(value => this.checkboxValue = value);
  }

  ngOnDestroy() {
    this.agentCheckboxValueBusSub.unsubscribe();
  }

  public goToAgentEditionPage(agentId: string): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'agents', agentId]);
  }

  public onDisableAgent(agent: SimpleWorkspaceAgentListElement): void {
      this.alertService.confirmWithCustomBtn(
        this.translateService.instant('disabled.alert.title.agent'),
        this.translateService.instant('disabled.alert.message.agent', {agent: agent.fullName}),
        this.translateService.instant('action.validate'),
        this.translateService.instant('action.cancel')
      ).subscribe(
        () => this.disableAgent(agent),
        () => noop()
      );
  }

  private disableAgent(agent: SimpleWorkspaceAgentListElement): void {
    this.spinner.show(agent.id);
    this.workspaceUserService.disable(agent.id).subscribe(
      () => {
        this.notificationService.success('disabled.agent.success');
        this.spinner.hide(agent.id);
        this.onAgentDisabled.emit();
      },
      () => {
        this.notificationService.error('disabled.agent.error');
        this.spinner.hide(agent.id);
      }
    );
  }

  public onResetPassword(agent: SimpleWorkspaceAgentListElement): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('forgotten.password.title'),
      this.translateService.instant('forgotten.password.message', {agent: agent.fullName}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')
    ).subscribe(
      () => this.resetPassword(agent),
      () => noop()
    );
  }

  private updateAgentLocale(newLocale: ServiceDeskLocale): void {
    this.workspaceUserService.updateLocale(this.agent.id, newLocale).subscribe(
        _ => { this.notificationService.success('common.success'); },
        _ => { this.notificationService.error('common.error'); }
    );
  }

  private resetPassword(agent: SimpleWorkspaceAgentListElement): void {
    this.spinner.show(agent.id);
    this.passwordService.renewUserPassword(agent.email).subscribe(
      () => {
        this.notificationService.success('forgotten.password.success');
        this.spinner.hide(agent.id);
      },
      () => {
        this.notificationService.error('forgotten.password.error');
        this.spinner.hide(agent.id);
      }
    );
  }

  checkboxChange(checkbox: boolean) {
    if (checkbox) {
      this.agentChecked.emit(this.agent.userId);
    } else {
      this.agentUnchecked.emit(this.agent.userId);
    }
  }
}
