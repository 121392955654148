import {Team} from './team';

export class TeamCreation {
  name: string;
  agents: string[];
  domains: string[];
  companies: string[];
  emails: string[];


  static from(team: Team): TeamCreation {
    const teamCreation = new TeamCreation();
    teamCreation.agents = team.agents.map(el => el.userId);
    teamCreation.domains = team.domains.map(el => el.id);
    teamCreation.companies = team.companies.map(el => el.id);
    teamCreation.name = team.name;
    teamCreation.emails = team.emails;
    return teamCreation;
  }
}
