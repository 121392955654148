import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CustomSearch} from '../../shared/models/entity/custom-menus/CustomSearch';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {SessionService} from './session.service';
import {CompleteCurrentUser} from '../../shared/models/entity/users/CompleteCurrentUser';

@Injectable({
  providedIn: 'root'
})
export class CustomSearchService implements OnDestroy {

  private baseUrl = `${environment.apiUrl}/api/customSearch`;
  private currentUser: CompleteCurrentUser;
  private reloadCurrentUserSub: Subscription;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {
    this.reloadCurrentUserSub = this.sessionService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  ngOnDestroy(): void {
    this.reloadCurrentUserSub.unsubscribe();
  }

  public saveCustomSearch(customSearch: CustomSearch): Observable<CustomSearch> {
    return this.http.post(`${this.baseUrl}/${this.currentUser.getActiveContextualWorkspace().workspaceUserId}`, customSearch).pipe(
      map((res) => plainToClass(CustomSearch, res))
    );
  }

  public updateCustomSearch(customSearchToUpdateId: string, newCustomSearch: CustomSearch): Observable<CustomSearch> {
    return this.http.put<CustomSearch>(`${this.baseUrl}/${this.currentUser.getActiveContextualWorkspace().workspaceUserId}/${customSearchToUpdateId}`, newCustomSearch)
      .pipe(map((res) => plainToClass(CustomSearch, res)));
  }

  public delete(id: string, workspaceUserId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${workspaceUserId}/${id}`);
  }

  rename(id: string, newName: string) {
    return this.http.put<void>(`${this.baseUrl}/${this.currentUser.getActiveContextualWorkspace().workspaceUserId}/${id}/rename`, {newName});
  }
}
