import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CompanyService} from '../../services/company.service';
import {CompanySearchCriteria} from '../../../shared/models/criterias/CompanySearchCriteria';
import {SessionService} from '../../services/session.service';
import {switchMap, take} from 'rxjs/operators';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AbstractResolver} from '../abstract-resolver';
import {
  PaginatedSimpleCompaniesWithDomainsCount
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleCompaniesWithDomainsCount';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';

@Injectable()
export class WorkspaceCompaniesPaginateResolver extends AbstractResolver<PaginatedSimpleCompaniesWithDomainsCount> {

  private initialPage: number = 0;
  private step: number = 50;
  private currentUser: CompleteCurrentUser;
  private companySearchCriterias = new CompanySearchCriteria();

  constructor(spinnerService: SmartSpinnerService,
              private companyService: CompanyService,
              private sessionService: SessionService,
              private searchCriteriaService: SearchCriteriaService,
  ) {
    super(spinnerService);
    this.sessionService.getCurrentUserOnce().subscribe(currentUser => this.currentUser = currentUser);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedSimpleCompaniesWithDomainsCount> {
    return this.searchCriteriaService.buildSearchCriteriaByQueriesFromRouteSnapshot(
      route,
      this.companySearchCriterias,
      {
        startingPage: this.initialPage,
        step: this.step
      }
    ).pipe(
      switchMap(searchCriterias => {
        return this.currentUser.isAgentAdmin()
          ? this.companyService.find(searchCriterias)
          : of(PaginatedSimpleCompaniesWithDomainsCount.emptyResult());
        }
      ),
      take(1),
    );
  }
}
