import {Role} from '../enums/Role';
import {Type} from 'class-transformer';
import {WorkspaceSimple} from './Workspace';
import {CompanyWithAutoAffectationSimple} from '../companies/CompanyAutoAffectation';
import {Domain} from '../classifications/Domain';
import {CompleteUserTeam} from '../teams/complete-user-team';
import {CustomSearch} from '../custom-menus/CustomSearch';

export class ContextualWorkspace extends WorkspaceSimple {
  public role: Role;
  public disabled: boolean;
  @Type(() => CompanyWithAutoAffectationSimple)
  public companies: Array<CompanyWithAutoAffectationSimple> = [];
  @Type(() => Domain)
  public domains: Domain[];
  @Type(() => CompleteUserTeam)
  public teams: Array<CompleteUserTeam>;
  public workspaceUserId: string;
  @Type(() => CustomSearch)
  public customSearches: CustomSearch[];
}
