import {Type} from 'class-transformer';
import {PaginatedMetadata} from '../PaginatedMetadata';
import {PaginatedResult} from '../PaginatedResult';
import {SimpleCompanyWithAgents} from '../../companies/SimpleCompanyWithAgents';
import {SimpleCompanyWithDomains} from '../../companies/SimpleCompanyWithDomains';

export class PaginatedSimpleCompaniesWithDomainsCount extends PaginatedResult {
  @Type(() => SimpleCompanyWithDomains)
  public result: Array<SimpleCompanyWithDomains>;

  static emptyResult(): PaginatedSimpleCompaniesWithDomainsCount {
    const paginatedCompanies = new PaginatedSimpleCompaniesWithDomainsCount(new PaginatedMetadata(0, 0, 0));
    paginatedCompanies.result = [];
    return paginatedCompanies;
  }
}
