import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {SessionService} from '../../../app-root/services/session.service';
import {News} from '../../../shared/models/entity/News';
import {NewsService} from '../../../app-root/services/news.service';
import {NewsSearchCriteria} from '../../../shared/models/criterias/NewsSearchCriteria';
import {PaginatedNews} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedNews';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {skip, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {NavigationService} from '../../../shared/services/navigation-service';

@Component({
  selector: 'news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, OnDestroy {

  public currentUser: CompleteCurrentUser;
  public news: Array<News> = [];
  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;
  public newsSearchCriteria: NewsSearchCriteria = new NewsSearchCriteria();
  public newsCount: number;

  @Input()
  public initialNews: PaginatedNews;
  private reloadOnNavigationSub: Subscription;

  constructor(private router: Router,
              private sessionService: SessionService,
              private spinner: SmartSpinnerService,
              private newsService: NewsService,
              private searchCriteriaService: SearchCriteriaService,
              private navigationService: NavigationService
  ) { }

  public ngOnInit(): void {
    this.sessionService.getCurrentUserOnce().subscribe(user => this.currentUser = user);

    this.reloadOnNavigationSub = this.searchCriteriaService.buildSearchCriteriaByQueries(
      this.newsSearchCriteria,
      {
        step: this.DEFAULT_STEP,
        startingPage: this.DEFAULT_STARTING_PAGE,
      }
    ).pipe(
      skip(1),
      tap(searchCriterias => this.loadNews(searchCriterias) )
    ).subscribe();

    if (this.initialNews) {
      this.news = this.initialNews.result;
      this.newsCount = this.initialNews.metadata.count;
    } else {
      this.searchCriteriaService.navigateWithQueries(this.newsSearchCriteria, this.navigationService.getNewsSettingsListUrl());
    }
  }

  ngOnDestroy(): void {
    this.reloadOnNavigationSub.unsubscribe();
  }

  public sortNews(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.newsSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.newsSearchCriteria.sort = sort.active;

    this.searchCriteriaService.navigateWithQueries(this.newsSearchCriteria, this.navigationService.getNewsSettingsListUrl());
  }

  public goToAddNewsPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'news', 'new']);
  }

  public filter(qSearchCriteria: string): void {
    this.newsSearchCriteria.q = qSearchCriteria;
    this.searchCriteriaService.navigateWithQueries(this.newsSearchCriteria, this.navigationService.getNewsSettingsListUrl(), {resetPage: true});
  }

  public loadNews(newsSearchCriteria: NewsSearchCriteria): void {
    this.spinner.show();
    this.newsService.find(newsSearchCriteria).subscribe(paginatedNews => {
      this.news = paginatedNews.result;
      this.newsCount = paginatedNews.metadata.count;
    }, () => {},  () => { this.spinner.hide(); });
  }

  public pageChanged(newsSearchCriteria: NewsSearchCriteria): void {
    this.searchCriteriaService.navigateWithQueries(newsSearchCriteria, this.navigationService.getNewsSettingsListUrl());
  }

  public stepChanged(newsSearchCriteria: NewsSearchCriteria) {
    this.searchCriteriaService.navigateWithQueries(newsSearchCriteria, this.navigationService.getNewsSettingsListUrl(), {resetPage: true});
  }
}
