import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {WorkspaceUser} from '../../../../models/entity/users/WorkspaceUser';

@Component({
  selector: 'a-select-workspace-users',
  templateUrl: './a-select-workspace-users.component.html',
  styleUrls: ['./a-select-workspace-users.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectWorkspaceUsersComponent),
      multi: true
    }
  ]
})
export class ASelectWorkspaceUsersComponent implements ControlValueAccessor {

  private _users: Array<WorkspaceUser>;
  public displayedUsers: { user: WorkspaceUser; fullName: string }[];
  public selectedUsers: { user: WorkspaceUser; fullName: string }[];

  @Input()
  get users(): Array<WorkspaceUser> {
    return this._users;
  }

  set users(users: Array<WorkspaceUser>) {
    this._users = users;
    if (users) {
      this.displayedUsers =
        users
          .sort((u1, u2) => u1.lastName < u2.lastName ? -1 : 1)
          .map(user => ({user: user, fullName: user.fullName}));
    }
  }

  private onNgChange: (values: Array<WorkspaceUser>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'users';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(users: Array<WorkspaceUser>): void {
    this.selectedUsers = users ? users.map(user => ({user: user, fullName: user.fullName})) : undefined;
  }

  public onChange(usersWithFullName: { user: WorkspaceUser; fullName: string }[]): void {
    this.selectedUsers = usersWithFullName;
    usersWithFullName ? this.onNgChange(usersWithFullName.map(userWithFullName => userWithFullName.user)) : this.onNgChange(undefined);
  }

  public compare(userWithFullName1: { user: WorkspaceUser; fullName: string },
                 userWithFullName2: { user: WorkspaceUser; fullName: string }): boolean {
    return userWithFullName1 != null && userWithFullName2 != null && userWithFullName1.user.userId === userWithFullName2.user.userId;
  }
}
