import {Attachment} from '../Attachment';
import {Type} from 'class-transformer';
import {CompleteUser} from '../users/CompleteUser';

export class CompleteFeedback {

  public id: string;
  public createdDate: Date;
  public topic: string;
  public text?: string;
  public senderEmail: string;
  @Type(() => CompleteUser)
  public sender?: CompleteUser;
  // Polling mail
  public mainRecipientEmail: string;
  // Recipients mails - mails config found in system
  public recipientsEmails?: Array<string>;
  // RecipientsCCEmails
  public recipientsCCEmails?: Array<string>;
  // recipientsEmails + recipientsCCEmails + sendEmail - mails config found in system
  public allEmails?: Array<string>;
  @Type(() => CompleteUser)
  public allUsers?: Array<CompleteUser> = [];
  @Type(() => Attachment)
  public attachments: Array<Attachment> = [];
}
