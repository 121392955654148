import {getNotPresentFrom} from './get-not-present-from';

export function equalsArray(arrayOne: Array<string>, arrayTwo: string[]) {
  if (!arrayOne && !arrayOne ) { return true; }
  if (!arrayOne) { return false; }
  if (!arrayTwo) { return false; }

  const sameSize = arrayOne.length === arrayTwo.length;
  const leftoversOne = getNotPresentFrom(arrayOne, arrayTwo);
  const leftoversTwo = getNotPresentFrom(arrayTwo, arrayOne);
  return sameSize && leftoversOne.length === 0 && leftoversTwo.length === 0;
}
