import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordService} from '../../../../app-root/services/password.service';
import {noop} from 'rxjs';
import {AlertService} from '../../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {ServiceDeskLocale} from '../../../models/entity/enums/ServiceDeskLocale';
import {FormControl} from '@angular/forms';
import {SimpleWorkspaceUserListElement} from '../../../models/entity/users/simple/SimpleWorkspaceUserListElement';

@Component({
  selector: 'tr[m-user]',
  templateUrl: './m-user.component.html',
  styleUrls: ['./m-user.component.scss']
})
export class MUserComponent implements OnInit {

  private _workspaceUserWithCompanies: SimpleWorkspaceUserListElement;
  public companyNames: Array<string> = [];

  @Input()
  public isAgentAdmin: boolean = false;

  @Output()
  public onUserDisabled: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  get workspaceUserWithCompanies(): SimpleWorkspaceUserListElement {
    return this._workspaceUserWithCompanies;
  }

  set workspaceUserWithCompanies(workspaceUserWithCompanies: SimpleWorkspaceUserListElement) {
    this._workspaceUserWithCompanies = workspaceUserWithCompanies;
    if (workspaceUserWithCompanies && workspaceUserWithCompanies.companies) {
      this.companyNames = workspaceUserWithCompanies.companies.map(company => company.name);
    }
  }

  private workspaceCode: string;
  localeSelectControl = new FormControl();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private workspaceUserService: WorkspaceUserService,
              private passwordService: PasswordService,
              private alertService: AlertService,
              public translateService: TranslateService,
              private notificationService: NotificationService,
              private spinner: NgxSpinnerService) {
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  ngOnInit(): void {
    this.localeSelectControl
      .setValue(
        this.workspaceUserWithCompanies.locale
          ? this.workspaceUserWithCompanies.locale
          : this.translateService.defaultLang.toUpperCase()
      );
    this.localeSelectControl.valueChanges.subscribe(newLocale => {
      this.updateWorkspaceUserLocale(newLocale);
    });
  }

  public goToUserEditionPage(userId: string): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'users', userId]);
  }

  public onDisableUser(user: SimpleWorkspaceUserListElement): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('disabled.alert.title.user'),
      this.translateService.instant('disabled.alert.message.user', {user: user.fullName}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')).subscribe(
      () => this.disableUser(user),
      () => noop()
    );
  }

  private disableUser(user: SimpleWorkspaceUserListElement): void {
    this.spinner.show(user.id);
    this.workspaceUserService.disable(user.id).subscribe(
      () => {
        this.notificationService.success('disabled.user.success');
        this.spinner.hide(user.id);
        this.onUserDisabled.emit();
      },
      () => {
        this.notificationService.error('disabled.user.error');
        this.spinner.hide(user.id);
      }
    );
  }

  public onResetPassword(user: SimpleWorkspaceUserListElement): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('forgotten.password.title'),
      this.translateService.instant('forgotten.password.message.user', {user: user.fullName}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')
    ).subscribe(
      () => this.resetPassword(user),
      () => noop()
    );
  }

  private resetPassword(user: SimpleWorkspaceUserListElement): void {
    this.spinner.show(user.id);
    this.passwordService.renewUserPassword(user.email).subscribe(
      () => {
        this.notificationService.success('forgotten.password.success');
        this.spinner.hide(user.id);
      },
      () => {
        this.notificationService.error('forgotten.password.error');
        this.spinner.hide(user.id);
      }
    );
  }

  updateWorkspaceUserLocale(newLocale: ServiceDeskLocale): void {
    this.workspaceUserService.updateLocale(
      this.workspaceUserWithCompanies.id,
      newLocale
    ).subscribe(_ => {
      this.notificationService.success('common.success');
    }, _ => {
      this.notificationService.error('common.error');
    });
  }
}
