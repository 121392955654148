import {Type} from 'class-transformer';
import {Role} from '../enums/Role';
import {WorkspaceUser} from './WorkspaceUser';
import {CompanyWithAutoAffectation} from '../companies/CompanyAutoAffectation';


export class WorkspaceUserWithCompanies extends WorkspaceUser {
  @Type(() => CompanyWithAutoAffectation)
  public companies: Array<CompanyWithAutoAffectation> = [];
  public allCompanies: boolean;
  public allCompaniesAutoAffected: boolean;

  static new(role: Role): WorkspaceUserWithCompanies {
    const workspaceUser = new WorkspaceUserWithCompanies();

    workspaceUser.role = role;

    return workspaceUser;
  }

  public copy(newFields: Partial<WorkspaceUserWithCompanies>): WorkspaceUserWithCompanies {
    return { ... this, ... newFields} as WorkspaceUserWithCompanies;
  }
}
