import { environment } from '../../../../../../environments/environment';

export enum ExportFormat {
  EXCEL = 'EXCEL',
  CSV = 'CSV'
}

export interface ExportFormatData {
  requestUrl: string;
  requestFileName: string;
}

export function buildRequestExportFormat(exportFormat: ExportFormat): ExportFormatData {

  switch (exportFormat) {
    case ExportFormat.EXCEL:
      return {
        requestUrl: `${environment.apiUrl}/api/requests/extract/${ExportFormat.EXCEL}`,
        requestFileName: 'reportRequest.xlsx'
      };
    case ExportFormat.CSV:
      return {
        requestUrl: `${environment.apiUrl}/api/requests/extract/${ExportFormat.CSV}`,
        requestFileName: 'reportRequest.csv'
      };
  }
}

export function buildCompanyDomainsExportFormat(): ExportFormatData {
  return {
    requestUrl: `${environment.apiUrl}/api/companies/extractDomains`,
    requestFileName: 'reportCompaniesDomains.xlsx'
  };
}

export function buildCompanyTeamsExportFormat(): ExportFormatData {
  return {
    requestUrl: `${environment.apiUrl}/api/companies/extractTeams`,
    requestFileName: 'reportCompaniesTeams.xlsx'
  };
}
