import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {QuillModules} from 'ngx-quill/lib/quill-editor.interfaces';
import * as Quill from 'quill';

@Component({
  selector: 'a-text-editor',
  templateUrl: './a-text-editor.component.html',
  styleUrls: ['./a-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ATextEditorComponent),
      multi: true
    }
  ]
})
export class ATextEditorComponent implements ControlValueAccessor {

  @Input()
  public name: string;

  @Input()
  public placeholderKey: string = '';

  @Input()
  public placeholderKeyI18nParams: any;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  public data: string;

  public modules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['video']
    ],
    clipboard: {
      matchers: [
        ['IMG', (node, delta) => {
          const Delta = Quill.import('delta');
          return new Delta().insert('');
        }],
      ]
    }
  };

  private onNgChange: (value: string) => void;
  private onNgTouched: () => void;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(obj: string): void {
    this.data = obj;
  }

  public onChange(data: string): void {
    this.data = data;
    this.onNgChange(data);
  }
}
