import { environment } from '../../../../../../environments/environment';

export enum SharedCollection {
  REQUESTS = 'REQUESTS',
  NEWS = 'NEWS',
  FEEDBACKS = 'FEEDBACKS',
  APPCUSTOMIZATION = 'APPCUSTOMIZATION',
  USERS = 'USERS',
  RESPONSETEMPLATE = 'RESPONSETEMPLATE',
  WORKSPACES = 'WORKSPACES'
}

interface AttachmentsApi {
  getUrl: () => string;
  postUrl: () => string;
  deleteUrl: () => string;
  getPublicUrl: () => string;
}

export function buildEntityAttachmentUrls(
  sharedCollection: SharedCollection | string,
  entityId: string,
  attachmentId?: string): AttachmentsApi {

  switch (sharedCollection) {
    case SharedCollection.REQUESTS:
      return {
        getUrl: () => `${environment.apiUrl}/api/attachments/${attachmentId}/requests/${entityId}`,
        postUrl: () => `${environment.apiUrl}/api/attachments/requests/${entityId}`,
        deleteUrl: () => { throw new Error('no DELETE url found'); },
        getPublicUrl: () => { throw new Error('no public GET url found'); }
      };
    case SharedCollection.NEWS:
      return {
        getUrl: () => `${environment.apiUrl}/api/attachments/${attachmentId}/news/${entityId}`,
        postUrl: () => `${environment.apiUrl}/api/attachments/news/${entityId}`,
        deleteUrl: () =>  { throw new Error('no DELETE url found'); },
        getPublicUrl: () => { throw new Error('no public GET url found'); }
      };
    case SharedCollection.FEEDBACKS:
      return {
        getUrl: () => `${environment.apiUrl}/api/attachments/${attachmentId}/feedbacks/${entityId}`,
        postUrl: () => { throw new Error('No POST url found'); },
        deleteUrl: () => { throw new Error('No DELETE url found'); },
        getPublicUrl: () => { throw new Error('No public GET url found'); }
      };
    case SharedCollection.USERS:
      return {
        getUrl: () => `${environment.apiUrl}/api/attachments/${attachmentId}`,
        postUrl: () => `${environment.apiUrl}/api/attachments/avatar/${entityId}`,
        deleteUrl: () => { throw new Error('no DELETE url found'); },
        getPublicUrl: () => { throw new Error('no public GET found'); }
      };
    case SharedCollection.RESPONSETEMPLATE:
      return {
        getUrl: () => `${environment.apiUrl}/api/attachments/responseTemplate/${attachmentId}`,
        postUrl: () => `${environment.apiUrl}/api/attachments/${attachmentId}/responseTemplate/${entityId}`,
        deleteUrl: () => { throw new Error('no DELETE url found'); },
        getPublicUrl: () => { throw new Error('no public GET found'); }
      };
    case SharedCollection.WORKSPACES:
      return {
        getUrl: () => `${environment.apiUrl}/api/attachments/${attachmentId}/workspaces/${entityId}`,
        postUrl: () => `${environment.apiUrl}/api/attachments/workspaces/${entityId}`,
        deleteUrl: () =>  { throw new Error('no DELETE url found'); },
        getPublicUrl: () => `${environment.apiUrl}/api/public/attachments/${attachmentId}/workspaces/${entityId}`
      };
  }
}
