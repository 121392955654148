import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClassificationService} from '../../../../../app-root/services/classification.service';
import {SimpleDomain} from '../../../../models/entity/classifications/SimpleDomain';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'a-select-domains-ids',
  templateUrl: './a-select-domain-ids.component.html',
  styleUrls: ['./a-select-domain-ids.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectDomainIdsComponent),
      multi: true
    }
  ]
})
export class ASelectDomainIdsComponent implements OnInit, ControlValueAccessor {

  public selectedDomains: Array<SimpleDomain>;
  private onNgChange: (values: Array<string>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'domains';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  @Input()
  public domains: Array<SimpleDomain>;

  private writedValue: Array<string>;

  public invalidDomain: SimpleDomain;
  public errorMode: boolean;

  constructor(
    private classificationService: ClassificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.invalidDomain = new SimpleDomain();
    this.invalidDomain.title = this.translateService.instant('delivery-type.unknown');
    if (!this.domains) {
      this.classificationService.findAllSimpleDomainsByCurrentWorkspace().subscribe(domains => {
        this.domains = domains;
        if (this.writedValue) {
          this.writeValue(this.writedValue);
        }
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(domains: Array<string>): void {
    this.resetError();
    this.writedValue = domains;
    if (domains == null) { this.selectedDomains = undefined; return; }
    if (this.parseValidity(domains) === false) { return; }

    const domainsFromDataset = this.domains.filter(domain => domains.includes(domain.id));
    this.selectedDomains = domainsFromDataset;
  }

  // 0 = invalid, 1 = valid
  private parseValidity(domains: Array<string>): boolean {
    const domainIds = this.domains.map(el => el.id);
    if (!domains.every(el => domainIds.indexOf(el) !== -1)) {
      this.toggleError();
      return false;
    }
    return true;
  }

  public onChange(domains: Array<SimpleDomain>): void {
    this.resetError();
    if (this.parseValidity(domains.map(el => el.id)) === false) { return; }

    this.selectedDomains = domains;
    this.onNgChange(this.selectedDomains ? this.selectedDomains.map(domain => domain.id) : []);
  }

  public compare(domain1: SimpleDomain, domain2: SimpleDomain): boolean {
    return domain1 != null && domain2 != null && domain1.id === domain2.id;
  }

  private toggleError(): void {
    this.errorMode = true;
  }

  private resetError(): void {
    this.errorMode = false;
  }
}
