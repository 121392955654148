import {Component, Input} from '@angular/core';
import {SessionService} from '../../../../app-root/services/session.service';
import {Router} from '@angular/router';
import {WorkspaceSimple} from '../../../models/entity/workspaces/Workspace';
import {Role} from '../../../models/entity/enums/Role';
import {plainToClass} from 'class-transformer';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {SimpleCompany} from '../../../models/entity/companies/SimpleCompany';
import {SmartSpinnerService} from '../../../services/smart-spinner.service';

@Component({
  selector: 'app-active-context-form',
  templateUrl: './app-active-context-form.component.html',
  styleUrls: ['./app-active-context-form.component.scss'],
})
export class AppActiveContextFormComponent {

  public USER_ROLE: Role = Role.USER;

  private _currentUser: CompleteCurrentUser;
  public workspaces: Array<WorkspaceSimple>;
  public companies: Array<SimpleCompany>;

  @Input()
  public set currentUser(currentUser: CompleteCurrentUser) {
    this._currentUser = plainToClass(CompleteCurrentUser, currentUser);
    this.workspaces = this._currentUser ? this._currentUser.getWorkspaces() : [];
    this.companies = this._currentUser ? this._currentUser.getCompaniesInActiveWorkspace() : [];
  }

  public get currentUser() {
    return this._currentUser;
  }

  constructor(private sessionService: SessionService, private router: Router, private smartSpinnerService: SmartSpinnerService) {}

  public onActiveWorkspaceChange(workspace: WorkspaceSimple): void {
    if (workspace) {
      this._currentUser.activeWorkspace = workspace;
      this._currentUser.activeCompany = null;
      this._currentUser.activeRole = this._currentUser.getRoleInWorkspace(workspace.id);
      this.companies = this._currentUser.getCompaniesInWorkspaces(workspace.id);

      // If user is not user in workspace no need to select a company
      if (!this._currentUser.isUserInWorkspace(workspace.id)) {
        this.applyContext();
      } else if (this.companies && this.companies.length === 1) {
        // If user is user but only have 1 company in workspace no need to select a company
        this._currentUser.activeCompany = this.companies[0];
        this.applyContext();
      }
    }
  }

  public onActiveCompanyChange(company: SimpleCompany): void {
    if (company) {
      this._currentUser.activeCompany = company;
      this.applyContext();
    }
  }

  private applyContext(): void {
    this.smartSpinnerService.show();
    this.sessionService.activeContext(this._currentUser.activeWorkspace, this._currentUser.activeCompany).subscribe(_ => this.smartSpinnerService.hide());
  }
}
