import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {Role} from '../../../shared/models/entity/enums/Role';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserWithCompanies} from '../../../shared/models/entity/users/WorkspaceUserWithCompanies';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class WorkspaceAgentResolver extends AbstractResolver<WorkspaceUserWithCompanies> {

  constructor(spinnerService: SmartSpinnerService, private workspaceUserService: WorkspaceUserService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkspaceUserWithCompanies> {
    const agentId = route.paramMap.get('agentId');
    return agentId ?
      this.workspaceUserService.getAgentByUserIdInCurrentWorkspace(agentId).pipe(take(1)) :
      of(WorkspaceUserWithCompanies.new(Role.AGENT)).pipe(take(1));
  }
}
