import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {Observable} from 'rxjs';
import {SharedCollection} from '../../../shared/models/entity/enums/SharedCollection';
import {NgForm} from '@angular/forms';
import {AttachmentService} from '../../../app-root/services/attachment.service';
import {NotificationService} from '../../../app-root/services/notification.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {switchMap} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Component({
  templateUrl: './current-user-profile-page.component.html',
  styleUrls: ['./current-user-profile-page.component.scss']
})
export class CurrentUserProfilePageComponent implements OnInit {

  public currentUser: CompleteCurrentUser;

  @ViewChild('form')
  public form: NgForm;

  public avatarChanged: boolean;
  public avatarToUploadPreview: string;
  public avatarToUpload: File;

  constructor(private notificationService: NotificationService,
              private attachmentService: AttachmentService,
              private sessionService: SessionService) {
  }

  public ngOnInit() {
    this.sessionService
      .getCurrentUserOnce()
      .subscribe(user => this.currentUser = plainToClass(CompleteCurrentUser, Object.assign({}, user)));
  }

  public onAvatarAdded(value: any): void {
    this.avatarChanged = true;
    this.avatarToUpload = value as File;
    this.form.form.markAsDirty();
    const reader = new FileReader();
    reader.readAsDataURL(value as Blob);
    reader.onload = (event) => this.avatarToUploadPreview = event.target.result.toString();
  }

  public onAvatarRemoved(): void {
    this.avatarChanged = true;
    this.currentUser.avatar = null;
    this.avatarToUpload = null;
    this.avatarToUploadPreview = null;
    this.form.form.markAsDirty();
  }

  public uploadAvatar(): void {
    let observable: Observable<any>;
    if (this.avatarChanged && this.avatarToUpload) {
      observable =
        this.attachmentService.uploadAttachment(
          {collection: SharedCollection.USERS, id: this.currentUser.id},
          this.avatarToUpload,
          this.currentUser
        );
    } else if (this.avatarChanged && !this.avatarToUpload) {
      observable = this.attachmentService.removeAvatar(this.currentUser.id);
    }

    if (observable) {
      observable.pipe(
        switchMap(() => this.sessionService.fetchCurrentUser()),
      ).subscribe(
        currentUser => {
          this.currentUser =  plainToClass(CompleteCurrentUser, Object.assign({}, currentUser));
          this.notificationService.success('settings.me.form.avatar.upload.success');
          this.form.form.markAsPristine();
        },
        () => this.notificationService.error('settings.me.form.avatar.upload.error')
      );
    }
  }
}
