import {Entity} from '../common/Entity';
import {CompleteCurrentUser} from './CompleteCurrentUser';
import {CompleteUser} from './CompleteUser';
import {Attachment} from '../Attachment';
import {Expose, Transform} from 'class-transformer';

export class User extends Entity {

  public firstName: string;
  public lastName: string;
  public disabled: boolean;
  public avatar?: Attachment;
  public superAdmin: boolean;
  public isDelegatedAuthenticationUser: boolean;
  @Expose({toClassOnly: true})
  @Transform(({obj}) => User.getFullName(obj), {toClassOnly: true})
  public fullName: string;

  static from(completeUser: CompleteUser) {
    const user = new User();
    user.id = completeUser.id;
    user.fullName = completeUser.fullName;
    user.firstName = completeUser.firstName;
    user.lastName = completeUser.lastName;
    user.disabled = completeUser.disabled;
    user.avatar = completeUser.avatar;
    return user;
  }

  static fromCompleteCurrentUser(completeUser: CompleteCurrentUser) {
    const user = new User();
    user.id = completeUser.id;
    user.fullName = completeUser.fullName;
    user.firstName = completeUser.firstName;
    user.lastName = completeUser.lastName;
    user.disabled = completeUser.disabled;
    user.avatar = completeUser.avatar;
    return user;
  }

  private static getFullName(obj: User): string {
    if (obj.disabled) {
      return `${obj.firstName} ${obj.lastName} (Désactivé)`;
    } else {
      return `${obj.firstName} ${obj.lastName}`;
    }
  }
}

export class UserWithEmail extends User {
  public email: string;
}
