import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SimpleCompany} from '../../../../models/entity/companies/SimpleCompany';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'a-select-company-id',
  templateUrl: './a-select-company-id.component.html',
  styleUrls: ['./a-select-company-id.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCompanyIdComponent),
      multi: true
    }
  ]
})
export class ASelectCompanyIdComponent implements ControlValueAccessor, OnInit {

  @Input()
  public placeholder: string = '';

  private _companies: Array<SimpleCompany>;

  private errorCompany: SimpleCompany;

  public displayedCompanies: Array<SimpleCompany>;
  public errorMode: boolean;

  @Input()
  get companies(): Array<SimpleCompany> {
    return this._companies;
  }

  set companies(companies: Array<SimpleCompany>) {
    this._companies = companies;
    this.updateDisplayedCompanies(companies);
  }

  public selectedCompany: SimpleCompany;

  private onNgChange: (value: string) => void;

  private onNgTouched: () => void;

  @Input()
  public clearable: boolean = true;

  constructor(
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.errorCompany = new SimpleCompany();
    this.errorCompany.id = 'error-company';
    this.errorCompany.name = this.translateService.instant('delivery-type.unknown');
  }

  public registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  public writeValue(companyId: string): void {
    this.resetError();
    if (companyId == null) { this.selectedCompany = undefined; return; }
    const company = this.companies.find(c => c.id === companyId);
    if (company == null) { this.activateError(); return; }
    this.selectedCompany = company;
  }

  public onChange(company: SimpleCompany): void {
    this.resetError();
    this.selectedCompany = company;
    this.onNgChange(company ? company.id : undefined);
  }

  public compare(company1: SimpleCompany, company2: SimpleCompany) {
    return company1 != null && company2 != null && company1.id === company2.id;
  }

  private updateDisplayedCompanies(companies: Array<SimpleCompany>) {
    this.displayedCompanies = companies || [];
    this.displayedCompanies.sort((c1, c2) => c1.name < c2.name ? -1 : 1);
  }

  private resetError(): void {
    this.errorMode = false;
    this.updateDisplayedCompanies([...this._companies]);
  }

  private activateError(): void {
    this.errorMode = true;
    this.selectedCompany = this.errorCompany;
    this.updateDisplayedCompanies([...this._companies, this.errorCompany]);
  }
}
