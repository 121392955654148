import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'm-badge-dropdown',
  templateUrl: './m-badge-dropdown.component.html',
  styleUrls: ['./m-badge-dropdown.component.scss']
})
export class MBadgeDropdownComponent {

  @Input() public totalElements: number;
  @Input() public dropdownTitleKey: string;

  public opened = false;
  public _positionDropdown: string;

  @Input()
  public set positionDropdown(positionDropdown: PositionDropdown) {
    switch (positionDropdown) {
      case PositionDropdown.TOPRIGHT:
        this._positionDropdown = '_topRight';
        break;
      case PositionDropdown.TOPLEFT:
        this._positionDropdown = '_topLeft';
        break;
      case PositionDropdown.TOPCENTER:
        this._positionDropdown = '_topCenter';
        break;
      case PositionDropdown.BOTTOMRIGHT:
        this._positionDropdown = '_bottomRight';
        break;
      case PositionDropdown.BOTTOMLEFT:
        this._positionDropdown = '_bottomLeft';
        break;
      case PositionDropdown.BOTTOMCENTER:
        this._positionDropdown = '_bottomCenter';
        break;
      case PositionDropdown.LEFT:
        this._positionDropdown = '_left';
        break;
      default:
        this._positionDropdown = '_bottomLeft';
        break;
    }
  }

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onClickOut(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)
      && (event.target.classList == null || !event.target.classList.contains('ng-option'))) {
      this.opened = false;
    } else {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}

export enum PositionDropdown {
  TOPRIGHT,
  TOPLEFT,
  TOPCENTER,
  BOTTOMRIGHT,
  BOTTOMLEFT,
  BOTTOMCENTER,
  LEFT
}
