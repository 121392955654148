import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {ClassificationService} from '../../services/classification.service';
import {Domain} from '../../../shared/models/entity/classifications/Domain';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class WorkspaceDomainsResolver extends AbstractResolver<Array<Domain>> {

  constructor(spinnerService: SmartSpinnerService,
              private classificationService: ClassificationService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Domain>> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap(currentUser => {
        if (currentUser.isAgentAdmin()) {
          return this.classificationService.findAllDomainsByCurrentWorkspace();
        } else {
          return of([]);
        }
      })
    ).pipe(
      take(1)
    );
  }
}
