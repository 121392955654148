import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {
  PaginatedAdministrationUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {AdministrationUserService} from '../../services/administration-user.service';
import {AdministrationUserSearchCriteria} from '../../../shared/models/criterias/AdministrationUserSearchCriteria';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';

@Injectable()
export class AdministrationUsersPaginateResolver extends AbstractResolver<PaginatedAdministrationUsers> {

  private initialPage: number = 0;
  private step: number = 10;
  private searchCriterias = new AdministrationUserSearchCriteria();

  constructor(
    spinnerService: SmartSpinnerService,
    private administrationUserService: AdministrationUserService,
    private searchCriteriaService: SearchCriteriaService,
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedAdministrationUsers> {
    return this.searchCriteriaService.buildSearchCriteriaByQueriesFromRouteSnapshot(
      route,
      this.searchCriterias,
      {
        startingPage: this.initialPage,
        step: this.step,
      }
    ).pipe(
      take(1),
      switchMap(searchCriterias => {
        return this.administrationUserService.search(searchCriterias);
      })
    );
  }
}
