import {Component, EventEmitter, Input, Output} from '@angular/core';
import {WorkspaceSimple} from '../../../models/entity/workspaces/Workspace';
import {AdministrationUserSearchCriteria} from '../../../models/criterias/AdministrationUserSearchCriteria';

@Component({
  selector: 'm-input-search-administration-users',
  templateUrl: './m-input-search-administration-users.component.html',
  styleUrls: ['./m-input-search-administration-users.component.scss']
})
export class MInputSearchAdministrationUsersComponent {

  @Input()
  public workspaceSelectPlaceholderKey: string = '';

  @Input()
  public workspaceSelectPlaceholderKeyI18nParams: any;

  @Input()
  public qPlaceholderKey: string;

  @Input()
  public qPlaceholderKeyI18nParams: any;

  @Input()
  public workspaces: Array<WorkspaceSimple>;

  @Input()
  public administrationUserSearchCriteria: AdministrationUserSearchCriteria;
  @Output()
  public administrationUserSearchCriteriaChange: EventEmitter<AdministrationUserSearchCriteria> = new EventEmitter<AdministrationUserSearchCriteria>();

  @Output()
  public searchFired: EventEmitter<void> = new EventEmitter<void>();


  public selectedWorkspace: WorkspaceSimple;

  public filter(): void {
    this.administrationUserSearchCriteria.workspaceId = this.selectedWorkspace ? this.selectedWorkspace.id : null;
    this.searchFired.emit();
  }
}
