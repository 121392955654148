import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SimpleTeamWithAgentsCount} from '../../../models/entity/teams/simple-team-with-agents-count';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';

@Component({
  selector: 'tr[m-team]',
  templateUrl: './m-team.component.html',
  styleUrls: ['./m-team.component.scss']
})
export class MTeamComponent {

  @Input() public team: SimpleTeamWithAgentsCount;

  @Output() public editClicked = new EventEmitter<void>();
  @Output() public deleteClicked = new EventEmitter<void>();
  @Output() public duplicateClicked = new EventEmitter<void>();

  constructor(public workspaceUserService: WorkspaceUserService) {
  }

}
