import {Injectable} from '@angular/core';
import {AbstractResolver} from '../abstract-resolver';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {TeamService} from '../../services/team.service';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {SimpleTeam} from '../../../shared/models/entity/teams/simple-team';
import {catchError, tap} from 'rxjs/operators';
import {SessionService} from '../../services/session.service';
import {of} from 'rxjs';
import {NavigationService} from '../../../shared/services/navigation-service';
import {NotificationService} from '../../services/notification.service';

@Injectable()
export class TeamByIdResolver extends AbstractResolver<SimpleTeam> {

  constructor(private teamService: TeamService,
              spinnerService: SmartSpinnerService,
              private sessionService: SessionService,
              private navigationService: NavigationService,
              private notificationService: NotificationService) {
    super(spinnerService);
  }
  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SimpleTeam> {
    const teamId = route.paramMap.get('teamId');
    return this.teamService.getById(teamId).pipe(
      catchError(() => {
        this.notificationService.error('team.resolve.error');
        this.navigationService.goToSettingsPage();
        return of(null);
      })
    );
  }
}
