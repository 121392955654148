import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {RequestService} from '../../../app-root/services/request.service';
import {SimpleRequest} from '../../../shared/models/entity/requests/SimpleRequest';
import {SessionService} from '../../../app-root/services/session.service';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {CompleteMessage} from '../../../shared/models/entity/requests/CompleteMessage';
import {NotificationService} from '../../../app-root/services/notification.service';
import {RequestStatus} from '../../../shared/models/entity/enums/RequestStatus';
import {RequestPriority} from '../../../shared/models/entity/enums/RequestPriority';
import {combineLatest, forkJoin, fromEvent, Observable, of, Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, take, tap} from 'rxjs/operators';
import {AttachmentService} from '../../../app-root/services/attachment.service';
import {SharedCollection} from '../../../shared/models/entity/enums/SharedCollection';
import {MAddItemComponent} from '../../../shared/lib-components/molecules/m-add-item/m-add-item.component';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TagService} from '../../../app-root/services/tag.service';
import {RequestSearchCriteria} from '../../../shared/models/criterias/RequestSearchCriteria';
import {ResponseTemplateService} from '../../../app-root/services/responseTemplate.service';
import {ResponseTemplateSearchCriteria} from '../../../shared/models/criterias/ResponseTemplateSearchCriteria';
import {AInputComponent} from '../../../shared/lib-components/atoms/forms/a-input/a-input.component';
import {WorkspaceUserService} from '../../../app-root/services/workspace-user.service';
import {CompleteRequest} from '../../../shared/models/entity/requests/CompleteRequest';
import {User} from '../../../shared/models/entity/users/User';
import {ResponseTemplate} from '../../../shared/models/entity/ResponseTemplate';
import {Domain} from '../../../shared/models/entity/classifications/Domain';
import {Category} from '../../../shared/models/entity/classifications/Category';
import {Attachment} from '../../../shared/models/entity/Attachment';
import {Company} from '../../../shared/models/entity/companies/Company';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {SimpleCompany} from '../../../shared/models/entity/companies/SimpleCompany';
import {CompanyService} from '../../../app-root/services/company.service';
import {CompanyWithAgents} from '../../../shared/models/entity/companies/CompanyWithAgents';
import {
  PaginatedResponseTemplates
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedResponseTemplates';
import {WorkspaceAgentSearchCriteria} from '../../../shared/models/criterias/WorkspaceAgentSearchCriteria';
import {SimpleWorkspaceUser} from '../../../shared/models/entity/users/simple/SimpleWorkspaceUser';
import {
  ASelectUserLazyloadingComponent
} from '../../../shared/lib-components/atoms/forms/a-select-user-lazyloading/a-select-user-lazyloading.component';
import {APopupComponent} from '../../../shared/lib-components/atoms/a-popup/a-popup.component';
import {ClassificationService} from '../../../app-root/services/classification.service';
import {distinctEntities, flatten} from '../../../shared/functions/array-utils';
import {NextOrPreviousRequests} from '../../../shared/models/entity/requests/NextOrPreviousRequests';

@Component({
  templateUrl: './request-details-page.component.html',
  styleUrls: ['./request-details-page.component.scss']
})
export class RequestDetailsPageComponent implements OnInit, AfterViewInit, OnDestroy {

  public user: CompleteCurrentUser;
  public currentUserCompanies: Array<SimpleCompany>;
  public isAffectedAgent: boolean = false;
  public isAffectedUser: boolean = false;
  public request: CompleteRequest;
  public requestNavigationsCriteria: RequestSearchCriteria = new RequestSearchCriteria();
  public requestCurrentUserPriority: RequestPriority;
  public messageText: string;
  public agentToAdd: User;
  public userToAdd: User;
  public relatedRequestToAdd: SimpleRequest;
  public filesToUpload: Array<File> = [];
  public maxFileSize: number = 30000000;
  public existingTags: string[] = [];
  public isWritingPrivateMessage: boolean = false;
  public privateMessageRecipients: User[] = [];
  public responseTemplates: Array<ResponseTemplate> = [];
  public openResponseTemplate: boolean = false;

  @ViewChild('messagesContainer')
  private messagesContainer: ElementRef;

  @ViewChildren('messages')
  private messages: QueryList<any>;

  @ViewChild('eventsContainer')
  private eventsContainer: ElementRef;

  @ViewChildren('events')
  private events: QueryList<any>;

  @ViewChild('relatedRequestsDropdown')
  private relatedRequestsDropdown: MAddItemComponent;

  @ViewChild('responseTemplateSearch')
  private searchInputComponent: AInputComponent;
  @ViewChild('affectableAgents')
  private affectableAgentsDropdown: ASelectUserLazyloadingComponent;
  @ViewChild('affectableUsers')
  private affectableUsersDropdown: ASelectUserLazyloadingComponent;
  @ViewChild('assignSelfModal')
  private popupAssignSelf: APopupComponent;
  public previousRequest: NextOrPreviousRequests;
  public nextRequest: NextOrPreviousRequests;
  public qResTemplateSearch: string;
  public loadAvatarsLazyLoading: boolean;
  public affectableAgentsObs: Observable<Array<User>>;
  public affectableUsersObs: Observable<User[]>;
  public requestsListCount: number;
  public domainsForCompany: Observable<Domain[]> = of([]);
  public requestFiles: Array<RequestFile>;
  CLOSED: RequestStatus = RequestStatus.CLOSED;
  private navigationSubscription: Subscription;
  private currentUser: CompleteCurrentUser;

  constructor(private requestService: RequestService,
              private sessionService: SessionService,
              private route: ActivatedRoute,
              private attachmentService: AttachmentService,
              private notificationService: NotificationService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private workspaceUserService: WorkspaceUserService,
              private translateService: TranslateService,
              private utilsService: UtilsService,
              private classificationService: ClassificationService,
              private tagService: TagService,
              private responseTemplateService: ResponseTemplateService,
              private activatedRoute: ActivatedRoute,
              private companyService: CompanyService,
              private changeDetector: ChangeDetectorRef
  ) {
    // we retrieve all request count from state if we've come here from request list
    this.requestsListCount = this.router.getCurrentNavigation().extras.state?.requestsListCount;
    this.sessionService.getCurrentUserOnce().subscribe(cu => this.currentUser = cu);
  }

  initializeScreen(): void {
    this.sessionService.getCurrentUserOnce().pipe(
      tap(user => {
        this.user = user;
        this.currentUserCompanies = user.getCompaniesInActiveWorkspace();
      }),
      switchMap(() => combineLatest([this.route.data, this.activatedRoute.queryParams])),
      take(1),
      tap( ([data, params]: [{ request: CompleteRequest }, Params]) => {
        this.requestNavigationsCriteria = new RequestSearchCriteria().fromParams(params, null, null);

        if (!data.request) { return; }

        this.request = data.request;
        this.requestFiles = this.computeRequestFiles();
        this.domainsForCompany = this.classificationService.findDomainsByCompany(this.request?.company?.id);
        this.requestCurrentUserPriority = this.request.getVisiblePriority(this.user);
        this.isAffectedAgent = this.user.isAgent()
          && this.request.agents.find(agent => agent.id === this.user.id) != null;
        this.isAffectedUser = this.user.isUser()
          && this.request.users.find(user => user.id === this.user.id) != null;
        if (!this.user.isUser()) {
          this.responseTemplateService
            .find(ResponseTemplateSearchCriteria.from(this.request.domain, this.request.category))
            .subscribe(responseTemplates => this.responseTemplates = responseTemplates.result);
        }
      }),
      tap(_ => this.changeAffectableAgentsObservable(this.request)),
      tap(_ => this.changeAffectableUsersObs(this.request)),
      tap(_ => this.getNextAndPreviousRequest(this.request)),
      finalize(() => { this.changeDetector.detectChanges(); })
    ).subscribe();
    this.tagService.getExistingTagsForRequest().subscribe(tags => this.existingTags = tags);
  }

  private getNextAndPreviousRequest(request: CompleteRequest) {
    forkJoin([
      this.requestService.getNext(request.code, this.requestNavigationsCriteria),
      this.requestService.getPrevious(request.code, this.requestNavigationsCriteria)
    ]).subscribe(([next, previous]) => {
      this.nextRequest = next;
      this.previousRequest = previous;
    });
  }

  ngOnInit(): void {
    this.initializeScreen();
    if (!this.requestsListCount) {
      this.requestService.count(this.requestNavigationsCriteria).subscribe(count => this.requestsListCount = count);
    }

    this.navigationSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.clearData();
        this.initializeScreen();
      }
    });
  }

  clearData() {
    this.previousRequest = undefined;
    this.nextRequest = undefined;
    this.request = undefined;
    this.responseTemplates = undefined;
    this.currentUserCompanies = undefined;
    this.requestCurrentUserPriority = undefined;
    this.isAffectedAgent = undefined;
    this.isAffectedUser = undefined;
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInputComponent.inputField.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((_: string) => {
        const criteria = ResponseTemplateSearchCriteria.empty();
        criteria.q = this.qResTemplateSearch;
        criteria.domainId = this.request.domain.id;
        return this.responseTemplateService
          .find(criteria);
      })).subscribe(responseTemplates => this.responseTemplates = responseTemplates.result);
    // Ugly tricks to wait images preview rendering before scrollToBottom
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      this.scrollToBottom();
    })();
    this.messages.changes.subscribe(() => this.scrollToBottom());
    this.events.changes.subscribe(() => this.scrollToBottom());
  }

  addSelf() {
    this.spinner.show();


    this.requestService
      .selfAssign(this.request.id)
      .subscribe(request => {
        this.request = request;
        this.refreshAffectedAgent();
        this.spinner.hide();
        this.notificationService.success('common.success');
      });
  }

  /*********************************
   ***  CREATION AND MSG SUBMIT  ***
   *********************************/

  public onSubmitMessage(): void {
    this.spinner.show();
    if (!this.request.id) {
      this.submitMessage(this.createRequest(this.messageText, this.currentUser)).pipe(
        tap(req => {
          this.messageText = undefined;
          this.filesToUpload = [];
          this.router.navigate(['workspaces', req.workspace.code, 'requests', req.code]);
        })
      ).subscribe(
        () => this.onSuccess('notification.request.created'),
        error => this.onError(error, 'notification.request.created.error'));
    } else {
      this.submitMessage(of(this.request))
        .pipe(
          tap(req => this.request = req),
          tap(_ => this.requestFiles = this.computeRequestFiles())
        ).subscribe(
        () => this.onSuccess('notification.message.created'),
        error => this.onError(error, 'notification.message.created.error'));
    }
  }

  private onSuccess(messageKey: string): void {
    this.notificationService.success(messageKey);
    this.spinner.hide();
  }

  private onError(error: any, genericErrorMessageKey: string): void {
    if (error && error.status === 413) {
      this.notificationService.error('notification.attachment.error.REQUEST_ENTITY_TOO_LARGE', {error: error.error || error.statusText});
    } else {
      this.notificationService.error(genericErrorMessageKey);
    }
    this.spinner.hide();
  }

  private submitMessage(requestObservable: Observable<CompleteRequest>): Observable<CompleteRequest> {
    return requestObservable.pipe(
      tap(request => this.request = request),
      switchMap(() => this.uploadFiles()),
      map(attachments => CompleteMessage.from(
        this.user,
        this.messageText,
        attachments,
        this.isWritingPrivateMessage,
        this.privateMessageRecipients.filter(u => u.id !== '-1')
        // We remove the 'admin only' user, it is carried by isWritingPrivateMessage
      )),
      switchMap(message => this.requestService.sendMessage(this.request.id, message)),
      tap(_ => {
        this.messageText = undefined;
        this.filesToUpload = [];
      }),
    );
  }

  private createRequest(description: string, currentUser: CompleteCurrentUser): Observable<CompleteRequest> {
    this.request.description = description;
    this.request.workspace = currentUser.activeWorkspace;
    this.request.createdDate = new Date();
    this.request.lastStatusChangeDate = new Date();
    return this.requestService.create(this.request);
  }


  /***********************
   ***  ATOMIC UPDATE  ***
   ***********************/

  public onStatusChange(newStatus: RequestStatus): void {
    // Note : Status can be changed only on update
    this.updateRequest(this.requestService.updateStatus(this.request.id, newStatus)).subscribe();
  }

  public onPriorityChange(newPriority: RequestPriority): void {
    this.request.id ?
      this.updateRequest(this.requestService.updatePriority(this.request.id, newPriority)).subscribe() :
      this.user.isUser() ? this.request.userPriority = newPriority : this.request.priority = newPriority;
  }

  public onTagsChange(newTags: Array<string>): void {
    if (this.request.tags.toString().toUpperCase() !== newTags.toString().toUpperCase()) {
      this.request.id ?
        this.updateRequest(this.requestService.updateTags(this.request.id, newTags)).subscribe() :
        this.request.tags = newTags;
    }
  }

  public onSelectedAgentToAdd(agentToAdd: User): void {
    this.agentToAdd = agentToAdd;
    if (agentToAdd && !this.request.agents.find(agent => agent.id === agentToAdd.id)) {
      this.request.agents.push(agentToAdd);
      this.affectableAgentsDropdown.removeUser(agentToAdd.id);
      if (this.request.id) {
        this.updateRequest(this.requestService.updateAgents(this.request.id, this.request.agents)).subscribe( _ => {
          this.refreshAffectedAgent();
        }, err => {
          this.affectableAgentsDropdown.addUser(agentToAdd); // adding back if an error popped
        });
      }
    } else {
      this.notificationService.warning('request.agent.already.affected', {fullName: agentToAdd.fullName});
    }
    setTimeout(() => this.agentToAdd = undefined);
  }

  public removeAgent(agentToRemove: User): void {
    const remainingAgents = this.request.agents.filter(agent => agent.id !== agentToRemove.id);
    this.affectableAgentsDropdown.addUser(agentToRemove);
    if (this.request.id) {
      this.updateRequest(this.requestService.updateAgents(this.request.id, remainingAgents)).subscribe( _ => {
        this.refreshAffectedAgent();
      }, err => {
        this.affectableAgentsDropdown.removeUser(agentToRemove.id); // remove back if an error popped
      });
    } else { // if request not created yet (new note screen)
      this.request.agents = remainingAgents;
    }
  }

  public onSelectedUserToAdd(userToAdd: User): void {
    this.userToAdd = userToAdd;
    if (userToAdd && !this.request.users.find(user => user.id === userToAdd.id)) {
      this.request.users.push(userToAdd);
      this.affectableUsersDropdown.removeUser(userToAdd.id);
      if (this.request.id) {
        this.updateRequest(this.requestService.updateUsers(this.request.id, this.request.users)).subscribe(() => {},
          err => {
            this.affectableUsersDropdown.addUser(userToAdd); // adding back if an error popped
          });
      }
    } else {
      this.notificationService.warning('request.user.already.affected', {fullName: userToAdd.fullName});
    }
    setTimeout(() => this.userToAdd = undefined);
  }

  public removeUser(userToRemove: User): void {
    const remainingUsers = this.request.users.filter(user => user.id !== userToRemove.id);
    this.affectableUsersDropdown.addUser(userToRemove);
    if ( this.request.id) {
      this.updateRequest(this.requestService.updateUsers(this.request.id, remainingUsers)).subscribe(() => {},
        err => {
          this.affectableUsersDropdown.removeUser(userToRemove.id); // remove back if an error popped
        });
    } else {
      this.request.users = remainingUsers;
    }
  }

  public onSelectedRequestToAdd(requestToAdd: SimpleRequest): void {
    this.relatedRequestToAdd = requestToAdd;
    if (requestToAdd && !this.request.relatedRequests.find(request => request.id === requestToAdd.id)) {
      this.request.relatedRequests.push(requestToAdd);
      if (this.request.id) {
        this.updateRequest(this.requestService.updateRelatedRequests(this.request.id, this.request.relatedRequests)).subscribe();
      }
    } else {
      this.notificationService.warning('request.related.request.already.linked', {codeWithTopic: requestToAdd.getCodeWithTopic()});
    }
    setTimeout(() => this.relatedRequestToAdd = undefined);
  }

  public removeRelatedRequest(relatedRequestToRemove: SimpleRequest): void {
    const remainingRelatedRequests = this.request.relatedRequests.filter(request => request.id !== relatedRequestToRemove.id);
    this.request.id ?
      this.updateRequest(this.requestService.updateRelatedRequests(this.request.id, remainingRelatedRequests)).subscribe() :
      this.request.relatedRequests = remainingRelatedRequests;
  }

  private actualizeResponseTemplates(request: CompleteRequest): Observable<PaginatedResponseTemplates> {
    return this.responseTemplateService.find(ResponseTemplateSearchCriteria.fromRequest(request)).pipe(
      tap(responseTemplates => this.responseTemplates = responseTemplates.result)
    );
  }

  public onRequestDomainChange(domain: Domain): void {
    this.request.category = null;
    this.request.domain = domain;
    this.changeAffectableAgentsObservable(this.request);
    this.changeAffectableUsersObs(this.request);
    this.actualizeResponseTemplates(this.request).subscribe();
  }

  public onRequestCategoryChange(category: Category): void {
    if (this.request.id) {
      this.updateRequest(
        this.requestService.updateDomain(this.request.id, this.request.domain?.id)
          .pipe(
            switchMap(_ => this.requestService.updateCategory(this.request.id, category?.id))
          )
      ).pipe(
        tap(request => this.changeAffectableAgentsObservable(request)),
        tap(request => this.changeAffectableUsersObs(request)),
        tap(request => this.actualizeResponseTemplates(request)),
      )
        .subscribe();
    } else {
      this.request.category = category;
      this.actualizeResponseTemplates(this.request).subscribe();
    }
  }

  private updateRequest(completeRequestObservable: Observable<CompleteRequest>): Observable<CompleteRequest> {
    this.spinner.show();
    return completeRequestObservable.pipe(
      tap((request: CompleteRequest) => {
        this.request = request;
        this.notificationService.success('request.update.success');
        this.spinner.hide();
      }),
      catchError(() => {
        this.notificationService.error('request.update.error');
        this.spinner.hide();
        return of(null);
      })
    );
  }

  public refreshAffectedAgent(): void {
    this.isAffectedAgent = this.user.isAgent()
      && this.request.agents.find(agent => agent.id === this.user.id) != null;
  }

  /***********************
   ***   ATTACHMENTS   ***
   ***********************/

  private fileToAttachmentContainer(el: File) {
    const res =  new RequestFile();
    res.name = el.name;
    res.canBeDownloaded = false;
    res.attachment = null;
    return res;
  }

  private attachmentToAttachmentContainer(el: Attachment) {
    const res =  new RequestFile();
    res.name = el.name;
    res.canBeDownloaded = true;
    res.attachment = el;
    return res;
  }

  public computeRequestFiles(): Array<RequestFile> {
    return this.filesToUpload
      .map(el => this.fileToAttachmentContainer(el))
      .sort((a, b) => this.utilsService.compareStrings(a.name, b.name, true))
      .concat(
        flatten(this.request.messages.map(message => message.attachments))
        .map((el: Attachment) => this.attachmentToAttachmentContainer(el))
        .sort((a, b) => this.utilsService.compareStrings(a.name, b.name, true))
      );
  }

  public addFileToUpload(value: File): void {
    const file: File = value;
    this.filesToUpload.push(file);
    this.requestFiles = this.computeRequestFiles();
  }

  public onAttachmentRemoved(removedAttachment: File): void {
    this.filesToUpload = this.filesToUpload.filter(file => file !== removedAttachment);
    this.requestFiles = this.computeRequestFiles();
  }

  private uploadFiles(): Observable<Attachment[]> {
    let uploadObservables: Array<Observable<Attachment>>;

    if (this.filesToUpload.length > 0) {
      uploadObservables = this.filesToUpload.map(file =>
        this.attachmentService.uploadAttachment({
          collection: SharedCollection.REQUESTS,
          id: this.request.id
        }, file, this.user));
      return forkJoin(uploadObservables);
    } else {
      return of([]);
    }
  }


  /***********************
   ***   OTHERS   ***
   ***********************/

  public goToRequestPage(relatedRequest: SimpleRequest): void {
    this.relatedRequestsDropdown.actionsOpened = false;
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests', relatedRequest.code]);
  }

  /**
   *
   * @param company
   * @private
   * @description if company has only one domain, then autofill the request domain with it
   */
  private autofillDomains(company: Company): void {
    if (company.visibleDomains().length === 1 && this.user.hasDomainInActiveWorkspace(company.visibleDomains()[0])) {
      this.request.domain = company.visibleDomains()[0];
      if (this.request.domain.categories.length === 1) {
        this.request.category = this.request.domain.categories[0];
      }
    }
  }

  public onRequestCompanyChange(company: SimpleCompany): void {
    if (company) {
      this.request.company = company;
      this.request.agents = this.request.agents.filter(agent => agent.id === this.user.id);
      this.request.users = [];
      this.request.domain = undefined;
      this.request.category = undefined;
      this.domainsForCompany = this.classificationService.findDomainsByCompany(this.request.company.id);
      this.changeAffectableAgentsObservable(this.request);
      this.changeAffectableUsersObs(this.request);
      this.companyService.getCompleteById(company.id)
        .pipe(
          tap((retrievedCompany: CompanyWithAgents) => this.autofillDomains(retrievedCompany)),
          switchMap( (_: CompanyWithAgents) =>
            this.responseTemplateService.find(
              ResponseTemplateSearchCriteria.from(this.request.domain, this.request.category)
            )
          ),
          tap(responseTemplates => this.responseTemplates = responseTemplates.result)
        ).subscribe();
    } else {
      this.domainsForCompany = of([]);
    }
  }

  private scrollToBottom(): void {
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    if (this.eventsContainer) {
      this.eventsContainer.nativeElement.scrollTop = this.eventsContainer.nativeElement.scrollHeight;
    }
  }

  private changeAffectableUsersObs(request: CompleteRequest): Observable<User[]> {
    if (!request.company) { this.affectableUsersObs = of([]); return; }
    if (this.isAffectedUser || this.isAffectedAgent || this.user.isAgentAdmin()) {
      this.affectableUsersObs =
        this.workspaceUserService.findUsersForCompany(request.company.id)
          .pipe(
            map(users => users.map(it => it.toUser())),
            map(users => users.filter(user => {
              return request.users.find(it => it.id === user.id) === undefined;
            }))
          );
    } else {
      this.affectableUsersObs = of([]);
    }
  }

  private changeAffectableAgentsObservable(request: CompleteRequest) {
    if (!request.company) { this.affectableAgentsObs = of([]); return; }
    const searchCriteria = new WorkspaceAgentSearchCriteria(
      undefined,
      undefined,
      request.company.id,
      undefined,
      undefined,
      request.domain?.id ? [request.domain.id] : []
    );
    if (this.isAffectedAgent || this.user.isAgentAdmin()) {
      this.affectableAgentsObs = forkJoin([
        this.workspaceUserService.findAgentsForCompanyAndDomain(request.company.id, request.domain?.id),
        this.workspaceUserService.findSimpleAgentsByTeams(searchCriteria)
      ])
        .pipe(
          map(([companyAgents, teamsAgents]) => [].concat(companyAgents).concat(teamsAgents)),
          map((agents: SimpleWorkspaceUser[]) => distinctEntities(agents)),
          map((agents: SimpleWorkspaceUser[]) => agents.map(it => it.toUser())),
          map((agents: SimpleWorkspaceUser[]) => agents.filter(agent => {
            return request.agents.find(it => it.id === agent.id) === undefined;
          })),
          switchMap((agents: Array<SimpleWorkspaceUser>) => of(agents))
        );
    } else {
      this.affectableAgentsObs = of([]);
    }
  }

  public getMissingFields(form: NgForm): string {
    const invalidFields = [];
    for (const name in form.controls) {
      if (form.controls[name].invalid) {
        invalidFields.push(this.translateService.instant('request.invalid.field',
          {fieldName: this.translateService.instant('request.' + name)}));
      }
    }
    return invalidFields.join('\n');
  }

  public onAttachmentClick(attachment: Attachment): void {
    this.attachmentService.getAttachment(attachment).subscribe(file => {
      const anchor = document.createElement('a');
      anchor.download = file.name;
      anchor.href = URL.createObjectURL(file);
      anchor.click();
    });
  }

  public onTabChange(): void {
    this.scrollToBottom();
  }

  public canModifyDomainOnRequestUpdate(): boolean {
    return this.isAffectedAgent || this.isAffectedUser;
  }

  public canModifyCategoryOnRequestUpdate(): boolean {
    return this.canModifyDomainOnRequestUpdate() &&  !!this.request.domain;
  }

  public navigateToRequestLists(resetPagination: boolean = false): void {
    if (resetPagination) {
      this.requestNavigationsCriteria.page = 0;
    }
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests'],
      {queryParams: this.requestNavigationsCriteria.toCleanQueryParams()});
  }

  public navigateToPrevious(): void {
    if (this.previousRequest.newPage != null) {
      this.requestNavigationsCriteria.page = this.previousRequest.newPage;
    }
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests', this.previousRequest.code],
      {
        queryParams: this.requestNavigationsCriteria.toCleanQueryParams()
      });
  }

  public ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  public navigateToNext(): void {
    if (this.nextRequest.code == null) {
      this.navigateToRequestLists(true);
      return;
    }

    if (this.nextRequest.newPage != null) {
      this.requestNavigationsCriteria.page = this.nextRequest.newPage;
    }
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests', this.nextRequest.code],
      {
        queryParams: this.requestNavigationsCriteria.toCleanQueryParams()
      });
  }

  public fillResponse(message: string, attachments: Array<Attachment>): void {
    this.messageText = this.messageText ? this.messageText : '' + message;
    this.closeResponseTemplates();
    if (attachments === null || attachments === undefined || attachments.length === 0) {
      return;
    }
    const attachmentsFilePromise = attachments
      .map(attachment => this.attachmentService.getAttachment(attachment));
    forkJoin(attachmentsFilePromise)
      .subscribe(fileList => this.filesToUpload = this.filesToUpload.concat(fileList));
  }

  public openResponseTemplates(): void {
    this.openResponseTemplate = true;
  }

  public closeResponseTemplates(): void {
    this.openResponseTemplate = false;
  }

  public updatePrivateMessageRecipients(recipients: User[]): void {
    this.privateMessageRecipients = recipients;
    this.isWritingPrivateMessage = !!recipients.length;
  }

  public loadAvatars() {
    this.loadAvatarsLazyLoading = true;
  }

  public fillAffectableAgents() {
    this.affectableAgentsDropdown.fillUsers();
  }

  public fillAffectableUsers() {
    this.affectableUsersDropdown.fillUsers();
  }

  public cancelSelfAssign() {
    this.popupAssignSelf.close();
  }

  public validateSelfAssign() {
    this.popupAssignSelf.close();
    this.addSelf();
  }

  public openPopupSelfAssign() {
    this.popupAssignSelf.open();
  }
}

class RequestFile {
  name: string;
  canBeDownloaded: boolean;
  attachment?: Attachment;
}
