export enum Role {
  AGENT_ADMIN = 'AGENT_ADMIN',
  USER = 'USER',
  AGENT = 'AGENT'
}


export function agentRoles(): Array<Role> {
  return [
    Role.AGENT_ADMIN,
    Role.AGENT,
  ];
}

export function userRoles(): Array<Role> {
  return [
    Role.USER
  ];
}
