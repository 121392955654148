import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from "../services/session.service";

@Injectable()
export class CurrentWorkspaceGuard implements CanActivate {

  constructor(private sessionService: SessionService,
              private router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> {
    return this.sessionService.getCurrentUser().pipe(
      map(currentUser => currentUser && currentUser.activeWorkspace ?
        this.router.createUrlTree(['workspaces', currentUser.activeWorkspace.code]) :
        this.router.createUrlTree(['noWorkspace']))
    );
  }
}
