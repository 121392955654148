import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClassificationService} from '../../../../../app-root/services/classification.service';
import {CompanyService} from '../../../../../app-root/services/company.service';
import {Domain} from '../../../../models/entity/classifications/Domain';

@Component({
  selector: 'a-select-domain',
  templateUrl: './a-select-domain.component.html',
  styleUrls: ['./a-select-domain.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectDomainComponent),
      multi: true
    }
  ]
})
export class ASelectDomainComponent implements ControlValueAccessor {

  public domains: Array<Domain>;
  public selectedDomain: Domain;
  private onNgChange: (values: Domain) => void;
  private onNgTouched: () => void;
  private _companyId: string;

  @Input()
  public name: string = 'domain';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean = false;

  @Input()
  get companyId(): string {
    return this._companyId;
  }

  set companyId(companyId: string) {
    this._companyId = companyId;
    this.injectDomains(companyId);
  }

  private injectDomains(companyId: string): void {
    if (companyId != null) {
      this.classificationService
        .findDomainsByCompany(companyId)
        .subscribe(domains => this.domains = domains);
    } else {
        this.classificationService.findAllDomainsByCurrentWorkspace().subscribe(domains => this.domains = domains);
    }
  }

  constructor(public classificationService: ClassificationService, public companyService: CompanyService) {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(domain: Domain): void {
    this.selectedDomain = domain;
  }

  public onChange(domain: Domain): void {
    this.selectedDomain = domain;
    this.onNgChange(domain);
  }

  public compare(domain1: Domain, domain2: Domain): boolean {
    return domain1 != null && domain2 != null && domain1.id === domain2.id;
  }
}
