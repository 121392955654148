import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../app-root/services/session.service';
import {PaginatedCriteria, SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {WorkspaceUserService} from '../../../app-root/services/workspace-user.service';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {WorkspaceAgentSearchCriteria} from '../../../shared/models/criterias/WorkspaceAgentSearchCriteria';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {Company} from '../../../shared/models/entity/companies/Company';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable, Subject, Subscription} from 'rxjs';
import {
  PaginatedSimpleWorkspaceUser
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceUser';
import {
  SimpleWorkspaceAgentListElement
} from '../../../shared/models/entity/users/simple/SimpleWorkspaceAgentListElement';
import {SimpleTeam} from '../../../shared/models/entity/teams/simple-team';
import {APopupComponent} from '../../../shared/lib-components/atoms/a-popup/a-popup.component';
import {TeamService} from '../../../app-root/services/team.service';
import {NotificationService} from '../../../app-root/services/notification.service';
import {finalize, skip, tap} from 'rxjs/operators';
import {CompanyService} from '../../../app-root/services/company.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {NavigationService} from '../../../shared/services/navigation-service';

@Component({
  selector: 'agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.scss']
})
export class AgentsListComponent implements OnInit, OnDestroy {

  public currentUser: CompleteCurrentUser;
  public agents: Array<SimpleWorkspaceAgentListElement> = [];
  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;
  public agentSearchCriteria: WorkspaceAgentSearchCriteria;
  public agentsCount: number;
  public allTeams: Array<SimpleTeam>;
  public allTeamsLoading: boolean;
  @ViewChild('teamAssignationModal')
  public teamAssignationModal: APopupComponent;
  public checkedAgents: Set<string> = new Set();
  private url: string[];
  private reloadOnNavigationSub: Subscription;

  @Input()
  public set initialAgentsSet(paginatedUsers: PaginatedSimpleWorkspaceUser) {
    this._initialAgentsSet = paginatedUsers;
    this.reset(paginatedUsers);
  }

  private _initialAgentsSet: PaginatedSimpleWorkspaceUser;

  @Output()
  public reloadAgents: EventEmitter<PaginatedCriteria> = new EventEmitter<PaginatedCriteria>();
  public areAllAgentsChecked: boolean;
  public agentCheckboxValueBus: Subject<boolean>;
  public allCompaniesObs: Observable<Company[]>;

  constructor(private router: Router,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private spinnerService: NgxSpinnerService,
              private sessionService: SessionService,
              private workspaceUserService: WorkspaceUserService,
              private teamService: TeamService,
              private companyService: CompanyService,
              private notificationService: NotificationService,
              private searchCriteriaService: SearchCriteriaService,
              private navigationService: NavigationService,
  ) {
    this.agentCheckboxValueBus = new Subject<boolean>();
    this.sessionService.getCurrentUserOnce().subscribe(user => {
      this.currentUser = user;
      this.url = ['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'agents'];
    });
  }

  ngOnDestroy(): void {
    this.reloadOnNavigationSub.unsubscribe();
  }

  public ngOnInit(): void {
    this.allCompaniesObs = this.companyService.findAllCurrent();
    this.reloadOnNavigationSub = this.searchCriteriaService.buildSearchCriteriaByQueries(
      this.agentSearchCriteria,
      {
        step: this.DEFAULT_STEP,
        startingPage: this.DEFAULT_STARTING_PAGE,
      }
    ).pipe(
      skip(1),
      tap(searchCriterias => this.loadAgents(searchCriterias) )
    ).subscribe();

    if (this._initialAgentsSet) {
      this.agents = this._initialAgentsSet.result;
      this.agentsCount = this._initialAgentsSet.metadata.count;
    } else {
      this.searchCriteriaService.navigateWithQueries(this.agentSearchCriteria, this.navigationService.getAgentsSettingsListUrl());
    }
  }

  public sortAgents(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.agentSearchCriteria.sort = sort.active;
    this.agentSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.searchCriteriaService.navigateWithQueries(this.agentSearchCriteria, this.url);
  }

  public filter(): void {
    this.searchCriteriaService.navigateWithQueries(this.agentSearchCriteria, this.navigationService.getAgentsSettingsListUrl(), {resetPage: true});
  }

  public goToAddAgentPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'agents', 'new']);
  }

  public onAgentDisabled(): void {
    this.reloadAgents.emit(this.agentSearchCriteria);
  }

  public onPageChanged(agentSearchCriteria: WorkspaceAgentSearchCriteria): void {
    this.searchCriteriaService.navigateWithQueries(agentSearchCriteria, this.navigationService.getAgentsSettingsListUrl());
  }

  public loadAgents(agentSearchCriteria: WorkspaceUserSearchCriteria) {
    this.spinnerService.show();
    return this.workspaceUserService.findSimpleAgentsListElements(agentSearchCriteria)
      .pipe(
        finalize(() => this.spinnerService.hide())
      ).subscribe( paginatedAgents => {
        this.agents = paginatedAgents.result;
        this.areAllAgentsChecked = false;
        this.agentsCount = paginatedAgents.metadata.count;
      });
  }

  public reset(newSet: PaginatedSimpleWorkspaceUser): void {
    this.agentSearchCriteria = new WorkspaceAgentSearchCriteria(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    this.agents = newSet.result;
  }

  /** mass team assignation **/

  openTeamAssignationModal() {
    this.teamAssignationModal.open();
    this.allTeamsLoading = true;
    this.teamService.findAllSimple().subscribe(teams => {
      this.allTeams = teams;
      this.allTeamsLoading = false;
    });
  }

  cancel() {
    this.teamAssignationModal.close();
  }

  validate(teamId: string) {
    this.spinnerService.show();
    const teamToUpdate = this.allTeams.find(el => el.id === teamId);
    this.teamService.addAgentsToTeam(teamToUpdate, Array.from(this.checkedAgents)).subscribe(_ => {
      this.notificationService.success('settings.agents.team-modal.validate.success');
      this.spinnerService.hide();
      this.teamAssignationModal.close();
      this.router
        .navigate(['/workspaces', this.currentUser.activeWorkspace.code, 'settings', 'teams', teamId])
        .catch(err => new Error(err));
    }, err => this.notificationService.error('basic.error', {err}));
  }

  switchCheckValueAll(value) {
    if (value) {
      this.checkedAgents = new Set<string>(this.agents.map(user => user.userId));
      this.areAllAgentsChecked = true;
      this.agentCheckboxValueBus.next(true);
    } else {
      this.checkedAgents = new Set<string>();
      this.areAllAgentsChecked = false;
      this.agentCheckboxValueBus.next(false);
    }
  }

  allAgentsChecked(): boolean {
    return this.agents.map(el => this.checkedAgents.has(el.userId)).every(el => el);
  }

  agentChecked(agentId: string) {
    this.checkedAgents.add(agentId);
    this.areAllAgentsChecked = this.allAgentsChecked();
  }

  agentUnchecked(agentId: string) {
    this.checkedAgents.delete(agentId);
    this.areAllAgentsChecked = this.allAgentsChecked();
  }

  onStepChanged(agentSearchCriteria: WorkspaceAgentSearchCriteria) {
    this.searchCriteriaService.navigateWithQueries(agentSearchCriteria, this.navigationService.getAgentsSettingsListUrl(), {resetPage: true});
  }
}
