import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'a-status-bullet',
  templateUrl: './a-status-bullet.component.html',
  styleUrls: ['./a-status-bullet.component.scss']
})
export class AStatusBulletComponent implements OnChanges, OnInit {

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public color: StateColor = 'DEFAULT';

  @Input()
  public size: Size = 'DEFAULT';

  public fromStateColor = fromStateColor;
  public fromSize = fromSize;
  public cssClass: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size || changes.color) {
      this.cssClass = 'statusBullet ' + fromSize(this.size) + ' ' +  fromStateColor(this.color);
    }
  }

  ngOnInit() {
    this.cssClass = 'statusBullet ' + fromSize(this.size) + ' ' +  fromStateColor(this.color);
  }
}

export type Size = 'LARGE' | 'DEFAULT' | 'SMALL' | 'TINY';

export function fromSize(size: Size): string {
  switch (size) {
    case 'LARGE':
      return '_large';
    case 'DEFAULT':
      return '_default';
    case 'SMALL':
      return '_small';
    case 'TINY':
      return '_tiny';
  }
}

export type StateColor = 'IN_PROGRESS' | 'WAITING_FOR_CUSTOMER' | 'CREATED' | 'WARNING' | 'PUBLISHED' | 'ARCHIVED'| 'CLOSED' | 'DEFAULT' ;

export function fromStateColor(color: StateColor): string {
  switch (color) {
    case 'IN_PROGRESS':
      return '_IN_PROGRESS';
    case 'WAITING_FOR_CUSTOMER':
      return '_WAITING_FOR_CUSTOMER';
    case 'CREATED':
      return '_CREATED';
    case 'WARNING':
      return '_WARNING';
    case 'PUBLISHED':
      return '_PUBLISHED';
    case 'ARCHIVED':
      return '_ARCHIVED';
    case 'CLOSED':
      return '_CLOSED';
    case 'DEFAULT':
      return '_DEFAULT';
  }
}
