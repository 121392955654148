import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserWithCompanies} from '../../../shared/models/entity/users/WorkspaceUserWithCompanies';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class WorkspaceCurrentUserResolver extends AbstractResolver<WorkspaceUserWithCompanies> {

  constructor(spinnerService: SmartSpinnerService,
              private workspaceUserService: WorkspaceUserService,
              private sessionService: SessionService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkspaceUserWithCompanies> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap(currentUser => this.workspaceUserService.getByUserIdInCurrentWorkspace(currentUser.id))
    ).pipe(take(1));
  }
}
