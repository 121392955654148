import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {NewsStatus} from '../entity/enums/NewsStatus';
import {NewsPriority} from '../entity/enums/NewsPriority';
import {Params} from '@angular/router';

export class NewsSearchCriteria extends PaginatedCriteria {

  public tags?: Array<string>;
  public status?: NewsStatus;
  public priority?: NewsPriority;
  public companyDomainsId?: Array<string>;
  public q?: string;
  public workspaceCode?: string = null;

  protected transformIntoHttpParams(base: HttpParams): HttpParams {
    if (this.priority) {
      base = base.set('priority', this.priority);
    }

    if (this.status) {
      base = base.set('status', this.status);
    }

    if (this.tags) {
      this.tags.forEach(tag => base = base.append('tags', tag));
    }

    if (this.companyDomainsId) {
      this.companyDomainsId.forEach(domain => {
        return base = base.append('companyIds', domain);
      });
    }

    if (this.q) {
      base = base.set('q', this.q.trim());
    }

    if (this.workspaceCode) {
      base = base.set('workspaceCode', this.workspaceCode);
    }

    return base;
  }

  fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, null);

    this.q = params.q;
    this.priority = params.priority;
    this.status = params.status;
    this.tags = params.tags;
    this.companyDomainsId = params.companyDomainsId;
    this.workspaceCode = params.workspaceCode;

    return this;
  }

  protected reset() {
    super.defaultReset();

    this.q = undefined;
    this.priority = undefined;
    this.status = undefined;
    this.tags = undefined;
    this.companyDomainsId = undefined;
    this.workspaceCode = undefined;
  }
}
