import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {CompanySearchCriteria} from '../../shared/models/criterias/CompanySearchCriteria';
import {CompanyWithAgents} from '../../shared/models/entity/companies/CompanyWithAgents';
import {Company} from '../../shared/models/entity/companies/Company';
import {environment} from '../../../../environments/environment';
import {SimpleCompanyWithAgents} from '../../shared/models/entity/companies/SimpleCompanyWithAgents';
import {SimpleCompany} from '../../shared/models/entity/companies/SimpleCompany';
import {
  PaginatedSimpleCompaniesWithDomainsCount
} from '../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleCompaniesWithDomainsCount';

@Injectable()
export class CompanyService {

  private baseUrl = `${environment.apiUrl}/api/companies`;

  constructor(private http: HttpClient) {}

  public findAllCurrent(): Observable<Array<Company>> {
    return this.http.get<Array<Company>>(`${this.baseUrl}/current`)
      .pipe(map((principal: any) => plainToClass(Company, principal as [Object])));
  }

  public findAllSimpleCurrent(): Observable<Array<SimpleCompany>> {
    return this.http.get<Array<Company>>(`${this.baseUrl}/simple/current`)
      .pipe(map((principal: any) => plainToClass(Company, principal as [Object])));
  }

  public find(companySearchCriteria: CompanySearchCriteria): Observable<PaginatedSimpleCompaniesWithDomainsCount> {
    return this.http.get<PaginatedSimpleCompaniesWithDomainsCount>(
      this.baseUrl + '/simpleWithAgents',
      {params: companySearchCriteria.toHttpParams()}
    )
      .pipe(map((principal: any) => plainToClass(PaginatedSimpleCompaniesWithDomainsCount, principal as Object)));
  }

  public isExistingCodeInWorkspace(code: string, workspaceId: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + `/code/${code}/workspaces/${workspaceId}/exists`);
  }

  public save(simpleCompanyWithAgents: SimpleCompanyWithAgents): Observable<SimpleCompanyWithAgents> {
    return simpleCompanyWithAgents.id ? this.update(simpleCompanyWithAgents) : this.create(simpleCompanyWithAgents);
  }

  private update(companyWithAgents: SimpleCompanyWithAgents): Observable<SimpleCompanyWithAgents> {
    return this.http.put<CompanyWithAgents>(this.baseUrl + `/${companyWithAgents.id}`, companyWithAgents)
      .pipe(map((principal: any) => plainToClass(SimpleCompanyWithAgents, principal as Object)));
  }

  private create(companyWithAgents: SimpleCompanyWithAgents): Observable<SimpleCompanyWithAgents> {
    return this.http.post<CompanyWithAgents>(this.baseUrl, companyWithAgents)
      .pipe(map((principal: any) => plainToClass(SimpleCompanyWithAgents, principal as Object)));
  }

  public extractCompanyWithTeamsColumns() {
    return this.http.get(`${this.baseUrl}/extractTeams`, {
      responseType: 'blob'
    });
  }

  public extractCompanyWithDomainsColumns(): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/extractDomains`, {
      responseType: 'blob'
    });
  }

  public getById(companyId: string): Observable<SimpleCompanyWithAgents> {
    return this.http.get(`${this.baseUrl}/simpleWithAgents/${companyId}`)
      .pipe(map(company => plainToClass(SimpleCompanyWithAgents, company)));
  }

  public getCompleteById(companyId: string): Observable<CompanyWithAgents> {
    return this.http.get<CompanyWithAgents>(`${this.baseUrl}/withAgents/${companyId}`)
      .pipe(map((principal: any) => plainToClass(CompanyWithAgents, principal as Object)));
  }
}
