import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AlertService} from '../../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {ResponseTemplateService} from '../../../../app-root/services/responseTemplate.service';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {Router} from '@angular/router';
import {noop} from 'rxjs';
import {ResponseTemplate} from '../../../models/entity/ResponseTemplate';

@Component({
  selector: 'tr[m-template-list-item]',
  templateUrl: './m-template-list-item.component.html',
  styleUrls: ['./m-template-list-item.component.scss']
})
export class MTemplateListItemComponent {

  @Input()
  public template: ResponseTemplate;

  @Output()
  public deleteTemplate: EventEmitter<string> = new EventEmitter<string>();

  constructor(private alertService: AlertService,
              private notificationService: NotificationService,
              private router: Router,
              private responseTemplateService: ResponseTemplateService,
              private translateService: TranslateService
  ) {}

  public deleteResponseTemplate(): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('settings.responseTemplate.delete.popup.title'),
      this.translateService.instant('settings.responseTemplate.delete.popup.message')
    ).subscribe( _ => {
      this.deleteTemplate.emit(this.template.id);
    }, _ => noop());
  }
}
