import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ARadioComponent} from './a-radio/a-radio.component';
import {ACheckboxComponent} from './a-checkbox/a-checkbox.component';
import {AInputComponent} from './a-input/a-input.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {DirectivesModule} from '../../../directives/directives.module';
import { ASelectMultipleQueriesComponent } from './a-select-multiple-queries/a-select-multiple-queries.component';
import {TranslateModule} from '@ngx-translate/core';
import { ASelectSimpleWorkspaceUsersComponent } from './a-select-simple-workspace-users/a-select-simple-workspace-users.component';
import { ASelectSimpleDomainsComponent } from './a-select-simple-domains/a-select-simple-domains.component';
import { ASelectUserLazyloadingComponent } from './a-select-user-lazyloading/a-select-user-lazyloading.component';
import { ASelectCategoryIdComponent } from './a-select-category-id/a-select-category-id.component';
import { ASelectDomainIdComponent } from './a-select-domain-id/a-select-domain-id.component';

const components = [
  ACheckboxComponent,
  ARadioComponent,
  AInputComponent,
  ASelectSimpleWorkspaceUsersComponent,
  ASelectMultipleQueriesComponent,
  ASelectSimpleDomainsComponent,
  ASelectUserLazyloadingComponent,
  ASelectCategoryIdComponent,
  ASelectDomainIdComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    DirectivesModule,
    TranslateModule
  ],
  declarations: [
    components,
  ],
  exports: [
    components,
  ]
})
export class UiFormsModule {
}
