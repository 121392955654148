import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-select-tags',
  templateUrl: './a-select-tags.component.html',
  styleUrls: ['./a-select-tags.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectTagsComponent),
      multi: true
    }
  ]
})
export class ASelectTagsComponent implements ControlValueAccessor {

  public tags: { name: string }[] = [];

  @Input()
  public existingTags: string[] = [];

  @Input()
  public name: string = 'tags';

  @Input()
  public clearable: boolean = false;

  @Input()
  public disabled: boolean;

  public selectedTags: { name: string }[];
  private onNgChange: (value: Array<string>) => void;
  private onNgTouched: () => void;

  @Input()
  public placeholderKey: string;

  @Input()
  public placeholderKeyI18nParams: any;

  constructor() {
    this.existingTags.forEach(tag => this.tags.push({name: tag}));
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(tags: Array<string>): void {
    if (tags) {
      this.selectedTags ? this.selectedTags.length = 0 : this.selectedTags = [];
      tags.forEach(tag => this.selectedTags.push({name: tag}));
    }
  }

  public onChange(tags: any): void {
    this.selectedTags = tags;
    tags ? this.onNgChange(tags.map(tag => {
      if (tag.name) {
        return tag.name;
      } else {
        return tag;
      }
    })) : this.onNgChange(undefined);
  }

  public addTagFn(name) {
    return {name: name, tag: true};
  }
}
