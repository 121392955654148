import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgForm} from '@angular/forms';
import {CompanyService} from '../../../../app-root/services/company.service';
import {
  AInputCompanyCodeValidatorComponent
} from '../../atoms/forms/a-input-company-code-validator/a-input-company-code-validator.component';
import {UtilsService} from '../../../../app-root/services/utils.service';
import {SessionService} from '../../../../app-root/services/session.service';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {NgxSpinnerService} from 'ngx-spinner';
import {TeamService} from '../../../../app-root/services/team.service';
import {equalsArray} from '../../../functions/equals-array';
import {ClassificationService} from '../../../../app-root/services/classification.service';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';
import {SimpleCompanyWithAgents} from '../../../models/entity/companies/SimpleCompanyWithAgents';
import {SimpleDomain} from '../../../models/entity/classifications/SimpleDomain';
import {LightTeam} from '../../../models/entity/teams/light-team';
import {Observable} from 'rxjs';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {finalize, map} from 'rxjs/operators';
import {WorkspaceAgentSearchCriteria} from '../../../models/criterias/WorkspaceAgentSearchCriteria';
import {PaginatedWorkspaceUser} from '../../../models/entity/paginated/paginated-entities/PaginatedWorkspaceUser';

@Component({
  selector: 'm-company-agents-form',
  templateUrl: './m-company-agents-form.component.html',
  styleUrls: ['./m-company-agents-form.component.scss']
})
export class MCompanyAgentsFormComponent implements OnInit {

  @ViewChild('codeInputComponent')
  private codeInputComponent: AInputCompanyCodeValidatorComponent;

  @Input()
  public title: string = '';

  @Output()
  public companyWithAgentsSaved = new EventEmitter<SimpleCompanyWithAgents>();

  @ViewChild('form')
  public form: NgForm;

  @Input()
  get simpleCompanyWithAgents(): SimpleCompanyWithAgents {
    return this._simpleCompanyWithAgents;
  }

  set simpleCompanyWithAgents(companyWithAgents: SimpleCompanyWithAgents) {
    this._simpleCompanyWithAgents = companyWithAgents;
    if (companyWithAgents) {
      this.setScreenUpdateMode(companyWithAgents);
      this.classificationService.findSimpleCompanyTeamDomains(this.simpleCompanyWithAgents.id).subscribe(domains => {
        this.companyTeamDomains = domains;
      });
      this.agentsAutoAffectedSorted = this.simpleCompanyWithAgents
        .agents
        .sort((a, b) => this.utilsService.compareStrings(a.firstName, b.firstName, true));
      this.initialCode = companyWithAgents.code;
      this.teamService.findByCompany(this.simpleCompanyWithAgents.id).subscribe(teams => {
        this.selectedTeams = teams;
        this.originalTeamIds = this.selectedTeams.map(el => el.id);
      });
      this.teamService.findAllLight().subscribe(teams => this.accessibleTeams = teams);
    }
  }

  public allAgentsObs: Observable<Array<SimpleWorkspaceUser>>;
  public teamObs: Observable<Array<LightTeam>>;
  public allDomainsObs: Observable<Array<SimpleDomain>>;
  public initialCode: string;
  public agentsAutoAffectedSorted: Array<SimpleWorkspaceUser>;
  public currentUser: CompleteCurrentUser;
  public accessibleTeams: Array<LightTeam>;
  public selectedTeams: Array<LightTeam>;
  public companyTeamDomains: Array<SimpleDomain>;
  public updateMode: boolean = false;

  private _simpleCompanyWithAgents: SimpleCompanyWithAgents;
  private originalTeamIds: string[];

  constructor(private companyService: CompanyService,
              private sessionService: SessionService,
              private notificationService: NotificationService,
              private teamService: TeamService,
              private spinner: NgxSpinnerService,
              private utilsService: UtilsService,
              private classificationService: ClassificationService,
              private workspaceUserService: WorkspaceUserService
              ) {
  }

  // data init

  ngOnInit(): void {
    this.sessionService.getCurrentUserOnce().subscribe(currentUser => this.currentUser = currentUser);
    this.allAgentsObs = this.workspaceUserService
      .findSimpleAgents(new WorkspaceAgentSearchCriteria(null, null))
      .pipe(
        map((paginatedResult: PaginatedWorkspaceUser) => paginatedResult.result)
      );
    this.teamObs = this.teamService.findAllLight();
    this.allDomainsObs = this.classificationService.findAllSimpleDomainsByCurrentWorkspace();

  }

  private setScreenUpdateMode(companyWithAgents: SimpleCompanyWithAgents) {
    this.updateMode = !!companyWithAgents.id;
  }

  // teams

  private teamsHaveBeenModified() {
    return !equalsArray(this.selectedTeams.map(el => el.id), this.originalTeamIds);
  }

  private updateTeamModifications(company: SimpleCompanyWithAgents) {
    this.teamService
      .addTeamsToCompany(company.id, this.selectedTeams.map(el => el.id))
      .subscribe();
  }

// crud

  public updateOrCreate(): void {
    this.spinner.show();
    this.companyService.save(this.simpleCompanyWithAgents)
      .pipe(
        finalize(() => this.spinner.hide())
      ).subscribe(companyWithAgents => {
        // update teams
        if (this.teamsHaveBeenModified()) {
          this.updateTeamModifications(companyWithAgents);
        }
        this.notificationService.success(this.simpleCompanyWithAgents.id ? 'notification.company.updated' : 'notification.company.saved');
        this.form.form.markAsPristine();
        this.companyWithAgentsSaved.emit(companyWithAgents);
      }, error => this.notificationService.error(this.simpleCompanyWithAgents.id ?
        'notification.company.updated.error' : 'notification.company.saved.error', {error: error.error || error.statusText}));
  }

  public onAgentsChange(agents: Array<SimpleWorkspaceUser>): void {
    this.simpleCompanyWithAgents.agents = agents;
    this.filterAutoAffectedAgents(agents);
    this.sortAutoAffectedAgents(agents);
  }

  private filterAutoAffectedAgents(agents: Array<SimpleWorkspaceUser>) {
    this.simpleCompanyWithAgents.autoAffectedAgents =
      this.simpleCompanyWithAgents.autoAffectedAgents.filter(agent => agents.map(it => it.userId).includes(agent.userId));
  }

  private sortAutoAffectedAgents(agents: Array<SimpleWorkspaceUser>) {
    this.agentsAutoAffectedSorted = agents
      .sort((a, b) => this.utilsService.compareStrings(a.firstName, b.firstName, true));
  }

  public codeIsValid(): boolean {
    return this.codeInputComponent && this.codeInputComponent.inputNgModel.errors == null;
  }
}
