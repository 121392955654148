import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  AGENTS_OR_USER_SEARCH_CRITERIA,
  WorkspaceAgentSearchCriteria,
} from '../../../models/criterias/WorkspaceAgentSearchCriteria';
import {SessionService} from '../../../../app-root/services/session.service';
import {Role} from '../../../models/entity/enums/Role';
import {Company} from '../../../models/entity/companies/Company';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {Observable} from 'rxjs';
import {ASelectCompanyAsyncComponent} from '../../atoms/forms/a-select-company/a-select-company-async.component';

@Component({
  selector: 'm-input-search-users',
  templateUrl: './m-input-search-users.component.html',
  styleUrls: ['./m-input-search-users.component.scss']
})
export class MInputSearchUsersComponent implements OnInit, AfterViewInit {
  @Input()
  public agentDisplay: boolean = true;

  @Input()
  public qPlaceholderKey: string;

  @Input()
  public qPlaceholderKeyI18nParams: any;

  @Input()
  public companySelectPlaceholderKey: string = '';

  @Input()
  public companySelectPlaceholderKeyI18nParams: any;

  @Input()
  public companiesObs: Observable<Company[]>;

  @Output()
  public searchFired: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public searchCriterias: AGENTS_OR_USER_SEARCH_CRITERIA;
  @Output()
  public searchCriteriasChange: EventEmitter<AGENTS_OR_USER_SEARCH_CRITERIA> = new EventEmitter<AGENTS_OR_USER_SEARCH_CRITERIA>();
  public selectedCompany: Company;
  public currentUser: CompleteCurrentUser;
  @ViewChild('selectCompanyAsync')
  public selectCompanyAsync: ASelectCompanyAsyncComponent;
  public selectedRole: Role | 'ALL';

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    this.sessionService.getCurrentUserOnce().subscribe(curUser => this.currentUser = curUser);
  }

  ngAfterViewInit() {
    if (this.searchCriterias.companyId) {
      this.selectCompanyAsync.selectCompany(this.searchCriterias.companyId);
    }
    if (this.searchCriterias instanceof WorkspaceAgentSearchCriteria) {
      if (this.searchCriterias.roles === null) { this.selectedRole = 'ALL'; }
      else { this.selectedRole = this.searchCriterias.roles[0]; }
    }
  }

  public filter(): void {
    this.searchCriterias.companyId = this.selectedCompany ? this.selectedCompany.id : null;
    if (this.searchCriterias instanceof WorkspaceAgentSearchCriteria) {
      if (this.selectedRole === 'ALL') { this.searchCriterias.roles = null; }
      else { this.searchCriterias.roles = [this.selectedRole]; }
    }
    this.searchFired.emit();
  }
}
