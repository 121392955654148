import {PaginatedResult} from '../PaginatedResult';
import {Type} from 'class-transformer';
import {WorkspaceUser} from '../../users/WorkspaceUser';
import {PaginatedMetadata} from '../PaginatedMetadata';

export class PaginatedWorkspaceUser extends PaginatedResult {
  @Type(() => WorkspaceUser)
  public result: Array<WorkspaceUser>;

  static emptyResult() {
    const empty = new PaginatedWorkspaceUser(new PaginatedMetadata(0, 0, 0));
    empty.result = [];
    return empty;
  }
}
