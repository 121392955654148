import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {SessionService} from '../services/session.service';
import {switchMap} from 'rxjs/operators';
import {NavigationService} from '../../shared/services/navigation-service';

@Injectable({
  providedIn: 'root'
})
export class IsTransportUserGuard implements CanActivate {

  constructor(
    private sessionService: SessionService,
    private navigationService: NavigationService,
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sessionService.getCurrentUserOnce().pipe(
      switchMap(currentUser => {
        const isUser = currentUser.isUser();
        if (isUser) {
          return of(true);
        } else {
          return of(this.navigationService.getDashboardUrlTree());
        }
      })
    );
  }

}
