export class Stats {
  public agentsCount: number;
  public usersCount: number;
  public newsCount: number;
  public companiesCount: number;
  public responseTemplatesCount: number;
  public teamsCount: number;

  static emptyStats(): Stats {
    return new Stats();
  }
}
