import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {BaererSessionService} from '../baerer-session.service';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private baererSessionService: BaererSessionService,
              private toastrService: ToastrService,
              private translateService: TranslateService
            ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const current = this.baererSessionService.current();

      if (current && !req.headers.has('Authorization')) {
        return next.handle(req.clone({
          setHeaders: {
            'Authorization': `Bearer ${current.toBearerHeaderValue()}`
          }
        })).pipe(catchError(err => {
          if (err.status === 401) {
            this.toastrService.error(this.translateService.instant('common.not-authorized'));
          }
          return throwError(err);
        }));
      }

    return next.handle(req);
  }
}
