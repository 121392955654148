import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ClassificationService} from '../../../../../app-root/services/classification.service';
import {SimpleDomain} from '../../../../models/entity/classifications/SimpleDomain';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-select-simple-domains',
  templateUrl: './a-select-simple-domains.component.html',
  styleUrls: ['./a-select-simple-domains.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectSimpleDomainsComponent),
      multi: true
    }
  ]
})
export class ASelectSimpleDomainsComponent implements ControlValueAccessor {


  public domains: Array<SimpleDomain>;
  public selectedDomains: Array<SimpleDomain>;
  private onNgChange: (values: Array<SimpleDomain>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'domains';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  constructor(classificationService: ClassificationService) {
    classificationService.findAllSimpleDomainsByCurrentWorkspace().subscribe(domains => this.domains = domains);
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(domains: Array<SimpleDomain>): void {
    this.selectedDomains = domains;
  }

  public onChange(domains: Array<SimpleDomain>): void {
    this.selectedDomains = domains;
    this.onNgChange(domains);
  }

  public compare(domain1: SimpleDomain, domain2: SimpleDomain): boolean {
    return domain1 != null && domain2 != null && domain1.id === domain2.id;
  }

}
