import {Entity} from '../common/Entity';
import {Attachment} from '../Attachment';
import {WorkspaceSimpleWithUserState} from '../workspaces/WorkspaceWithUserState';
import {Expose, Transform, Type} from 'class-transformer';

export class UserAdministrationSearchResult extends Entity {

  public firstName: string;
  public lastName: string;
  public superAdmin: boolean;
  public email: string;
  public disabled: boolean;
  public avatar?: Attachment;
  @Type(() => WorkspaceSimpleWithUserState)
  public workspaces: Array<WorkspaceSimpleWithUserState>;
  public isDelegatedAuthenticationUser: boolean;
  @Expose({toClassOnly: true})
  @Transform(({obj}) => UserAdministrationSearchResult.getFullName(obj), {toClassOnly: true})
  public fullName: string;

  private static getFullName(obj: UserAdministrationSearchResult): string {
    return `${obj.firstName} ${obj.lastName}`;
  }

  public getWorkspacesStr(): string {
    return this.workspaces ? this.workspaces.map(workspace => workspace.getNameWithUserState()).join(', ') : '';
  }

  public isDisabledOrDeleted() {
    return this.disabled;
  }
}
