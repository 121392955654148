import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'a-badge',
    templateUrl: './a-badge.component.html',
    styleUrls: ['./a-badge.component.scss']
})
export class ABadgeComponent implements OnChanges, OnInit {

  @Input()
  public loading: boolean = false;
  @Input()
  public size: Size = 'DEFAULT';

  @Input()
  public visibility: Visibility = 'HIGH';

  @Input()
  public pulse: boolean;

  @Input()
  public disabled: boolean;

  public fromSize = fromSize;
  public cssClass: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size || changes.visibility) {
      this.cssClass = 'badge ' + fromSize(this.size) + ' ' + fromVisibility(this.visibility);
    }
  }

  ngOnInit() {
    this.cssClass = 'badge ' + fromSize(this.size) + ' ' + fromVisibility(this.visibility);
  }
}

export type Size = 'LARGE' | 'DEFAULT' | 'SMALL' | 'TINY';


export function fromSize(size: Size): string {
  switch (size) {
    case 'LARGE':
      return '_large';
    case 'DEFAULT':
      return '_default';
    case 'SMALL':
      return '_small';
    case 'TINY':
      return '_tiny';
  }
}

export type Visibility = 'HIGH' | 'HIGHREVERT' | 'MEDIUM' | 'LOW' | 'LOWREVERT' | 'SUCCESS'  | 'WARNING' | 'ERROR' |  'UNKNOWN' | 'IN_PROGRESS' | 'NOT_STARTED' | 'DONE';

export function fromVisibility(visibility: Visibility): string {
  switch (visibility) {
    case 'HIGHREVERT':
      return '_highRevert';
    case 'HIGH':
      return '_high';
    case 'MEDIUM':
      return '_medium';
    case 'LOWREVERT':
      return '_lowRevert';
    case 'LOW':
      return '_low';
    case 'SUCCESS':
      return '_success';
    case 'WARNING':
      return '_warning';
    case 'ERROR':
      return '_error';
    case 'UNKNOWN':
      return '_unknown';
    case 'DONE':
      return '_done';
    case 'NOT_STARTED':
      return '_notStarted';
    case 'IN_PROGRESS':
      return '_inProgress';
  }
}
