import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-input-queries',
  templateUrl: './a-select-multiple-queries.component.html',
  styleUrls: ['./a-select-multiple-queries.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectMultipleQueriesComponent),
      multi: true
    }
  ]
})
export class ASelectMultipleQueriesComponent {

  @Input()
  public name: string = 'queries';

  @Input()
  public clearable: boolean = false;

  @Input()
  public disabled: boolean;

  public selectedQueries: string[];

  private onNgChange: (value: Array<string>) => void;
  private onNgTouched: () => void;

  @Input()
  public placeholderKey: string;

  @Input()
  public placeholderKeyI18nParams: any;

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(queries: Array<string>): void {
    this.selectedQueries = queries;
  }

  public onChange(queries: any): void {
    this.selectedQueries = queries;

    queries ? this.onNgChange(queries) : this.onNgChange(undefined);
  }

  public addQueryFn(name) {
    return name;
  }
}
