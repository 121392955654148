import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {RequestService} from '../../services/request.service';
import {SessionService} from '../../services/session.service';
import {CompleteRequest} from '../../../shared/models/entity/requests/CompleteRequest';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {AbstractResolver} from '../abstract-resolver';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';

@Injectable()
export class RequestByCodeResolver extends AbstractResolver<CompleteRequest> {

  constructor(spinnerService: SmartSpinnerService,
              private requestService: RequestService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompleteRequest> {
    const requestCode = route.paramMap.get('requestCode');
    return requestCode ?
      this.requestService.findByCode(requestCode).pipe(take(1)) :
      this.sessionService.getCurrentUser()
        .pipe(switchMap((user: CompleteCurrentUser) => of(CompleteRequest.new(user))))
        .pipe(take(1));
  }
}
