import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {FileSizePipe} from '../../../../app-root/services/pipes/file-size.pipe';

@Component({
  selector: 'm-file-upload',
  templateUrl: './m-file-upload.component.html',
  styleUrls: ['./m-file-upload.component.scss']
})
export class MFileUploadComponent {

  @Input()
  public fileTypes: Array<String> = [];

  @Input()
  public uploadNoteKey: string = undefined;

  @Input()
  public maxFileSize: number = 10000000;

  @Output()
  public fileEmitter: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('fileUpload')
  private fileUpload: ElementRef;

  @Input()
  public id: string;

  constructor(private notificationService: NotificationService, private sizePipe: FileSizePipe) {}

  public emitFile(event: any): void {
    const file = event[0] as File;

    if (file.size > this.maxFileSize) {
      this.notificationService
        .warning(
          'notification.attachment.fileTooBigError',
          {limitSize: this.sizePipe.transform(this.maxFileSize), currentFileSize: this.sizePipe.transform(file.size)}
        );
    } else if (this.fileTypes.length !== 0 && this.fileTypes.indexOf(file.type) < 0) {
      this.notificationService.warning('notification.attachment.fileTypeNotSupported', {fileType: file.type});
    } else {
      this.fileEmitter.emit(file);
      this.fileUpload.nativeElement.value = '';
    }
  }

  public getAcceptedFileTypesAsString(): String {
    return this.fileTypes.length !== 0 ?  this.fileTypes.reduce((existing, newElement) => existing + ',' + newElement) : '';
  }
}
