import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {SimpleWorkspaceUser} from '../../../models/entity/users/simple/SimpleWorkspaceUser';
import {forkJoin, of, Subject} from 'rxjs';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {WorkspaceAgentSearchCriteria} from '../../../models/criterias/WorkspaceAgentSearchCriteria';
import {SimpleRequest} from '../../../models/entity/requests/SimpleRequest';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CollectionUtils} from '../../../services/collection-utils';

@Component({
  selector: 'm-agents-async-select',
  templateUrl: './m-agents-async-select.component.html',
  styleUrls: ['./m-agents-async-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MAgentsAsyncSelectComponent),
      multi: true
    }
  ]
})
export class MAgentsAsyncSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  displayedAgents: SimpleWorkspaceUser[];
  agentsLoading: boolean;
  selectedAgents: SimpleWorkspaceUser[];
  searchOutput: Subject<string> = new Subject<string>();
  currentQ: string;
  notFoundText: string = 'common.search';
  hideSelected: boolean = true;
  private onNgChange: (values: SimpleRequest) => void;
  private onNgTouched: () => void;

  constructor(
    private workspaceUserService: WorkspaceUserService
  ) { }

  ngOnInit(): void {
    this.searchOutput
      .pipe(
        tap(() => this.notFoundText = 'common.search'),
        tap(() => this.displayedAgents = []),
        tap(q => this.currentQ = q),
        filter(q => this.filterQ(q)),
        tap(() => this.agentsLoading = true),
        debounceTime(250),
        switchMap(q => {
          this.notFoundText = 'common.no-result';
          const res = new WorkspaceAgentSearchCriteria();
          res.q = q;
          return forkJoin([this.workspaceUserService.findSimpleAgents(res), of(q)]);
        })
      )
      .subscribe(([agents, q]) => {
        this.agentsLoading = false;
        if (this.currentQ === q) {
          this.displayedAgents = agents.result;
        }
        this.setHideSelected();
      }, err => {
        console.error(`[m-agents-async-select] something bad happened\nError:\n${err}`);
      });

    this.setHideSelected();
  }

  private filterQ(q) {
    return q !== undefined && q.trim() !== '';
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  onChange($event: any) {
    this.onNgChange($event);
    this.notFoundText = 'common.search';
    this.displayedAgents = [];
  }

  writeValue(agents: SimpleWorkspaceUser[]): void {
    this.selectedAgents = agents;
  }

  ngOnDestroy() {
    this.searchOutput.unsubscribe();
  }

  onSearch($event: { term: string; items: any[] }) {
    this.searchOutput.next($event.term);
  }

  setHideSelected() {
    this.hideSelected = !(CollectionUtils.empty(this.displayedAgents) || this.displayedAgents == null);
  }
}
