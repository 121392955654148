import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {Params} from '@angular/router';

export class CompanySearchCriteria extends PaginatedCriteria {

  constructor(
    public page: number = PaginatedCriteria.DEFAULT_PAGE,
    public step: number = PaginatedCriteria.DEFAULT_STEP,
    public q?: string) {
    super(page, step);
  }

  public transformIntoHttpParams(base: HttpParams): HttpParams {
    if (this.q) {
      base = base.set('q', this.q.trim());
    }

    return base;
  }

  fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, null);

    this.q = params.q;

    return this;
  }

  protected reset() {
    super.defaultReset();

    this.q = undefined;
  }
}
