import {WorkspaceSimple} from '../workspaces/Workspace';
import {Type} from 'class-transformer';
import {User} from './User';
import {SimpleCompany} from '../companies/SimpleCompany';
import {ServiceDeskLocale} from '../enums/ServiceDeskLocale';
import {Domain} from '../classifications/Domain';
import {SimpleWorkspaceUser} from './simple/SimpleWorkspaceUser';
import {WorkspaceUserWithAvatar} from './interfaces/WorkspaceUserWithAvatar';

export class WorkspaceUser extends SimpleWorkspaceUser implements WorkspaceUserWithAvatar {
  @Type(() => WorkspaceSimple)
  public activeWorkspace: WorkspaceSimple;
  @Type(() => SimpleCompany)
  public activeCompany: SimpleCompany;
  public superAdmin: boolean;
  public email: string;
  public locale: ServiceDeskLocale;
  @Type(() => Domain)
  public subscribedDomains?: Array<Domain>;
  public isDelegatedAuthenticationUser: boolean;

  public toUser(): User {
    const user = new User();
    user.id = this.userId;
    user.firstName = this.firstName;
    user.lastName = this.lastName;
    user.disabled = this.disabled;
    user.avatar = this.avatar;
    user.superAdmin = this.superAdmin;

    return user;
  }
}

