import {WorkspaceUserSearchCriteria} from './WorkspaceUserSearchCriteria';
import {Role} from '../entity/enums/Role';
import {HttpParams} from '@angular/common/http';
import {Params} from '@angular/router';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';

export class WorkspaceAgentSearchCriteria extends WorkspaceUserSearchCriteria {

  constructor(public page: number = PaginatedCriteria.DEFAULT_PAGE,
              public step: number = PaginatedCriteria.DEFAULT_STEP,
              public companyId?: string,
              public roles?: Array<Role>,
              public q?: string,
              public domains?: string[]) {
    super(page, step, companyId, q);
  }

  static newEmptyWithPagination(page?: number, step?: number) {
    return new WorkspaceAgentSearchCriteria(page, step);
  }

  protected transformIntoHttpParams(base: HttpParams): HttpParams {
    let params = super.transformIntoHttpParams(base);

    if (this.roles != null) {
      this.roles.forEach(el => {
        params = params.append('roles', el);
      });
    }

    if (this.domains != null) {
      if (this.domains.length === 0) {
        params = params.append('domains', '');
      } else {
        this.domains.forEach(el => params = params.append('domains', el));
      }
    }

    return params;
  }

  fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.fromParams(params, defaultStep, defaultPage);

    this.roles = params.roles ? this.retrieveArray(params.roles).map(el => Role[el]) : null;
    this.domains = params.domains ? this.retrieveArray(params.domains) : null;

    return this;
  }

  protected reset() {
    super.reset();

    this.roles = undefined;
    this.domains = undefined;
  }
}

export type AGENTS_OR_USER_SEARCH_CRITERIA = WorkspaceAgentSearchCriteria | WorkspaceUserSearchCriteria;
