import {ServiceDeskLocale} from '../../enums/ServiceDeskLocale';
import {Attachment} from '../../Attachment';
import {Role} from '../../enums/Role';
import {User} from '../User';
import {UserUtils} from '../interfaces/UserUtils';
import {Expose, Transform} from 'class-transformer';

export class SimpleWorkspaceUser implements UserUtils {
  public role: Role;
  public id: string;
  public userId: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public disabled: boolean;
  public avatar?: Attachment;
  public locale?: ServiceDeskLocale;
  public isDelegatedAuthenticationUser: boolean;
  public superAdmin: boolean;
  @Expose({toClassOnly: true})
  @Transform(({obj}) => SimpleWorkspaceUser.getFullName(obj), {toClassOnly: true})
  public fullName: string;

  private static getFullName(userObj: SimpleWorkspaceUser): string {
    if (userObj.disabled) {
      return `${userObj.firstName} ${userObj.lastName} (Désactivé)`;
    } else {
      return `${userObj.firstName} ${userObj.lastName}`;
    }
  }

  public toUser(): User {
    const user = new User();

    user.id = this.userId;
    user.firstName = this.firstName;
    user.lastName = this.lastName;
    user.disabled = this.disabled;
    user.avatar = this.avatar;
    user.superAdmin = this.superAdmin;
    user.fullName = this.fullName;

    return user;
  }

  public constructor(init?: Partial<SimpleWorkspaceUser>) {
    Object.assign(this, init);
  }

  public copy(newFields: Partial<SimpleWorkspaceUser>): SimpleWorkspaceUser {
    return new SimpleWorkspaceUser({ ... this, ... newFields});
  }

  public isAgentAdmin(): boolean {
    return this.role === Role.AGENT_ADMIN;
  }

  public isAgent(): boolean {
    return this.role === Role.AGENT || this.role === Role.AGENT_ADMIN;
  }

  public isUser(): boolean {
    return this.role === Role.USER;
  }
}
