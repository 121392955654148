import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PasswordService} from '../../../app-root/services/password.service';
import {NotificationService} from '../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {WorkspaceService} from '../../../app-root/services/workspace.service';
import {Attachment} from '../../../shared/models/entity/Attachment';
import {TranslateService} from '@ngx-translate/core';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {finalize} from 'rxjs/operators';
import {OAuth2CodeFlowService} from '../../../shared/services/o-auth2-code-flow.service';

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public credentials: Credentials = {
    login: '',
    password: ''
  };

  public newPasswordRequestEmail: string;
  public authenticationError = false;
  public forgotPasswordMode = false;
  public connexionSpinnerName: string = 'connexion';
  public lostPasswordSpinnerName: string = 'passwordLost';
  public loginText: string;
  public loginLogos: Array<Attachment>;

  private queries: Params;
  oktaConnectionURL: string;

  constructor(protected sessionService: SessionService,
              private passwordService: PasswordService,
              protected spinner: NgxSpinnerService,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private workspaceService: WorkspaceService,
              private oAuth2CodeFlowService: OAuth2CodeFlowService,
              protected router: Router,
              protected route: ActivatedRoute
  ) {
    route.queryParams.subscribe(params => {
      this.queries = params;

      if (this.queries['workspaceCode']) {
        workspaceService.getLoginCustomization(this.queries['workspaceCode']).subscribe(
          loginCustom => {
            this.loginText = loginCustom.text;
            this.loginLogos = loginCustom.logos;
          },
          err => {
            console.warn(`No login customization was found for workspace code ${this.queries['workspaceCode']}`);
          });
      }
      if (this.queries['authenticationError']) {
        this.authenticationError = true
      }
    });

    route.queryParams.subscribe(params => {
      if (params && params.token) {
        this.spinner.show(this.connexionSpinnerName);
        // Ugly fix to retrieve encoded value (+ are transform into space on params.token)
        // doesn't work.. should be fixed (KHI)
        this.sessionService.authenticateFromToken(this.router.url.split('token=').pop()).subscribe(
          (completeCurrentUser: CompleteCurrentUser) => {
            this.navigateToDashboardOrRedirect(completeCurrentUser.getActiveContextualWorkspace().code, completeCurrentUser);
          },
          () => {
            this.authenticationError = true;
            this.spinner.hide(this.connexionSpinnerName);
          });
      }
    });

    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      if (navigation.extras.state.logout) {
        this.notificationService.success('login.logout.success');
      } else if (navigation.extras.state.unauthorized) {
        this.sessionService.clearSession();
        this.notificationService.warning('login.logout.unauthorized');
      }
    }
  }

  ngOnInit(): void {
    this.oAuth2CodeFlowService.loadConfiguration().subscribe(configuration => this.oktaConnectionURL =
      `${configuration.domain}/authorize?` +
      `audience=${configuration.audience}&` +
      `client_id=${configuration.clientId}&` +
      `redirect_uri=${configuration.redirectUri}&` +
      `connection=${configuration.connection}&` +
      `scope=profile email openid&` +
      `response_type=code&state=STATE`)
  }

  protected navigateToDashboardOrRedirect(workspaceCode: string, connectedUser: CompleteCurrentUser): void {
    if (this.queries['redirectTo']) {
      const url = this.queries['redirectTo'].split('/');
      url.shift();
      this.router.navigate(url);
    } else {
      const userCanNavigateToWorkspaceQuery = connectedUser.hasWorkspace(this.queries['workspaceCode']);
      if (userCanNavigateToWorkspaceQuery) {
        this
          .router
          .navigate(['workspaces', this.queries['workspaceCode'], 'dashboard'])
          .catch(_ => {
            console.warn(`Navigation failed for workspace ${this.queries['workspaceCode']}`);
            this.router.navigate(['workspaces', workspaceCode, 'dashboard']);
          });
      } else {
        console.warn(`User could not navigate to workspace ${this.queries['workspaceCode']}. Navigating to ${workspaceCode}`);
        this.router.navigate(['workspaces', workspaceCode, 'dashboard']);
      }
    }
  }

  public toggleForgotPassword(): void {
    this.forgotPasswordMode = !this.forgotPasswordMode;
  }

  public authenticate(credentials: Credentials): void {
    this.spinner.show(this.connexionSpinnerName);
    this.sessionService.authenticate(credentials.login, credentials.password)
      .pipe(
        finalize(() => this.spinner.hide(this.connexionSpinnerName))
      )
      .subscribe(
      completeUser => {
        this.navigateToDashboardOrRedirect(completeUser.getActiveContextualWorkspace().code, completeUser);
      },
      () => this.authenticationError = true
    );
  }

  public renewUserPassword(): void {
    this.spinner.show(this.lostPasswordSpinnerName);
    this.passwordService.renewUserPassword(this.newPasswordRequestEmail).subscribe(
      () => {
        this.spinner.hide(this.lostPasswordSpinnerName);
        this.router.navigate(['/login/emailSent']);
      },
      () => {
        this.spinner.hide(this.lostPasswordSpinnerName);
        this.router.navigate(['/login/emailSent']);
      });
  }
}

export interface Credentials {
  login: string;
  password: string;
}
