import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {AbstractResolver} from '../abstract-resolver';
import {
  PaginatedSimpleWorkspaceUser
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceUser';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {
  PaginatedSimpleWorkspaceAgentListElements
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleWorkspaceAgentListElements';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {SessionService} from '../../services/session.service';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {switchMap, take} from 'rxjs/operators';

@Injectable()
export class SimpleWorkspaceAgentListElementsResolver extends AbstractResolver<PaginatedSimpleWorkspaceAgentListElements> {

  private initialPage: number = 0;
  private step: number = 10;
  private searchCriterias = new WorkspaceUserSearchCriteria(this.initialPage, this.step);
  private currentUser: CompleteCurrentUser;

  constructor(spinnerService: SmartSpinnerService,
              private workspaceUserService: WorkspaceUserService,
              private sessionService: SessionService,
              private searchCriteriaservice: SearchCriteriaService
  ) {
    super(spinnerService);
    this.sessionService.getCurrentUserOnce().subscribe(currentUser => this.currentUser = currentUser);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedSimpleWorkspaceUser> {
    return this.searchCriteriaservice.buildSearchCriteriaByQueriesFromRouteSnapshot(
      route,
      this.searchCriterias,
      {
        startingPage: this.initialPage,
        step: this.step
      }
    ).pipe(
      take(1),
      switchMap(searchCriterias => {
        return this.workspaceUserService
          .findSimpleAgentsListElements(searchCriterias);
      }),
    );
  }
}
