import {Routes} from '@angular/router';
import {SettingsPageComponent} from './pages/settings/settings-page.component';
import {AgentGuard} from '../app-root/guards/agent.guard';
import {AgentAdminGuard} from '../app-root/guards/agent-admin.guard';
import {AgentDetailsPageComponent} from './pages/agent-details/agent-details-page.component';
import {WorkspaceAgentResolver} from '../app-root/resolvers/agents/workspace-agent.resolver';
import {CurrentUserProfilePageComponent} from './pages/current-user-profile/current-user-profile-page.component';
import {WorkspaceUserWithCompaniesResolver} from '../app-root/resolvers/user/workspace-user-with-companies.resolver';
import {CompanyDetailsPageComponent} from './pages/company-details/company-details-page.component';
import {NewsDetailsPageComponent} from './pages/news-details/news-details-page.component';
import {NewsResolver} from '../app-root/resolvers/news/news.resolver';
import {DomainDetailsPageComponent} from './pages/domain-details/domain-details-page.component';
import {DomainResolver} from '../app-root/resolvers/domain/domain.resolver';
import {WorkspaceNewsResolver} from '../app-root/resolvers/news/workspace-news.resolver';
import {
  WorkspaceCompaniesPaginateResolver
} from '../app-root/resolvers/companies/workspace-companies-paginate.resolver';
import {
  ResponseTemplateDetailsPageComponent
} from './pages/response-template-details/response-template-details-page.component';
import {ResponseTemplateResolver} from '../app-root/resolvers/responseTemplates/response-template.resolver';
import {
  SimpleWorkspaceAgentListElementsResolver
} from '../app-root/resolvers/agents/simple-workspace-agent-list-elements-resolver.service';
import {
  SimpleWorkspaceUserListElementsResolver
} from '../app-root/resolvers/user/simple-workspace-user-list-elements-resolver.service';
import {UserDetailsPageComponent} from './pages/user-details/user-details-page.component';
import {
  WorkspaceResponseTemplatesPaginateResolver
} from '../app-root/resolvers/responseTemplates/workspace-response-templates-paginate.resolver';
import {WorkspaceCompaniesResolver} from '../app-root/resolvers/companies/workspace-companies.resolver';
import {WorkspaceDomainsResolver} from '../app-root/resolvers/domain/workspace-domains.resolver';
import {WorkspaceCurrentUserResolver} from '../app-root/resolvers/user/workspace-current-user.resolver';
import {WorkspaceAgentsResolver} from '../app-root/resolvers/agents/workspace-agents.resolver';
import {UserAccountTabComponent} from './pages/tabs/user-account/user-account-tab.component';
import {AgentsTabComponent} from './pages/tabs/agents/agents-tab.component';
import {UsersTabComponent} from './pages/tabs/users/users-tab.component';
import {CompaniesTabComponent} from './pages/tabs/companies/companies-tab.component';
import {NewsTabComponent} from './pages/tabs/news/news-tab.component';
import {TemplatesTabComponent} from './pages/tabs/templates/templates-tab.component';
import {CustomizationTabComponent} from './pages/tabs/customization/customization-tab.component';
import {TeamsTabComponent} from './pages/tabs/teams/teams-tab.component';
import {TeamDetailsComponent} from './pages/team-details/team-details.component';
import {SimpleCompanyWithAgentsResolver} from '../app-root/resolvers/companies/simple-company-with-agents.resolver';
import {SimpleWorkspaceAgentsResolver} from '../app-root/resolvers/agents/simple-workspace-agents.resolver';
import {TeamsListResolver} from '../app-root/resolvers/teams/teams-list.resolver';
import {CurrentUserTeamsResolver} from '../app-root/resolvers/teams/current-user-teams.resolver';
import {UserTeamsResolver} from '../app-root/resolvers/teams/user-teams.resolver';
import {TeamByIdResolver} from '../app-root/resolvers/teams/team-by-id-resolver.service';

export const settingsRoutes: Routes = [
  {
    path: '', component: SettingsPageComponent, canActivate: [AgentGuard],
    children: [
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {
        path: 'information', component: UserAccountTabComponent,
        resolve: {
          currentWorkspaceUser: WorkspaceCurrentUserResolver,
          userTeams: CurrentUserTeamsResolver
        }
      },
      {
        path: 'agents', component: AgentsTabComponent,
        resolve: {
          initialAgents: SimpleWorkspaceAgentListElementsResolver,
        }
      },
      {
        path: 'users', component: UsersTabComponent, canActivate: [AgentAdminGuard],
        resolve: {
          initialUsers: SimpleWorkspaceUserListElementsResolver,
        }
      },
      {
        path: 'companies', component: CompaniesTabComponent, canActivate: [AgentAdminGuard],
        resolve: {
          initialCompanies: WorkspaceCompaniesPaginateResolver
        }
      },
      {
        path: 'news', component: NewsTabComponent, canActivate: [AgentAdminGuard],
        resolve: {
          initialNews: WorkspaceNewsResolver
        }
      },
      {
        path: 'templates', component: TemplatesTabComponent, canActivate: [AgentAdminGuard],
        resolve: {
          initialResponseTemplates: WorkspaceResponseTemplatesPaginateResolver
        }
      },
      {
        path: 'customization', component: CustomizationTabComponent,
        resolve: {
          domains: WorkspaceDomainsResolver
        }
      },
      { path: 'teams', component: TeamsTabComponent,
         resolve: {
          initialTeams: TeamsListResolver
       }
      }
    ]
  },
  {
    path: 'agents/new', component: AgentDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      agent: WorkspaceAgentResolver,
      allCompanies: WorkspaceCompaniesResolver
    },
  },
  {
    path: 'agents/:agentId', component: AgentDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      agent: WorkspaceAgentResolver,
      allCompanies: WorkspaceCompaniesResolver,
      userTeams: UserTeamsResolver
    }
  },
  {
    path: 'me', component: CurrentUserProfilePageComponent
  },
  {
    path: 'users/new', component: UserDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      user: WorkspaceUserWithCompaniesResolver,
      allCompanies: WorkspaceCompaniesResolver
    },
  },
  {
    path: 'users/:userId', component: UserDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      user: WorkspaceUserWithCompaniesResolver,
      allCompanies: WorkspaceCompaniesResolver,
      userTeams: UserTeamsResolver
    }
  },
  {
    path: 'companies/new', component: CompanyDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      companyWithAgents: SimpleCompanyWithAgentsResolver,
    }
  },
  {
    path: 'companies/:companyId', component: CompanyDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      companyWithAgents: SimpleCompanyWithAgentsResolver,
    }
  },
  {
    path: 'news/new', component: NewsDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      news: NewsResolver
    }
  },
  {
    path: 'news/:newsId', component: NewsDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      news: NewsResolver
    }
  },
  {
    path: 'domains/new', component: DomainDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      domain: DomainResolver
    },
  },
  {
    path: 'domains/:domainId', component: DomainDetailsPageComponent, canActivate: [AgentAdminGuard],
    resolve: {
      domain: DomainResolver
    }
  },
  {
    path: 'responseTemplate/new', canActivate: [AgentAdminGuard], component: ResponseTemplateDetailsPageComponent,
    resolve: {
      responseTemplate: ResponseTemplateResolver
    }
  },
  { path: 'responseTemplate/:responseTemplateId', canActivate: [AgentAdminGuard], component: ResponseTemplateDetailsPageComponent,
    resolve: {
      responseTemplate: ResponseTemplateResolver
    }
  },
  {
    path: 'teams/new', component: TeamDetailsComponent, canActivate: [AgentAdminGuard]
  },
  {
    path: 'teams/:teamId', component: TeamDetailsComponent, canActivate: [AgentAdminGuard],
    resolve: {
      team: TeamByIdResolver
    }
  }
];
