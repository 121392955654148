import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AlertService {

  constructor(private translateService: TranslateService) {
  }

  public confirmWithSelectInput(title: string,
                               message: string,
                               confirmText: string,
                               cancelText: string,
                               selectOptions: Map<string, string>
  ): Observable<string> {

    return new Observable<string>((observer: Observer<string>) => {
      Swal.fire({
        title,
        text: message,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: confirmText,
        showCancelButton: true,
        cancelButtonText: cancelText,
        input: 'select',
        inputOptions: selectOptions
      }).then(result => {
        if (result.value) {
          observer.next(result.value);
        } else if (result.dismiss) {
          observer.error(null);
        }
        observer.complete();
      });
    });
  }

  public confirmWithCustomBtn(title: string,
                              message: string,
                              confirmText: string = 'action.validate',
                              cancelText: string = 'action.cancel'
  ): Observable<void> {

    return new Observable<void>((observer: Observer<void>) => {
      Swal.fire({
        title,
        text: message,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: this.translateService.instant(confirmText),
        showCancelButton: true,
        cancelButtonText: this.translateService.instant(cancelText)
      }).then(result => {
        if (result.value) {
          observer.next(null);
        } else if (result.dismiss) {
          observer.error(null);
        }
        observer.complete();
      });
    });
  }
}
