import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ResponseTemplateSearchCriteria} from '../../../models/criterias/ResponseTemplateSearchCriteria';

@Component({
  selector: 'm-input-search-response-templates',
  templateUrl: './m-input-search-response-templates.component.html',
  styleUrls: ['./m-input-search-response-templates.component.scss']
})
export class MInputSearchResponseTemplatesComponent {

  @Input()
  public searchCriteria: ResponseTemplateSearchCriteria;

  @Output()
  public searchCriteriaChange = new EventEmitter<ResponseTemplateSearchCriteria>();

  @Output()
  public searchFired = new EventEmitter<void>();

  public filter(): void {
    this.searchFired.emit();
  }

  public onDomainChange(selectedDomain: string): void {
    this.searchCriteria.domainId = selectedDomain;
    this.searchCriteria.categoryId = null;
  }

  public onCategoryChange(selectedCategory: string) {
    this.searchCriteria.categoryId = selectedCategory;
  }
}
