import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {User} from '../../../../models/entity/users/User';
import {Observable} from 'rxjs/internal/Observable';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {logger} from 'codelyzer/util/logger';
import {Subscription} from 'rxjs';

@Component({
  selector: 'a-select-user-lazyloading',
  templateUrl: './a-select-user-lazyloading.component.html',
  styleUrls: ['./a-select-user-lazyloading.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectUserLazyloadingComponent),
      multi: true
    }
  ]
})
export class ASelectUserLazyloadingComponent implements ControlValueAccessor, OnChanges {

  @Input()
  public name: string;
  @Input()
  public usersObs: Observable<User[]>;
  @Input()
  public loadOnOpen: boolean = true;
  public dropdownLoading: boolean;
  private displayedUsers: { fullName: string; user: User }[] = undefined;
  public selectedUser: User;
  private currentLoadingSubscription: Subscription;
  private flagForReload: boolean = false;
  private onNgChange: (values: User) => void;
  private onNgTouched: () => void;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.usersObs) {
      this.flagForReload = true;
    }
  }

  loadUsers() {
    if (this.usersObs === undefined) { console.info("[a-select-user-lazyloading] No observables were supplied"); return; }
    this.dropdownLoading = true;
    this.currentLoadingSubscription = this.usersObs.subscribe(users => {
      this.displayedUsers =
        users
          .sort((u1, u2) => u1.lastName < u2.lastName ? -1 : 1)
          .map(user => ({user: user, fullName: user.fullName}));
    }, err => {
      console.error(`[a-select-user-lazyloading] couldn't load users\nError:\n${err}`);
    }, () => {
      this.dropdownLoading = false;
    });
  }

  onChange(user: User): void {
    this.selectedUser = user;
    user ? this.onNgChange(user) : this.onNgChange(undefined);
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(user: User): void {
    this.selectedUser = user ? user : undefined;
  }

  compare(userWithFullName1: User, userWithFullName2: User): boolean {
    return userWithFullName1 != null && userWithFullName2 != null && userWithFullName1.id === userWithFullName2.id;
  }

  fillUsers() {
    if ((this.displayedUsers === undefined || this.flagForReload === true)) {
      if (this.dropdownLoading) { this.cancelCurrentLoading(); }
      this.resetUsers();
      this.loadUsers();
      this.flagForReload = false;
    }
  }

  removeUser(userId: string) {
    this.displayedUsers = this.displayedUsers.filter(el => el.user.id !== userId);
  }

  addUser(user: User) {
    this.displayedUsers = this.displayedUsers
      .concat([{user: user, fullName: user.fullName}])
      .sort((u1, u2) => u1.user.lastName < u2.user.lastName ? -1 : 1);
  }

  openEventFired() {
    if (!this.loadOnOpen) { return; }
    this.fillUsers();
  }

  private cancelCurrentLoading() {
    this.currentLoadingSubscription.unsubscribe();
  }

  private resetUsers() {
    this.displayedUsers = [];
  }
}
