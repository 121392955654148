import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SimpleRequest} from '../../shared/models/entity/requests/SimpleRequest';
import {CompleteMessage} from '../../shared/models/entity/requests/CompleteMessage';
import {RequestSearchCriteria} from '../../shared/models/criterias/RequestSearchCriteria';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {RequestStatus} from '../../shared/models/entity/enums/RequestStatus';
import {RequestPriority} from '../../shared/models/entity/enums/RequestPriority';
import {QualificationDataModification} from '../../shared/models/entity/feedbacks/QualificationData';
import {PaginatedRequests} from '../../shared/models/entity/paginated/paginated-entities/PaginatedRequests';
import {SearchResultRequest} from '../../shared/models/entity/requests/RequestSearchResult';
import {CompleteRequest} from '../../shared/models/entity/requests/CompleteRequest';
import {buildRequestExportFormat, ExportFormat} from '../../shared/models/entity/enums/ExportFormat';
import {User} from '../../shared/models/entity/users/User';
import {environment} from '../../../../environments/environment';
import {NextOrPreviousRequests} from '../../shared/models/entity/requests/NextOrPreviousRequests';

@Injectable()
export class RequestService {

  private baseUrl = `${environment.apiUrl}/api/requests`;

  constructor(private http: HttpClient) {}

  public findRelatedRequestsForCompanyAgent(companyId: string, agentId: string): Observable<Array<SearchResultRequest>>  {
    return this.http.get<SearchResultRequest>(`${this.baseUrl}/company/${companyId}/agent/${agentId}`)
      .pipe(map((principal: any) => plainToClass(SearchResultRequest, principal as [Object])));
  }

  public findByCode(code: string): Observable<CompleteRequest> {
    return this.http.get<CompleteRequest>(this.baseUrl + '/' + code)
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public substituteAgentOnCompany(companyId: string, removedAgentId: string, substituteAgentId: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/substitute/${companyId}`, {removedAgentId, substituteAgentId});
  }

  public sendMessage(requestId: string, message: CompleteMessage): Observable<CompleteRequest> {
    return this.http.post<CompleteMessage>(this.baseUrl + `/${requestId}/message`, message)
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public search(searchCriteria: RequestSearchCriteria): Observable<PaginatedRequests> {
    return this.http.get<PaginatedRequests>(this.baseUrl, {params: searchCriteria.toHttpParams()})
      .pipe(map((principal: any) => plainToClass(PaginatedRequests, principal as Object)));
  }

  public searchSimple(searchCriteria: RequestSearchCriteria): Observable<Array<SimpleRequest>> {
    return this.http.get<Array<SimpleRequest>>(this.baseUrl + '/simple',
      {params: searchCriteria.toHttpParams()})
      .pipe(map((principal: any) => plainToClass(SimpleRequest, principal as [Object])));
  }

  public searchSimpleWithoutPagination(searchCriteria: RequestSearchCriteria): Observable<Array<SimpleRequest>> {
    return this.http.get<Array<SimpleRequest>>(this.baseUrl + '/simple',
      {params: searchCriteria.toHttpParamsWithoutPagination()})
      .pipe(map((principal: any) => plainToClass(SimpleRequest, principal as [Object])));
  }

  public updateStatus(requestId: string, newStatus: RequestStatus): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/status` , {status: newStatus})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updatePriority(requestId: string, newPriority: RequestPriority): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/priority`, {priority: newPriority})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateTags(requestId: string, newTags: Array<String>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/tags`, {tags: newTags})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public create(request: CompleteRequest): Observable<CompleteRequest> {
    return this.http.post<CompleteRequest>(this.baseUrl, request)
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public createFromFeedback(feedbackId: string, qualificationData: QualificationDataModification): Observable<CompleteRequest> {
    return this.http.post<CompleteRequest>(`${this.baseUrl}/feedback/${feedbackId}`, qualificationData)
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateUsers(requestId: string, users: Array<User>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/users` , {users: users.map(user => user.id)})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateAgents(requestId: string, agents: Array<User>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/agents` , {agents: agents.map(agent => agent.id)})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateRelatedRequests(requestId: string, relatedRequests: Array<SimpleRequest>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/relatedRequests` ,
      {relatedRequests: relatedRequests.map(request => request.id)})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public sendReminder(requestId: string) {
    return this.http.put<void>(this.baseUrl + `/${requestId}/reminder`, {});
  }

  public extractRequestList(searchCriteria: RequestSearchCriteria, exportFormat: ExportFormat): Observable<Blob> {
    return this.http.get(buildRequestExportFormat(exportFormat).requestUrl, {
      params: searchCriteria.toHttpParamsWithoutPagination(),
      responseType: 'blob'
    });
  }

  public updateDomain(requestId: string, domainId: string): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/domain`, {domainId: domainId})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateCategory(requestId: string, categoryId: string): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/category`, {categoryId: categoryId})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public bulkClose(requestIds: Array<string>): Observable<void> {
    let params = new HttpParams();
    if (requestIds.length !== 0) {
      requestIds.forEach(el => params = params.append('requestIds', el));
    }
    return this.http.put<void>(`${this.baseUrl}/massClose`, {}, {params});
  }

  public getNext(requestCode: string, requestNavigationsCriteria: RequestSearchCriteria): Observable<NextOrPreviousRequests> {
    return this.http.get<NextOrPreviousRequests>(this.baseUrl + `/${requestCode}/next`, {
      params: requestNavigationsCriteria.toHttpParams()
    })
      .pipe(
        map(next => plainToClass(NextOrPreviousRequests, next))
      );
  }

  public getPrevious(requestCode: string, requestNavigationsCriteria: RequestSearchCriteria): Observable<NextOrPreviousRequests> {
    return this.http.get<NextOrPreviousRequests>(this.baseUrl + `/${requestCode}/previous`, {
      params: requestNavigationsCriteria.toHttpParams()
    })
      .pipe(
        map(previous => plainToClass(NextOrPreviousRequests, previous))
      );
  }

  public count(requestNavigationsCriteria: RequestSearchCriteria): Observable<number> {
    return this.http.get<number>(this.baseUrl + `/count`, {
      params: requestNavigationsCriteria.toHttpParams()
    });
  }

  public selfAssign(id: string): Observable<CompleteRequest> {
    return this.http.post<CompleteRequest>(`${this.baseUrl}/${id}/selfAssign`, {}).pipe(
      map(req => plainToClass(CompleteRequest, req))
    );
  }
}
