import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {Params} from '@angular/router';

export class WorkspacesSearchCriteria extends PaginatedCriteria {

  public q?: string;
  public workspaceCode?: string;
  public companyId?: string;

  protected transformIntoHttpParams(base: HttpParams): HttpParams {
    if (this.q) {
      base = base.set('q', this.q.trim());
    }

    if (this.workspaceCode) {
      base = base.set('workspaceCode', this.workspaceCode);
    }

    if (this.companyId) {
      base = base.set('companyId', this.companyId);
    }

    return base;
  }

  fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, null);

    this.q = params.q;
    this.workspaceCode = params.workspaceCode;
    this.companyId = params.companyId;

    return this;
  }

  protected reset() {
    super.defaultReset();

    this.q = undefined;
    this.workspaceCode = undefined;
    this.companyId = undefined;
  }
}
