import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActualizeMetricsBusService {
  private readonly actualizeMetricsSubject: Subject<void>;
  constructor() {
    this.actualizeMetricsSubject = new Subject<void>();
  }

  public emit() {
    this.actualizeMetricsSubject.next();
  }

  public get(): Subject<void> {
    return this.actualizeMetricsSubject;
  }

}
