import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppModule} from './app.module';
import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {AppPageComponent} from './pages/app/app-page.component';
import {ServicesModule} from './services/services.module';
import {LoggedInGuard} from './guards/logged-in.guard';
import {RequestByCodeResolver} from './resolvers/requests/request-by-code.resolver';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AgentGuard} from './guards/agent.guard';
import {WorkspaceAgentResolver} from './resolvers/agents/workspace-agent.resolver';
import {AgentAdminGuard} from './guards/agent-admin.guard';
import {NewsResolver} from './resolvers/news/news.resolver';
import {WorkspaceUserWithCompaniesResolver} from './resolvers/user/workspace-user-with-companies.resolver';
import {QuillModule} from 'ngx-quill';
import {WorkspaceFeedbacksResolver} from './resolvers/feedback/workspace-feedbacks.resolver';
import {FeedbackResolver} from './resolvers/feedback/feedback.resolver';
import {DomainResolver} from './resolvers/domain/domain.resolver';
import {WorkspaceCompaniesPaginateResolver} from './resolvers/companies/workspace-companies-paginate.resolver';
import {WorkspaceNewsResolver} from './resolvers/news/workspace-news.resolver';
import {RequestNavigationsResolver} from './resolvers/requests/request-navigations.resolver';
import {ResponseTemplateResolver} from './resolvers/responseTemplates/response-template.resolver';
import {NoActiveWorkspaceGuard} from './guards/no-active-workspace.guard';
import {NoWorkspaceModule} from '../no-workspace/no-workspace.module';
import {CurrentDashboardCustomizationResolver} from './resolvers/workspace/current-dashboard-customization.resolver';
import {SuperAdminGuard} from './guards/super-admin.guard';
import {WorkspacesPaginateResolver} from './resolvers/workspace/workspaces-paginate.resolver';
import {WorkspaceModificationResolver} from './resolvers/workspace/workspace-modification.resolver';
import {UsersResolver} from './resolvers/user/users.resolver';
import {AdministrationUsersPaginateResolver} from './resolvers/administration/administration-users-paginate.resolver';
import {AdministrationUserResolver} from './resolvers/administration/administration-user.resolver';
import {
  SimpleWorkspaceAgentListElementsResolver
} from './resolvers/agents/simple-workspace-agent-list-elements-resolver.service';
import {
  SimpleWorkspaceUserListElementsResolver
} from './resolvers/user/simple-workspace-user-list-elements-resolver.service';
import {WorkspacesChoiceModule} from '../workspaces-choice/workspaces-choice.module';
import {
  WorkspaceResponseTemplatesPaginateResolver
} from './resolvers/responseTemplates/workspace-response-templates-paginate.resolver';
import {WorkspaceCompaniesResolver} from './resolvers/companies/workspace-companies.resolver';
import {WorkspaceDomainsResolver} from './resolvers/domain/workspace-domains.resolver';
import {WorkspaceCurrentUserResolver} from './resolvers/user/workspace-current-user.resolver';
import {HasActiveWorkspaceGuard} from './guards/has-active-workspace.guard';
import {WorkspaceAgentsResolver} from './resolvers/agents/workspace-agents.resolver';
import {WorkspaceByCodeResolver} from './resolvers/workspace/workspace-by-code.resolver';
import {CurrentWorkspaceResolver} from './resolvers/workspace/current-workspace.resolver';
import {CurrentWorkspaceGuard} from './guards/current-workspace.guard';
import {environment} from '../../../environments/environment';
import {SimpleCompanyWithAgentsResolver} from './resolvers/companies/simple-company-with-agents.resolver';
import {SimpleWorkspaceAgentsResolver} from './resolvers/agents/simple-workspace-agents.resolver';
import {TeamsListResolver} from './resolvers/teams/teams-list.resolver';
import {CurrentUserTeamsResolver} from './resolvers/teams/current-user-teams.resolver';
import {UserTeamsResolver} from './resolvers/teams/user-teams.resolver';
import {TeamByIdResolver} from './resolvers/teams/team-by-id-resolver.service';
import {TransportUserNewNoteGuard} from './guards/transport-user-new-note.guard';
import {OAuth2CodeFlowHandlerComponent} from './oauth2-codeflow-handler.component';

export function translateLoader(http: HttpClient) {
  return {
    getTranslation(lang: string): Observable<any> {
      return http.get<{ [key: string]: string }>(`${environment.apiUrl}/api/public/labels`, {
        headers: new HttpHeaders({'Accept-Language': lang})
      });
    }
  };
}

@NgModule({
  imports: [
    BrowserModule,
    AppModule,
    HttpClientModule,
    ServicesModule,
    NoWorkspaceModule,
    WorkspacesChoiceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoader),
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right'
    }),
    QuillModule.forRoot()
  ],
  bootstrap: [AppPageComponent],
  declarations: [OAuth2CodeFlowHandlerComponent],
  providers: [
    LoggedInGuard,
    AgentGuard,
    NoActiveWorkspaceGuard,
    HasActiveWorkspaceGuard,
    TransportUserNewNoteGuard,
    AgentAdminGuard,
    RequestByCodeResolver,
    SuperAdminGuard,
    RequestNavigationsResolver,
    NewsResolver,
    CurrentDashboardCustomizationResolver,
    WorkspaceAgentResolver,
    WorkspaceNewsResolver,
    WorkspaceDomainsResolver,
    WorkspaceCurrentUserResolver,
    WorkspaceCompaniesPaginateResolver,
    DomainResolver,
    WorkspaceUserWithCompaniesResolver,
    ResponseTemplateResolver,
    WorkspaceResponseTemplatesPaginateResolver,
    WorkspaceCompaniesResolver,
    WorkspaceAgentsResolver,
    WorkspaceFeedbacksResolver,
    FeedbackResolver,
    WorkspacesPaginateResolver,
    AdministrationUsersPaginateResolver,
    AdministrationUserResolver,
    WorkspaceModificationResolver,
    UsersResolver,
    SimpleWorkspaceAgentListElementsResolver,
    SimpleWorkspaceUserListElementsResolver,
    WorkspaceByCodeResolver,
    CurrentWorkspaceResolver,
    SimpleCompanyWithAgentsResolver,
    SimpleWorkspaceAgentsResolver,
    TeamsListResolver,
    CurrentUserTeamsResolver,
    UserTeamsResolver,
    CurrentWorkspaceGuard,
    TeamByIdResolver,
  ]
})
export class AppRootModule {
}
