import {Type} from 'class-transformer';
import {User} from '../users/User';
import {TranslateService} from '@ngx-translate/core';
import {Domain} from '../classifications/Domain';
import {Category} from '../classifications/Category';
import {getRequestPriorityMetaData, RequestPriority} from '../enums/RequestPriority';
import {RequestStatus} from '../enums/RequestStatus';
import {SimpleRequest} from './SimpleRequest';
import {ContextualUser} from '../users/ContextualUser';

export enum EventType {
  MESSAGE = 'MESSAGE',
  STATUSCHANGE = 'STATUSCHANGE',
  REMIND = 'REMIND',
  AGENTCHANGE = 'AGENTCHANGE',
  PRIORITYCHANGE = 'PRIORITYCHANGE',
  USERPRIORITYCHANGE = 'USERPRIORITYCHANGE',
  TAGCHANGE = 'TAGCHANGE',
  RELATEDREQUESTCHANGE = 'RELATEDREQUESTCHANGE',
  USERCHANGE = 'USERCHANGE',
  DOMAINCHANGE = 'DOMAINCHANGE',
  CATEGORYCHANGE = 'CATEGORYCHANGE'
}

export abstract class Event {

  public type: EventType;
  @Type(() => Date)
  public creationDate: Date;
  @Type(() => ContextualUser)
  public author: ContextualUser;

  public abstract getIconClass(): string;
  public abstract getLabel(translateService: TranslateService): string;

  public getCommonLabel(translateService: TranslateService, interpolateParams?: Object): string {
    return this.getAuthorLabel(translateService) + ' ' +
      translateService.instant('event.message.' + this.type, interpolateParams);
  }

  public getAuthorLabel(translateService: TranslateService): string {
    return '[' + translateService.instant('user.role.' + this.author.role) + '] ' +  this.author.fullName;
  }
}

export class AgentsChangedEvent extends Event {

  @Type(() => User)
  public agents: Array<User>;

  constructor(agents: Array<User>) {
    super();
    this.type = EventType.AGENTCHANGE;
    this.agents = agents;
  }

  public getIconClass(): string {
    return 'fa fa-user-tie';
  }

  public getLabel(translateService: TranslateService): string {
    return this.getCommonLabel(translateService,
      {agents: this.agents ? this.agents.map(agent => agent.fullName).toString() : [].toString()});
  }
}

export class UsersChangedEvent extends Event {

  @Type(() => User)
  public users: Array<User> = [];

  constructor(users: Array<User>) {
    super();
    this.type = EventType.USERCHANGE;
    this.users = users;
  }

  public getIconClass(): string {
    return 'fa fa-user';
  }

  public getLabel(translateService: TranslateService): string {
    return this.getCommonLabel(translateService,
      {users: this.users ? this.users.map(user => user.fullName).toString() : [].toString()});
  }
}

export class ReminderSendedEvent extends Event {

  @Type(() => User)
  public recipients: Array<User> = [];

  constructor(recipients: Array<User>) {
    super();
    this.type = EventType.REMIND;
    this.recipients = recipients;
  }

  public getIconClass(): string {
    return 'fa fa-bell';
  }

  public getLabel(translateService: TranslateService): string {
    return this.getCommonLabel(translateService,
      {recipients: this.recipients ? this.recipients.map(recipient => recipient.fullName).toString() : [].toString()});
  }
}

export class TagsChangedEvent extends Event {

  public tags: Array<string>;

  constructor(tags: Array<string>) {
    super();
    this.type = EventType.TAGCHANGE;
    this.tags = tags;
  }

  public getIconClass(): string {
    return 'fa fa-pen';
  }

  public getLabel(translateService: TranslateService): string {
    return this.tags && this.tags.length > 0 ?
      this.getCommonLabel(translateService, {tags: this.tags.toString()}) :
      this.getAuthorLabel(translateService) + ' ' + translateService.instant('event.message.' + this.type + '.none');
  }
}

export class RelatedRequestChangedEvent extends Event {

  @Type(() => SimpleRequest)
  public relatedRequests: Array<SimpleRequest> = [];

  constructor(relatedRequests: Array<SimpleRequest>) {
    super();
    this.type = EventType.RELATEDREQUESTCHANGE;
    this.relatedRequests = relatedRequests;
  }

  public getIconClass(): string {
    return 'fa fa-pen';
  }

  public getLabel(translateService: TranslateService): string {
    return this.relatedRequests && this.relatedRequests.length > 0 ?
      this.getCommonLabel(translateService, {requests: this.relatedRequests.map(request => request.code).toString()}) :
      this.getAuthorLabel(translateService) + ' ' + translateService.instant('event.message.' + this.type + '.none');
  }
}

export class DomainChangedEvent extends Event {

  @Type(() => Domain)
  public domain?: Domain;

  constructor(domain: Domain) {
    super();
    this.type = EventType.DOMAINCHANGE;
    this.domain = domain;
  }

  public getIconClass(): string {
    return 'fas fa-book';
  }

  public getLabel(translateService: TranslateService): string {
    return this.domain ?
      this.getCommonLabel(translateService, {domain: this.domain.title}) :
      this.getAuthorLabel(translateService) + ' ' + translateService.instant('event.message.' + this.type + '.none');
  }
}

export class CategoryChangedEvent extends Event {

  @Type(() => Category)
  public category: Category;

  constructor(category: Category) {
    super();
    this.type = EventType.CATEGORYCHANGE;
    this.category = category;
  }

  public getIconClass(): string {
    return 'fas fa-book';
  }

  public getLabel(translateService: TranslateService): string {
    return this.category ?
      this.getCommonLabel(translateService, {category: this.category.title}) :
      this.getAuthorLabel(translateService) + ' ' + translateService.instant('event.message.' + this.type + '.none');
  }
}

export class NewMessageEvent extends Event {

  public text: string;
  public restrictedAccess: boolean;
  public attachment: boolean;

  constructor(text: string, attachment: boolean, restrictedAccess: boolean) {
    super();
    this.type = EventType.MESSAGE;
    this.text = text;
    this.restrictedAccess = restrictedAccess;
    this.attachment = attachment;
  }

  public getIconClass(): string {
    return this.restrictedAccess ? 'fas fa-eye-slash' : this.attachment ? 'fa fa-paperclip' : 'fa fa-envelope';
  }

  public getRestrictedMessageLabel(translateService: TranslateService): string {
    return this.getCommonLabel(translateService, null)
      + ' ' + translateService.instant('event.message.' + this.type + '.restricted');
  }

  public getLabel(translateService: TranslateService): string {
    return this.restrictedAccess ?
      this.getRestrictedMessageLabel(translateService) : this.getCommonLabel(translateService, null);
  }
}

export class PriorityChangedEvent extends Event {

  public previousPriority?: RequestPriority;
  public newPriority: RequestPriority;

  constructor(previousPriority: RequestPriority, newPriority: RequestPriority) {
    super();
    this.type = EventType.PRIORITYCHANGE;
    this.previousPriority = previousPriority;
    this.newPriority = newPriority;
  }

  public getIconClass(): string {
    return getRequestPriorityMetaData(this.previousPriority).criticality < getRequestPriorityMetaData(this.newPriority).criticality ?
      'fa fa-chevron-up' : 'fa fa-chevron-down';
  }

  public getLabel(translateService: TranslateService): string {
    return this.getCommonLabel(translateService, null) +
      translateService.instant('request.priority.' + this.newPriority);
  }
}

export class UserPriorityChangedEvent extends Event {

  public previousPriority?: RequestPriority;
  public newPriority: RequestPriority;

  constructor(previousPriority: RequestPriority, newPriority: RequestPriority) {
    super();
    this.type = EventType.USERPRIORITYCHANGE;
    this.previousPriority = previousPriority;
    this.newPriority = newPriority;
  }

  public getIconClass(): string {
    return 'fa fa-user';
  }

  public getLabel(translateService: TranslateService): string {
    return this.getCommonLabel(translateService, null) +
      translateService.instant('request.priority.' + this.newPriority);
  }
}

export class StatusChangedEvent extends Event {

  public previousStatus?: RequestStatus;
  public newStatus: RequestStatus;

  constructor(previousStatus: RequestStatus, newStatus: RequestStatus) {
    super();
    this.type = EventType.STATUSCHANGE;
    this.previousStatus = previousStatus;
    this.newStatus = newStatus;
  }

  public getIconClass(): string {
    return 'fa fa-pen';
  }

  public getLabel(translateService: TranslateService): string {
    return this.getCommonLabel(translateService, null) +
      translateService.instant('request.status.' + this.newStatus);
  }
}
