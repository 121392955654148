import {Type} from 'class-transformer';
import {Domain} from '../classifications/Domain';
import {Category} from '../classifications/Category';
import {SimpleCompany} from '../companies/SimpleCompany';
import {User} from '../users/User';
import {SimpleRequest} from './SimpleRequest';
import {
  AgentsChangedEvent,
  CategoryChangedEvent,
  DomainChangedEvent,
  EventType,
  NewMessageEvent,
  PriorityChangedEvent,
  RelatedRequestChangedEvent,
  ReminderSendedEvent,
  StatusChangedEvent,
  TagsChangedEvent,
  UserPriorityChangedEvent,
  UsersChangedEvent
} from './Event';
import {WorkspaceSimple} from '../workspaces/Workspace';
import {CompleteCurrentUser} from '../users/CompleteCurrentUser';
import {RequestPriority} from '../enums/RequestPriority';
import {Company} from '../companies/Company';
import {RequestStatus} from '../enums/RequestStatus';
import {CompleteMessage} from './CompleteMessage';
import {Entity} from '../common/Entity';

export class CompleteRequest extends Entity {

  @Type(() => Date)
  public handlingDate?: Date;
  @Type(() => Date)
  public closedDate?: Date;
  @Type(() => Domain)
  public domain: Domain;
  @Type(() => Category)
  public category: Category;
  @Type(() => SimpleCompany)
  public company: SimpleCompany;
  @Type(() => User)
  public author: User;
  @Type(() => SimpleRequest)
  public relatedRequests: Array<SimpleRequest> = [];
  @Type(() => CompleteMessage)
  public messages: Array<CompleteMessage>;
  @Type(() => Event, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: AgentsChangedEvent, name: EventType.AGENTCHANGE },
        { value: UsersChangedEvent, name: EventType.USERCHANGE },
        { value: ReminderSendedEvent, name: EventType.REMIND },
        { value: TagsChangedEvent, name: EventType.TAGCHANGE },
        { value: RelatedRequestChangedEvent, name: EventType.RELATEDREQUESTCHANGE },
        { value: DomainChangedEvent, name: EventType.DOMAINCHANGE },
        { value: CategoryChangedEvent, name: EventType.CATEGORYCHANGE },
        { value: NewMessageEvent, name: EventType.MESSAGE },
        { value: PriorityChangedEvent, name: EventType.PRIORITYCHANGE },
        { value: UserPriorityChangedEvent, name: EventType.USERPRIORITYCHANGE },
        { value: StatusChangedEvent, name: EventType.STATUSCHANGE },
      ],
    },
  })
  public events: Array<Event>;
  public workspace: WorkspaceSimple;
  public code?: string;
  public topic?: string;
  public tags: Array<string> = [];
  @Type(() => User)
  public agents: Array<User> = [];
  @Type(() => User)
  public users: Array<User> = [];
  public status: RequestStatus;
  public priority?: RequestPriority;
  public userPriority: RequestPriority;
  public description?: string;
  public assignedToCurrent?: boolean;
  @Type(() => Date)
  public lastStatusChangeDate: Date;
  @Type(() => Date)
  public lastReminderDate?: Date;
  @Type(() => Date)
  public createdDate: Date;

  static new(user: CompleteCurrentUser): CompleteRequest {
    const currentUser = User.fromCompleteCurrentUser(user);

    const completeRequest = new CompleteRequest();
    completeRequest.author = currentUser;
    completeRequest.tags = [];
    completeRequest.relatedRequests = [];
    completeRequest.userPriority = RequestPriority.NORMAL;
    completeRequest.messages = [];

    if (user.isUser()) {
      completeRequest.company = Company.from(user.activeCompany, user.activeWorkspace);
      completeRequest.status = RequestStatus.IN_PROGRESS;
      completeRequest.users = [currentUser];
      completeRequest.agents = [];
    } else if (user.isAgent()) {
      completeRequest.status = RequestStatus.WAITING_FOR_CUSTOMER;
      completeRequest.users = [];
      completeRequest.agents = [currentUser];
      completeRequest.priority = RequestPriority.NOT_DEFINE;
    }

    return completeRequest;
  }

  public getDisplayableLastStatusChangeDate(): Date {
    return this.lastStatusChangeDate ? this.lastStatusChangeDate : this.createdDate;
  }

  public getVisiblePriority(user: CompleteCurrentUser): RequestPriority {
    return user.isUser() ? this.userPriority : this.priority;
  }

}
