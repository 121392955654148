import {Routes} from '@angular/router';
import {AdministrationPageComponent} from './pages/administration/administration-page.component';
import {
  AdministrationWorkspacePageComponent
} from './pages/administration-workspace/administration-workspace-page.component';
import {AdministrationUserPageComponent} from './pages/administration-user/administration-user-page.component';
import {WorkspacesPaginateResolver} from '../app-root/resolvers/workspace/workspaces-paginate.resolver';
import {WorkspaceModificationResolver} from '../app-root/resolvers/workspace/workspace-modification.resolver';
import {UsersResolver} from '../app-root/resolvers/user/users.resolver';
import {
  AdministrationUsersPaginateResolver
} from '../app-root/resolvers/administration/administration-users-paginate.resolver';
import {AdministrationUserResolver} from '../app-root/resolvers/administration/administration-user.resolver';
import {AgentGuard} from '../app-root/guards/agent.guard';
import {AdministrationUsersComponent} from './pages/administration-users/administration-users.component';
import {AdministrationWorkspacesComponent} from './pages/administration-workspaces/administration-workspaces.component';

export const administrationRoutes: Routes = [
  {
    path: '',
    component: AdministrationPageComponent,
    children: [
      {
        path: 'users',
        component: AdministrationUsersComponent,
        resolve: {
          initialWorkspaces: WorkspacesPaginateResolver,
          initialUsers: AdministrationUsersPaginateResolver,
        }
      },
      {
        path: 'workspaces',
        component: AdministrationWorkspacesComponent,
        resolve: {
          initialWorkspaces: WorkspacesPaginateResolver,
        }
      },
    ]
  },
  {
    path: 'workspaces/new',
    component: AdministrationWorkspacePageComponent,
    canActivate: [AgentGuard],
    resolve: {
      workspace: WorkspaceModificationResolver,
      users: UsersResolver
    },
  },
  {
    path: 'workspaces/:workspaceId',
    component: AdministrationWorkspacePageComponent,
    resolve: {
      workspace: WorkspaceModificationResolver,
      users: UsersResolver
    },
  },
  {
    path: 'users/new',
    component: AdministrationUserPageComponent,
    resolve: {
      user: AdministrationUserResolver
    }
  },
  {
    path: 'users/:userId',
    component: AdministrationUserPageComponent,
    resolve: {
      user: AdministrationUserResolver
    }
  }
];
