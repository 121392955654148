import {SimpleCompany} from '../companies/SimpleCompany';
import {Role} from '../enums/Role';
import {Expose, Transform, Type} from 'class-transformer';
import {WorkspaceSimple} from '../workspaces/Workspace';
import {ContextualWorkspace} from '../workspaces/ContextualWorkspace';
import {Attachment} from '../Attachment';
import {ServiceDeskLocale} from '../enums/ServiceDeskLocale';
import {Domain} from '../classifications/Domain';
import {flatten} from '@angular/compiler';
import {Entity} from '../common/Entity';
import {distinctBy} from '../../../functions/array-utils';
import {SimpleDomain} from '../classifications/SimpleDomain';

export class CompleteCurrentUser {

  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public activeRole: Role;
  public disabled: boolean;
  public superAdmin: boolean;
  public avatar?: Attachment;
  @Type(() => SimpleCompany)
  public activeCompany: SimpleCompany;
  @Type(() => WorkspaceSimple)
  public activeWorkspace: WorkspaceSimple;
  @Type(() => ContextualWorkspace)
  public contextualWorkspaces: Array<ContextualWorkspace> = [];
  public locale: ServiceDeskLocale;
  public isDelegatedAuthenticationUser: boolean;
  @Expose()
  @Transform(({obj}) => CompleteCurrentUser.getFullName(obj), {toClassOnly: true})
  public fullName: string;

  static getFullName(obj: CompleteCurrentUser): string {
    if (obj.disabled) {
      return `${obj.firstName} ${obj.lastName} (Désactivé)`;
    } else {
      return `${obj.firstName} ${obj.lastName}`;
    }
  }

  public isAgent(): boolean {
    return Role.AGENT === this.activeRole || this.isAgentAdmin();
  }

  public isUser(): boolean {
    return Role.USER === this.activeRole;
  }

  public isAgentAdmin(): boolean {
    return Role.AGENT_ADMIN === this.activeRole;
  }

  public isAgentInWorkspace(workspaceId: String): boolean {
    return Role.AGENT === this.getRoleInWorkspace(workspaceId) || this.isAgentAdminInWorkspace(workspaceId);
  }

  public isUserInWorkspace(workspaceId: String): boolean {
    return Role.USER === this.getRoleInWorkspace(workspaceId);
  }

  public isAgentAdminInWorkspace(workspaceId: String): boolean {
    return Role.AGENT_ADMIN === this.getRoleInWorkspace(workspaceId);
  }

  public getWorkspaces(): Array<WorkspaceSimple> {
    return this.contextualWorkspaces ?
      this.contextualWorkspaces.map(workspace => WorkspaceSimple.fromContextualWorkspace(workspace)) : [];
  }

  public hasWorkspace(workspaceCode: string): boolean {
    if (!this.contextualWorkspaces || !workspaceCode) {
      return false;
    }

    return this.contextualWorkspaces.find(workspace => workspace.code === workspaceCode) !== undefined;
  }

  public getActiveContextualWorkspace(): ContextualWorkspace {
    if (this.contextualWorkspaces && this.activeWorkspace) {
      return this.contextualWorkspaces.find(wks => wks.id === this.activeWorkspace.id);
    } else {
      return undefined;
    }
  }

  public getDomainsInActiveWorkspace(): Array<SimpleDomain> {
    return this.distinct(this.getSelfDomains().concat(this.getTeamsDomains()));
  }

  private distinct<T extends Entity>(array: T[]): T[] {
    return array.filter((el, i, arr) => {
      return arr.findIndex(it => el.id === it.id) === i;
    });
  }

  public getSelfDomains(): Array<SimpleDomain> {
    if (this.contextualWorkspaces && this.activeWorkspace) {
      const workspace = this.getActiveContextualWorkspace();
      return workspace.domains ? workspace.domains : [];
    } else {
      return [];
    }
  }

  public getTeamsDomains(): Array<SimpleDomain> {
    if (this.contextualWorkspaces && this.activeWorkspace) {
      const workspace = this.getActiveContextualWorkspace();
      return workspace.teams ? flatten(workspace.teams.map(team => team.domains)) : [];
    } else {
      return [];
    }
  }

  public hasDomainInActiveWorkspace(domain: Domain): boolean {
    return this.getDomainsInActiveWorkspace().map(el => el.id).includes(domain.id);
  }

  public getCompaniesInActiveWorkspace(): Array<SimpleCompany> {
    return distinctBy(this.getSelfCompaniesInActiveWorkspace().concat(this.getTeamCompaniesInActiveWorkspace()), company => company.id);
  }

  public getSelfCompaniesInActiveWorkspace(): Array<SimpleCompany> {
    if (this.contextualWorkspaces && this.activeWorkspace) {
      const workspace = this.contextualWorkspaces.find(wks => wks.id === this.activeWorkspace.id);
      return workspace && workspace.companies
        ? workspace.companies.map(company => company.company)
        : [];
    } else {
      return [];
    }
  }

  public getWorkspaceById(workspaceId: String): ContextualWorkspace {
    return this.contextualWorkspaces ? this.contextualWorkspaces.find(workspace => workspace.id === workspaceId) : null;
  }

  public getCompaniesInWorkspaces(workspaceId: String): Array<SimpleCompany> {
    const workspace = this.getWorkspaceById(workspaceId);
    return workspace && workspace.companies ? workspace.companies.map(company => company.company) : [];
  }

  public getCompaniesNameInWorkspaces(workspaceId: String): Array<string> {
    const workspace = this.getWorkspaceById(workspaceId);
    return workspace && workspace.companies ? workspace.companies.map(company => company.company.name) : [];
  }

  public getRoleInWorkspace(workspaceId: String): Role {
    const workspace = this.getWorkspaceById(workspaceId);
    return workspace ? workspace.role : null;
  }

  public visibleCompanyIds(): Array<string> {
    return this.isAgent() ? this.getCompaniesInActiveWorkspace().map(it => it.id) : [this.activeCompany.id];
  }

  public getTeamCompaniesInActiveWorkspace(): Array<SimpleCompany> {
    if (this.contextualWorkspaces && this.activeWorkspace) {
      const workspace = this.contextualWorkspaces.find(wks => wks.id === this.activeWorkspace.id);
      return workspace && workspace.teams ? distinctBy(flatten(workspace.teams.map(team => team.companies)), company => company.id) : [];
    } else {
      return [];
    }
  }
}
