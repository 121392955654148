import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {User} from '../../../../models/entity/users/User';

@Component({
  selector: 'a-select-user',
  templateUrl: './a-select-user.component.html',
  styleUrls: ['./a-select-user.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectUserComponent),
      multi: true
    }
  ]
})
export class ASelectUserComponent implements ControlValueAccessor {

  private _users: Array<User>;
  public displayedUsers: { user: User; fullName: string }[];
  public selectedUser: { user: User; fullName: string };

  private onNgChange: (values: User) => void;
  private onNgTouched: () => void;

  @Input()
  get users(): Array<User> {
    return this._users;
  }

  set users(users: Array<User>) {
    this._users = users;
    if (users) {
      this.displayedUsers =
        users
          .sort((u1, u2) => u1.lastName < u2.lastName ? -1 : 1)
          .map(user => ({user: user, fullName: user.fullName}));
    }
  }

  @Input()
  public name: string = 'user';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(user: User): void {
    this.selectedUser = user ? {user: user, fullName: user.fullName} : undefined;
  }

  public onChange(userWithFullName: { user: User; fullName: string }): void {
    this.selectedUser = userWithFullName;
    userWithFullName ? this.onNgChange(userWithFullName.user) : this.onNgChange(undefined);
  }

  public compare(userWithFullName1: { user: User; fullName: string },
                 userWithFullName2: { user: User; fullName: string }): boolean {
    return userWithFullName1 != null && userWithFullName2 != null && userWithFullName1.user.id === userWithFullName2.user.id;
  }
}
