import {Expose, Type} from 'class-transformer';
import {SimpleRequest} from './SimpleRequest';
import {SimpleClassification} from '../classifications/SimpleClassification';
import {RequestStatus} from '../enums/RequestStatus';
import {RequestPriority} from '../enums/RequestPriority';
import {CompleteCurrentUser} from '../users/CompleteCurrentUser';
import {Entity} from '../common/Entity';
import {User} from '../users/User';

export class SearchResultRequest extends Entity {
  @Type(() => SimpleClassification)
  public classification?: SimpleClassification;
  @Type(() => SimpleRequest)
  public relatedRequests: Array<SimpleRequest> = [];
  public lastMessageDate: Date;
  public code?: string;
  public topic?: string;
  public tags: Array<string> = [];
  public agentsCount: number;
  public usersCount: number;
  public status: RequestStatus;
  public priority?: RequestPriority;
  public userPriority: RequestPriority;
  public description?: string;
  public assignedToCurrent?: boolean;
  @Type(() => Date)
  public lastStatusChangeDate: Date;
  @Type(() => Date)
  public lastReminderDate?: Date;
  @Type(() => Date)
  public createdDate: Date;
  public lastAgentToRespond?: string;
  @Type(() => User)
  public creator: User;
  @Type(() => Map)
  public metadata?: Map<string, string>;

  /** not data, used in ui - for performances **/
  @Expose({toClassOnly: true})
  public checkedInList: boolean;

  public getDisplayableLastStatusChangeDate(): Date {
    return this.lastStatusChangeDate ? this.lastStatusChangeDate : this.createdDate;
  }

  public getVisiblePriority(user: CompleteCurrentUser): RequestPriority {
    return user.isUser() ? this.userPriority : this.priority;
  }

  public getMetadata(metadata_key: string): string {
    return this.metadata?.get(metadata_key);
  }
}
