import {Team} from './team';
import {SimpleTeam} from './simple-team';
import {distinct} from '../../../functions/array-utils';

export class TeamUpdate {
  ref: string;
  agents: string[];
  domains: string[];
  companies: string[];
  emails: string[];
  name: string;

  constructor() {
    this.companies = [];
    this.domains = [];
    this.agents = [];
  }

  static from(team: Team): TeamUpdate {
    const teamUpdate = new TeamUpdate();
    teamUpdate.ref = team.id;
    teamUpdate.agents = team.agents.map(el => el.userId);
    teamUpdate.domains = team.domains.map(el => el.id);
    teamUpdate.companies = team.companies.map(el => el.id);
    teamUpdate.name = team.name;
    teamUpdate.emails = team.emails;
    return teamUpdate;
  }

  static fromSimple(team: SimpleTeam): TeamUpdate {
    const teamUpdate = new TeamUpdate();
    teamUpdate.name = team.name;
    teamUpdate.ref = team.id;
    teamUpdate.agents = team.agents.map(el => el.userId);
    teamUpdate.domains = team.domains.map(el => el.id);
    teamUpdate.companies = team.companies.map(el => el.id);
    teamUpdate.emails = team.emails;
    return teamUpdate;
  }

  addAgentId(agentId: string): TeamUpdate {
    this.agents.push(agentId);
    return this;
  }

  removeAgentId(agentId: string): TeamUpdate {
    this.agents = this.agents.filter(id => id !== agentId);
    return this;
  }

  removeCompanyId(companyId: string) {
    this.companies = this.companies.filter(id => id !== companyId);
    return this;
  }

  addCompanyId(id: string) {
    this.companies.push(id);
    return this;
  }

  addCompaniesId(companiesId: Array<string>) {
    this.companies = this.companies.concat(companiesId);
    return this;
  }

  addEmails(emails: Array<string>): TeamUpdate {
    this.emails = emails;
    return this;
  }

  addAgentsIds(agentsIds: string[]): TeamUpdate {
    this.agents = distinct(this.agents.concat(agentsIds));
    return this;
  }
}
