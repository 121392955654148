import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {NewsSearchCriteria} from '../../shared/models/criterias/NewsSearchCriteria';
import {PaginatedNews} from '../../shared/models/entity/paginated/paginated-entities/PaginatedNews';
import {News} from '../../shared/models/entity/News';
import { environment } from '../../../../environments/environment';

@Injectable()
export class NewsService {

  private baseUrl = `${environment.apiUrl}/api/news`;

  constructor(private http: HttpClient) {}

  public find(search: NewsSearchCriteria = null, ordering: boolean = false): Observable<PaginatedNews> {
    return this.http.get<PaginatedNews>(this.baseUrl, {params: this.toHttpParams(search, ordering)})
      .pipe(map((principal: any) => plainToClass(PaginatedNews, principal as Object)));
  }

  public getById(id: string): Observable<News> {
    return this.http.get<News>(this.baseUrl + '/' + id)
      .pipe(map((principal: any) =>  plainToClass(News, principal as Object)));
  }

  public createNews(news: News): Observable<News> {
    return this.http.post<News>(this.baseUrl, news)
      .pipe(map((principal: any) =>  plainToClass(News, principal as Object)));
  }

  public updateNews(news: News) {
    return this.http.put<News>(this.baseUrl + '/' + news.id, news)
      .pipe(map((principal: any) =>  plainToClass(News, principal as Object)));
  }

  public toHttpParams(newsSearchCriteria: NewsSearchCriteria, ordering: boolean ): HttpParams {
    let params: HttpParams;

    params = newsSearchCriteria.toHttpParams();
    params = params.set('ordering', ordering.toString());

    return params;
  }
}
