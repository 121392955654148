import {PaginatedCriteria} from '../paginated/PaginatedCriteria';
import { HttpParams } from '@angular/common/http';
import {Params} from '@angular/router';

export class TeamSearchCriteria extends PaginatedCriteria {
  public q?: string;
  public workspaceId: string;

  protected transformIntoHttpParams(base: HttpParams): HttpParams {
    base = base.set('workspaceId', this.workspaceId);

    if (this.q) {
      base = base.set('q', this.q.trim());
    }

    return base;
  }

  fromParams(params: Params, defaultStep: number, defaultPage: number): this {
    super.initializeFromParams(params, defaultStep, defaultPage, null);

    this.q = params.q;
    this.workspaceId = params.workspaceId;

    return this;
  }

  protected reset() {
    super.defaultReset();

    this.q = undefined;
    this.workspaceId = undefined;
  }
}
