import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
// @ts-ignore
import { Foundation } from 'foundation-sites';
// @ts-ignore
import $ from 'jquery';

let sequence = 0;

@Component({
  selector: 'm-dropdown',
  templateUrl: './m-dropdown.component.html',
  styleUrls: ['./m-dropdown.component.scss']
})
export class MDropdownComponent implements OnInit, AfterViewInit {

  @Input() closeOnClick: boolean = false;
  @ViewChild('dropdown') public dropdownEl!: ElementRef<HTMLDivElement>;
  @Output() public opened = new EventEmitter<void>();
  private dropdown!: Foundation.Dropdown;
  public id: string;

  public ngAfterViewInit() {
    setTimeout(() => this.dropdown = new Foundation.Dropdown($(this.dropdownEl.nativeElement)), 0);
  }

  public toggle() {
    this.dropdown.toggle();
  }

  constructor() {
    sequence++; // Inline version doesn't work in storybook
    this.id = 'dd-' + sequence;
  }

  ngOnInit(): void {
  }

  fireDropdownOpened(): void {
    this.toggle();
    this.opened.emit();
  }
}
