import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {map, switchMap} from 'rxjs/operators';
import {SessionService} from '../services/session.service';
import {BaererSessionService} from '../services/baerer-session.service';
import {of} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {ServiceDeskAuthToken} from '../../shared/models/ServiceDeskSession';
import {CurrentUserStorageService} from '../services/current-user-storage.service';

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(private sessionService: SessionService,
              private spinnerService: NgxSpinnerService,
              private bearerSessionService: BaererSessionService,
              private currentUserStorageService: CurrentUserStorageService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.sessionService.getCurrentUserOnce().pipe(
      switchMap(currentUser => {
        if (currentUser) {
          return of(currentUser);
        } else {
          this.spinnerService.show('connexion');
          return of(this.sessionService.maybeFetchCurrentUserFromStorage());
        }
      }),
      map(user => {
        this.spinnerService.hide('connexion');
        if (user) { return true; }
        return this.router.createUrlTree(['login']);
      })
    );
  }
}
