import {Injectable} from '@angular/core';
import {AbstractResolver} from '../abstract-resolver';
import {
  PaginatedSimpleTeamsWithAgentsCount
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedSimpleTeamsWithAgentsCount';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {TeamService} from '../../services/team.service';
import {SmartSpinnerService} from '../../../shared/services/smart-spinner.service';
import {TeamSearchCriteria} from '../../../shared/models/entity/teams/team-search-criteria';
import {SearchCriteriaService} from '../../../shared/services/search-criteria.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {switchMap, take} from 'rxjs/operators';

@Injectable()
export class TeamsListResolver extends AbstractResolver<PaginatedSimpleTeamsWithAgentsCount> {

  DEFAULT_STARTING_PAGE: number = 0;
  DEFAULT_STEP: number = 10;
  private currentUser: CompleteCurrentUser;
  private teamsSearchCriteria = new TeamSearchCriteria();

  constructor(
    private teamService: TeamService,
    spinnerService: SmartSpinnerService,
    private searchCriteriaService: SearchCriteriaService) {
    super(spinnerService);
  }
  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedSimpleTeamsWithAgentsCount> {
    return this
      .searchCriteriaService
      .buildSearchCriteriaByQueriesFromRouteSnapshot(
        route,
        this.teamsSearchCriteria,
        {
          startingPage: this.DEFAULT_STARTING_PAGE,
          step: this.DEFAULT_STEP
        }
    ).pipe(
      take(1),
      switchMap(searchCriteria => {
        return this.teamService.find(searchCriteria);
      })
    );
  }

}
