import {PaginatedResult} from '../PaginatedResult';
import {Type} from 'class-transformer';
import {PaginatedMetadata} from '../PaginatedMetadata';
import {SimpleWorkspaceUserListElement} from '../../users/simple/SimpleWorkspaceUserListElement';

export class PaginatedSimpleWorkspaceUserListElements extends PaginatedResult {
  @Type(() => SimpleWorkspaceUserListElement)
  public result: Array<SimpleWorkspaceUserListElement>;

  public static emptyResult(): PaginatedSimpleWorkspaceUserListElements {
    const empty = new PaginatedSimpleWorkspaceUserListElements(new PaginatedMetadata(0, 0, 0));
    empty.result = [];
    return empty;
  }
}
