import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ServiceDeskLocale} from '../../../models/entity/enums/ServiceDeskLocale';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'm-select-locale',
  templateUrl: './m-select-locale.component.html',
  styleUrls: ['./m-select-locale.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MSelectLocaleComponent),
      multi: true
    }
  ]
})
export class MSelectLocaleComponent implements OnInit, ControlValueAccessor {

  @Input()
  required: boolean;

  @Input()
  clearable: boolean;

  selectLocaleControl = new FormControl();
  serviceDeskLocaleKeys = Object.keys(ServiceDeskLocale);
  disabled: boolean;

  onChange = (newValue: ServiceDeskLocale) => {};
  onTouched = () => {};

  constructor() {
    this.selectLocaleControl.valueChanges.subscribe(newValue => {
        this.onChange(newValue);
    });
  }

  setPristine(): void {
    this.selectLocaleControl.markAsPristine();
  }

  setUntouched(): void {
    this.selectLocaleControl.markAsUntouched();
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: ServiceDeskLocale): void {
    if (!this.disabled) {
      this.selectLocaleControl.setValue(obj);
    }
  }
}
